import * as FeatherIcon from 'react-feather';
import { ISubMenu } from '../../../interfaces/menu';
import { PermissaoCodigo } from '../../enums/permissao-codigo.enum';
import { ProgramaCodigo } from '../../enums/programa-codigo.enum';

export const CadastrosLocalidades: ISubMenu = {
  name: 'Localidades',
  icon: FeatherIcon.MapPin,
  children: [
    {
      programaCod: ProgramaCodigo.Cidade,
      name: 'Cidades',
      path: '/cidades/listar',
      component: 'listarCidades',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/cidades/listar',
          component: 'listarCidades',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/cidades/criar',
          component: 'cidades',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/cidades/editar/:id',
          component: 'cidades',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/cidades/visualizar/:id',
          component: 'cidades',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.Estado,
      name: 'Estados',
      path: '/estados/listar',
      component: 'listarEstados',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/estados/listar',
          component: 'listarEstados',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/estados/criar',
          component: 'estados',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/estados/editar/:id',
          component: 'estados',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/estados/visualizar/:id',
          component: 'estados',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.Pais,
      name: 'Países',
      path: '/paises/listar',
      component: 'listarPaises',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/paises/listar',
          component: 'listarPaises',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/paises/criar',
          component: 'paises',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/paises/editar/:id',
          component: 'paises',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/paises/visualizar/:id',
          component: 'paises',
        },
      ],
    },
  ],
};
