import {
  GET_TABLE_PREFERENCE,
  SET_TABLE_ORDER,
  SET_TABLE_ORDER_ASYNC,
  SET_TABLE_PREFERENCE,
  SET_TABLE_PREFERENCE_ASYNC,
  PREFERENCE_READY,
  SET_LIMIT_PAGE,
  SET_LIMIT_PAGE_ASYNC,
  SET_CAMPO_BUSCA_PADRAO,
  SET_CAMPO_BUSCA_PADRAO_ASYNC,
  SET_PAGE,
} from './constants';

export const getTablePreferences = (programId: number) => ({
  type: GET_TABLE_PREFERENCE,
  payload: programId,
});

export const setTablePreferences = (columns) => ({
  type: SET_TABLE_PREFERENCE,
  payload: columns,
});

export const setTableOrder = (programaId, order) => {
  return {
    type: SET_TABLE_ORDER,
    payload: { programaId, order },
  };
};

export const setTableOrderAsync = (programaId, order) => ({
  type: SET_TABLE_ORDER_ASYNC,
  payload: { programaId, order },
});

export const setTablePreferencesAsync = (columns) => ({
  type: SET_TABLE_PREFERENCE_ASYNC,
  payload: columns,
});

export const setPreferencesReady = () => ({
  type: PREFERENCE_READY,
});

export const setTablePage = (programaId, page) => ({
  type: SET_PAGE,
  payload: { programaId, page },
});

export const setLimitPageTable = (programaId, limit, page) => ({
  type: SET_LIMIT_PAGE,
  payload: { programaId, limit, page },
});

export const setLimitPageTableAsync = (programaId, limit, page) => ({
  type: SET_LIMIT_PAGE_ASYNC,
  payload: { programaId, limit, page },
});

export const setCampoBuscaPadrao = (programaId: number, campo: string) => ({
  type: SET_CAMPO_BUSCA_PADRAO,
  payload: { programaId, campo },
});

export const setCampoBuscaPadraoAsync = (programaId: number, campo: string) => ({
  type: SET_CAMPO_BUSCA_PADRAO_ASYNC,
  payload: { programaId, campo },
});
