import Swal from 'sweetalert2';
import { filtroPadraoStatus } from '../../../constants/filtros';
import {
  FILTRAR_DEPOSITOS,
  GERAR_RELATORIO_DEPOSITOS,
  GERAR_RELATORIO_DEPOSITOS_ERRO,
  GERAR_RELATORIO_DEPOSITOS_SUCESSO,
  LISTAR_DEPOSITOS,
  LISTAR_DEPOSITOS_ERRO,
  LISTAR_DEPOSITOS_SUCESSO,
} from './constants';

const INIT_STATE = {
  depositos: [],
  filtros: { ...filtroPadraoStatus },
  carregando: false,
  relatorio: {},
  gerandoRelatorio: false,
};

const Depositos = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LISTAR_DEPOSITOS:
      return { ...state, carregando: true };
    case FILTRAR_DEPOSITOS:
      return { ...state, filtros: action.payload, carregando: true };
    case LISTAR_DEPOSITOS_SUCESSO:
      return { ...state, depositos: action.payload, carregando: false };
    case LISTAR_DEPOSITOS_ERRO:
      Swal.fire({
        icon: 'error',
        title: 'Erro ao buscar depósitos',
        timer: 2000,
        text: action.payload,
      });
      return { ...state, depositos: [], carregando: false };
    case GERAR_RELATORIO_DEPOSITOS:
      return { ...state, filtros: action.payload.filtros, relatorio: action.payload.relatorio, gerandoRelatorio: true };
    case GERAR_RELATORIO_DEPOSITOS_SUCESSO:
      if (action.payload) {
        Swal.fire({
          icon: 'success',
          title: action.payload.titulo,
          timer: action?.payload?.timer || 2000,
          text: action.payload.mensagem,
        });
      }
      return { ...state, gerandoRelatorio: false };
    case GERAR_RELATORIO_DEPOSITOS_ERRO:
      Swal.fire({
        icon: 'error',
        title: 'Falha ao gerar Relatório',
        timer: 2000,
        text: action.payload,
      });
      return { ...state, gerandoRelatorio: false };
    default:
      return state;
  }
};

export default Depositos;
