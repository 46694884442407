import api from '../../services/api';
import { AxiosResponse } from 'axios';
import { IMotivoCancelamentoContratoCadastro, IMotivoCancelamentoContratoConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterMotivosCancelamentosContratosAsync = (
  query,
): Promise<
  AxiosResponse<{ result: IMotivoCancelamentoContratoConsulta[]; total: number }>
> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `motivos-cancelamentos-contratos${queryStr}`,
    method: 'GET',
  });
};

export const obterMotivoCancelamentoContratoAsync = (
  id: number,
): Promise<AxiosResponse<IMotivoCancelamentoContratoConsulta>> => {
  return api.request({
    url: `motivos-cancelamentos-contratos/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoMotivoCancelamentoContratoAsync = (): Promise<
  AxiosResponse<number>
> => {
  return api.request({
    url: `motivos-cancelamentos-contratos/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarMotivosCancelamentosContratosAsync = (
  motivoCancelamentoContrato: IMotivoCancelamentoContratoCadastro,
): Promise<AxiosResponse<IMotivoCancelamentoContratoConsulta>> => {
  return api.request({
    url: `motivos-cancelamentos-contratos`,
    data: motivoCancelamentoContrato,
    method: 'POST',
  });
};

export const atualizarMotivosCancelamentosContratosAsync = (
  id: number,
  motivoCancelamentoContrato: IMotivoCancelamentoContratoCadastro,
): Promise<AxiosResponse<IMotivoCancelamentoContratoConsulta>> => {
  return api.request({
    url: `motivos-cancelamentos-contratos/${id}`,
    data: motivoCancelamentoContrato,
    method: 'PATCH',
  });
};

export const removerMotivosCancelamentosContratosEmMassaAsync = (
  ids: number[],
): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `motivos-cancelamentos-contratos`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerMotivosCancelamentosContratosAsync = (
  id: number,
): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `motivos-cancelamentos-contratos/${id}`,
    method: 'DELETE',
  });
