import api from '../../services/api';
import { montarQueryString } from '../../utils';
import { IUnidadeProdutivaCulturaConsulta } from './cadastro/unidades-produtivas-culturas/interfaces';
import { IUnidadeProdutivaConsulta, IUnidadeProdutivaCadastro } from './interfaces';

export const obterUnidadesProdutivasAsync = (query = {}) => {
  const querystr = montarQueryString(query);

  return api.request<{ result: IUnidadeProdutivaConsulta[]; total: number }>({
    url: `unidades-produtivas${querystr}`,
    method: 'GET',
  });
};

export const obterUnidadesProdutivasCulturasAsync = (query = {}) => {
  const querystr = montarQueryString(query);

  return api.request<{ result: IUnidadeProdutivaCulturaConsulta[]; total: number }>({
    url: `unidades-produtivas/culturas${querystr}`,
    method: 'GET',
  });
};

export const obterUnidadeProdutivaAsync = (id: number) => {
  return api.request<IUnidadeProdutivaConsulta>({
    url: `unidades-produtivas/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoUnidadeProdutivaAsync = () => {
  return api.request<number>({
    url: `unidades-produtivas/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarUnidadeProdutivaAsync = (unidadeProdutiva: IUnidadeProdutivaCadastro) => {
  return api.request<IUnidadeProdutivaConsulta>({
    url: `unidades-produtivas`,
    method: 'POST',
    data: unidadeProdutiva,
  });
};

export const atualizarUnidadeProdutivaAsync = (id: number, unidadeProdutiva: IUnidadeProdutivaCadastro) => {
  return api.request<IUnidadeProdutivaConsulta>({
    url: `unidades-produtivas/${id}`,
    method: 'PATCH',
    data: unidadeProdutiva,
  });
};

export const encerrarCulturaAsync = (unidadeProdutivaCulturaId: number) => {
  return api.request<IUnidadeProdutivaCulturaConsulta>({
    url: `unidades-produtivas/encerrar-cultura/${unidadeProdutivaCulturaId}`,
    method: 'PATCH',
  });
};

export const encerrarCulturasUnidadeProdutivaAsync = (unidadeProdutivaId: number) => {
  return api.request<IUnidadeProdutivaCulturaConsulta[]>({
    url: `unidades-produtivas/encerrar-culturas-unidade-produtiva/${unidadeProdutivaId}`,
    method: 'PATCH',
  });
};

export const removerUnidadesProdutivasEmMassaAsync = (ids: number[]) => {
  return api.request<{ affected: number }>({
    url: `unidades-produtivas`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerUnidadeProdutivaAsync = (id: number) =>
  api.request<{ affected: number }>({
    url: `unidades-produtivas/${id}`,
    method: 'DELETE',
  });
