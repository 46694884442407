import Swal from 'sweetalert2';
import { filtroPadraoStatus } from '../../../constants/filtros';
import {
  LISTAR_TRIBUTACOES_FEDERAIS_PIS_COFINS     ,    
  LISTAR_TRIBUTACOES_FEDERAIS_PIS_COFINS_ERRO   , 
  LISTAR_TRIBUTACOES_FEDERAIS_PIS_COFINS_SUCESSO ,
  FILTRAR_TRIBUTACOES_FEDERAIS_PIS_COFINS        ,
} from './constants';

const INIT_STATE = {
  tributacoesFederaisPisCofins: [],
  filtros: { ...filtroPadraoStatus },
  carregando: false,
};

const TributacaoFederalPisCofinsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LISTAR_TRIBUTACOES_FEDERAIS_PIS_COFINS:
      return { ...state, carregando: true };
    case FILTRAR_TRIBUTACOES_FEDERAIS_PIS_COFINS:
      return { ...state, filtros: action.payload, carregando: true };
    case LISTAR_TRIBUTACOES_FEDERAIS_PIS_COFINS_SUCESSO:
      return { ...state, tributacoesFederaisPisCofins: action.payload, carregando: false };
    case LISTAR_TRIBUTACOES_FEDERAIS_PIS_COFINS_ERRO:
      Swal.fire({
        icon: 'error',
        title: 'Erro ao buscar tributacao federal pis cofins',
        timer: 2000,
        text: action.payload,
      });
      return { ...state, tributacoesFederaisPisCofins: [], carregando: false };
    default:
      return state;
  }
};

export default TributacaoFederalPisCofinsReducer;
