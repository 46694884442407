import {
  LISTAR_SEGMENTOS_SUCESSO,
  GERAR_RELATORIO_SEGMENTOS,
  GERAR_RELATORIO_SEGMENTOS_ERRO,
  GERAR_RELATORIO_SEGMENTOS_SUCESSO,
  LISTAR_SEGMENTOS_ERRO,
  LISTAR_SEGMENTOS,
  FILTRAR_SEGMENTOS,
} from './constants';

export const obterTodosSegmentos = () => ({
  type: LISTAR_SEGMENTOS,
});

export const obterSegmentosSucesso = (segmento) => ({
  type: LISTAR_SEGMENTOS_SUCESSO,
  payload: segmento,
});

export const obterSegmentosErro = (mensagem) => ({
  type: LISTAR_SEGMENTOS_ERRO,
  payload: mensagem,
});

export const filtrarSegmentos = (f) => {
  return ({
    type: FILTRAR_SEGMENTOS,
    payload: f,
  })
};

export const gerarRelatorioSegmentos = (payload) => ({
  type: GERAR_RELATORIO_SEGMENTOS,
  payload: payload,
});

export const gerarRelatorioSegmentosSucesso = (alert) => ({
  type: GERAR_RELATORIO_SEGMENTOS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioSegmentosErro = (mensagem) => ({
  type: GERAR_RELATORIO_SEGMENTOS_ERRO,
  payload: mensagem,
});

