import {
  FILTRAR_TRIBUTACOES_ESTADUAIS,
  LISTAR_TRIBUTACOES_ESTADUAIS,
  LISTAR_TRIBUTACOES_ESTADUAIS_ERRO,
  LISTAR_TRIBUTACOES_ESTADUAIS_SUCESSO,
} from './constants';

export const obterTodosTributacoesEstaduais = () => ({
  type: LISTAR_TRIBUTACOES_ESTADUAIS,
});

export const obterTributacoesEstaduaisSucesso = (tributacoesEstaduais) => ({
  type: LISTAR_TRIBUTACOES_ESTADUAIS_SUCESSO,
  payload: tributacoesEstaduais,
});

export const obterTributacoesEstaduaisErro = (mensagem) => ({
  type: LISTAR_TRIBUTACOES_ESTADUAIS_ERRO,
  payload: mensagem,
});

export const filtrarTributacoesEstaduais = (filtros) => ({
  type: FILTRAR_TRIBUTACOES_ESTADUAIS,
  payload: filtros,
});
