import React, { useEffect, useState } from 'react';
import { Check, Edit } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, Col, Input, Row, UncontrolledTooltip } from 'reactstrap';
import { UploadImagemComSelfie } from '../../../components/UploadImagemComSelfie';
import { Alert } from '../../../helpers/Alert';
import { apiExceptionHandler } from '../../../helpers/exception-handler';
import { State } from '../../../redux/reducers';
import { updateUser } from '../../auth/store/actions';
import { atualizarUsuarioAsync, deletarAvatarUsuario, obterUsuarioAsync, uploadAvatarUsuario } from '../requests';
import {
  Botao,
  InfoBasicasUsuario,
  InfoTitulo,
  NomeUsuario,
  OrgUsuario,
  LiInfoUsuario,
  DivImagemUsuario,
} from './styled';

interface PerfilUsuarioProps {
  usuario;
  submit: (data: { nome: string; celular: string; email: string; avatar: File | null | undefined }) => void;
}

const Perfil: React.FC = () => {
  const usuarioLogado = useSelector((state: State) => state.Auth.user);
  const [usuario, setUsuario] = useState<any>();
  const dispatch = useDispatch();

  useEffect(() => {
    pegarDados();
    // eslint-disable-next-line
  }, []);

  const pegarDados = async () => {
    const dadosUsuario = await obterUsuarioAsync(usuarioLogado.id);
    setUsuario(dadosUsuario.data);
  };

  const submit = async (data) => {
    try {
      if (data.avatar && typeof data.avatar !== 'string') {
        await uploadAvatarUsuario(usuario.id, data.avatar);
      }

      if (usuario.id && !data.avatar) {
        await deletarAvatarUsuario(Number(usuario.id));
      }

      const { data: retorno } = await atualizarUsuarioAsync(usuario.id, data);

      await Alert({
        title: 'Perfil salvo com sucesso!',
        type: 'success',
      });

      const usuarioAtualizado = { ...usuario };
      usuarioAtualizado.nome = data.nome;
      usuarioAtualizado.email = data.email;
      usuarioAtualizado.celular = data.celular;
      usuarioAtualizado.avatar = retorno.avatar;
      setUsuario(usuarioAtualizado);

      // atualiza os dados do usuário no menu lateral
      dispatch(updateUser({ ...data, avatar: retorno.avatar }));
    } catch (err) {
      apiExceptionHandler(err);
    }
  };

  return <>{usuario ? <PerfilUsuario usuario={usuario} submit={(data) => submit(data)} /> : ''}</>;
};

const PerfilUsuario: React.FC<PerfilUsuarioProps> = ({ usuario, submit }) => {
  const [editarNome, setEditarNome] = useState<boolean>(false);
  const [editarEmail, setEditarEmail] = useState<boolean>(false);
  const [editarCelular, setEditarCelular] = useState<boolean>(false);

  const [nome, setNome] = useState<string>(usuario.nome);
  const [celular, setCelular] = useState<string>(usuario.celular);
  const [email, setEmail] = useState<string>(usuario.email);
  const [arquivoImagemUsuario, setArquivoImagemUsuario] = useState<File | undefined | null>(usuario.avatar);

  return (
    <Card style={{ marginTop: 95 }}>
      <CardBody>
        <Row>
          <Col lg={6}>
            <DivImagemUsuario>
              <UploadImagemComSelfie
                isReadOnly={false}
                savedUserImage={usuario.avatar}
                setUserImageFile={setArquivoImagemUsuario}
              />
            </DivImagemUsuario>
          </Col>

          <Col lg={6}>
            <InfoBasicasUsuario>
              <p style={{ display: 'inline' }}>
                {editarNome ? (
                  <>
                    <Input
                      defaultValue={nome}
                      type="text"
                      style={{
                        width: '100px',
                        display: 'inline',
                        fontWeight: 'bold',
                        fontSize: '2em',
                        height: '40px',
                      }}
                      onChange={(e) => setNome(e.target.value)}
                    ></Input>
                    <Botao id="btn_confirmar_nome">
                      <Check size={17} onClick={() => setEditarNome(!editarNome)} />
                    </Botao>
                    <UncontrolledTooltip target="btn_confirmar_nome">{'Confirmar'}</UncontrolledTooltip>
                  </>
                ) : (
                  <>
                    <NomeUsuario>{nome}</NomeUsuario>
                    <UncontrolledTooltip target="btn_editar_nome">{'Editar'}</UncontrolledTooltip>
                    <Botao id="btn_editar_nome">
                      <Edit size={17} onClick={() => setEditarNome(!editarNome)} />
                    </Botao>
                  </>
                )}
                <OrgUsuario>, {usuario?.organizacoes[0]?.organizacao?.nome ?? ''}.</OrgUsuario>
                {/* <EmpresaUsuario>, {usuario?.empresa?.razaoSocial ?? ''}.</EmpresaUsuario> */}
              </p>

              <ul style={{ padding: '0px' }}>
                <LiInfoUsuario>
                  <p>
                    <InfoTitulo>Login:</InfoTitulo> {usuario.login}
                  </p>
                </LiInfoUsuario>
                <LiInfoUsuario>
                  <p>
                    {editarEmail ? (
                      <>
                        <InfoTitulo>Email:</InfoTitulo>
                        <Input
                          defaultValue={email}
                          type="text"
                          style={{
                            width: '150px',
                            display: 'inline',
                            height: '25px',
                          }}
                          onChange={(e) => setEmail(e.target.value)}
                        ></Input>
                        <Botao id="btn_confirmar_email">
                          <Check size={17} onClick={() => setEditarEmail(!editarEmail)} />
                        </Botao>
                        <UncontrolledTooltip placement="right" target="btn_confirmar_email">
                          {'Confirmar'}
                        </UncontrolledTooltip>
                      </>
                    ) : (
                      <>
                        <InfoTitulo>Email:</InfoTitulo> {email}
                        <Botao id="btn_editar_email">
                          <Edit size={17} onClick={() => setEditarEmail(!editarEmail)} />
                        </Botao>
                        <UncontrolledTooltip placement="right" target="btn_editar_email">
                          {'Editar'}
                        </UncontrolledTooltip>
                      </>
                    )}
                  </p>
                </LiInfoUsuario>

                <LiInfoUsuario style={{ marginTop: '-5px' }}>
                  <p>
                    {editarCelular ? (
                      <>
                        <InfoTitulo>Celular:</InfoTitulo>
                        <Input
                          defaultValue={celular}
                          type="text"
                          style={{
                            width: '150px',
                            display: 'inline',
                            height: '25px',
                          }}
                          onChange={(e) => setCelular(e.target.value)}
                        ></Input>
                        <Botao id="btn_confirmar_celular">
                          <Check
                            size={17}
                            onClick={() => {
                              setEditarCelular(!editarCelular);
                            }}
                          />
                        </Botao>
                        <UncontrolledTooltip placement="right" target="btn_confirmar_celular">
                          {'Confirmar'}
                        </UncontrolledTooltip>
                      </>
                    ) : (
                      <>
                        <InfoTitulo>Celular:</InfoTitulo> {celular}
                        <Botao id="btn_editar_celular">
                          <Edit size={17} onClick={() => setEditarCelular(!editarCelular)} />
                        </Botao>
                        <UncontrolledTooltip placement="right" target="btn_editar_celular">
                          {'Editar'}
                        </UncontrolledTooltip>
                      </>
                    )}
                  </p>
                </LiInfoUsuario>

                {usuario.roles?.map((role, index) => (
                  <LiInfoUsuario key={index}>
                    <p>{role.name}</p>
                  </LiInfoUsuario>
                ))}

                <br />

                <LiInfoUsuario>
                  <Button
                    onClick={() => submit({ nome: nome, email: email, celular: celular, avatar: arquivoImagemUsuario })}
                    disabled={editarNome || editarEmail || editarCelular}
                  >
                    Confirmar
                  </Button>
                </LiInfoUsuario>
              </ul>
            </InfoBasicasUsuario>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default Perfil;
