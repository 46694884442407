import {
  FILTRAR_NCMS,
  GERAR_RELATORIO_NCMS,
  GERAR_RELATORIO_NCMS_ERRO,
  GERAR_RELATORIO_NCMS_SUCESSO,
  LISTAR_NCMS,
  LISTAR_NCMS_ERRO,
  LISTAR_NCMS_SUCESSO,
} from './constants';

export const obterTodosNCMs = () => ({
  type: LISTAR_NCMS,
});

export const obterNCMsSucesso = (ncm) => ({
  type: LISTAR_NCMS_SUCESSO,
  payload: ncm,
});

export const obterNCMsErro = (mensagem) => ({
  type: LISTAR_NCMS_ERRO,
  payload: mensagem,
});

export const filtrarNCMs = (filtros) => ({
  type: FILTRAR_NCMS,
  payload: filtros,
});

export const gerarRelatorioNCMs = (payload) => ({
  type: GERAR_RELATORIO_NCMS,
  payload: payload,
});

export const gerarRelatorioNCMsSucesso = (alert) => ({
  type: GERAR_RELATORIO_NCMS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioNCMsErro = (mensagem) => ({
  type: GERAR_RELATORIO_NCMS_ERRO,
  payload: mensagem,
});