import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { ProgramaCodigo } from '../../../constants/enums/programa-codigo.enum';
import { SET_LIMIT_PAGE, SET_TABLE_ORDER } from '../../../redux/table/constants';
import {
  FILTRAR_OCORRENCIAS_FINANCEIRAS,
  GERAR_RELATORIO_OCORRENCIAS_FINANCEIRAS,
  LISTAR_OCORRENCIAS_FINANCEIRAS,
} from './constants';
import { obterOcorrenciasFinanceirasAsync } from '../requests';
import {
  gerarRelatorioOcorrenciasFinanceirasErro,
  gerarRelatorioOcorrenciasFinanceirasSucesso,
  obterOcorrenciasFinanceirasErro,
  obterOcorrenciasFinanceirasSucesso,
} from './actions';
import { montarFiltroParaApi } from '../../../utils/api-filter';
import { DEFAULT_DOWNLOAD_SUCCESS_MESSAGE, gerarRelatorioRacing } from '../../../services/requests/relatorio';

function* listarTodosOcorrenciasFinanceiras() {
  try {
    const filtro = yield select((state) => state.OcorrenciasFinanceiras.filtros);
    const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.OcorrenciasFinanceiras]);

    let query = { filtro: montarFiltroParaApi(filtro) };

    if (preferencias) {
      query.ordenacao = {
        campo: preferencias?.ordenarPor || 'nome',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      };
      query.paginacao = {
        pagina: preferencias?.page || 1,
        limite: preferencias?.limit || 50,
      };
    }

    const { data } = yield call(obterOcorrenciasFinanceirasAsync, query);
    yield put(obterOcorrenciasFinanceirasSucesso(data));
  } catch (error) {
    yield put(obterOcorrenciasFinanceirasErro(error.response?.data?.message || error.message));
  }
}

function* relatorioOcorrenciasFinanceiras() {
  const { filtros, relatorio } = yield select((state) => state.OcorrenciasFinanceiras);
  const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.OcorrenciasFinanceiras]);
  const { modelo, tipo, comFiltro, colunas } = relatorio;
  const columns = colunas?.map((coluna) => coluna.value);
  let query = {};

  if (comFiltro) {
    query = {
      filtro: {
        ...montarFiltroParaApi(filtros),
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
      ordenacao: {
        campo: preferencias?.ordenarPor || 'nome',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      },
    };
  } else {
    query = {
      filtro: {
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
    };
  }

  try {
    const { data, headers } = yield call(gerarRelatorioRacing, ProgramaCodigo.OcorrenciasFinanceiras, query, tipo);

    if (headers['content-type'].includes('application/json')) {
      yield put(
        gerarRelatorioOcorrenciasFinanceirasSucesso({
          titulo: 'Pedido de impressão salvo.',
          mensagem: 'Seu relatório está sendo gerado.',
        }),
      );
    } else {
      const url = URL.createObjectURL(
        new Blob([data], {
          type: headers['content-type'] || 'application/pdf',
        }),
      );

      if (url) {
        yield put(gerarRelatorioOcorrenciasFinanceirasSucesso(DEFAULT_DOWNLOAD_SUCCESS_MESSAGE));
        window.open(url, '_blank');
      }
    }
  } catch (e) {
    yield put(
      gerarRelatorioOcorrenciasFinanceirasErro(
        'Ocorreu um erro ao tentar gerar o relatório. Tente novamente mais tarde.',
      ),
    );
  }
}

function* ordenarOcorrenciasFinanceiras({ payload }) {
  if (payload.programaId === ProgramaCodigo.OcorrenciasFinanceiras) {
    yield call(listarTodosOcorrenciasFinanceiras);
  }
}

function* mudarLimitePaginacao({ payload }) {
  if (payload.programaId === ProgramaCodigo.OcorrenciasFinanceiras) {
    yield call(listarTodosOcorrenciasFinanceiras);
  }
}

export function* watchListarOcorrenciasFinanceiras() {
  yield takeLatest(LISTAR_OCORRENCIAS_FINANCEIRAS, listarTodosOcorrenciasFinanceiras);
}

export function* watchFiltrarOcorrenciasFinanceiras() {
  yield takeLatest(FILTRAR_OCORRENCIAS_FINANCEIRAS, listarTodosOcorrenciasFinanceiras);
}

export function* watchRelatorioOcorrenciasFinanceiras() {
  yield takeLatest(GERAR_RELATORIO_OCORRENCIAS_FINANCEIRAS, relatorioOcorrenciasFinanceiras);
}

export function* watchOrdenarOcorrenciasFinanceiras() {
  yield takeLatest(SET_TABLE_ORDER, ordenarOcorrenciasFinanceiras);
}

export function* watchLimitePaginacao() {
  yield takeLatest(SET_LIMIT_PAGE, mudarLimitePaginacao);
}

function* ocorrenciasFinanceirasSaga() {
  yield all([
    fork(watchListarOcorrenciasFinanceiras),
    fork(watchFiltrarOcorrenciasFinanceiras),
    fork(watchRelatorioOcorrenciasFinanceiras),
    fork(watchOrdenarOcorrenciasFinanceiras),
    fork(watchLimitePaginacao),
  ]);
}

export default ocorrenciasFinanceirasSaga;
