import * as FeatherIcon from 'react-feather';
import { ISubMenu } from '../../../interfaces/menu';
import { PermissaoCodigo } from '../../enums/permissao-codigo.enum';
import { ProgramaCodigo } from '../../enums/programa-codigo.enum';

export const CadastrosPessoas: ISubMenu = {
  name: 'Pessoas',
  icon: FeatherIcon.UserX,
  children: [
    {
      programaCod: ProgramaCodigo.Pessoas,
      name: 'Pessoas',
      path: '/pessoas/listar',
      style: {
        fontWeight: 'bold',
      },
      component: 'pessoasLista',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/pessoas/listar',
          component: 'pessoasLista',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/pessoas/criar',
          component: 'pessoasCadastro',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/pessoas/editar/:id',
          component: 'pessoasCadastro',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/pessoas/visualizar/:id',
          component: 'pessoasCadastro',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.GruposEconomicosPessoas,
      name: 'Grupos Econômicos de Pessoas',
      path: '/grupos-economicos-pessoas/listar',
      component: 'listarGruposEconomicosPessoas',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/grupos-economicos-pessoas/listar',
          component: 'listarGruposEconomicosPessoas',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/grupos-economicos-pessoas/criar',
          component: 'gruposEconomicosPessoas',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/grupos-economicos-pessoas/editar/:id',
          component: 'gruposEconomicosPessoas',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/grupos-economicos-pessoas/visualizar/:id',
          component: 'gruposEconomicosPessoas',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.GruposPessoas,
      name: 'Grupos de Pessoas',
      path: '/grupos-pessoas/listar',
      component: 'listarGruposPessoas',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/grupos-pessoas/listar',
          component: 'listarGruposPessoas',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/grupos-pessoas/criar',
          component: 'gruposPessoas',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/grupos-pessoas/editar/:id',
          component: 'gruposPessoas',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/grupos-pessoas/visualizar/:id',
          component: 'gruposPessoas',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.OpcoesCadastrais,
      name: 'Opções Cadastrais',
      path: '/opcoes-cadastrais/listar',
      component: 'listarOpcoesCadastrais',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/opcoes-cadastrais/listar',
          component: 'listarOpcoesCadastrais',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/opcoes-cadastrais/criar',
          component: 'opcoesCadastrais',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/opcoes-cadastrais/editar/:id',
          component: 'opcoesCadastrais',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/opcoes-cadastrais/visualizar/:id',
          component: 'opcoesCadastrais',
        },
      ],
    },
  ],
};
