import {
  FILTRAR_NOTAS_FISCAIS_SERVICOS,
  GERAR_RELATORIO_NOTAS_FISCAIS_SERVICOS,
  GERAR_RELATORIO_NOTAS_FISCAIS_SERVICOS_ERRO,
  GERAR_RELATORIO_NOTAS_FISCAIS_SERVICOS_SUCESSO,
  LISTAR_NOTAS_FISCAIS_SERVICOS,
  LISTAR_NOTAS_FISCAIS_SERVICOS_ERRO,
  LISTAR_NOTAS_FISCAIS_SERVICOS_SUCESSO,
} from './constants';

export const obterTodosNotasFiscaisServicos = () => ({
  type: LISTAR_NOTAS_FISCAIS_SERVICOS,
});

export const obterNotasFiscaisServicosSucesso = (notasFiscaisServicos) => ({
  type: LISTAR_NOTAS_FISCAIS_SERVICOS_SUCESSO,
  payload: notasFiscaisServicos,
});

export const obterNotasFiscaisServicosErro = (mensagem) => ({
  type: LISTAR_NOTAS_FISCAIS_SERVICOS_ERRO,
  payload: mensagem,
});

export const filtrarNotasFiscaisServicos = (filtros) => ({
  type: FILTRAR_NOTAS_FISCAIS_SERVICOS,
  payload: filtros,
});
export const gerarRelatorioNotasFiscaisServicos = (payload) => ({
  type: GERAR_RELATORIO_NOTAS_FISCAIS_SERVICOS,
  payload: payload,
});

export const gerarRelatorioNotasFiscaisServicosSucesso = (alert) => ({
  type: GERAR_RELATORIO_NOTAS_FISCAIS_SERVICOS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioNotasFiscaisServicosErro = (mensagem) => ({
  type: GERAR_RELATORIO_NOTAS_FISCAIS_SERVICOS_ERRO,
  payload: mensagem,
});
