import { AxiosResponse } from 'axios';
import api from '../../services/api';
import { montarQueryString } from '../../utils';
import { IVariedadeCadastro, IVariedadeConsulta } from './interfaces';

export const obterVariedadesAsync = (
  query,
): Promise<AxiosResponse<{ result: IVariedadeConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `variedades${queryStr}`,
    method: 'GET',
  });
};

export const obterVariedadeAsync = (id: number): Promise<AxiosResponse<IVariedadeConsulta>> => {
  return api.request({
    url: `variedades/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoVariedadeAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `variedades/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarVariedadeAsync = (variedade: IVariedadeCadastro): Promise<AxiosResponse<IVariedadeConsulta>> => {
  return api.request({
    url: `variedades`,
    data: variedade,
    method: 'POST',
  });
};

export const uploadImagemVariedadeAsync = (id: number, imagem: File) => {
  const formData = new FormData();
  formData.append('imagem', imagem);

  return api.request({
    url: `/variedades/${id}/upload`,
    data: formData,
    method: 'POST',
  });
};

export const deletarImagemVariedadeAsync = (id: number) => {
  return api.request({
    url: `/variedades/${id}/deletar-imagem`,
    method: 'PATCH',
  });
};

export const atualizarVariedadeAsync = (
  id: number,
  variedade: IVariedadeCadastro,
): Promise<AxiosResponse<IVariedadeConsulta>> => {
  return api.request({
    url: `variedades/${id}`,
    data: variedade,
    method: 'PATCH',
  });
};

export const removerVariedadeEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `variedades`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerVariedadeAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `variedades/${id}`,
    method: 'DELETE',
  });
