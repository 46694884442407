import { AxiosResponse } from 'axios';

import api from '../../services/api';
import { ISistemaProducaoCadastro, ISistemaProducaoConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterSistemasProducaoAsync = (
  query
): Promise<AxiosResponse<{ result: ISistemaProducaoConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `sistemas-producao${queryStr}`,
    method: 'GET',
  });
};

export const obterSistemaProducaoAsync = (id: number): Promise<AxiosResponse<ISistemaProducaoConsulta>> => {
  return api.request({
    url: `sistemas-producao/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoSistemaProducaoAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `sistemas-producao/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarSistemaProducaoAsync = (
  sistemaProducao: ISistemaProducaoCadastro
): Promise<AxiosResponse<ISistemaProducaoConsulta>> => {
  return api.request({
    url: `sistemas-producao`,
    data: sistemaProducao,
    method: 'POST',
  });
};

export const atualizarSistemaProducaoAsync = (
  id: number,
  sistemaProducao: ISistemaProducaoCadastro
): Promise<AxiosResponse<ISistemaProducaoConsulta>> => {
  return api.request({
    url: `sistemas-producao/${id}`,
    data: sistemaProducao,
    method: 'PATCH',
  });
};

export const removerSistemasProducaoEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `sistemas-producao`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerSistemaProducaoAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `sistemas-producao/${id}`,
    method: 'DELETE',
  });
