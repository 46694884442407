import { AxiosResponse } from 'axios';

import api from '../../services/api';
import { IPaisCadastro, IPaisConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterPaisesAsync = (query = {}): Promise<AxiosResponse<{ result: IPaisConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `pais${queryStr}`,
    method: 'GET',
  });
};

export const obterPaisAsync = (id: number): Promise<AxiosResponse<IPaisConsulta>> => {
  return api.request({
    url: `pais/${id}`,
    method: 'GET',
  });
};

export const criarPaisAsync = (pais: IPaisCadastro): Promise<AxiosResponse<IPaisConsulta>> => {
  return api.request({
    url: `pais`,
    data: pais,
    method: 'POST',
  });
};

export const atualizarPaisAsync = (id: number, pais: IPaisCadastro): Promise<AxiosResponse<IPaisConsulta>> => {
  return api.request({
    url: `pais/${id}`,
    data: pais,
    method: 'PATCH',
  });
};

export const removerPaisesEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `pais`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerPaisAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `pais/${id}`,
    method: 'DELETE',
  });
