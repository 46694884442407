import {
  FILTRAR_GRUPO_PRODUTO,
  GERAR_RELATORIO_GRUPO_PRODUTO,
  GERAR_RELATORIO_GRUPO_PRODUTO_ERRO,
  GERAR_RELATORIO_GRUPO_PRODUTO_SUCESSO,
  LISTAR_GRUPO_PRODUTO,
  LISTAR_GRUPO_PRODUTO_ERRO,
  LISTAR_GRUPO_PRODUTO_SUCESSO,
} from './constants';

export const obterTodosGrupoProduto = () => ({
  type: LISTAR_GRUPO_PRODUTO,
});

export const obterGrupoProdutoSucesso = (GrupoProduto) => ({
  type: LISTAR_GRUPO_PRODUTO_SUCESSO,
  payload: GrupoProduto,
});

export const obterGrupoProdutoErro = (mensagem) => ({
  type: LISTAR_GRUPO_PRODUTO_ERRO,
  payload: mensagem,
});

export const filtrarGrupoProduto = (filtros) => ({
  type: FILTRAR_GRUPO_PRODUTO,
  payload: filtros,
});

export const gerarRelatorioGrupoProduto = (payload) => ({
  type: GERAR_RELATORIO_GRUPO_PRODUTO,
  payload: payload,
});

export const gerarRelatorioGrupoProdutoSucesso = (alert) => ({
  type: GERAR_RELATORIO_GRUPO_PRODUTO_SUCESSO,
  payload: alert,
});

export const gerarRelatorioGrupoProdutoErro = (mensagem) => ({
  type: GERAR_RELATORIO_GRUPO_PRODUTO_ERRO,
  payload: mensagem,
});