import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { ProgramaCodigo } from '../../../constants/enums/programa-codigo.enum';
import { SET_LIMIT_PAGE, SET_TABLE_ORDER } from '../../../redux/table/constants';
import { montarFiltroParaApi } from '../../../utils/api-filter';
import { obterModelosRelatoriosAsync } from '../requests';
import { obterModelosRelatoriosErro, obterModelosRelatoriosSucesso } from './actions';
import { FILTRAR_MODELOS_RELATORIO, LISTAR_MODELOS_RELATORIO } from './constants';

function* listarTodosModelosRelatorios() {
  try {
    const filtro = yield select((state) => state.ModelosRelatorios.filtros);
    const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.ModelosRelatorios]);

    let query = { filtro: montarFiltroParaApi(filtro) };

    if (preferencias) {
      query.ordenacao = {
        campo: preferencias?.ordenarPor || 'nome',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      };
      query.paginacao = {
        pagina: preferencias?.page || 1,
        limite: preferencias?.limit || 50,
      };
    }

    const { data } = yield call(obterModelosRelatoriosAsync, query);
    yield put(obterModelosRelatoriosSucesso(data));
  } catch (error) {
    yield put(obterModelosRelatoriosErro(error.response?.data?.message || error.message));
  }
}

function* ordenarModelosRelatorios({ payload }) {
  if (payload.programaId === ProgramaCodigo.ModelosRelatorios) {
    yield call(listarTodosModelosRelatorios);
  }
}

function* mudarLimitePaginacao({ payload }) {
  if (payload.programaId === ProgramaCodigo.ModelosRelatorios) {
    yield call(listarTodosModelosRelatorios);
  }
}

export function* watchListarModelosRelatorios() {
  yield takeLatest(LISTAR_MODELOS_RELATORIO, listarTodosModelosRelatorios);
}

export function* watchFiltrarModelosRelatorios() {
  yield takeLatest(FILTRAR_MODELOS_RELATORIO, listarTodosModelosRelatorios);
}

export function* watchOrdenarModelosRelatorios() {
  yield takeLatest(SET_TABLE_ORDER, ordenarModelosRelatorios);
}

export function* watchLimitePaginacao() {
  yield takeLatest(SET_LIMIT_PAGE, mudarLimitePaginacao);
}

function* modelosRelatoriosSaga() {
  yield all([
    fork(watchListarModelosRelatorios),
    fork(watchFiltrarModelosRelatorios),
    fork(watchOrdenarModelosRelatorios),
    fork(watchLimitePaginacao),
  ]);
}

export default modelosRelatoriosSaga;
