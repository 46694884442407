import {
  FILTRAR_SUB_GRUPOS_FINANCEIROS,
  GERAR_RELATORIO_SUB_GRUPOS_FINANCEIROS,
  GERAR_RELATORIO_SUB_GRUPOS_FINANCEIROS_ERRO,
  GERAR_RELATORIO_SUB_GRUPOS_FINANCEIROS_SUCESSO,
  LISTAR_SUB_GRUPOS_FINANCEIROS,
  LISTAR_SUB_GRUPOS_FINANCEIROS_ERRO,
  LISTAR_SUB_GRUPOS_FINANCEIROS_SUCESSO,
} from './constants';

export const obterTodosSubGruposFinanceiros = () => ({
  type: LISTAR_SUB_GRUPOS_FINANCEIROS,
});

export const obterSubGruposFinanceirosSucesso = (subGruposFinanceiros) => ({
  type: LISTAR_SUB_GRUPOS_FINANCEIROS_SUCESSO,
  payload: subGruposFinanceiros,
});

export const obterSubGruposFinanceirosErro = (mensagem) => ({
  type: LISTAR_SUB_GRUPOS_FINANCEIROS_ERRO,
  payload: mensagem,
});

export const filtrarSubGruposFinanceiros = (filtros) => ({
  type: FILTRAR_SUB_GRUPOS_FINANCEIROS,
  payload: filtros,
});

export const gerarRelatorioSubGruposFinanceiros = (payload) => ({
  type: GERAR_RELATORIO_SUB_GRUPOS_FINANCEIROS,
  payload: payload,
});

export const gerarRelatorioSubGruposFinanceirosSucesso = (alert) => ({
  type: GERAR_RELATORIO_SUB_GRUPOS_FINANCEIROS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioSubGruposFinanceirosErro = (mensagem) => ({
  type: GERAR_RELATORIO_SUB_GRUPOS_FINANCEIROS_ERRO,
  payload: mensagem,
});