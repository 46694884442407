import api from '../../services/api';
import { AxiosResponse } from 'axios';
import { IEntradaSaidaProdutoCadastro, IEntradaSaidaProdutoConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterEntradasSaidasProdutosAsync = (
  query,
): Promise<AxiosResponse<{ result: IEntradaSaidaProdutoConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);
  return api.request({
    url: `entradas-saidas-produtos${queryStr}`,
    method: 'GET',
  });
};

export const obterEntradaSaidaProdutoAsync = (id: number): Promise<AxiosResponse<IEntradaSaidaProdutoConsulta>> => {
  return api.request({
    url: `entradas-saidas-produtos/${id}`,
    method: 'GET',
  });
};

export const criarEntradasSaidasProdutosAsync = (
  entradaSaidaProduto: IEntradaSaidaProdutoCadastro,
): Promise<AxiosResponse<IEntradaSaidaProdutoConsulta>> => {
  return api.request({
    url: `entradas-saidas-produtos`,
    data: entradaSaidaProduto,
    method: 'POST',
  });
};

export const zerarEstoqueAsync = (dto: { data: string; depositoId: number }) => {
  return api.request<void>({
    url: `entradas-saidas-produtos/zerar-estoque`,
    data: dto,
    method: 'POST',
  });
};

export const atualizarEntradasSaidasProdutosAsync = (
  id: number,
  entradaSaidaProduto: IEntradaSaidaProdutoCadastro,
): Promise<AxiosResponse<IEntradaSaidaProdutoConsulta>> => {
  return api.request({
    url: `entradas-saidas-produtos/${id}`,
    data: entradaSaidaProduto,
    method: 'PATCH',
  });
};

export const removerEntradasSaidasProdutosEmMassaAsync = (
  ids: number[],
): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `entradas-saidas-produtos`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerEntradasSaidasProdutosAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `entradas-saidas-produtos/${id}`,
    method: 'DELETE',
  });
