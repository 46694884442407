import {
  FILTRAR_SETORES_MESAS,
  GERAR_RELATORIO_SETORES_MESAS,
  GERAR_RELATORIO_SETORES_MESAS_ERRO,
  GERAR_RELATORIO_SETORES_MESAS_SUCESSO,
  LISTAR_SETORES_MESAS,
  LISTAR_SETORES_MESAS_ERRO,
  LISTAR_SETORES_MESAS_SUCESSO,
} from './constants';

export const obterTodosSetoresMesas = () => ({
  type: LISTAR_SETORES_MESAS,
});

export const obterSetoresMesasSucesso = (setoresMesas) => ({
  type: LISTAR_SETORES_MESAS_SUCESSO,
  payload: setoresMesas,
});

export const obterSetoresMesasErro = (mensagem) => ({
  type: LISTAR_SETORES_MESAS_ERRO,
  payload: mensagem,
});

export const filtrarSetoresMesas = (filtros) => ({
  type: FILTRAR_SETORES_MESAS,
  payload: filtros,
});
export const gerarRelatorioSetoresMesas = (payload) => ({
  type: GERAR_RELATORIO_SETORES_MESAS,
  payload: payload,
});

export const gerarRelatorioSetoresMesasSucesso = (alert) => ({
  type: GERAR_RELATORIO_SETORES_MESAS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioSetoresMesasErro = (mensagem) => ({
  type: GERAR_RELATORIO_SETORES_MESAS_ERRO,
  payload: mensagem,
});