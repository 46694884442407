import { AxiosResponse } from 'axios';
import api from '../../services/api';
import { montarQueryString } from '../../utils';
import { IPermissaoConsulta } from './interfaces';

export const obterPermissaoAsync = (id: number): Promise<AxiosResponse<IPermissaoConsulta>> => {
  return api.request({
    url: `permissao/${id}`,
    method: 'GET',
  });
};

export const obterPermissoesAsync = (
  query = {}
): Promise<AxiosResponse<{ result: IPermissaoConsulta[]; total: number }>> => {
  const querystr = montarQueryString(query);
  return api.request({
    url: `permissao${querystr}`,
    method: 'GET',
  });
};
