import {
  FILTRAR_CONCEITOS_QUALIDADE,
  LISTAR_CONCEITOS_QUALIDADE,
  LISTAR_CONCEITOS_QUALIDADE_ERRO,
  LISTAR_CONCEITOS_QUALIDADE_SUCESSO,
} from './constants';

export const obterTodosConceitosQualidade = () => ({
  type: LISTAR_CONCEITOS_QUALIDADE,
});

export const obterConceitosQualidadeSucesso = (conceitosQualidade) => ({
  type: LISTAR_CONCEITOS_QUALIDADE_SUCESSO,
  payload: conceitosQualidade,
});

export const obterConceitosQualidadeErro = (mensagem) => ({
  type: LISTAR_CONCEITOS_QUALIDADE_ERRO,
  payload: mensagem,
});

export const filtrarConceitosQualidade = (filtros) => ({
  type: FILTRAR_CONCEITOS_QUALIDADE,
  payload: filtros,
});
