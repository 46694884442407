import React from 'react';
import { CampoFiltroAvancadoLista } from '../../components/PartialPages/Filtro';
import SelecaoAtividade, { ISelecaoAtividade } from '../../components/SelecaoAsync/SelecaoAtividade';
import SelecaoCultura from '../../components/SelecaoAsync/SelecaoCultura';
import SelecaoGrupoAtividade from '../../components/SelecaoAsync/SelecaoGrupoAtividade';
import SelecaoPropriedade from '../../components/SelecaoAsync/SelecaoPropriedades';
import SelecaoSafra from '../../components/SelecaoAsync/SelecaoSafra';
import SelecaoUnidadeProdutiva from '../../components/SelecaoAsync/SelecaoUnidadeProdutiva';
import SelecaoVariedadeClone from '../../components/SelecaoAsync/SelecaoVariedadeClone';
import SelecaoVariedades from '../../components/SelecaoAsync/SelecaoVariedades';
import { IOptions } from '../../components/Tabela';
import { TipoRelatorio } from '../../constants/enums/impressao.enum';
import { ProgramaCodigo } from '../../constants/enums/programa-codigo.enum';
import { filtrosPadroes } from '../../constants/filtros';
import { removerDuplicadosArray } from '../../helpers/remover-duplicados-array';
import { formatarDecimal } from '../../utils/format';
import { DateRangeInputValidator } from '../../utils/validators';
import { IRecomendacaoTecnicaMaquinaConsulta } from './cadastro/Maquinas/interfaces';
import BadgeStatus from './lista/BadgeStatus';
import SelecaoStatus from './lista/SelecaoStatus';

export const PROGRAMA_COD = ProgramaCodigo.RecomendacoesTecnicas;

export const camposTabela: IOptions[] = [
  { value: 'id', label: 'Id', type: 'string' },
  { value: 'dataCriacao', label: 'Criado em', type: 'dateTime', selecionado: false },
  { value: 'dataUltimaAlteracao', label: 'Última alteração', type: 'dateTime', selecionado: false },
  { value: 'dataInicial', label: 'Data Inicial', type: 'date' },
  { value: 'dataFinal', label: 'Data Final', type: 'date' },
  { value: 'atividade.nome', label: 'Atividade', type: 'string' },
  {
    value: 'propriedades',
    label: 'Propriedades',
    type: 'string',
    isSortble: false,
    render: ({ row }) => {
      return (
        removerDuplicadosArray(
          row?.original?.unidadesProdutivasCulturas?.map((uni) => uni.unidadeProdutiva.propriedade.nome),
        ).join(', ') || ''
      );
    },
  },
  {
    value: 'areaTotal',
    label: 'Área (ha/m²)',
    type: 'string',
    align: 'right',
    render: ({ value }) => formatarDecimal(value, 3),
  },
  {
    value: 'status',
    label: 'Status',
    type: 'string',
    render: ({ value }) => <BadgeStatus>{value}</BadgeStatus>,
  },
];

export const filtrosSimples = [
  { value: 'atividade.nome', label: 'Atividade' },
  { value: 'id', label: 'Id' },
  { value: 'propriedade.nome', label: 'Propriedade' },
  { value: 'safra.nome', label: 'Safra' },
];

export const filtrosAvancados: CampoFiltroAvancadoLista = [
  {
    agrupado: true,
    label: 'Filtros de id, códigos e datas',
    filtros: filtrosPadroes,
  },

  {
    name: 'propriedade.id',
    label: 'Propriedades',
    col: {
      md: 6,
      sm: 12,
    },
    type: 'text',
    customInputRender: (props) => <SelecaoPropriedade multiple filters={{ status: [0, 1] }} {...props} />,
    addFilterCheckbox: true,
  },
  {
    name: 'safra.id',
    label: 'Safras',
    col: {
      md: 6,
      sm: 12,
    },
    type: 'text',
    customInputRender: (props, values) => {
      const propriedadesIds = values['propriedade.id']?.map((propriedade) => propriedade.value);
      const culturasIds = values['cultura.id']?.map((cultura) => cultura.value);
      return (
        <SelecaoSafra
          multiple
          filters={{ status: [0, 1], propriedadesLancamento: propriedadesIds, culturasLancamento: culturasIds }}
          {...props}
        />
      );
    },
    addFilterCheckbox: true,
  },
  {
    name: 'unidadeProdutiva.id',
    label: 'Unidades Produtivas',
    col: {
      md: 6,
      sm: 12,
    },
    type: 'text',
    customInputRender: (props, values) => {
      const propriedadesIds = values['propriedade.id']?.map((propriedade) => propriedade.value);
      return (
        <SelecaoUnidadeProdutiva multiple filters={{ status: [0, 1], 'propriedade.id': propriedadesIds }} {...props} />
      );
    },
    addFilterCheckbox: true,
  },
  {
    name: 'cultura.id',
    label: 'Culturas',
    col: {
      md: 6,
      sm: 12,
    },
    type: 'text',
    customInputRender: (props) => <SelecaoCultura multiple filters={{ status: [0, 1] }} {...props} />,
    addFilterCheckbox: true,
  },
  {
    name: 'variedade.id',
    label: 'Variedades',
    col: {
      md: 6,
      sm: 12,
    },
    type: 'text',
    customInputRender: (props, values) => {
      const culturasIds = values['cultura.id']?.map((cultura) => cultura.value);
      return <SelecaoVariedades multiple filters={{ status: [0, 1], 'cultura.id': culturasIds }} {...props} />;
    },
    addFilterCheckbox: true,
  },
  {
    name: 'variedadeClone.id',
    label: 'Variedades Clone',
    col: {
      md: 6,
      sm: 12,
    },
    type: 'text',
    customInputRender: (props, values) => {
      const variedadesIds = values['variedade.id']?.map((variedade) => variedade.value);
      return <SelecaoVariedadeClone multiple filters={{ status: [0, 1], 'variedade.id': variedadesIds }} {...props} />;
    },
    addFilterCheckbox: true,
  },
  {
    name: 'grupoAtividade.id',
    label: 'Grupos de Atividade',
    col: {
      md: 6,
      sm: 12,
    },
    type: 'text',
    customInputRender: (props) => <SelecaoGrupoAtividade multiple filters={{ status: [0, 1] }} {...props} />,
    addFilterCheckbox: true,
  },
  {
    name: 'atividade.id',
    label: 'Atividades',
    col: {
      md: 6,
      sm: 12,
    },
    type: 'text',
    customInputRender: (props, values) => {
      const gruposAtividadesIds = values['grupoAtividade.id']?.map((grupoAtividade) => grupoAtividade.value);
      return (
        <SelecaoAtividade multiple filters={{ status: [0, 1], 'grupoAtividade.id': gruposAtividadesIds }} {...props} />
      );
    },
    addFilterCheckbox: true,
  },
  {
    name: 'statusRecomendacao',
    label: 'Status',
    col: {
      md: 12,
      sm: 12,
    },
    type: 'text',
    customInputRender: () => <SelecaoStatus multiple />,
    addFilterCheckbox: true,
  },
  {
    name: 'dataInicial',
    label: 'Data Inicial',
    col: {
      md: 6,
      sm: 12,
    },
    type: 'dataintervalo',
    rules: [DateRangeInputValidator],
  },
  {
    name: 'dataFinal',
    label: 'Data Final',
    col: {
      md: 6,
      sm: 12,
    },
    type: 'dataintervalo',
    rules: [DateRangeInputValidator],
  },
];

export const tipoRelatorio = [
  { value: TipoRelatorio.pdf, label: 'PDF' },
  { value: TipoRelatorio.csv, label: 'CSV' },
  { value: TipoRelatorio.xlsx, label: 'XLSX' },
];

export interface IRecomendacaoTecnicaContext {
  grupoAtividadeId: number;
  setGrupoAtividadeId: (id: number) => void;
  atividadeSelecionada: ISelecaoAtividade | undefined;
  controlesProdutividadeIds: number[] | undefined;
  setControlesProdutividadeIds: (ids: number[] | undefined) => void;
  propriedadesIds: number[];
  setPropriedadesIds: (ids: number[]) => void;
  culturasIds: number[];
  setCulturasIds: (ids: number[]) => void;
  visualizaVariedade: boolean;
  setVisualizaVariedade: (visualiza: boolean) => void;
  visualizaVariedadeClone: boolean;
  setVisualizaVariedadeClone: (visualiza: boolean) => void;
  areaTotal: number;
  setAreaTotal: (area: number) => void;
  maquinas: IRecomendacaoTecnicaMaquinaConsulta[];
  unidadesProdutivasCulturasIds: number[];
  setUnidadesProdutivasCulturasIds: (ids: number[]) => void;
}

export const RecomendacaoTecnicaContext = React.createContext<IRecomendacaoTecnicaContext>({
  grupoAtividadeId: 0,
  setGrupoAtividadeId: () => {},
  atividadeSelecionada: undefined,
  controlesProdutividadeIds: undefined,
  setControlesProdutividadeIds: () => {},
  propriedadesIds: [0],
  setPropriedadesIds: () => {},
  culturasIds: [0],
  setCulturasIds: () => {},
  visualizaVariedade: false,
  setVisualizaVariedade: () => {},
  visualizaVariedadeClone: false,
  setVisualizaVariedadeClone: () => {},
  areaTotal: 0,
  setAreaTotal: () => {},
  maquinas: [],
  unidadesProdutivasCulturasIds: [0],
  setUnidadesProdutivasCulturasIds: () => {},
});

export enum ModelosRelatorioRecomendacaoTecnica {
  Individual = 1,
  Analitico = 2,
}

export const camposTabelaRelatorio = {
  [ModelosRelatorioRecomendacaoTecnica.Analitico]: [
    { value: 'produto', label: 'Produto' },
    { value: 'alvos', label: 'Alvos' },
    { value: 'volumeCaldaTotal', label: 'Volume Calda Total' },
    { value: 'volumeCaldaHectares', label: 'Volume Calda ha/m²' },
    { value: 'doseHectares', label: 'Dose ha/m²' },
    { value: 'dose100Litros', label: 'Dose 100 Litros' },
    { value: 'quantidadeAplicada', label: 'Quant. Aplicada' },
  ],
};

export const modelosRelatorio = [{ value: ModelosRelatorioRecomendacaoTecnica.Analitico, label: 'Analítico' }];
