import {
  FILTRAR_CONTAS,
  GERAR_RELATORIO_CONTAS,
  GERAR_RELATORIO_CONTAS_ERRO,
  GERAR_RELATORIO_CONTAS_SUCESSO,
  LISTAR_CONTAS,
  LISTAR_CONTAS_ERRO,
  LISTAR_CONTAS_SUCESSO,
} from './constants';

export const obterTodosContas = () => ({
  type: LISTAR_CONTAS,
});

export const obterContasSucesso = (contas) => ({
  type: LISTAR_CONTAS_SUCESSO,
  payload: contas,
});

export const obterContasErro = (mensagem) => ({
  type: LISTAR_CONTAS_ERRO,
  payload: mensagem,
});

export const filtrarContas = (filtros) => ({
  type: FILTRAR_CONTAS,
  payload: filtros,
});

export const gerarRelatorioContas = (payload) => ({
  type: GERAR_RELATORIO_CONTAS,
  payload: payload,
});

export const gerarRelatorioContasSucesso = (alert) => ({
  type: GERAR_RELATORIO_CONTAS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioContasErro = (mensagem) => ({
  type: GERAR_RELATORIO_CONTAS_ERRO,
  payload: mensagem,
});