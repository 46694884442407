import { AxiosResponse } from 'axios';

import api from '../../services/api';
import { IPrincipioAtivoCadastro, IPrincipioAtivoConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterPrincipiosAtivosAsync = (
  query,
): Promise<AxiosResponse<{ result: IPrincipioAtivoConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  const data = api.request({
    url: `principios-ativo${queryStr}`,
    method: 'GET',
  });


  return data;

  
};

export const obterPrincipioAtivoAsync = (id: number): Promise<AxiosResponse<IPrincipioAtivoConsulta>> => {
  return api.request({
    url: `principios-ativo/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoPrincipioAtivoAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `principios-ativo/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarPrincipioAtivoAsync = (principioAtivo: IPrincipioAtivoCadastro): Promise<AxiosResponse<IPrincipioAtivoConsulta>> => {
  return api.request({
    url: `principios-ativo`,
    data: principioAtivo,
    method: 'POST',
  });
};

export const atualizarPrincipioAtivoAsync = (
  id: number,
  principioAtivo: IPrincipioAtivoCadastro,
): Promise<AxiosResponse<IPrincipioAtivoConsulta>> => {
  return api.request({
    url: `principios-ativo/${id}`,
    data: principioAtivo,
    method: 'PATCH',
  });
};

export const removerPrincipiosAtivosEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `principios-ativo`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerPrincipioAtivoAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `principios-ativo/${id}`,
    method: 'DELETE',
  });
