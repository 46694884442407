import { AxiosResponse } from 'axios';
import api from '../../services/api';
import { montarQueryString } from '../../utils';
import { IPromocaoCadastro, IPromocaoConsulta } from './interfaces';

export const obterPromocaoAsync = (id: number): Promise<AxiosResponse<IPromocaoConsulta>> => {
  return api.request({
    url: `promocoes/${id}`,
    method: 'GET',
  });
};

export const obterPromocoesAsync = (
  query = {},
): Promise<AxiosResponse<{ result: IPromocaoConsulta[]; total: number }>> => {
  const querystr = montarQueryString(query);
  return api.request({
    url: `promocoes${querystr}`,
    method: 'GET',
  });
};

export const criarPromocaoAsync = (promocao: IPromocaoCadastro) => {
  return api.request<IPromocaoConsulta>({
    url: `promocoes`,
    method: 'POST',
    data: promocao,
  });
};

export const atualizarPromocaoAsync = (id: number, promocao: IPromocaoCadastro) => {
  return api.request<IPromocaoConsulta>({
    url: `promocoes/${id}`,
    method: 'PATCH',
    data: promocao,
  });
};

export const removerPromocoesEmMassaAsync = (ids: number[]) => {
  return api.request<{ affected: number }>({
    url: `promocoes`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerPromocaoAsync = (id: number) => {
  return api.request<{ affected: number }>({
    url: `promocoes/${id}`,
    method: 'DELETE',
  });
};
