import {
  FILTRAR_PEDIDOS,
  GERAR_RELATORIO_PEDIDOS,
  GERAR_RELATORIO_PEDIDOS_ERRO,
  GERAR_RELATORIO_PEDIDOS_SUCESSO,
  LISTAR_PEDIDOS,
  LISTAR_PEDIDOS_ERRO,
  LISTAR_PEDIDOS_SUCESSO,
} from './constants';

export const obterTodosPedidos = () => ({
  type: LISTAR_PEDIDOS,
});

export const obterPedidosSucesso = (Pedidos) => ({
  type: LISTAR_PEDIDOS_SUCESSO,
  payload: Pedidos,
});

export const obterPedidosErro = (mensagem) => ({
  type: LISTAR_PEDIDOS_ERRO,
  payload: mensagem,
});

export const filtrarPedidos = (filtros) => ({
  type: FILTRAR_PEDIDOS,
  payload: filtros,
});

export const gerarRelatorioPedidos = (payload) => ({
  type: GERAR_RELATORIO_PEDIDOS,
  payload: payload,
});

export const gerarRelatorioPedidosSucesso = (alert) => ({
  type: GERAR_RELATORIO_PEDIDOS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioPedidosErro = (mensagem) => ({
  type: GERAR_RELATORIO_PEDIDOS_ERRO,
  payload: mensagem,
});
