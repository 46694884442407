import {
  FILTRAR_LISTAS_PRECOS,
  GERAR_RELATORIO_LISTAS_PRECOS,
  GERAR_RELATORIO_LISTAS_PRECOS_ERRO,
  GERAR_RELATORIO_LISTAS_PRECOS_SUCESSO,
  LISTAR_LISTAS_PRECOS,
  LISTAR_LISTAS_PRECOS_ERRO,
  LISTAR_LISTAS_PRECOS_SUCESSO,
} from './constants';

export const obterTodosListasPrecos = () => ({
  type: LISTAR_LISTAS_PRECOS,
});

export const obterListasPrecosSucesso = (segmento) => ({
  type: LISTAR_LISTAS_PRECOS_SUCESSO,
  payload: segmento,
});

export const obterListasPrecosErro = (mensagem) => ({
  type: LISTAR_LISTAS_PRECOS_ERRO,
  payload: mensagem,
});

export const filtrarListasPrecos = (f) => {
  return {
    type: FILTRAR_LISTAS_PRECOS,
    payload: f,
  };
};

export const gerarRelatorioListasPrecos = (payload) => ({
  type: GERAR_RELATORIO_LISTAS_PRECOS,
  payload: payload,
});

export const gerarRelatorioListasPrecosSucesso = (alert) => ({
  type: GERAR_RELATORIO_LISTAS_PRECOS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioListasPrecosErro = (mensagem) => ({
  type: GERAR_RELATORIO_LISTAS_PRECOS_ERRO,
  payload: mensagem,
});
