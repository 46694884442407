import {
  FILTRAR_RECOMENDACOES_TECNICAS,
  LISTAR_RECOMENDACOES_TECNICAS,
  LISTAR_RECOMENDACOES_TECNICAS_ERRO,
  LISTAR_RECOMENDACOES_TECNICAS_SUCESSO,
  GERAR_RELATORIO_RECOMENDACOES_TECNICAS,
  GERAR_RELATORIO_RECOMENDACOES_TECNICAS_SUCESSO,
  GERAR_RELATORIO_RECOMENDACOES_TECNICAS_ERRO,
  GERAR_IMPRESSAO_RECOMENDACOES_TECNICAS,
} from './constants';

export const obterTodosRecomendacoesTecnicas = () => ({
  type: LISTAR_RECOMENDACOES_TECNICAS,
});

export const obterRecomendacoesTecnicasSucesso = (recomendacoesTecnicas) => ({
  type: LISTAR_RECOMENDACOES_TECNICAS_SUCESSO,
  payload: recomendacoesTecnicas,
});

export const obterRecomendacoesTecnicasErro = (mensagem) => ({
  type: LISTAR_RECOMENDACOES_TECNICAS_ERRO,
  payload: mensagem,
});

export const filtrarRecomendacoesTecnicas = (filtros) => ({
  type: FILTRAR_RECOMENDACOES_TECNICAS,
  payload: filtros,
});

export const gerarRelatorioRecomendacoesTecnicas = (payload) => ({
  type: GERAR_RELATORIO_RECOMENDACOES_TECNICAS,
  payload: payload,
});

export const gerarRelatorioRecomendacoesTecnicasSucesso = (alert) => ({
  type: GERAR_RELATORIO_RECOMENDACOES_TECNICAS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioRecomendacoesTecnicasErro = (mensagem) => ({
  type: GERAR_RELATORIO_RECOMENDACOES_TECNICAS_ERRO,
  payload: mensagem,
});

export const gerarImpressaoRecomendacoesTecnicas = (payload) => ({
  type: GERAR_IMPRESSAO_RECOMENDACOES_TECNICAS,
  payload: payload,
});