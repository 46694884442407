import { 
  FILTRAR_SERVICO, 
  GERAR_RELATORIO_SERVICO,
  GERAR_RELATORIO_SERVICO_ERRO,
  GERAR_RELATORIO_SERVICO_SUCESSO,
  LISTAR_SERVICO, 
  LISTAR_SERVICO_ERRO, 
  LISTAR_SERVICO_SUCESSO } from './constants';

export const obterTodosServico = () => ({
  type: LISTAR_SERVICO,
});

export const obterServicoSucesso = (servicos) => ({
  type: LISTAR_SERVICO_SUCESSO,
  payload: servicos,
});

export const obterServicoErro = (mensagem) => ({
  type: LISTAR_SERVICO_ERRO,
  payload: mensagem,
});

export const filtrarServico = (filtros) => ({
  type: FILTRAR_SERVICO,
  payload: filtros,
});

export const gerarRelatorioServico = (payload) => ({
  type: GERAR_RELATORIO_SERVICO,
  payload: payload,
});

export const gerarRelatorioServicoSucesso = (alert) => ({
  type: GERAR_RELATORIO_SERVICO_SUCESSO,
  payload: alert,
});

export const gerarRelatorioServicoErro = (mensagem) => ({
  type: GERAR_RELATORIO_SERVICO_ERRO,
  payload: mensagem,
});