import api from '../../services/api';
import { montarQueryString } from '../../utils';
import { IVisitasTecnicasCadastro } from './interfaces';

export const obterVisitasTecnicasAsync = (query) => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `visitas-tecnicas${queryStr}`,
    method: 'GET',
  });
};

export const obterVisitaTecnicaAsync = (id: number) => {
  return api.request({
    url: `visitas-tecnicas/${id}`,
    method: 'GET',
  });
};

export const criarVisitaTecnicaAsync = (VisitaTecnica: IVisitasTecnicasCadastro) => {
  return api.request({
    url: `visitas-tecnicas`,
    data: VisitaTecnica,
    method: 'POST',
  });
};

export const atualizarVisitaTecnicaAsync = (id: number, VisitaTecnica: IVisitasTecnicasCadastro) => {
  return api.request({
    url: `visitas-tecnicas/${id}`,
    data: VisitaTecnica,
    method: 'PATCH',
  });
};

export const removerVisitasTecnicasEmMassaAsync = (ids: number[]) => {
  return api.request({
    url: `visitas-tecnicas`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerVisitaTecnicaAsync = (id: number) =>
  api.request({
    url: `visitas-tecnicas/${id}`,
    method: 'DELETE',
  });

export const uploadImagemVisitaTecnicaAsync = (id: number, imagem: File) => {
  const formData = new FormData();
  formData.append('imagem', imagem);

  return api.request({
    url: `visitas-tecnicas/${id}/upload`,
    data: formData,
    method: 'POST',
  });
};

export const uploadVideoVisitaTecnicaAsync = (id: number, video: File) => {
  const formData = new FormData();
  formData.append('video', video);

  return api.request({
    url: `visitas-tecnicas/${id}/upload-video`,
    data: formData,
    method: 'POST',
  });
};


export const deletarImagemVisitaTecnicaAsync = (id: number) => {
  return api.request({
    url: `visitas-tecnicas/${id}/deletar-imagem`,
    method: 'PATCH',
  });
};

export const deletarVideoVisitaTecnicaAsync = (id: number) => {
  return api.request({
    url: `visitas-tecnicas/${id}/deletar-video`,
    method: 'PATCH',
  });
};