import React from 'react';
import { obterProgramasAsync } from '../../pages/programas/requests';
import SelecaoAsyncBase, { SelectOption } from './SelecaoAsyncBase';

interface IFilters {
  status?: number[];
  paginacao?: boolean;
}

interface SelecaoProgramaOption {
  value?: SelectOption;
  onChange?: (value: SelectOption) => void;
  multiple?: boolean;
  filters?: IFilters;
  disabled?: boolean;
  isClearable?: boolean;
  menuPlacement?: string;
}

const SelecaoPrograma: React.FC<SelecaoProgramaOption> = ({ filters, disabled, isClearable, ...props }) => {
  const carregarProgramas = async (text) => {
    let busca = text;
    let buscarPor = isNaN(text) ? 'nome' : 'codigo';

    const { data } = await obterProgramasAsync({
      filtro: {
        buscarPor,
        busca,
        status: [1],
        listarInternos: 0,
        ...filters,
      },
      ordenacao: {
        campo: 'nome',
        ordem: 'ASC',
      },
    });
    return data.result.map(formatarParaSelectOption);
  };

  const formatarParaSelectOption = (op) => ({
    label: op.nome,
    value: op.id,
    codigo: op.codigo,
  });

  return (
    <SelecaoAsyncBase
      isClearable={isClearable}
      disabled={disabled}
      nomeRecurso="Programas"
      carregarOpcoes={carregarProgramas}
      {...props}
    />
  );
};

export default SelecaoPrograma;
