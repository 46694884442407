import styled from 'styled-components'

export const Container = styled.div`
  width: fit-content;
`

export const WebcamContainer = styled.div`
  height: 401px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Button = styled.button`
  display: block;
  width: fit-content;
  padding: 0.5rem 1rem;
  color: #fff;
  border: none;
  outline: none;
  width: 100%;
  border-radius: 0.3rem;
  background-color: var(--primary); 
  margin-top: 1rem;
  padding: 0.75rem 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
`