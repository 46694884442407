import Swal from 'sweetalert2';
import {
  FILTRAR_LANCAMENTOS_ATIVIDADES,
  GERAR_RELATORIO_LANCAMENTOS_ATIVIDADES,
  GERAR_RELATORIO_LANCAMENTOS_ATIVIDADES_ERRO,
  GERAR_RELATORIO_LANCAMENTOS_ATIVIDADES_SUCESSO,
  LISTAR_LANCAMENTOS_ATIVIDADES,
  LISTAR_LANCAMENTOS_ATIVIDADES_ERRO,
  LISTAR_LANCAMENTOS_ATIVIDADES_SUCESSO,
} from './constants';

const INIT_STATE = {
  lancamentosAtividades: [],
  filtros: {},
  carregando: false,
  relatorio: {},
  gerandoRelatorio: false,
};

const LancamentosAtividadesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LISTAR_LANCAMENTOS_ATIVIDADES:
      return { ...state, carregando: true };
    case FILTRAR_LANCAMENTOS_ATIVIDADES:
      return { ...state, filtros: action.payload, carregando: true };
    case LISTAR_LANCAMENTOS_ATIVIDADES_SUCESSO:
      return { ...state, lancamentosAtividades: action.payload, carregando: false };
    case LISTAR_LANCAMENTOS_ATIVIDADES_ERRO:
      Swal.fire({
        icon: 'error',
        title: 'Erro ao buscar lançamentos de atividades',
        timer: 2000,
        text: action.payload,
      });
      return { ...state, lancamentosAtividades: [], carregando: false };
    case GERAR_RELATORIO_LANCAMENTOS_ATIVIDADES:
      return { ...state, filtros: action.payload.filtros, relatorio: action.payload.relatorio, gerandoRelatorio: true };
    case GERAR_RELATORIO_LANCAMENTOS_ATIVIDADES_SUCESSO:
      if (action.payload) {
        Swal.fire({
          icon: 'success',
          title: action.payload.titulo,
          timer: action?.payload?.timer || 2000,
          text: action.payload.mensagem,
        });
      }
      return { ...state, gerandoRelatorio: false };
    case GERAR_RELATORIO_LANCAMENTOS_ATIVIDADES_ERRO:
      Swal.fire({
        icon: 'error',
        title: 'Falha ao gerar Relatório',
        timer: 2000,
        text: action.payload,
      });
      return { ...state, gerandoRelatorio: false };
    default:
      return state;
  }
};

export default LancamentosAtividadesReducer;
