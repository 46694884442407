import {
  FILTRAR_MAQUINAS_IMPLEMENTOS,
  GERAR_RELATORIO_MAQUINAS_IMPLEMENTOS,
  GERAR_RELATORIO_MAQUINAS_IMPLEMENTOS_ERRO,
  GERAR_RELATORIO_MAQUINAS_IMPLEMENTOS_SUCESSO,
  LISTAR_MAQUINAS_IMPLEMENTOS,
  LISTAR_MAQUINAS_IMPLEMENTOS_ERRO,
  LISTAR_MAQUINAS_IMPLEMENTOS_SUCESSO,
} from './constants';

export const obterTodosMaquinasImplementos = () => ({
  type: LISTAR_MAQUINAS_IMPLEMENTOS,
});

export const obterMaquinasImplementosSucesso = (maquinasImplementos) => ({
  type: LISTAR_MAQUINAS_IMPLEMENTOS_SUCESSO,
  payload: maquinasImplementos,
});

export const obterMaquinasImplementosErro = (mensagem) => ({
  type: LISTAR_MAQUINAS_IMPLEMENTOS_ERRO,
  payload: mensagem,
});

export const filtrarMaquinasImplementos = (filtros) => ({
  type: FILTRAR_MAQUINAS_IMPLEMENTOS,
  payload: filtros,
});

export const gerarRelatorioMaquinasImplementos = (payload) => ({
  type: GERAR_RELATORIO_MAQUINAS_IMPLEMENTOS,
  payload: payload,
});

export const gerarRelatorioMaquinasImplementosSucesso = (alert) => ({
  type: GERAR_RELATORIO_MAQUINAS_IMPLEMENTOS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioMaquinasImplementosErro = (mensagem) => ({
  type: GERAR_RELATORIO_MAQUINAS_IMPLEMENTOS_ERRO,
  payload: mensagem,
});