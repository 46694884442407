import React from 'react';
import { IVariedadeConsulta } from '../../pages/variedades/interfaces';
import { obterVariedadesAsync } from '../../pages/variedades/requests';
import SelecaoAsyncBase, { SelectOption } from './SelecaoAsyncBase';

interface IFilters {
  status?: number[];
  'cultura.id'?: number[];
  'unidadesProdutivasCulturas.id'?: number[];
}

interface ISelecaoVariedadesOption {
  value?: SelectOption;
  onChange?: (value: SelectOption) => void;
  multiple?: boolean;
  filters?: IFilters;
  autoSelect?: boolean;
  callback?: (value: { result: any[]; total: number }) => void;
}

const SelecaoVariedades: React.FC<ISelecaoVariedadesOption> = ({ filters, callback, ...props }) => {
  const carregarOpcoes = async (filtro: string) => {
    let busca = filtro;
    let buscarPor = isNaN(parseInt(filtro)) ? 'nome' : 'codigo';

    const { data } = await obterVariedadesAsync({
      filtro: {
        buscarPor,
        busca,
        status: [1],
        ...filters,
      },
      ordenacao: {
        campo: 'nome',
        ordem: 'ASC',
      },
    });

    callback && callback(data);

    return data.result.map(formatarParaSelectOption);
  };

  const formatarParaSelectOption = (variedade: IVariedadeConsulta) => ({
    label: variedade.codigo.toString() + ' - ' + variedade.nome,
    value: variedade.id,
  });

  return (
    <SelecaoAsyncBase
      key={`selecao-variedade-${JSON.stringify(filters)}`}
      nomeRecurso="Variedades"
      carregarOpcoes={carregarOpcoes}
      {...props}
    />
  );
};

export default SelecaoVariedades;
