import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardBody, Col, Row, Spinner, Table } from 'reactstrap';

import { Container, ContainerMapa, ContainerTexto, Content } from './styles';
import { obterRastreabilidadePropriedadeAsync } from './requests';
import { apiExceptionHandler } from '../../../helpers/exception-handler';
import { MapaComMarcador } from '../../../components/MapaComMarcador';
import { formatarDecimal } from '../../../utils/format';
import { IRastreabilidadePropriedadeConsulta } from './interfaces';

interface IParams {
  propriedadeId?: string;
}

const RastreabilidadePropriedade: React.FC = () => {
  const { propriedadeId } = useParams<IParams>();

  const [carregando, setCarregando] = useState(false);
  const [rastreabilidade, setRastreabilidade] = useState<IRastreabilidadePropriedadeConsulta>();

  useEffect(() => {
    const buscarDados = async () => {
      try {
        setCarregando(true);

        if (propriedadeId) {
          const { data } = await obterRastreabilidadePropriedadeAsync(+propriedadeId);

          setRastreabilidade(data);
        }
      } catch (error) {
        apiExceptionHandler(error);
      } finally {
        setCarregando(false);
      }
    };

    buscarDados();
  }, [propriedadeId]);

  return (
    <Container carregando={carregando}>
      {carregando ? (
        <Spinner />
      ) : !rastreabilidade ? (
        <h6>Não encontrado</h6>
      ) : (
        <Content>
          <h1>{rastreabilidade?.revenda?.nome}</h1>

          <Card>
            <CardBody>
              <h3>Local</h3>

              <Row style={{ marginTop: '1rem' }}>
                <Col xl={6}>
                  <img src={rastreabilidade?.propriedade?.imagem} alt="Propriedade" width="100%" height="auto" />
                </Col>

                <Col xl={6}>
                  <ContainerTexto>
                    <h5>Nome:</h5>
                    <span>{rastreabilidade?.propriedade?.nome}</span>
                  </ContainerTexto>

                  <ContainerTexto>
                    <h5>Telefones: </h5>
                    <span>{rastreabilidade?.propriedade?.fones?.map((telefone) => telefone.fone)?.join(', ')}</span>
                  </ContainerTexto>

                  <ContainerTexto>
                    <h5>Endereço:</h5>
                    <span>
                      {(rastreabilidade?.propriedade?.enderecos?.[0]?.logradouro || '') +
                        ', ' +
                        (rastreabilidade?.propriedade?.enderecos?.[0]?.numero || '')}
                    </span>
                  </ContainerTexto>

                  <ContainerTexto>
                    <h5>Bairro:</h5>
                    <span>{rastreabilidade?.propriedade?.enderecos?.[0]?.bairro}</span>
                  </ContainerTexto>

                  <ContainerTexto>
                    <h5>CEP:</h5>
                    <span>{rastreabilidade?.propriedade?.enderecos?.[0]?.CEP}</span>
                  </ContainerTexto>

                  <ContainerTexto>
                    <h5>Cidade:</h5>
                    <span>
                      {(rastreabilidade?.propriedade?.enderecos?.[0]?.cidade?.nome || '') +
                        ' - ' +
                        (rastreabilidade?.propriedade?.enderecos?.[0]?.cidade?.estado?.sigla || '')}
                    </span>
                  </ContainerTexto>
                </Col>
              </Row>
              <br />
              <Row>
                <Col xl={6}>
                  <h3>Produtores</h3>

                  <Table responsive>
                    <thead>
                      <tr style={{ textAlign: 'center' }}>
                        <th>Nome</th>
                        <th>CPF/CNPJ</th>
                        <th>IE</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rastreabilidade?.propriedade?.pessoas?.map((produtor, index) => (
                        <tr key={index}>
                          <td>{produtor.pessoa.razaoSocial}</td>
                          <td style={{ textAlign: 'center' }}>{produtor.pessoa?.documentos?.[0]?.documento}</td>
                          <td style={{ textAlign: 'center' }}>{produtor.ie}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>

                <Col xl={6}>
                  <ContainerMapa>
                    <MapaComMarcador
                      coordenadaPropriedade={{
                        lat: Number(rastreabilidade?.propriedade?.latitude),
                        lng: Number(rastreabilidade?.propriedade?.longitude),
                      }}
                      podeCriarNovaMarcacao={false}
                      alterarCoordenadaPropriedade={() => {}}
                    />
                  </ContainerMapa>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <Row>
                <Col>
                  <h3>Unidades Produtivas</h3>

                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Nome</th>
                        <th style={{ textAlign: 'right' }}>Área Total (ha)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rastreabilidade?.unidadesProdutivas?.map((up, index) => (
                        <tr key={index}>
                          <td>{up.nome}</td>
                          <td style={{ textAlign: 'right' }}>{formatarDecimal(up.hectares, 3)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Content>
      )}
    </Container>
  );
};

export default RastreabilidadePropriedade;
