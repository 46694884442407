import api from '../../services/api';
import { montarQueryString } from '../../utils';
import { IRecomendacaoTecnicaCadastro, IRecomendacaoTecnicaConsulta } from './interfaces';

export const obterRecomendacoesTecnicasAsync = (query = {}) => {
  const querystr = montarQueryString(query);

  return api.request<{ result: IRecomendacaoTecnicaConsulta[]; total: number }>({
    url: `recomendacoes-tecnicas${querystr}`,
    method: 'GET',
  });
};

export const obterRecomendacaoTecnicaAsync = (id: number) => {
  return api.request<IRecomendacaoTecnicaConsulta>({
    url: `recomendacoes-tecnicas/${id}`,
    method: 'GET',
  });
};

export const criarRecomendacaoTecnicaAsync = (recomendacaoTecnica: IRecomendacaoTecnicaCadastro) => {
  return api.request<IRecomendacaoTecnicaConsulta>({
    url: `recomendacoes-tecnicas`,
    method: 'POST',
    data: recomendacaoTecnica,
  });
};

export const atualizarRecomendacaoTecnicaAsync = (id: number, recomendacaoTecnica: IRecomendacaoTecnicaCadastro) => {
  return api.request<IRecomendacaoTecnicaConsulta>({
    url: `recomendacoes-tecnicas/${id}`,
    method: 'PATCH',
    data: recomendacaoTecnica,
  });
};

export const marcarRecomendacaoNaoExecutadaAsync = (id: number) => {
  return api.request<void>({
    url: `recomendacoes-tecnicas/marcar-nao-executada/${id}`,
    method: 'PATCH',
  });
};

export const removerRecomendacoesTecnicasEmMassaAsync = (ids: number[]) => {
  return api.request<{ affected: number }>({
    url: `recomendacoes-tecnicas`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerRecomendacaoTecnicaAsync = (id: number) =>
  api.request<{ affected: number }>({
    url: `recomendacoes-tecnicas/${id}`,
    method: 'DELETE',
  });
