import { AxiosResponse } from 'axios';

import api from '../../services/api';
import { IGrupoQuimicoCadastro, IGrupoQuimicoConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterGruposQuimicosAsync = (
  query,
): Promise<AxiosResponse<{ result: IGrupoQuimicoConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `grupos-quimicos${queryStr}`,
    method: 'GET',
  });
};

export const obterGrupoQuimicoAsync = (id: number): Promise<AxiosResponse<IGrupoQuimicoConsulta>> => {
  return api.request({
    url: `grupos-quimicos/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoGrupoQuimicoAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `grupos-quimicos/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarGrupoQuimicoAsync = (
  grupoQuimico: IGrupoQuimicoCadastro,
): Promise<AxiosResponse<IGrupoQuimicoConsulta>> => {
  return api.request({
    url: `grupos-quimicos`,
    data: grupoQuimico,
    method: 'POST',
  });
};

export const atualizarGrupoQuimicoAsync = (
  id: number,
  grupoQuimico: IGrupoQuimicoCadastro,
): Promise<AxiosResponse<IGrupoQuimicoConsulta>> => {
  return api.request({
    url: `grupos-quimicos/${id}`,
    data: grupoQuimico,
    method: 'PATCH',
  });
};

export const removerGruposQuimicosEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `grupos-quimicos`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerGrupoQuimicoAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `grupos-quimicos/${id}`,
    method: 'DELETE',
  });
