import {
  FILTRAR_ORCAMENTOS,
  GERAR_RELATORIO_ORCAMENTOS,
  GERAR_RELATORIO_ORCAMENTOS_ERRO,
  GERAR_RELATORIO_ORCAMENTOS_SUCESSO,
  LISTAR_ORCAMENTOS,
  LISTAR_ORCAMENTOS_ERRO,
  LISTAR_ORCAMENTOS_SUCESSO,
} from './constants';

export const obterTodosOrcamentos = () => ({
  type: LISTAR_ORCAMENTOS,
});

export const obterOrcamentosSucesso = (Orcamentos) => ({
  type: LISTAR_ORCAMENTOS_SUCESSO,
  payload: Orcamentos,
});

export const obterOrcamentosErro = (mensagem) => ({
  type: LISTAR_ORCAMENTOS_ERRO,
  payload: mensagem,
});

export const filtrarOrcamentos = (filtros) => ({
  type: FILTRAR_ORCAMENTOS,
  payload: filtros,
});

export const gerarRelatorioOrcamentos = (payload) => ({
  type: GERAR_RELATORIO_ORCAMENTOS,
  payload: payload,
});

export const gerarRelatorioOrcamentosSucesso = (alert) => ({
  type: GERAR_RELATORIO_ORCAMENTOS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioOrcamentosErro = (mensagem) => ({
  type: GERAR_RELATORIO_ORCAMENTOS_ERRO,
  payload: mensagem,
});
