import React from 'react';
import { ISelecao } from '../../interfaces/selecao';
import { IAtividadeConsulta } from '../../pages/atividades/interfaces';
import { obterAtividadesAsync } from '../../pages/atividades/requests';
import { IGrupoProdutoConsulta } from '../../pages/grupos-produtos/interfaces';
import SelecaoAsyncBase, { SelectOption } from './SelecaoAsyncBase';

interface IFilters {
  status?: number[];
  'grupoAtividade.id'?: number[];
}

interface ISelecaoAtividadeOption {
  value?: SelectOption;
  onChange?: (value: SelectOption) => void;
  multiple?: boolean;
  filters?: IFilters;
  autoFocus?: boolean;
}

export interface ISelecaoAtividade extends ISelecao {
  produtos: boolean;
  maquinas: boolean;
  monitoramento: boolean;
  irrigacao: boolean;
  doencas: boolean;
  colheita: boolean;
  origem: boolean;
  estagiosFenologicos: boolean;
  gruposProdutos?: IGrupoProdutoConsulta[];
}

const SelecaoAtividade: React.FC<ISelecaoAtividadeOption> = ({ filters, ...props }) => {
  const carregarOpcoes = async (filtro: string) => {
    let busca = filtro;
    let buscarPor = isNaN(parseInt(filtro)) ? 'nome' : 'codigo';

    const { data } = await obterAtividadesAsync({
      filtro: {
        buscarPor,
        busca,
        status: [1],
        ...filters,
      },
      ordenacao: {
        campo: 'nome',
        ordem: 'ASC',
      },
    });

    return data.result.map(formatarParaSelectOption);
  };

  const formatarParaSelectOption = (atividade: IAtividadeConsulta) => ({
    label: atividade.codigo + ' - ' + atividade.nome,
    value: atividade.id,

    produtos: atividade.produtos,
    maquinas: atividade.maquinas,
    monitoramento: atividade.monitoramento,
    irrigacao: atividade.irrigacao,
    colheita: atividade.colheita,
    doencas: atividade.doencas,
    origem: atividade.origem,
    gruposProdutos: atividade.gruposProdutos,
    estagiosFenologicos: atividade.estagiosFenologicos,
  });

  return (
    <SelecaoAsyncBase
      autoFocus={props.autoFocus}
      key={`selecao-atividade-${JSON.stringify(filters)}`}
      nomeRecurso="Atividades"
      carregarOpcoes={carregarOpcoes}
      {...props}
    />
  );
};

export default SelecaoAtividade;
