import {
  FILTRAR_HISTORICOS_PADRAO,
  GERAR_RELATORIO_HISTORICOS_PADRAO,
  GERAR_RELATORIO_HISTORICOS_PADRAO_ERRO,
  GERAR_RELATORIO_HISTORICOS_PADRAO_SUCESSO,
  LISTAR_HISTORICOS_PADRAO,
  LISTAR_HISTORICOS_PADRAO_ERRO,
  LISTAR_HISTORICOS_PADRAO_SUCESSO,
} from './constants';

export const obterTodosHistoricosPadrao = () => ({
  type: LISTAR_HISTORICOS_PADRAO,
});

export const obterHistoricosPadraoSucesso = (historicosPadrao) => ({
  type: LISTAR_HISTORICOS_PADRAO_SUCESSO,
  payload: historicosPadrao,
});

export const obterHistoricosPadraoErro = (mensagem) => ({
  type: LISTAR_HISTORICOS_PADRAO_ERRO,
  payload: mensagem,
});

export const filtrarHistoricosPadrao = (filtros) => ({
  type: FILTRAR_HISTORICOS_PADRAO,
  payload: filtros,
});

export const gerarRelatorioHistoricosPadrao = (payload) => ({
  type: GERAR_RELATORIO_HISTORICOS_PADRAO,
  payload: payload,
});

export const gerarRelatorioHistoricosPadraoSucesso = (alert) => ({
  type: GERAR_RELATORIO_HISTORICOS_PADRAO_SUCESSO,
  payload: alert,
});

export const gerarRelatorioHistoricosPadraoErro = (mensagem) => ({
  type: GERAR_RELATORIO_HISTORICOS_PADRAO_ERRO,
  payload: mensagem,
});