import {
  LISTAR_REGISTROS_CLIMATICOS_SUCESSO,
  LISTAR_REGISTROS_CLIMATICOS_ERRO,
  LISTAR_REGISTROS_CLIMATICOS,
  FILTRAR_REGISTROS_CLIMATICOS,
  GERAR_RELATORIO_REGISTROS_CLIMATICOS,
  GERAR_RELATORIO_REGISTROS_CLIMATICOS_SUCESSO,
  GERAR_RELATORIO_REGISTROS_CLIMATICOS_ERRO,
} from './constants';

export const obterTodasRegistrosClimaticos = () => ({
  type: LISTAR_REGISTROS_CLIMATICOS,
});

export const obterTodasRegistrosClimaticosSucesso = (segmento) => ({
  type: LISTAR_REGISTROS_CLIMATICOS_SUCESSO,
  payload: segmento,
});

export const obterTodasRegistrosClimaticosErro = (mensagem) => ({
  type: LISTAR_REGISTROS_CLIMATICOS_ERRO,
  payload: mensagem,
});

export const filtrarRegistrosClimaticos = (f) => {
  return {
    type: FILTRAR_REGISTROS_CLIMATICOS,
    payload: f,
  };
};

export const gerarRelatorioRegistrosClimaticos = (payload) => ({
  type: GERAR_RELATORIO_REGISTROS_CLIMATICOS,
  payload: payload,
});

export const gerarRelatorioRegistrosClimaticosSucesso = (alert) => ({
  type: GERAR_RELATORIO_REGISTROS_CLIMATICOS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioRegistrosClimaticosErro = (mensagem) => ({
  type: GERAR_RELATORIO_REGISTROS_CLIMATICOS_ERRO,
  payload: mensagem,
});
