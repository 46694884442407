import Swal from 'sweetalert2';
import { filtroPadraoStatus } from '../../../constants/filtros';
import {
  FILTRAR_INTEGRADORES,
  LISTAR_INTEGRADORES,
  LISTAR_INTEGRADORES_ERRO,
  LISTAR_INTEGRADORES_SUCESSO,
} from './constants';

const INIT_STATE = {
  integradores: [],
  filtros: { ...filtroPadraoStatus },
  carregando: false,
};

const IntegradoresReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LISTAR_INTEGRADORES:
      return { ...state, carregando: true };
    case FILTRAR_INTEGRADORES:
      return { ...state, filtros: action.payload, carregando: true };
    case LISTAR_INTEGRADORES_SUCESSO:
      return { ...state, integradores: action.payload, carregando: false };
    case LISTAR_INTEGRADORES_ERRO:
      Swal.fire({
        icon: 'error',
        title: 'Erro ao buscar integradores',
        timer: 2000,
        text: action.payload,
      });
      return { ...state, integradores: [], carregando: false };
    default:
      return state;
  }
};

export default IntegradoresReducer;
