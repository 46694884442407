import api from '../../services/api';
import { AxiosResponse } from 'axios';
import { IGrupoPessoaCadastro, IGrupoPessoaConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterGruposPessoasAsync = (
  query,
): Promise<
  AxiosResponse<{ result: IGrupoPessoaConsulta[]; total: number }>
> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `grupos-pessoas${queryStr}`,
    method: 'GET',
  });
};

export const obterGrupoPessoaAsync = (
  id: number,
): Promise<AxiosResponse<IGrupoPessoaConsulta>> => {
  return api.request({
    url: `grupos-pessoas/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoGrupoPessoaAsync = (): Promise<
  AxiosResponse<number>
> => {
  return api.request({
    url: `grupos-pessoas/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarGruposPessoasAsync = (
  grupoPessoa: IGrupoPessoaCadastro,
): Promise<AxiosResponse<IGrupoPessoaConsulta>> => {
  return api.request({
    url: `grupos-pessoas`,
    data: grupoPessoa,
    method: 'POST',
  });
};

export const atualizarGruposPessoasAsync = (
  id: number,
  grupoPessoa: IGrupoPessoaCadastro,
): Promise<AxiosResponse<IGrupoPessoaConsulta>> => {
  return api.request({
    url: `grupos-pessoas/${id}`,
    data: grupoPessoa,
    method: 'PATCH',
  });
};

export const removerGruposPessoasEmMassaAsync = (
  ids: number[],
): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `grupos-pessoas`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerGruposPessoasAsync = (
  id: number,
): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `grupos-pessoas/${id}`,
    method: 'DELETE',
  });
