import {
  FILTRAR_CLASSES_PRODUTOS,
  GERAR_RELATORIO_CLASSES_PRODUTOS,
  GERAR_RELATORIO_CLASSES_PRODUTOS_ERRO,
  GERAR_RELATORIO_CLASSES_PRODUTOS_SUCESSO,
  LISTAR_CLASSES_PRODUTOS,
  LISTAR_CLASSES_PRODUTOS_ERRO,
  LISTAR_CLASSES_PRODUTOS_SUCESSO,
} from './constants';

export const obterTodosClassesProdutos = () => ({
  type: LISTAR_CLASSES_PRODUTOS,
});

export const obterClassesProdutosSucesso = (classesProdutos) => ({
  type: LISTAR_CLASSES_PRODUTOS_SUCESSO,
  payload: classesProdutos,
});

export const obterClassesProdutosErro = (mensagem) => ({
  type: LISTAR_CLASSES_PRODUTOS_ERRO,
  payload: mensagem,
});

export const filtrarClassesProdutos = (filtros) => ({
  type: FILTRAR_CLASSES_PRODUTOS,
  payload: filtros,
});

export const gerarRelatorioClassesProdutos = (payload) => ({
  type: GERAR_RELATORIO_CLASSES_PRODUTOS,
  payload: payload,
});

export const gerarRelatorioClassesProdutosSucesso = (alert) => ({
  type: GERAR_RELATORIO_CLASSES_PRODUTOS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioClassesProdutosErro = (mensagem) => ({
  type: GERAR_RELATORIO_CLASSES_PRODUTOS_ERRO,
  payload: mensagem,
});
