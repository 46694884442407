import axios from 'axios';
import { Cookies } from 'react-cookie';
import { setLogoutUser } from '../helpers/authUtils';

interface ITokens {
  access_token: string;
  refresh_token: {
    refreshToken: string;
    expiraEm: number;
  };
}

function getTokens(): ITokens {
  const cookies = new Cookies();
  const access_token = cookies.get('token');
  const refresh_token = cookies.get('refresh');

  return { access_token, refresh_token };
}

function setTokens(tokens: ITokens) {
  const cookies = new Cookies();

  cookies.set('token', tokens.access_token, {
    path: '/',
    sameSite: true,
  });

  cookies.set('refresh', tokens.refresh_token, {
    path: '/',
    sameSite: true,
  });
}

function getAuthHeader() {
  const { access_token } = getTokens();

  return `Bearer ${access_token}`;
}

async function atualizarTokens(): Promise<ITokens> {
  try {
    const { refresh_token } = getTokens();

    const { data } = await api.post<ITokens>('auth/refresh-token', {
      refresh_token: refresh_token.refreshToken,
    });

    return data;
  } catch (error) {
    setLogoutUser();
    window.location.href = '/account/login?alert=true';
    throw error;
  }
}

let isRefreshing = false;
let failedQueue: any[] = [];

function processQueue(error: any, access_token: string) {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(access_token);
    }
  });

  failedQueue = [];
}

const domain = process.env.REACT_APP_API_BASEURL || `http://localhost:8888`;

const api = axios.create({
  baseURL: domain,
});

api.interceptors.request.use((config) => {
  const authHeader = getAuthHeader();

  if (config?.baseURL === domain && !!authHeader) {
    config.headers.Authorization = authHeader;
    config.headers.Accept = 'application/json';
  }

  return config;
});

api.interceptors.response.use(
  (response) => {
    if (response?.config?.baseURL === domain && !!response?.data?.access_token) {
      setTokens(response?.data);
    }

    return response;
  },
  async (error) => {
    if (error.response?.status === 401) {
      const originalRequest = error.config;
      const { refresh_token } = getTokens();

      if (originalRequest.baseURL === domain && refresh_token && !originalRequest._retry) {
        if (isRefreshing) {
          // se chegar alguma requisição enquanto estiver atualizando o access_token joga para uma fila
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          })
            .then(() => {
              originalRequest.headers.Authorization = getAuthHeader();
              return api(originalRequest);
            })
            .catch((err) => err);
        }

        // se o access_token do header da request for diferente do cookie, significa que ele foi atualizado
        const authHeader = getAuthHeader();
        if (originalRequest.headers.Authorization !== authHeader) {
          originalRequest.headers.Authorization = authHeader;
          return Promise.resolve(api(originalRequest));
        }

        originalRequest._retry = true;
        isRefreshing = true;

        // se não for nenhuma das situações anteriores, gera um novo access_token e processa a fila
        return new Promise((resolve, reject) => {
          atualizarTokens()
            .then((data) => {
              setTokens(data); // atualiza os tokens nos cookies
              processQueue(null, data.access_token); // resolve a fila
              resolve(api(originalRequest)); // resolve a request atual
            })
            .catch((err) => {
              processQueue(err, ''); // rejeita a fila caso não consiga gerar o novo access_token
              reject(err); // rejeita a request atual
            })
            .then(() => {
              isRefreshing = false;
            });
        });
      }
    }

    return Promise.reject(error);
  },
);

export default api;
