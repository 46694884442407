import Swal from 'sweetalert2';
import {
  FILTRAR_CONTAS_RECEBER,
  GERAR_RELATORIO_CONTAS_RECEBER,
  GERAR_RELATORIO_CONTAS_RECEBER_ERRO,
  GERAR_RELATORIO_CONTAS_RECEBER_SUCESSO,
  LISTAR_CONTAS_RECEBER,
  LISTAR_CONTAS_RECEBER_ERRO,
  LISTAR_CONTAS_RECEBER_SUCESSO,
} from './constants';

const INIT_STATE = {
  contasReceber: [],
  filtros: {},
  carregando: false,
  relatorio: {},
  gerandoRelatorio: false,
};

const ContasReceberReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LISTAR_CONTAS_RECEBER:
      return { ...state, carregando: true };
    case FILTRAR_CONTAS_RECEBER:
      return { ...state, filtros: action.payload, carregando: true };
    case LISTAR_CONTAS_RECEBER_SUCESSO:
      return { ...state, contasReceber: action.payload, carregando: false };
    case LISTAR_CONTAS_RECEBER_ERRO:
      Swal.fire({
        icon: 'error',
        title: 'Erro ao buscar Contas a Receber',
        timer: 2000,
        text: action.payload,
      });
      return { ...state, contasReceber: [], carregando: false };
    case GERAR_RELATORIO_CONTAS_RECEBER:
      return { ...state, filtros: action.payload.filtros, relatorio: action.payload.relatorio, gerandoRelatorio: true };
    case GERAR_RELATORIO_CONTAS_RECEBER_SUCESSO:
      if (action.payload) {
        Swal.fire({
          icon: 'success',
          title: action.payload.titulo,
          timer: action?.payload?.timer || 2000,
          text: action.payload.mensagem,
        });
      }
      return { ...state, gerandoRelatorio: false };
    case GERAR_RELATORIO_CONTAS_RECEBER_ERRO:
      Swal.fire({
        icon: 'error',
        title: 'Falha ao gerar Relatório',
        timer: 2000,
        text: action.payload,
      });
      return { ...state, gerandoRelatorio: false };
    default:
      return state;
  }
};

export default ContasReceberReducer;
