import {
  FILTRAR_ENTRADA_SAIDA_PRODUTO,
  GERAR_RELATORIO_ENTRADAS_SAIDAS_PRODUTOS,
  GERAR_RELATORIO_ENTRADAS_SAIDAS_PRODUTOS_ERRO,
  GERAR_RELATORIO_ENTRADAS_SAIDAS_PRODUTOS_SUCESSO,
  LISTAR_ENTRADA_SAIDA_PRODUTO,
  LISTAR_ENTRADA_SAIDA_PRODUTO_ERRO,
  LISTAR_ENTRADA_SAIDA_PRODUTO_SUCESSO,
} from './constants';

export const obterTodosEntradasSaidasProdutos = () => ({
  type: LISTAR_ENTRADA_SAIDA_PRODUTO,
});

export const obterEntradasSaidasProdutosSucesso = (entradasSaidasProdutos) => ({
  type: LISTAR_ENTRADA_SAIDA_PRODUTO_SUCESSO,
  payload: entradasSaidasProdutos,
});

export const obterEntradasSaidasProdutosErro = (mensagem) => ({
  type: LISTAR_ENTRADA_SAIDA_PRODUTO_ERRO,
  payload: mensagem,
});

export const filtrarEntradasSaidasProdutos = (filtros) => ({
  type: FILTRAR_ENTRADA_SAIDA_PRODUTO,
  payload: filtros,
});

export const gerarRelatorioEntradasSaidasProdutos = (payload) => ({
  type: GERAR_RELATORIO_ENTRADAS_SAIDAS_PRODUTOS,
  payload: payload,
});

export const gerarRelatorioEntradasSaidasProdutosSucesso = (alert) => ({
  type: GERAR_RELATORIO_ENTRADAS_SAIDAS_PRODUTOS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioEntradasSaidasProdutosErro = (mensagem) => ({
  type: GERAR_RELATORIO_ENTRADAS_SAIDAS_PRODUTOS_ERRO,
  payload: mensagem,
});
