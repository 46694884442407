import { all, call, fork, put, takeEvery, select, takeLatest } from 'redux-saga/effects';
import {
  GET_TABLE_PREFERENCE,
  SET_TABLE_PREFERENCE_ASYNC,
  SET_TABLE_ORDER_ASYNC,
  SET_LIMIT_PAGE_ASYNC,
  SET_CAMPO_BUSCA_PADRAO_ASYNC,
} from './constants';
import {
  setTablePreferences,
  setPreferencesReady,
  setTableOrder,
  setLimitPageTable,
  setCampoBuscaPadrao,
} from './actions';
import { obterPreferenceAsync, salvarPreferenceAsync } from '../../services/requests/preference';
import { Alert } from '../../helpers/Alert';

function* getTablePreferences({ payload }) {
  try {
    let datas = yield select((reducer) => reducer.TableConfig);

    if (!datas[payload]) {
      const { data } = yield call(obterPreferenceAsync, payload);

      yield put(setTablePreferences(data));
    } else {
      yield put(setPreferencesReady());
    }
  } catch (error) {
    console.error(error);
    /*Alert({
      type: 'error',
      title: 'Ocorreu um erro ao obter as preferências das tabelas!',
    });*/
  }
}

function* setTablePreference({ payload }) {
  try {
    yield call(salvarPreferenceAsync, payload.id, payload.preferencias);
    yield put(setTablePreferences(payload));
  } catch (error) {
    console.error(error);
    Alert({
      type: 'error',
      title: 'Ocorreu um erro ao salvar as preferências das tabelas!',
    });
  }
}

function* setTableOrderAsync({ payload }) {
  try {
    const { programaId, order } = payload;
    const datas = yield select((reducer) => reducer.TableConfig);
    const preference = yield datas[programaId];
    const newPreference = { ...preference, ordenarPor: order.field, ordem: order.order };

    yield call(salvarPreferenceAsync, programaId, newPreference);
    yield put(setTableOrder(programaId, newPreference));
  } catch (error) {
    Alert({
      type: 'error',
      title: 'Ocorreu um erro ao salvar as preferências das tabelas!',
    });
  }
}

function* setLimitPageAsync({ payload }) {
  try {
    const { programaId, limit, page } = payload;
    let datas = yield select((reducer) => reducer.TableConfig);
    const preference = yield datas[programaId];
    const newPreference = { ...preference, limit, page: 1 };
    if (preference?.limit !== limit) yield call(salvarPreferenceAsync, programaId, newPreference);
    yield put(setLimitPageTable(programaId, limit, page));
  } catch (error) {
    Alert({
      type: 'error',
      title: 'Ocorreu um erro ao salvar a paginação da tabela!',
    });
  }
}

function* setCampoBuscarPorAsync({ payload }) {
  try {
    const { programaId, campo } = payload;
    const datas = yield select((reducer) => reducer.TableConfig);
    const preference = yield datas[programaId];
    const newPreference = { ...preference, buscarPor: campo };

    yield call(salvarPreferenceAsync, programaId, newPreference);
    yield put(setCampoBuscaPadrao(programaId, campo));
  } catch (error) {
    Alert({
      type: 'error',
      title: 'Ocorreu um erro ao salvar as preferências de filtro!',
    });
  }
}

export function* getTablePreferencesAsync() {
  //@ts-ignore
  yield takeEvery(GET_TABLE_PREFERENCE, getTablePreferences);
}

export function* setTablePreferencesAsync() {
  //@ts-ignore
  yield takeEvery(SET_TABLE_PREFERENCE_ASYNC, setTablePreference);
}

export function* setTableOrders() {
  //@ts-ignore
  yield takeLatest(SET_TABLE_ORDER_ASYNC, setTableOrderAsync);
}

export function* setLimitPage() {
  //@ts-ignore
  yield takeLatest(SET_LIMIT_PAGE_ASYNC, setLimitPageAsync);
}

export function* setCampoBuscarPor() {
  //@ts-ignore
  yield takeLatest(SET_CAMPO_BUSCA_PADRAO_ASYNC, setCampoBuscarPorAsync);
}

function* preferencesSaga() {
  yield all([
    fork(getTablePreferencesAsync),
    fork(setTablePreferencesAsync),
    fork(setLimitPage),
    fork(setTableOrders),
    fork(setCampoBuscarPor),
  ]);
}

export default preferencesSaga;
