import {
  FILTRAR_VARIEDADES_CLONES,
  GERAR_RELATORIO_VARIEDADES_CLONES,
  GERAR_RELATORIO_VARIEDADES_CLONES_ERRO,
  GERAR_RELATORIO_VARIEDADES_CLONES_SUCESSO,
  LISTAR_VARIEDADES_CLONES,
  LISTAR_VARIEDADES_CLONES_ERRO,
  LISTAR_VARIEDADES_CLONES_SUCESSO,
} from './constants';

export const obterTodosVariedadesClones = () => ({
  type: LISTAR_VARIEDADES_CLONES,
});

export const obterVariedadesClonesSucesso = (sistemasProducao) => ({
  type: LISTAR_VARIEDADES_CLONES_SUCESSO,
  payload: sistemasProducao,
});

export const obterVariedadesClonesErro = (mensagem) => ({
  type: LISTAR_VARIEDADES_CLONES_ERRO,
  payload: mensagem,
});

export const filtrarVariedadesClones = (filtros) => ({
  type: FILTRAR_VARIEDADES_CLONES,
  payload: filtros,
});

export const gerarRelatorioVariedadesClones = (payload) => ({
  type: GERAR_RELATORIO_VARIEDADES_CLONES,
  payload: payload,
});

export const gerarRelatorioVariedadesClonesSucesso = (alert) => ({
  type: GERAR_RELATORIO_VARIEDADES_CLONES_SUCESSO,
  payload: alert,
});

export const gerarRelatorioVariedadesClonesErro = (mensagem) => ({
  type: GERAR_RELATORIO_VARIEDADES_CLONES_ERRO,
  payload: mensagem,
});
