import {
  FILTRAR_VEICULOS,
  GERAR_RELATORIO_VEICULOS,
  GERAR_RELATORIO_VEICULOS_ERRO,
  GERAR_RELATORIO_VEICULOS_SUCESSO,
  LISTAR_VEICULOS,
  LISTAR_VEICULOS_ERRO,
  LISTAR_VEICULOS_SUCESSO,
} from './constants';

export const obterTodosVeiculos = () => ({
  type: LISTAR_VEICULOS,
});

export const obterVeiculosSucesso = (veiculos) => ({
  type: LISTAR_VEICULOS_SUCESSO,
  payload: veiculos,
});

export const obterVeiculosErro = (mensagem) => ({
  type: LISTAR_VEICULOS_ERRO,
  payload: mensagem,
});

export const filtrarVeiculos = (filtros) => ({
  type: FILTRAR_VEICULOS,
  payload: filtros,
});

export const gerarRelatorioVeiculos = (payload) => ({
  type: GERAR_RELATORIO_VEICULOS,
  payload: payload,
});

export const gerarRelatorioVeiculosSucesso = (alert) => ({
  type: GERAR_RELATORIO_VEICULOS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioVeiculosErro = (mensagem) => ({
  type: GERAR_RELATORIO_VEICULOS_ERRO,
  payload: mensagem,
});