export function removerDuplicadosArray<Type = any>(vetorInicial: Type[]): Type[] {
  const vetorFinal: any[] = [];

  for (let i = 0; i < vetorInicial.length; ++i) {
    let y = 0;

    for (; y < vetorFinal.length; ++y) {
      if (vetorInicial[i] === vetorFinal[y]) break;
    }

    if (y === vetorFinal.length) {
      vetorFinal.push(vetorInicial[i]);
    }
  }

  return vetorFinal;
}

export function removerDuplicadosArrayPorKey<Type = any>(array: Type[], key: string): Type[] {
  const unique = array?.filter((value, index, self) => index === self.findIndex((t) => t[key] === value[key]));

  return unique;
}
