import {
  FILTRAR_GRUPO_SERVICO,
  GERAR_RELATORIO_GRUPO_SERVICO,
  GERAR_RELATORIO_GRUPO_SERVICO_ERRO,
  GERAR_RELATORIO_GRUPO_SERVICO_SUCESSO,
  LISTAR_GRUPO_SERVICO,
  LISTAR_GRUPO_SERVICO_ERRO,
  LISTAR_GRUPO_SERVICO_SUCESSO,
} from './constants';

export const obterTodosGrupoServico = () => ({
  type: LISTAR_GRUPO_SERVICO,
});

export const obterGrupoServicoSucesso = (GrupoServico) => ({
  type: LISTAR_GRUPO_SERVICO_SUCESSO,
  payload: GrupoServico,
});

export const obterGrupoServicoErro = (mensagem) => ({
  type: LISTAR_GRUPO_SERVICO_ERRO,
  payload: mensagem,
});

export const filtrarGrupoServico = (filtros) => ({
  type: FILTRAR_GRUPO_SERVICO,
  payload: filtros,
});

export const gerarRelatorioGrupoServico = (payload) => ({
  type: GERAR_RELATORIO_GRUPO_SERVICO,
  payload: payload,
});

export const gerarRelatorioGrupoServicoSucesso = (alert) => ({
  type: GERAR_RELATORIO_GRUPO_SERVICO_SUCESSO,
  payload: alert,
});

export const gerarRelatorioGrupoServicoErro = (mensagem) => ({
  type: GERAR_RELATORIO_GRUPO_SERVICO_ERRO,
  payload: mensagem,
})