export enum PermissaoCodigo {
  Acesso = 1,
  Incluir = 2,
  Alterar = 3,
  Excluir = 4,
  Relatorio = 5,
  MultiCultura = 6,
  AlterarDataBaixa = 7,
  AlterarValoresBaixa = 8,
  BaixarTituloFinanceiro = 9,
}
