import {
  FILTRAR_PRINCIPIOS_ATIVOS,
  GERAR_RELATORIO_PRINCIPIOS_ATIVOS,
  GERAR_RELATORIO_PRINCIPIOS_ATIVOS_ERRO,
  GERAR_RELATORIO_PRINCIPIOS_ATIVOS_SUCESSO,
  LISTAR_PRINCIPIOS_ATIVOS,
  LISTAR_PRINCIPIOS_ATIVOS_ERRO,
  LISTAR_PRINCIPIOS_ATIVOS_SUCESSO,
} from './constants';

export const obterTodosPrincipiosAtivos = () => ({
  type: LISTAR_PRINCIPIOS_ATIVOS,
});

export const obterPrincipiosAtivosSucesso = (principioAtivo) => ({
  type: LISTAR_PRINCIPIOS_ATIVOS_SUCESSO,
  payload: principioAtivo,
});

export const obterPrincipiosAtivosErro = (mensagem) => ({
  type: LISTAR_PRINCIPIOS_ATIVOS_ERRO,
  payload: mensagem,
});

export const filtrarPrincipiosAtivos = (filtros) => ({
  type: FILTRAR_PRINCIPIOS_ATIVOS,
  payload: filtros,
});

export const gerarRelatorioPrincipiosAtivos = (payload) => ({
  type: GERAR_RELATORIO_PRINCIPIOS_ATIVOS,
  payload: payload,
});

export const gerarRelatorioPrincipiosAtivosSucesso = (alert) => ({
  type: GERAR_RELATORIO_PRINCIPIOS_ATIVOS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioPrincipiosAtivosErro = (mensagem) => ({
  type: GERAR_RELATORIO_PRINCIPIOS_ATIVOS_ERRO,
  payload: mensagem,
});
