import { AxiosResponse } from 'axios';

import api from '../../services/api';
import { ICategoriaProdutoAgroCadastro, ICategoriaProdutoAgroConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterCategoriasProdutosAgroAsync = (
  query,
): Promise<AxiosResponse<{ result: ICategoriaProdutoAgroConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `categorias-produtos-agro${queryStr}`,
    method: 'GET',
  });
};

export const obterCategoriaProdutoAgroAsync = (id: number): Promise<AxiosResponse<ICategoriaProdutoAgroConsulta>> => {
  return api.request({
    url: `categorias-produtos-agro/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoCategoriaProdutoAgroAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `categorias-produtos-agro/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarCategoriaProdutoAgroAsync = (
  categoriaProdutoAgro: ICategoriaProdutoAgroCadastro,
): Promise<AxiosResponse<ICategoriaProdutoAgroConsulta>> => {
  return api.request({
    url: `categorias-produtos-agro`,
    data: categoriaProdutoAgro,
    method: 'POST',
  });
};

export const atualizarCategoriaProdutoAgroAsync = (
  id: number,
  categoriaProdutoAgro: ICategoriaProdutoAgroCadastro,
): Promise<AxiosResponse<ICategoriaProdutoAgroConsulta>> => {
  return api.request({
    url: `categorias-produtos-agro/${id}`,
    data: categoriaProdutoAgro,
    method: 'PATCH',
  });
};

export const removerCategoriasProdutosAgroEmMassaAsync = (
  ids: number[],
): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `categorias-produtos-agro`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerCategoriaProdutoAgroAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `categorias-produtos-agro/${id}`,
    method: 'DELETE',
  });
