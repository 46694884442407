import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { ProgramaCodigo } from '../../../constants/enums/programa-codigo.enum';
import { FILTRAR_REVENDA, LISTAR_REVENDA } from './constants';
import { obterRevendasAsync } from '../requests';
import { obterRevendaErro, obterRevendaSucesso } from './actions';
import { SET_LIMIT_PAGE, SET_TABLE_ORDER } from '../../../redux/table/constants';
import { montarFiltroParaApi } from '../../../utils/api-filter';

function* listarTodosRevenda() {
  try {
    const filtro = yield select((state) => state.Revenda.filtros);
    const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.Revenda]);

    let query = { filtro: montarFiltroParaApi(filtro) };

    if (preferencias) {
      query.ordenacao = {
        campo: preferencias?.ordenarPor || 'nome',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      };
      query.paginacao = {
        pagina: preferencias?.page || 1,
        limite: preferencias?.limit || 50,
      };
    }

    const { data } = yield call(obterRevendasAsync, query);
    yield put(obterRevendaSucesso(data));
  } catch (error) {
    yield put(obterRevendaErro(error.response?.data?.message || error.message));
  }
}

function* ordenarRevenda({ payload }) {
  if (payload.programaId === ProgramaCodigo.Revenda) {
    yield call(listarTodosRevenda);
  }
}

function* mudarLimitePaginacao({ payload }) {
  if (payload.programaId === ProgramaCodigo.Revenda) {
    yield call(listarTodosRevenda);
  }
}

export function* watchListarRevenda() {
  yield takeLatest(LISTAR_REVENDA, listarTodosRevenda);
}

export function* watchFiltrarRevenda() {
  yield takeLatest(FILTRAR_REVENDA, listarTodosRevenda);
}

export function* watchOrdenarRevenda() {
  yield takeLatest(SET_TABLE_ORDER, ordenarRevenda);
}

export function* watchLimitePaginacao() {
  yield takeLatest(SET_LIMIT_PAGE, mudarLimitePaginacao);
}

function* revendaSaga() {
  yield all([
    fork(watchListarRevenda),
    fork(watchFiltrarRevenda),
    fork(watchOrdenarRevenda),
    fork(watchLimitePaginacao),
  ]);
}

export default revendaSaga;
