import {
  LISTAR_ORGANIZACOES_SUCESSO,
  GERAR_RELATORIO_ORGANIZACOES,
  GERAR_RELATORIO_ORGANIZACOES_ERRO,
  GERAR_RELATORIO_ORGANIZACOES_SUCESSO,
  LISTAR_ORGANIZACOES_ERRO,
  LISTAR_ORGANIZACOES,
  FILTRAR_ORGANIZACOES,
} from './constants';

export const obterTodosOrganizacoes = () => ({
  type: LISTAR_ORGANIZACOES,
});

export const obterOrganizacoesSucesso = (organizacoes) => ({
  type: LISTAR_ORGANIZACOES_SUCESSO,
  payload: organizacoes,
});

export const obterOrganizacoesErro = (mensagem) => ({
  type: LISTAR_ORGANIZACOES_ERRO,
  payload: mensagem,
});

export const filtrarOrganizacoes = (filtros) => ({
  type: FILTRAR_ORGANIZACOES,
  payload: filtros,
});

export const gerarRelatorioOrganizacoes = (payload) => ({
  type: GERAR_RELATORIO_ORGANIZACOES,
  payload: payload,
});

export const gerarRelatorioOrganizacoesSucesso = (alert) => ({
  type: GERAR_RELATORIO_ORGANIZACOES_SUCESSO,
  payload: alert,
});

export const gerarRelatorioOrganizacoesErro = (mensagem) => ({
  type: GERAR_RELATORIO_ORGANIZACOES_ERRO,
  payload: mensagem,
});
