import { AxiosResponse } from 'axios';
import api from '../../services/api';
import {
  IOrcamentoCadastro,
  IOrcamentoConsulta,
  IOrcamentoProdutoConsulta,
  IOrcamentoServicoConsulta,
} from './interfaces';
import { montarQueryString } from '../../utils';

export const obterOrcamentosAsync = (
  query,
): Promise<AxiosResponse<{ result: IOrcamentoConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `orcamentos${queryStr}`,
    method: 'GET',
  });
};

export const obterOrcamentoAsync = (id: number): Promise<AxiosResponse<any>> => {
  return api.request({
    url: `orcamentos/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoOrcamentoAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `orcamentos/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarOrcamentoAsync = (Orcamento: IOrcamentoCadastro): Promise<AxiosResponse<IOrcamentoConsulta>> => {
  return api.request({
    url: `orcamentos`,
    data: Orcamento,
    method: 'POST',
  });
};

export const atualizarOrcamentoAsync = (
  id: number,
  Orcamento: IOrcamentoCadastro,
): Promise<AxiosResponse<IOrcamentoConsulta>> => {
  return api.request({
    url: `orcamentos/${id}`,
    data: Orcamento,
    method: 'PATCH',
  });
};

export const desistenciaOrcamentoAsync = (
  produtos: { id: number; quantidadeDesistida: number }[],
): Promise<AxiosResponse> => {
  return api.request({
    url: `orcamentos/desistencia`,
    data: produtos,
    method: 'PATCH',
  });
};

export const removerOrcamentosEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `orcamentos`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerOrcamentoAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `orcamentos/${id}`,
    method: 'DELETE',
  });

export const obterItensFaturamento = (query: {
  orcamentosIds?: number[];
}): Promise<AxiosResponse<{ produtos: IOrcamentoProdutoConsulta[]; servicos: IOrcamentoServicoConsulta[] }>> => {
  const queryStr = montarQueryString(query);
  return api.request({
    url: `orcamentos/obter-itens-faturamento${queryStr}`,
    method: 'GET',
  });
};

export const obterItensDesistencia = (query: {
  orcamentosIds?: number[];
}): Promise<AxiosResponse<{ produtos: IOrcamentoProdutoConsulta[] }>> => {
  const queryStr = montarQueryString(query);
  return api.request({
    url: `orcamentos/obter-itens-desistencia${queryStr}`,
    method: 'GET',
  });
};

export const aprovarDocumentoAsync = (id: number) => {
  return api.request<void>({
    url: `orcamentos/marcar-aprovado/${id}`,
    method: 'PATCH',
  });
};

export const naoAprovarDocumentoAsync = (id: number) => {
  return api.request<void>({
    url: `orcamentos/marcar-nao-aprovado/${id}`,
    method: 'PATCH',
  });
};