import {
  FILTRAR_NOTAS_FISCAIS,
  GERAR_RELATORIO_NOTAS_FISCAIS,
  GERAR_RELATORIO_NOTAS_FISCAIS_ERRO,
  GERAR_RELATORIO_NOTAS_FISCAIS_SUCESSO,
  LISTAR_NOTAS_FISCAIS,
  LISTAR_NOTAS_FISCAIS_ERRO,
  LISTAR_NOTAS_FISCAIS_SUCESSO,
} from './constants';

export const obterTodosNotasFiscais = () => ({
  type: LISTAR_NOTAS_FISCAIS,
});

export const obterNotasFiscaisSucesso = (notasFiscais) => ({
  type: LISTAR_NOTAS_FISCAIS_SUCESSO,
  payload: notasFiscais,
});

export const obterNotasFiscaisErro = (mensagem) => ({
  type: LISTAR_NOTAS_FISCAIS_ERRO,
  payload: mensagem,
});

export const filtrarNotasFiscais = (filtros) => ({
  type: FILTRAR_NOTAS_FISCAIS,
  payload: filtros,
});
export const gerarRelatorioNotasFiscais = (payload) => ({
  type: GERAR_RELATORIO_NOTAS_FISCAIS,
  payload: payload,
});

export const gerarRelatorioNotasFiscaisSucesso = (alert) => ({
  type: GERAR_RELATORIO_NOTAS_FISCAIS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioNotasFiscaisErro = (mensagem) => ({
  type: GERAR_RELATORIO_NOTAS_FISCAIS_ERRO,
  payload: mensagem,
});
