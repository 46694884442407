import { AxiosResponse } from 'axios';

import api from '../../services/api';
import { montarQueryString } from '../../utils';
import { IServicoBaseCadastro, IServicoBaseConsulta, IServicoCadastro, IServicoConsulta } from './interfaces';

export const obterServicosBaseAsync = (
  query,
): Promise<AxiosResponse<{ result: IServicoBaseConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `servicos-base${queryStr}`,
    method: 'GET',
  });
};

export const obterServicoBaseAsync = (id: number): Promise<AxiosResponse<IServicoBaseConsulta>> => {
  return api.request({
    url: `servicos-base/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoServicoBaseAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `servicos-base/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarServicoBaseAsync = (
  sistemaProducao: IServicoBaseCadastro,
): Promise<AxiosResponse<IServicoBaseConsulta>> => {
  return api.request({
    url: `servicos-base`,
    data: sistemaProducao,
    method: 'POST',
  });
};

export const atualizarServicoBaseAsync = (
  id: number,
  sistemaProducao: IServicoBaseCadastro,
): Promise<AxiosResponse<IServicoBaseConsulta>> => {
  return api.request({
    url: `servicos-base/${id}`,
    data: sistemaProducao,
    method: 'PATCH',
  });
};

export const criarServicoAsync = (sistemaProducao: IServicoCadastro): Promise<AxiosResponse<IServicoConsulta>> => {
  return api.request({
    url: `servicos`,
    data: sistemaProducao,
    method: 'POST',
  });
};

export const atualizarServicoAsync = (
  id: number,
  sistemaProducao: IServicoCadastro,
): Promise<AxiosResponse<IServicoConsulta>> => {
  return api.request({
    url: `servicos/${id}`,
    data: sistemaProducao,
    method: 'PATCH',
  });
};

export const removerServicoEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `servicos-base`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerServicoBaseAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `servicos-base/${id}`,
    method: 'DELETE',
  });
