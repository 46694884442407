import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { ProgramaCodigo } from '../../../constants/enums/programa-codigo.enum';
import { SET_LIMIT_PAGE, SET_TABLE_ORDER } from '../../../redux/table/constants';
import { montarFiltroParaApi } from '../../../utils/api-filter';
import { obterAberturasFechamentosPDVAsync } from '../requests';
import { obterAberturaFechamentoPDVErro, obterAberturaFechamentoPDVSucesso } from './actions';
import { FILTRAR_ABERTURAS_FECHAMENTOS_PDV, LISTAR_ABERTURAS_FECHAMENTOS_PDV } from './constants';

function* listarTodosAberturaFechamentoPDV() {
  try {
    const filtro = yield select((state) => state.AberturaFechamentoPDV.filtros);
    const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.AberturaFechamentoPDV]);

    let query = { filtro: montarFiltroParaApi(filtro) };

    if (preferencias) {
      query.ordenacao = {
        campo: preferencias?.ordenarPor || 'id',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      };
      query.paginacao = {
        pagina: preferencias?.page || 1,
        limite: preferencias?.limit || 50,
      };
    }

    const { data } = yield call(obterAberturasFechamentosPDVAsync, query);
    yield put(obterAberturaFechamentoPDVSucesso(data));
  } catch (error) {
    yield put(obterAberturaFechamentoPDVErro(error.response?.data?.message || error.message));
  }
}

function* ordenarAberturaFechamentoPDV({ payload }) {
  if (payload.programaId === ProgramaCodigo.AberturaFechamentoPDV) {
    yield call(listarTodosAberturaFechamentoPDV);
  }
}

function* mudarLimitePaginacao({ payload }) {
  if (payload.programaId === ProgramaCodigo.AberturaFechamentoPDV) {
    yield call(listarTodosAberturaFechamentoPDV);
  }
}

export function* watchListarAberturaFechamentoPDV() {
  yield takeLatest(LISTAR_ABERTURAS_FECHAMENTOS_PDV, listarTodosAberturaFechamentoPDV);
}

export function* watchFiltrarAberturaFechamentoPDV() {
  yield takeLatest(FILTRAR_ABERTURAS_FECHAMENTOS_PDV, listarTodosAberturaFechamentoPDV);
}

export function* watchOrdenarAberturaFechamentoPDV() {
  yield takeLatest(SET_TABLE_ORDER, ordenarAberturaFechamentoPDV);
}

export function* watchLimitePaginacao() {
  yield takeLatest(SET_LIMIT_PAGE, mudarLimitePaginacao);
}

function* aberturaFechamentoPDVSaga() {
  yield all([
    fork(watchListarAberturaFechamentoPDV),
    fork(watchFiltrarAberturaFechamentoPDV),
    fork(watchOrdenarAberturaFechamentoPDV),
    fork(watchLimitePaginacao),
  ]);
}

export default aberturaFechamentoPDVSaga;
