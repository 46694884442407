import { AxiosResponse } from 'axios';
import api from '../../services/api';
import { montarQueryString } from '../../utils';
import { Role, Usuario } from './interfaces';

export const obterUsuarioAsync = (usuarioId: number) => {
  return api.request({
    url: `usuario/${usuarioId}`,
    method: 'GET',
  });
};

export const obterUsuariosAsync = (query = {}) => {
  const querystr = montarQueryString(query);
  return api.request({
    url: `usuario${querystr}`,
    method: 'GET',
  });
};

export const obterTiposUsuarioAsync = (query = {}) => {
  const querystr = montarQueryString(query);
  return api.request<Role[]>({
    url: `usuario/tipo_cadastro${querystr}`,
    method: 'GET',
  });
};

export const cadastrarUsuarioAsync = (usuario: Usuario) => {
  return api.request({
    url: `usuario`,
    method: 'POST',
    data: usuario,
  });
};

export const atualizarUsuarioAsync = (id, data) => {
  return api.request({
    url: `usuario/${id}`,
    method: 'PATCH',
    data,
  });
};

export const uploadAvatarUsuario = (id: number, avatar?: File) => {
  const formData = new FormData();
  formData.append('avatar', avatar || '');

  return api.request({
    url: `/usuario/${id}/upload`,
    data: formData,
    method: 'PATCH',
  });
};

export const deletarAvatarUsuario = (id: number) => {
  return api.patch(`/usuario/${id}/deletar-avatar`);
};

export const vincularOrganizacoesAsync = (
  usuarioId: number,
  organizacoesRoles: { organizacaoId: number; roleId: number | undefined }[],
  organizacoesBloqueadas?: { organizacaoId: number }[],
) => {
  return api.request({
    url: `usuario/${usuarioId}/vincular-organizacoes`,
    method: 'PATCH',
    data: { organizacoesRoles, organizacoesBloqueadas },
  });
};

export const vincularEmpresasAsync = (usuarioId: number, perfilEmpresas: { empresa: number; perfil: number }[]) => {
  return api.request({
    url: `usuario/${usuarioId}/vincular_empresas`,
    method: 'PATCH',
    data: { perfilEmpresas },
  });
};

export const vincularAcessoRapidoAsync = (programa: { programaId: number; cor: string }[]) => {
  return api.request({
    url: `usuario/vincular_acesso_rapido`,
    method: 'PATCH',
    data: { programa },
  });
};

export const acessoRapidoAsync = () => {
  return api.request({
    url: `usuario/acesso_rapido`,
    method: 'GET',
  });
};

export const vincularSegmentosSolucoesAsync = (
  usuarioId: number,
  dados: { segmentosIds: number[]; solucoesIds: number[] },
) => {
  return api.request({
    url: `usuario/${usuarioId}/vincular_segmentos_solucoes`,
    method: 'PATCH',
    data: dados,
  });
};

export const removerUsuarioAsync = (id): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `usuario/${id}`,
    method: 'DELETE',
  });
};

export const removerUsuariosEmMassaAsync = (ids): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `usuario`,
    method: 'DELETE',
    data: { ids },
  });
};
