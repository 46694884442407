import { AxiosResponse } from 'axios';

import api from '../../services/api';
import { montarQueryString } from '../../utils';
import { IAtividadeControleProdutividadeConsulta } from '../atividade-controle-produtividade/interfaces';
import { IAtividadeCadastro, IAtividadeConsulta } from './interfaces';

export const atividadesParaIntegrador = (dto: { organizacaoId: number }) => {
  const querystr = montarQueryString(dto);
  return api.request<IAtividadeConsulta[]>({
    url: `atividades/atividades-integrador${querystr}`,
    method: 'GET',
  });
};

export const obterAtividadesAsync = (
  query,
): Promise<AxiosResponse<{ result: IAtividadeConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `atividades${queryStr}`,
    method: 'GET',
  });
};

export const salvarIconeAtividadeAsync = (atividadeId: number, file: File) => {
  let formData = new FormData();
  formData.append('file', file);

  return api.request({
    url: `atividades/${atividadeId}/icone`,
    method: 'POST',
    data: formData,
  });
};

export const deletarIconeAtividadeAsync = (atividadeId: number) => {

  return api.request({
    url: `atividades/${atividadeId}/deletar-icone`,
    method: 'patch',
  });
}

export const obterAtividadeAsync = (id: number): Promise<AxiosResponse<IAtividadeConsulta>> => {
  return api.request({
    url: `atividades/${id}`,
    method: 'GET',
  });
};

export const obterControlesProdutividadeAtividadeAsync = (
  atividadeId: number,
  query: { culturasIds?: number[] },
): Promise<AxiosResponse<IAtividadeControleProdutividadeConsulta[]>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `atividades/buscar-controles-produtividade/${atividadeId}${queryStr}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoAtividadeAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `atividades/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarAtividadeAsync = (atividade: IAtividadeCadastro): Promise<AxiosResponse<IAtividadeConsulta>> => {
  return api.request({
    url: `atividades`,
    data: atividade,
    method: 'POST',
  });
};

export const atualizarAtividadeAsync = (
  id: number,
  atividade: IAtividadeCadastro,
): Promise<AxiosResponse<IAtividadeConsulta>> => {
  return api.request({
    url: `atividades/${id}`,
    data: atividade,
    method: 'PATCH',
  });
};

export const removerAtividadesEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `atividades`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerAtividadeAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `atividades/${id}`,
    method: 'DELETE',
  });
