import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { ProgramaCodigo } from '../../../constants/enums/programa-codigo.enum';
import { SET_LIMIT_PAGE, SET_TABLE_ORDER } from '../../../redux/table/constants';
import { FILTRAR_NOTAS_FISCAIS, GERAR_RELATORIO_NOTAS_FISCAIS, LISTAR_NOTAS_FISCAIS } from './constants';
import { obterNotasFiscaisAsync } from '../requests';
import {
  gerarRelatorioNotasFiscaisErro,
  gerarRelatorioNotasFiscaisSucesso,
  obterNotasFiscaisErro,
  obterNotasFiscaisSucesso,
} from './actions';
import { montarFiltroParaApi } from '../../../utils/api-filter';
import { DEFAULT_DOWNLOAD_SUCCESS_MESSAGE, gerarRelatorioRacing } from '../../../services/requests/relatorio';

function* listarTodosNotasFiscais() {
  try {
    const filtro = yield select((state) => state.NotasFiscais.filtros);
    const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.NotasFiscais]);

    let query = { filtro: montarFiltroParaApi(filtro) };

    if (preferencias) {
      query.ordenacao = {
        campo: preferencias?.ordenarPor || 'id',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      };
      query.paginacao = {
        pagina: preferencias?.page || 1,
        limite: preferencias?.limit || 50,
      };
    }

    const { data } = yield call(obterNotasFiscaisAsync, query);
    yield put(obterNotasFiscaisSucesso(data));
  } catch (error) {
    yield put(obterNotasFiscaisErro(error.response?.data?.message || error.message));
  }
}

function* relatorioNotasFiscais() {
  const { filtros, relatorio } = yield select((state) => state.NotasFiscais);
  const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.NotasFiscais]);
  const { modelo, tipo, comFiltro, colunas } = relatorio;
  const columns = colunas?.map((coluna) => coluna.value);
  let query = {};

  if (comFiltro) {
    query = {
      filtro: {
        ...montarFiltroParaApi(filtros),
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
      ordenacao: {
        campo: preferencias?.ordenarPor || 'id',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      },
    };
  } else {
    query = {
      filtro: {
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
    };
  }

  try {
    const { data, headers } = yield call(gerarRelatorioRacing, ProgramaCodigo.NotasFiscais, query, tipo);

    if (headers['content-type'].includes('application/json')) {
      yield put(
        gerarRelatorioNotasFiscaisSucesso({
          titulo: 'Pedido de impressão salvo.',
          mensagem: 'Seu relatório está sendo gerado.',
        }),
      );
    } else {
      const url = URL.createObjectURL(
        new Blob([data], {
          type: headers['content-type'] || 'application/pdf',
        }),
      );

      if (url) {
        yield put(gerarRelatorioNotasFiscaisSucesso(DEFAULT_DOWNLOAD_SUCCESS_MESSAGE));
        window.open(url, '_blank');
      }
    }
  } catch (e) {
    yield put(
      gerarRelatorioNotasFiscaisErro('Ocorreu um erro ao tentar gerar o relatório. Tente novamente mais tarde.'),
    );
  }
}

function* ordenarNotasFiscais({ payload }) {
  if (payload.programaId === ProgramaCodigo.NotasFiscais) {
    yield call(listarTodosNotasFiscais);
  }
}

function* mudarLimitePaginacao({ payload }) {
  if (payload.programaId === ProgramaCodigo.NotasFiscais) {
    yield call(listarTodosNotasFiscais);
  }
}

export function* watchListarNotasFiscais() {
  yield takeLatest(LISTAR_NOTAS_FISCAIS, listarTodosNotasFiscais);
}

export function* watchFiltrarNotasFiscais() {
  yield takeLatest(FILTRAR_NOTAS_FISCAIS, listarTodosNotasFiscais);
}

export function* watchRelatorioNotasFiscais() {
  yield takeLatest(GERAR_RELATORIO_NOTAS_FISCAIS, relatorioNotasFiscais);
}

export function* watchOrdenarNotasFiscais() {
  yield takeLatest(SET_TABLE_ORDER, ordenarNotasFiscais);
}

export function* watchLimitePaginacao() {
  yield takeLatest(SET_LIMIT_PAGE, mudarLimitePaginacao);
}

function* notasFiscaisSaga() {
  yield all([
    fork(watchListarNotasFiscais),
    fork(watchFiltrarNotasFiscais),
    fork(watchRelatorioNotasFiscais),
    fork(watchOrdenarNotasFiscais),
    fork(watchLimitePaginacao),
  ]);
}

export default notasFiscaisSaga;
