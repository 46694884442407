import {
  FILTRAR_MOVIMENTACOES_PRODUTOS,
  GERAR_RELATORIO_MOVIMENTACOES_PRODUTOS,
  GERAR_RELATORIO_MOVIMENTACOES_PRODUTOS_ERRO,
  GERAR_RELATORIO_MOVIMENTACOES_PRODUTOS_SUCESSO,
  LISTAR_MOVIMENTACOES_PRODUTOS,
  LISTAR_MOVIMENTACOES_PRODUTOS_ERRO,
  LISTAR_MOVIMENTACOES_PRODUTOS_SUCESSO,
} from './constants';

export const obterTodosMovimentacoesProdutos = () => ({
  type: LISTAR_MOVIMENTACOES_PRODUTOS,
});

export const obterMovimentacoesProdutosSucesso = (MovimentacoesProdutos) => ({
  type: LISTAR_MOVIMENTACOES_PRODUTOS_SUCESSO,
  payload: MovimentacoesProdutos,
});

export const obterMovimentacoesProdutosErro = (mensagem) => ({
  type: LISTAR_MOVIMENTACOES_PRODUTOS_ERRO,
  payload: mensagem,
});

export const filtrarMovimentacoesProdutos = (filtros) => ({
  type: FILTRAR_MOVIMENTACOES_PRODUTOS,
  payload: filtros,
});

export const gerarRelatorioMovimentacoesProdutos = (payload) => ({
  type: GERAR_RELATORIO_MOVIMENTACOES_PRODUTOS,
  payload: payload,
});

export const gerarRelatorioMovimentacoesProdutosSucesso = (alert) => ({
  type: GERAR_RELATORIO_MOVIMENTACOES_PRODUTOS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioMovimentacoesProdutosErro = (mensagem) => ({
  type: GERAR_RELATORIO_MOVIMENTACOES_PRODUTOS_ERRO,
  payload: mensagem,
});
