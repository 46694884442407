import { AxiosResponse } from 'axios';

import api from '../../services/api';
import { ITransferenciaCaixaBancoCadastro, ITransferenciaCaixaBancoConsulta } from './interfaces';
import { montarQueryString } from '../../utils';
import { IBaixaPorValorTitulo } from '../../components/Financeiro/BaixaPorValor/interfaces';
import { IBaixaMultiplaCadastro } from '../../components/Financeiro/BaixaMultipla/interfaces';

export const obterTransferenciasCaixaBancosAsync = (
  query,
): Promise<AxiosResponse<{ result: ITransferenciaCaixaBancoConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);
  return api.request({
    url: `transferencias-caixa-bancos${queryStr}`,
    method: 'GET',
  });
};

export const obterTransferenciaCaixaBancoAsync = (id: number): Promise<AxiosResponse<ITransferenciaCaixaBancoConsulta>> => {
  return api.request({
    url: `transferencias-caixa-bancos/${id}`,
    method: 'GET',
  });
};

export const criarTransferenciaCaixaBancoAsync = (
  transferenciaCaixaBanco: ITransferenciaCaixaBancoCadastro,
): Promise<AxiosResponse<ITransferenciaCaixaBancoConsulta>> => {
  return api.request({
    url: `transferencias-caixa-bancos`,
    data: transferenciaCaixaBanco,
    method: 'POST',
  });
};

export const atualizarTransferenciaCaixaBancoAsync = (
  id: number,
  transferenciaCaixaBanco: ITransferenciaCaixaBancoCadastro,
): Promise<AxiosResponse<ITransferenciaCaixaBancoConsulta>> => {
  return api.request({
    url: `transferencias-caixa-bancos/${id}`,
    data: transferenciaCaixaBanco,
    method: 'PATCH',
  });
};

export const removerTransferenciasCaixaBancosEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `transferencias-caixa-bancos`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerTransferenciaCaixaBancoAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `transferencias-caixa-bancos/${id}`,
    method: 'DELETE',
  });

export const obterPagamentosAsync = (id: number): Promise<AxiosResponse<ITransferenciaCaixaBancoConsulta>> => {
  return api.request({
    url: `transferencias-caixa-bancos/obter-pagamentos/${id}`,
    method: 'GET',
  });
};

export const baixaMultiplaTransferenciasCaixaBancos = (pagamentos: IBaixaMultiplaCadastro[]): Promise<AxiosResponse<void>> => {
  return api.request({
    url: `transferencias-caixa-bancos/baixa-multipla`,
    data: pagamentos,
    method: 'PATCH',
  });
};

export const baixaRapidaTransferenciasCaixaBancos = (titulosIds: number[]): Promise<AxiosResponse<void>> => {
  return api.request({
    url: `transferencias-caixa-bancos/baixa-rapida`,
    data: titulosIds,
    method: 'PATCH',
  });
};

export const baixaPorValorTransferenciasCaixaBancos = (dto: IBaixaPorValorTitulo): Promise<AxiosResponse<void>> => {
  return api.request({
    url: `transferencias-caixa-bancos/baixa-por-valor`,
    data: dto,
    method: 'PATCH',
  });
};
