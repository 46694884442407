import { AxiosResponse } from 'axios';
import api from '../../services/api';
import { montarQueryString } from '../../utils';
import { IListaPrecosCadastro, IListaPrecosConsulta } from './interfaces';

export const obterListaPrecosAsync = (id: number): Promise<AxiosResponse<IListaPrecosConsulta>> => {
  return api.request({
    url: `listas-precos/${id}`,
    method: 'GET',
  });
};

export const obterListasPrecosAsync = (
  query = {},
): Promise<AxiosResponse<{ result: IListaPrecosConsulta[]; total: number }>> => {
  const querystr = montarQueryString(query);
  return api.request({
    url: `listas-precos${querystr}`,
    method: 'GET',
  });
};

export const criarListaPrecosAsync = (ListaPrecos: IListaPrecosCadastro) => {
  return api.request<IListaPrecosConsulta>({
    url: `listas-precos`,
    method: 'POST',
    data: ListaPrecos,
  });
};

export const atualizarListaPrecosAsync = (id: number, ListaPrecos: IListaPrecosCadastro) => {
  return api.request<IListaPrecosConsulta>({
    url: `listas-precos/${id}`,
    method: 'PATCH',
    data: ListaPrecos,
  });
};

export const removerListasPrecosEmMassaAsync = (ids: number[]) => {
  return api.request<{ affected: number }>({
    url: `listas-precos`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerListaPrecosAsync = (id: number) => {
  return api.request<{ affected: number }>({
    url: `listas-precos/${id}`,
    method: 'DELETE',
  });
};

export const gerarProximoCodigoListaPrecosAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `listas-precos/gerar-proximo-codigo`,
    method: 'GET',
  });
};
