import React, { Dispatch, SetStateAction, useRef } from 'react';
import { FiCamera } from 'react-icons/fi';
import Webcam from 'react-webcam';

import { Boolean } from 'aws-sdk/clients/appstream';
import { Alert } from '../../helpers/Alert';
import * as S from './styles';

type CameraProps = {
  setImageURL: Dispatch<SetStateAction<string | undefined>>;
  setHasActiveCamera: Dispatch<SetStateAction<Boolean>>;
  setUserImageFile: (file: File | undefined | null) => void;
};

const videoConstraints = {
  width: 380,
  height: 380,
  facingMode: 'user',
};

export const Camera = ({ setImageURL, setHasActiveCamera, setUserImageFile }: CameraProps) => {
  const webcamRef = useRef<Webcam>(null);

  const convertBase64ToFile = async (base64: string) => {
    try {
      const response = await fetch(base64);
      const blob = await response.blob();

      return new File([blob], 'avatar.jpeg', { type: 'image/jpeg' });
    } catch (error) {
      await Alert({
        type: 'error',
        title: 'Erro na conversão do arquivo!',
        text: 'Não foi possível converter a imagem de Base64 para um arquivo do tipo JPEG.',
        showConfirmButton: true,
      });
    }
  };

  const handleTakePicture = async () => {
    if (webcamRef.current) {
      const screenshotAsBase64 = webcamRef.current.getScreenshot();

      if (screenshotAsBase64) {
        const imageFile = await convertBase64ToFile(screenshotAsBase64);

        setImageURL(screenshotAsBase64);
        setHasActiveCamera(false);
        setUserImageFile(imageFile);
      }
    }
  };

  return (
    <S.Container>
      <S.WebcamContainer>
        <Webcam
          width={videoConstraints.width}
          height={videoConstraints.height}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          videoConstraints={videoConstraints}
        />
        <div>
          <span>
            {videoConstraints.width}x{videoConstraints.width}
          </span>
        </div>
      </S.WebcamContainer>

      <S.Button type="button" onClick={handleTakePicture}>
        <FiCamera size={24} color="#fff" />
        Tirar foto
      </S.Button>
    </S.Container>
  );
};
