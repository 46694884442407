import styled from 'styled-components';

export const DivImagemUsuario = styled.div`
  display: flex;
  justify-content: center;
`;

export const InfoBasicasUsuario = styled.div`
  margin-top: 15px;
`;

export const Botao = styled.button`
  height: 35px;
  width: 35px;
  background-color: transparent;
  border: none;
`;

export const InfoUsuario = styled.ul`
  margin-top: 80px;
  height: 60%;
  width: 75vw;
  border-color: lightgray;
  border-style: solid;
  border-width: 2px;
  border-radius: 10px;
  padding-top: 20px;
  list-style: none;
  li {
    display: inline-block;
    /* padding-left: 12%; */
  }
`;

export const InfoTitulo = styled.span`
  font-weight: bold;
`;

export const NomeUsuario = styled.h1`
  padding-bottom: 0px;
  margin-bottom: 0px;
  display: inline;
`;

const infosUsuarioStyle = `display: inline;`;

export const OrgUsuario = styled.h4`
  ${infosUsuarioStyle}
`;

export const EmpresaUsuario = styled.h4`
  ${infosUsuarioStyle}
`;

export const LiInfoUsuario = styled.li`
  list-style: none;
  p {
    margin: 0;
    padding: 0;
    /* margin-left: 40px; */
  }
`;
