import { AxiosResponse } from 'axios';
import api from '../../services/api';
import { ICotacaoCadastro, ICotacaoConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterCotacoesAsync = (query): Promise<AxiosResponse<{ result: ICotacaoConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `cotacoes${queryStr}`,
    method: 'GET',
  });
};

export const obterCotacaoAsync = (id: number): Promise<AxiosResponse<ICotacaoConsulta>> => {
  return api.request({
    url: `cotacoes/${id}`,
    method: 'GET',
  });
};

export const obterCotacaoMoedaPeriodo = (
  id: number,
  data: string,
): Promise<AxiosResponse<{ ask: string; bid: string }>> => {
  return api.request({
    url: `cotacoes/get-cotacao-moeda-periodo/${id}/${data}`,
    method: 'GET',
  });
};

export const obterCotacaoDomper = (
  moedaId: number,
  data: string,
): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `cotacoes/get-cotacao-domper/${moedaId}/${data}`,
    method: 'GET',
  });
};

export const obterCotacaoMoeda = (id: number): Promise<AxiosResponse<{ ask: string; bid: string }>> => {
  return api.request({
    url: `cotacoes/get-cotacao-moeda/${id}`,
    method: 'GET',
  });
};

export const criarCotacaoAsync = (Cotacao: ICotacaoCadastro): Promise<AxiosResponse<ICotacaoConsulta>> => {
  return api.request({
    url: `cotacoes`,
    data: Cotacao,
    method: 'POST',
  });
};

export const atualizarCotacaoAsync = (
  id: number,
  Cotacao: ICotacaoCadastro,
): Promise<AxiosResponse<ICotacaoConsulta>> => {
  return api.request({
    url: `cotacoes/${id}`,
    data: Cotacao,
    method: 'PATCH',
  });
};

export const removerCotacoesEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `cotacoes`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerCotacaoAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `cotacoes/${id}`,
    method: 'DELETE',
  });
