import {
  FILTRAR_OPCOES_CADASTRAIS,
  GERAR_RELATORIO_OPCOES_CADASTRAIS,
  GERAR_RELATORIO_OPCOES_CADASTRAIS_ERRO,
  GERAR_RELATORIO_OPCOES_CADASTRAIS_SUCESSO,
  LISTAR_OPCOES_CADASTRAIS,
  LISTAR_OPCOES_CADASTRAIS_ERRO,
  LISTAR_OPCOES_CADASTRAIS_SUCESSO,
} from './constants';

export const obterTodosOpcoesCadastrais = () => ({
  type: LISTAR_OPCOES_CADASTRAIS,
});

export const obterOpcoesCadastraisSucesso = (opcoesCadastrais) => ({
  type: LISTAR_OPCOES_CADASTRAIS_SUCESSO,
  payload: opcoesCadastrais,
});

export const obterOpcoesCadastraisErro = (mensagem) => ({
  type: LISTAR_OPCOES_CADASTRAIS_ERRO,
  payload: mensagem,
});

export const filtrarOpcoesCadastrais = (filtros) => ({
  type: FILTRAR_OPCOES_CADASTRAIS,
  payload: filtros,
});

export const gerarRelatorioOpcoesCadastrais = (payload) => ({
  type: GERAR_RELATORIO_OPCOES_CADASTRAIS,
  payload: payload,
});

export const gerarRelatorioOpcoesCadastraisSucesso = (alert) => ({
  type: GERAR_RELATORIO_OPCOES_CADASTRAIS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioOpcoesCadastraisErro = (mensagem) => ({
  type: GERAR_RELATORIO_OPCOES_CADASTRAIS_ERRO,
  payload: mensagem,
});