import {
  FILTRAR_LANCAMENTOS_ATIVIDADES,
  LISTAR_LANCAMENTOS_ATIVIDADES,
  LISTAR_LANCAMENTOS_ATIVIDADES_ERRO,
  LISTAR_LANCAMENTOS_ATIVIDADES_SUCESSO,
  GERAR_RELATORIO_LANCAMENTOS_ATIVIDADES,
  GERAR_RELATORIO_LANCAMENTOS_ATIVIDADES_ERRO,
  GERAR_RELATORIO_LANCAMENTOS_ATIVIDADES_SUCESSO,
} from './constants';

export const obterTodosLancamentosAtividades = () => ({
  type: LISTAR_LANCAMENTOS_ATIVIDADES,
});

export const obterLancamentosAtividadesSucesso = (lancamentosAtividades) => ({
  type: LISTAR_LANCAMENTOS_ATIVIDADES_SUCESSO,
  payload: lancamentosAtividades,
});

export const obterLancamentosAtividadesErro = (mensagem) => ({
  type: LISTAR_LANCAMENTOS_ATIVIDADES_ERRO,
  payload: mensagem,
});

export const filtrarLancamentosAtividades = (filtros) => ({
  type: FILTRAR_LANCAMENTOS_ATIVIDADES,
  payload: filtros,
});

export const gerarRelatorioLancamentosAtividades = (payload) => ({
  type: GERAR_RELATORIO_LANCAMENTOS_ATIVIDADES,
  payload: payload,
});

export const gerarRelatorioLancamentosAtividadesSucesso = (alert) => ({
  type: GERAR_RELATORIO_LANCAMENTOS_ATIVIDADES_SUCESSO,
  payload: alert,
});

export const gerarRelatorioLancamentosAtividadesErro = (mensagem) => ({
  type: GERAR_RELATORIO_LANCAMENTOS_ATIVIDADES_ERRO,
  payload: mensagem,
});
