import {
  LISTAR_PROGRAMAS_SUCESSO,
  GERAR_RELATORIO_PROGRAMAS,
  GERAR_RELATORIO_PROGRAMAS_ERRO,
  GERAR_RELATORIO_PROGRAMAS_SUCESSO,
  LISTAR_PROGRAMAS_ERRO,
  LISTAR_PROGRAMAS,
  FILTRAR_PROGRAMAS,
} from './constants';

export const obterTodosProgramas = () => ({
  type: LISTAR_PROGRAMAS,
});

export const obterProgramasSucesso = (programas) => ({
  type: LISTAR_PROGRAMAS_SUCESSO,
  payload: programas,
});

export const obterProgramasErro = (mensagem) => ({
  type: LISTAR_PROGRAMAS_ERRO,
  payload: mensagem,
});

export const filtrarProgramas = (filtros) => ({
  type: FILTRAR_PROGRAMAS,
  payload: filtros,
});

export const gerarRelatorioProgramas = (payload) => ({
  type: GERAR_RELATORIO_PROGRAMAS,
  payload: payload,
});

export const gerarRelatorioProgramasSucesso = (alert) => ({
  type: GERAR_RELATORIO_PROGRAMAS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioProgramasErro = (mensagem) => ({
  type: GERAR_RELATORIO_PROGRAMAS_ERRO,
  payload: mensagem,
});
