import { LISTAR_PARAMETROS_SUCESSO,
  GERAR_RELATORIO_PARAMETROS,
  GERAR_RELATORIO_PARAMETROS_ERRO,
  GERAR_RELATORIO_PARAMETROS_SUCESSO,
   LISTAR_PARAMETROS_ERRO, 
   LISTAR_PARAMETROS, 
   FILTRAR_PARAMETROS } from './constants';

export const obterTodosParametros = () => ({
  type: LISTAR_PARAMETROS,
});

export const obterParametrosSucesso = (parametros) => ({
  type: LISTAR_PARAMETROS_SUCESSO,
  payload: parametros,
});

export const obterParametrosErro = (mensagem) => ({
  type: LISTAR_PARAMETROS_ERRO,
  payload: mensagem,
});

export const filtrarParametros = (filtros) => ({
  type: FILTRAR_PARAMETROS,
  payload: filtros,
});

export const gerarRelatorioParametros = (payload) => ({
  type: GERAR_RELATORIO_PARAMETROS,
  payload: payload,
});

export const gerarRelatorioParametrosSucesso = (alert) => ({
  type: GERAR_RELATORIO_PARAMETROS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioParametrosErro = (mensagem) => ({
  type: GERAR_RELATORIO_PARAMETROS_ERRO,
  payload: mensagem,
});
