import { GERAR_RELATORIO_MONITORAMENTO_CARENCIA, GERAR_RELATORIO_MONITORAMENTO_CARENCIA_ERRO, GERAR_RELATORIO_MONITORAMENTO_CARENCIA_SUCESSO } from './constants';

export const gerarRelatorioMonitoramentoCarencia = (payload) => ({
  type: GERAR_RELATORIO_MONITORAMENTO_CARENCIA,
  payload: payload,
});

export const gerarRelatorioMonitoramentoCarenciaSucesso = (alert) => ({
  type: GERAR_RELATORIO_MONITORAMENTO_CARENCIA_SUCESSO,
  payload: alert,
});

export const gerarRelatorioMonitoramentoCarenciaErro = (mensagem) => ({
  type: GERAR_RELATORIO_MONITORAMENTO_CARENCIA_ERRO,
  payload: mensagem,
});
