import { AxiosResponse } from 'axios';
import api from '../../services/api';
import { ISistemaConducaoCadastro, ISistemaConducaoConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterSistemasConducaoAsync = (
  query
): Promise<AxiosResponse<{ result: ISistemaConducaoConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `sistemas-conducao${queryStr}`,
    method: 'GET',
  });
};

export const obterSistemaConducaoAsync = (id: number): Promise<AxiosResponse<ISistemaConducaoConsulta>> => {
  return api.request({
    url: `sistemas-conducao/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoSistemaConducaoAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `sistemas-conducao/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarSistemaConducaoAsync = (sistemaConducao: ISistemaConducaoCadastro): Promise<AxiosResponse<ISistemaConducaoConsulta>> => {
  return api.request({
    url: `sistemas-conducao`,
    data: sistemaConducao,
    method: 'POST',
  });
};

export const atualizarSistemaConducaoAsync = (
  id: number,
  sistemaConducao: ISistemaConducaoCadastro
): Promise<AxiosResponse<ISistemaConducaoConsulta>> => {
  return api.request({
    url: `sistemas-conducao/${id}`,
    data: sistemaConducao,
    method: 'PATCH',
  });
};

export const removerSistemasConducaoEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `sistemas-conducao`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerSistemaConducaoAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `sistemas-conducao/${id}`,
    method: 'DELETE',
  });
