import React from 'react';
import { Container } from '../../../styles/global';
import { useSelector } from 'react-redux';
import { Spinner, Input, Card, CardTitle, Button, Row } from 'reactstrap';
import { State } from '../../../redux/reducers';
import { Form, FormField } from '../../../components/FormField';
import { Alert } from '../../../helpers/Alert';
import { trocarSenhaPrimeiroAcessoAsync } from '../requests';
import { apiExceptionHandler } from '../../../helpers/exception-handler';

const AlterarSenha: React.FC = () => {
  const { user: usuario, loading } = useSelector((state: State) => state.Auth);

  const submit = async (values) => {
    if (!values.senha || !values.senhaAntiga) {
      throw Alert({
        title: 'A senha não pode estar vazia',
        type: 'error',
      });
    }

    if (values.senha.length < 8) {
      throw Alert({
        title: 'Nova senha deve conter no mínimo 8 caracteres',
        type: 'error',
      });
    }

    if (values.senha === values.confirmacaoSenha) {
      try {
        await trocarSenhaPrimeiroAcessoAsync(values);
        await Alert({
          title: 'Nova senha salva com sucesso!',
          type: 'success',
        });
        window.location.href = '/dashboard';
      } catch (err) {
        apiExceptionHandler(err);
      }
    } else {
      throw Alert({
        title: 'A confirmação da nova senha não corresponde a anterior',
        type: 'error',
      });
    }
  };

  if (loading) return <Spinner />;
  if (!usuario) {
    window.location.href = '/account/login';
  }

  return (
    <Container>
      <Card body style={{ maxWidth: 300 }}>
        <CardTitle tag="h5">Alterar Senha</CardTitle>
        <Form onFinish={submit}>
          <Row>
            <FormField responsivity={{ xs: 12 }} name="senhaAntiga" label="Senha Atual">
              <Input type="password" />
            </FormField>

            <FormField
              rules={[
                {
                  pattern: /(?=^.{8,}$)((?=.*\d)(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/g,
                  message:
                    'A Nova Senha deve conter no mínimo: 8 dígitos, uma letra maiúscula, uma minúscula, um número e um caracter especial',
                },
              ]}
              responsivity={{ xs: 12 }}
              name="senha"
              label="Nova Senha"
            >
              <Input type="password" />
            </FormField>

            <FormField responsivity={{ xs: 12 }} name="confirmacaoSenha" label="Confirme a nova senha">
              <Input type="password" />
            </FormField>
          </Row>
          <Button>Alterar</Button>
        </Form>
      </Card>
    </Container>
  );
};

export default AlterarSenha;
