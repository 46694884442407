import { AxiosResponse } from 'axios';

import api from '../../services/api';
import { IGrupoMaquinaImplementoCadastro, IGrupoMaquinaImplementoConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterGruposMaquinasImplementosAsync = (
  query,
): Promise<AxiosResponse<{ result: IGrupoMaquinaImplementoConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `grupos-maquinas-implementos${queryStr}`,
    method: 'GET',
  });
};

export const obterGrupoMaquinaImplementoAsync = (
  id: number,
): Promise<AxiosResponse<IGrupoMaquinaImplementoConsulta>> => {
  return api.request({
    url: `grupos-maquinas-implementos/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoGrupoMaquinaImplementoAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `grupos-maquinas-implementos/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarGrupoMaquinaImplementoAsync = (
  grupoMaquinaImplemento: IGrupoMaquinaImplementoCadastro,
): Promise<AxiosResponse<IGrupoMaquinaImplementoConsulta>> => {
  return api.request({
    url: `grupos-maquinas-implementos`,
    data: grupoMaquinaImplemento,
    method: 'POST',
  });
};

export const atualizarGrupoMaquinaImplementoAsync = (
  id: number,
  grupoMaquinaImplemento: IGrupoMaquinaImplementoCadastro,
): Promise<AxiosResponse<IGrupoMaquinaImplementoConsulta>> => {
  return api.request({
    url: `grupos-maquinas-implementos/${id}`,
    data: grupoMaquinaImplemento,
    method: 'PATCH',
  });
};

export const removerGruposMaquinasImplementosEmMassaAsync = (
  ids: number[],
): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `grupos-maquinas-implementos`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerGrupoMaquinaImplementoAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `grupos-maquinas-implementos/${id}`,
    method: 'DELETE',
  });
