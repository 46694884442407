import * as FeatherIcon from 'react-feather';
import { ISubMenu } from '../../../interfaces/menu';
import { PermissaoCodigo } from '../../enums/permissao-codigo.enum';
import { ProgramaCodigo } from '../../enums/programa-codigo.enum';

export const CadastrosProdutos: ISubMenu = {
  name: 'Produtos',
  icon: FeatherIcon.Package,
  children: [
    {
      programaCod: ProgramaCodigo.Produtos,
      name: 'Produtos',
      path: '/produtos/listar',
      style: {
        fontWeight: 'bold',
      },
      component: 'listarProdutos',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/produtos/listar',
          component: 'listarProdutos',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/produtos/criar',
          component: 'produtos',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/produtos/editar/:id',
          component: 'produtos',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/produtos/visualizar/:id',
          component: 'produtos',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/produtos/replicar/:id',
          component: 'produtos',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.ClassesProdutos,
      name: 'Classes de Produtos',
      path: '/classes-produtos/listar',
      component: 'listarClassesProdutos',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/classes-produtos/listar',
          component: 'listarClassesProdutos',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/classes-produtos/criar',
          component: 'classesProdutos',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/classes-produtos/editar/:id',
          component: 'classesProdutos',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/classes-produtos/visualizar/:id',
          component: 'classesProdutos',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.CoresProdutos,
      name: 'Cores de Produtos',
      path: '/cores-produtos/listar',
      component: 'listarCoresProdutos',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/cores-produtos/listar',
          component: 'listarCoresProdutos',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/cores-produtos/criar',
          component: 'coresProdutos',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/cores-produtos/editar/:id',
          component: 'coresProdutos',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/cores-produtos/visualizar/:id',
          component: 'coresProdutos',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.DepartamentoProduto,
      name: 'Departamentos de Produtos',
      path: '/departamentos-produtos/listar',
      component: 'listarDepartamentoProdutos',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/departamentos-produtos/listar',
          component: 'listarDepartamentoProdutos',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/departamentos-produtos/criar',
          component: 'departamentoProdutos',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/departamentos-produtos/editar/:id',
          component: 'departamentoProdutos',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/departamentos-produtos/visualizar/:id',
          component: 'departamentoProdutos',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.Depositos,
      name: 'Depósitos',
      path: '/depositos/listar',
      component: 'listarDepositos',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/depositos/listar',
          component: 'listarDepositos',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/depositos/criar',
          component: 'depositos',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/depositos/editar/:id',
          component: 'depositos',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/depositos/visualizar/:id',
          component: 'depositos',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.GrupoProduto,
      name: 'Grupos de Produtos',
      path: '/grupos-produtos/listar',
      component: 'listarGruposProduto',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/grupos-produtos/listar',
          component: 'listarGruposProduto',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/grupos-produtos/criar',
          component: 'gruposProduto',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/grupos-produtos/editar/:id',
          component: 'gruposProduto',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/grupos-produtos/visualizar/:id',
          component: 'gruposProduto',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.GruposQuimicos,
      name: 'Grupos Químicos',
      path: '/grupos-quimicos/listar',
      component: 'listarGruposQuimicos',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/grupos-quimicos/listar',
          component: 'listarGruposQuimicos',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/grupos-quimicos/criar',
          component: 'gruposQuimicos',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/grupos-quimicos/editar/:id',
          component: 'gruposQuimicos',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/grupos-quimicos/visualizar/:id',
          component: 'gruposQuimicos',
        },
      ],
    },

    {
      programaCod: ProgramaCodigo.InformacoesNutricionais,
      name: 'Informações Nutricionais',
      path: '/informacoes-nutricionais/listar',
      component: 'listarInformacoesNutricionais',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/informacoes-nutricionais/listar',
          component: 'listarInformacoesNutricionais',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/informacoes-nutricionais/criar',
          component: 'informacoesNutricionais',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/informacoes-nutricionais/editar/:id',
          component: 'informacoesNutricionais',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/informacoes-nutricionais/visualizar/:id',
          component: 'informacoesNutricionais',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.ListasPrecos,
      name: 'Listas de Preços',
      path: '/listas-precos/listar',
      component: 'listarListasPrecos',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/listas-precos/listar',
          component: 'listarListasPrecos',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/listas-precos/criar',
          component: 'listasPrecos',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/listas-precos/editar/:id',
          component: 'listasPrecos',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/listas-precos/visualizar/:id',
          component: 'listasPrecos',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.MarcasProdutos,
      name: 'Marcas de Produtos',
      path: '/marcas-produtos/listar',
      component: 'listarMarcasProdutos',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/marcas-produtos/listar',
          component: 'listarMarcasProdutos',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/marcas-produtos/criar',
          component: 'marcasProdutos',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/marcas-produtos/editar/:id',
          component: 'marcasProdutos',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/marcas-produtos/visualizar/:id',
          component: 'marcasProdutos',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.PrincipiosAtivo,
      name: 'Princípios Ativos',
      path: '/principios-ativos/listar',
      component: 'listarPrincipiosAtivos',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/principios-ativos/listar',
          component: 'listarPrincipiosAtivos',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/principios-ativos/criar',
          component: 'principiosAtivos',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/principios-ativos/editar/:id',
          component: 'principiosAtivos',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/principios-ativos/visualizar/:id',
          component: 'principiosAtivos',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.Promocoes,
      name: 'Promoções',
      path: '/promocoes/listar',
      component: 'listarPromocoes',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/promocoes/listar',
          component: 'listarPromocoes',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/promocoes/criar',
          component: 'promocoes',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/promocoes/editar/:id',
          component: 'promocoes',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/promocoes/visualizar/:id',
          component: 'promocoes',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.SubGrupoProduto,
      name: 'Subgrupos de Produtos',
      path: '/sub-grupos-produto/listar',
      component: 'listarSubGruposProduto',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/sub-grupos-produto/listar',
          component: 'listarSubGruposProduto',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/sub-grupos-produto/criar',
          component: 'subGruposProduto',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/sub-grupos-produto/editar/:id',
          component: 'subGruposProduto',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/sub-grupos-produto/visualizar/:id',
          component: 'subGruposProduto',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.TamanhoProdutos,
      name: 'Tamanhos de Produtos',
      path: '/tamanho-produtos/listar',
      component: 'listarTamanhoProdutos',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/tamanho-produtos/listar',
          component: 'listarTamanhoProdutos',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/tamanho-produtos/criar',
          component: 'tamanhoProdutos',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/tamanho-produtos/editar/:id',
          component: 'tamanhoProdutos',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/tamanho-produtos/visualizar/:id',
          component: 'tamanhoProdutos',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.UnidadeMedidaProduto,
      name: 'Unidades de Medida',
      path: '/unidades-medida-produto/listar',
      component: 'listarUnidadesMedidasProdutos',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/unidades-medida-produto/listar',
          component: 'listarUnidadesMedidasProdutos',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/unidades-medida-produto/criar',
          component: 'unidadesMedidasProdutos',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/unidades-medida-produto/editar/:id',
          component: 'unidadesMedidasProdutos',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/unidades-medida-produto/visualizar/:id',
          component: 'unidadesMedidasProdutos',
        },
      ],
    },
  ],
};
