import api from '../../services/api';
import { AxiosResponse } from 'axios';
import { ISafraCadastro, ISafraConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterSafrasAsync = (query): Promise<AxiosResponse<{ result: ISafraConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `safras${queryStr}`,
    method: 'GET',
  });
};

export const obterSafraAsync = (id: number): Promise<AxiosResponse<ISafraConsulta>> => {
  return api.request({
    url: `safras/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoSafraAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `safras/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarSafraAsync = (safra: ISafraCadastro): Promise<AxiosResponse<ISafraConsulta>> => {
  return api.request({
    url: `safras`,
    data: safra,
    method: 'POST',
  });
};

export const atualizarSafraAsync = (id: number, safra: ISafraCadastro): Promise<AxiosResponse<ISafraConsulta>> => {
  return api.request({
    url: `safras/${id}`,
    data: safra,
    method: 'PATCH',
  });
};

export const removerSafrasEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `safras`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerSafraAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `safras/${id}`,
    method: 'DELETE',
  });
