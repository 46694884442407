import React from 'react';
import SelecaoBase from '../../../components/SelecaoSimples/SelecaoBase';
import { StatusRecomendacaoTecnica } from '../../../constants/enums/status-recomendacao-tecnica.enum';
import { ISelecao } from '../../../interfaces/selecao';

interface IProps {
  value?: ISelecao;
  disabled?: boolean;
  multiple?: boolean;
  onChange?: (value?: ISelecao) => void;
}

const statusOptions = [
  { value: StatusRecomendacaoTecnica.Aberta, label: 'Aberta' },
  { value: StatusRecomendacaoTecnica.ExecutadaIntegral, label: 'Executada Integral' },
  { value: StatusRecomendacaoTecnica.ExecutadaComAlteracoes, label: 'Executada com Alterações' },
  { value: StatusRecomendacaoTecnica.NaoExecutada, label: 'Não Executada' },
];

const SelecaoStatus: React.FC<IProps> = ({ value, disabled, multiple, onChange, ...props }) => {
  return (
    <SelecaoBase
      disabled={disabled}
      options={statusOptions}
      onChange={onChange}
      value={value}
      multiple={!!multiple}
      {...props}
    />
  );
};

export default SelecaoStatus;
