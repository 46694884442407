import {
  FILTRAR_ABERTURAS_FECHAMENTOS_PDV,
  LISTAR_ABERTURAS_FECHAMENTOS_PDV,
  LISTAR_ABERTURAS_FECHAMENTOS_PDV_ERRO,
  LISTAR_ABERTURAS_FECHAMENTOS_PDV_SUCESSO,
} from './constants';

export const obterTodosAberturaFechamentoPDV = () => ({
  type: LISTAR_ABERTURAS_FECHAMENTOS_PDV,
});

export const obterAberturaFechamentoPDVSucesso = (aberturaFechamentoPDV) => ({
  type: LISTAR_ABERTURAS_FECHAMENTOS_PDV_SUCESSO,
  payload: aberturaFechamentoPDV,
});

export const obterAberturaFechamentoPDVErro = (mensagem) => ({
  type: LISTAR_ABERTURAS_FECHAMENTOS_PDV_ERRO,
  payload: mensagem,
});

export const filtrarAberturaFechamentoPDV = (filtros) => ({
  type: FILTRAR_ABERTURAS_FECHAMENTOS_PDV,
  payload: filtros,
});
