// @flow
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILED,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILED,
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAILED,
  UPDATE_USER,
  SETAR_ORGANIZACOES_AUTORIZADAS,
  SETAR_EMPRESAS_AUTORIZADAS,
  OBTER_ORGANIZACOES_AUTORIZADAS,
  SELECIONAR_ORGANIZACAO,
  OBTER_MODULOS_PERMISSOES,
  SELECIONAR_EMPRESA,
  OBTER_EMPRESAS_AUTORIZADAS,
  SETAR_PROGRAMAS_PERMISSOES,
  PRIMEIRO_ACESSO_TROCAR_SENHA,
  SELECIONAR_SEGMENTO,
  OBTER_SEGMENTOS_AUTORIZADOS,
  SETAR_SEGMENTOS_AUTORIZADOS,
  SELECIONAR_SOLUCAO_ID,
  SETAR_PARAMETROS_REDUX,
  OBTER_PARAMETROS_REDUX,
} from './constants';

export const loginUser = (login, password) => ({
  type: LOGIN_USER,
  payload: { login, password },
});

export const selecionarSolucaoId = (solucaoId) => ({
  type: SELECIONAR_SOLUCAO_ID,
  payload: solucaoId,
});

export const loginUserSuccess = (user) => ({
  type: LOGIN_USER_SUCCESS,
  payload: user,
});

export const loginUserFailed = (error) => ({
  type: LOGIN_USER_FAILED,
  payload: error,
});

export const registerUser = (fullname, email, password) => ({
  type: REGISTER_USER,
  payload: { fullname, email, password },
});

export const registerUserSuccess = (user) => ({
  type: REGISTER_USER_SUCCESS,
  payload: user,
});

export const registerUserFailed = (error) => ({
  type: REGISTER_USER_FAILED,
  payload: error,
});

export const logoutUser = () => ({
  type: LOGOUT_USER,
});

export const forgetPassword = (login) => ({
  type: FORGET_PASSWORD,
  payload: { login },
});

export const forgetPasswordSuccess = (passwordResetStatus) => ({
  type: FORGET_PASSWORD_SUCCESS,
  payload: passwordResetStatus,
});

export const forgetPasswordFailed = (error) => ({
  type: FORGET_PASSWORD_FAILED,
  payload: error,
});

export const updateUser = (data) => ({
  type: UPDATE_USER,
  payload: data,
});

export const primeiroAcessoTrocarSenha = ({ senha, senhaAntiga, confirmacaoSenha }) => ({
  type: PRIMEIRO_ACESSO_TROCAR_SENHA,
  payload: {
    senhaAntiga,
    senha,
    confirmacaoSenha,
  },
});

export const buscarOrganizacoesAutorizadas = () => ({
  type: OBTER_ORGANIZACOES_AUTORIZADAS,
});

export const buscarEmpresasAutorizadas = () => ({
  type: OBTER_EMPRESAS_AUTORIZADAS,
});

export const buscarSegmentosAutorizados = () => ({
  type: OBTER_SEGMENTOS_AUTORIZADOS,
});

export const obterModulosPermissoes = () => ({
  type: OBTER_MODULOS_PERMISSOES,
});

export const selecionarOrganizacao = (organizacao) => ({
  type: SELECIONAR_ORGANIZACAO,
  payload: organizacao,
});

export const selecionarEmpresa = (empresa) => ({
  type: SELECIONAR_EMPRESA,
  payload: empresa,
});

export const selecionarSegmento = (segmento) => ({
  type: SELECIONAR_SEGMENTO,
  payload: segmento,
});

export const setarOrganizacoesAutorizadas = (organizacoes) => ({
  type: SETAR_ORGANIZACOES_AUTORIZADAS,
  payload: organizacoes,
});

export const setarEmpresasAutorizadas = (empresas) => ({
  type: SETAR_EMPRESAS_AUTORIZADAS,
  payload: empresas,
});

export const setarSegmentosAutorizados = (segmentos) => ({
  type: SETAR_SEGMENTOS_AUTORIZADOS,
  payload: segmentos,
});

export const setarProgramasPermissoes = (programas) => ({
  type: SETAR_PROGRAMAS_PERMISSOES,
  payload: programas,
});

export const obterParametrosRedux = () => ({
  type: OBTER_PARAMETROS_REDUX,
});

export const setarParametrosRedux = (parametros) => ({
  type: SETAR_PARAMETROS_REDUX,
  payload: parametros,
});
