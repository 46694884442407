export enum SituacaoContrato {
    Ativo = 1,
    Cancelado = 2,
    Suspenso = 3,
  }

  export enum SituacaoComodatoContrato {
    Aberto = 1,
    Fechado = 2,
  }
  
  export enum TipoMovimentacaoContrato {
    Entrada = 1,
    Saida = 2,
  }