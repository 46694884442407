import {
  FILTRAR_CONTRATOS,
  GERAR_RELATORIO_CONTRATOS,
  GERAR_RELATORIO_CONTRATOS_ERRO,
  GERAR_RELATORIO_CONTRATOS_SUCESSO,
  LISTAR_CONTRATOS,
  LISTAR_CONTRATOS_ERRO,
  LISTAR_CONTRATOS_SUCESSO,
} from './constants';

export const obterTodosContratos = () => ({
  type: LISTAR_CONTRATOS,
});

export const obterContratosSucesso = (contratos) => ({
  type: LISTAR_CONTRATOS_SUCESSO,
  payload: contratos,
});

export const obterContratosErro = (mensagem) => ({
  type: LISTAR_CONTRATOS_ERRO,
  payload: mensagem,
});

export const filtrarContratos = (filtros) => ({
  type: FILTRAR_CONTRATOS,
  payload: filtros,
});

export const gerarRelatorioContratos = (payload) => ({
  type: GERAR_RELATORIO_CONTRATOS,
  payload: payload,
});

export const gerarRelatorioContratosSucesso = (alert) => ({
  type: GERAR_RELATORIO_CONTRATOS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioContratosErro = (mensagem) => ({
  type: GERAR_RELATORIO_CONTRATOS_ERRO,
  payload: mensagem,
});