import {
  FILTRAR_ENTRADA_FRUTAS,
  LISTAR_ENTRADA_FRUTAS,
  LISTAR_ENTRADA_FRUTAS_ERRO,
  LISTAR_ENTRADA_FRUTAS_SUCESSO,
} from './constants';

export const obterTodosEntradaFrutas = () => ({
  type: LISTAR_ENTRADA_FRUTAS,
});

export const obterEntradaFrutasSucesso = (segmento) => ({
  type: LISTAR_ENTRADA_FRUTAS_SUCESSO,
  payload: segmento,
});

export const obterEntradaFrutasErro = (mensagem) => ({
  type: LISTAR_ENTRADA_FRUTAS_ERRO,
  payload: mensagem,
});

export const filtrarEntradaFrutas = (f) => {
  return {
    type: FILTRAR_ENTRADA_FRUTAS,
    payload: f,
  };
};
