import api from '../../services/api';
import { montarQueryString } from '../../utils';
import { ICentroCustoRateio, ILancamentoCustoCadastro, ILancamentoCustoConsulta } from './interfaces';

export const obterLancamentosCustosAsync = (query = {}) => {
  const querystr = montarQueryString(query);

  return api.request<{ result: ILancamentoCustoConsulta[]; total: number }>({
    url: `lancamentos-custos${querystr}`,
    method: 'GET',
  });
};

export const obterLancamentoCustoAsync = (id: number) => {
  return api.request<ILancamentoCustoConsulta>({
    url: `lancamentos-custos/${id}`,
    method: 'GET',
  });
};

export const obterCentrosCustoRateioAsync = (
  centroCustoPrincipalId: number,
  query: {
    propriedadesIds: number[] | undefined;
    culturasIds: number[] | undefined;
    variedadesIds: number[] | undefined;
    variedadesClonesIds: number[] | undefined;
  },
) => {
  const querystr = montarQueryString(query);

  return api.request<ICentroCustoRateio>({
    url: `lancamentos-custos/centros-custo-rateio/${centroCustoPrincipalId}${querystr}`,
    method: 'GET',
  });
};

export const criarLancamentoCustoAsync = (lancamentoCusto: ILancamentoCustoCadastro) => {
  return api.request<ILancamentoCustoConsulta>({
    url: `lancamentos-custos`,
    method: 'POST',
    data: lancamentoCusto,
  });
};

export const atualizarLancamentoCustoAsync = (id: number, lancamentoCusto: ILancamentoCustoCadastro) => {
  return api.request<ILancamentoCustoConsulta>({
    url: `lancamentos-custos/${id}`,
    method: 'PATCH',
    data: lancamentoCusto,
  });
};

export const removerLancamentosCustosEmMassaAsync = (ids: number[]) => {
  return api.request<{ affected: number }>({
    url: `lancamentos-custos`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerLancamentoCustoAsync = (id: number) =>
  api.request<{ affected: number }>({
    url: `lancamentos-custos/${id}`,
    method: 'DELETE',
  });
