import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { ProgramaCodigo } from '../../../constants/enums/programa-codigo.enum';
import { SET_LIMIT_PAGE, SET_TABLE_ORDER } from '../../../redux/table/constants';
import { DEFAULT_DOWNLOAD_SUCCESS_MESSAGE, gerarRelatorioRacing } from '../../../services/requests/relatorio';
import { montarFiltroParaApi } from '../../../utils/api-filter';
import { obterGruposQuimicosAsync } from '../requests';
import {
  gerarRelatorioGruposQuimicosErro,
  gerarRelatorioGruposQuimicosSucesso,
  obterGruposQuimicosErro,
  obterGruposQuimicosSucesso,
} from './actions';
import { FILTRAR_GRUPOS_QUIMICOS, GERAR_RELATORIO_GRUPOS_QUIMICOS, LISTAR_GRUPOS_QUIMICOS } from './constants';

function* listarTodosGruposQuimicos() {
  try {
    const filtro = yield select((state) => state.GruposQuimicos.filtros);
    const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.GruposQuimicos]);

    let query = { filtro: montarFiltroParaApi(filtro) };

    if (preferencias) {
      query.ordenacao = {
        campo: preferencias?.ordenarPor || 'nome',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      };
      query.paginacao = {
        pagina: preferencias?.page || 1,
        limite: preferencias?.limit || 50,
      };
    }

    const { data } = yield call(obterGruposQuimicosAsync, query);
    yield put(obterGruposQuimicosSucesso(data));
  } catch (error) {
    yield put(obterGruposQuimicosErro(error.response?.data?.message || error.message));
  }
}

function* relatorioGruposQuimicos() {
  const { filtros, relatorio } = yield select((state) => state.GruposQuimicos);
  const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.GruposQuimicos]);
  const { modelo, tipo, comFiltro, colunas } = relatorio;
  const columns = colunas?.map((coluna) => coluna.value);
  let query = {};

  if (comFiltro) {
    query = {
      filtro: {
        ...montarFiltroParaApi(filtros),
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
      ordenacao: {
        campo: preferencias?.ordenarPor || 'nome',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      },
    };
  } else {
    query = {
      filtro: {
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
    };
  }

  try {
    const { data, headers } = yield call(gerarRelatorioRacing, ProgramaCodigo.GruposQuimicos, query, tipo);
    const contentType = headers['content-type'];

    if (!contentType.includes('application/json')) {
      const url = URL.createObjectURL(
        new Blob([data], {
          type: contentType || 'application/pdf',
        }),
      );

      if (url) {
        yield put(gerarRelatorioGruposQuimicosSucesso(DEFAULT_DOWNLOAD_SUCCESS_MESSAGE));
        window.open(url, '_blank');
      }
    } else {
      yield put(
        gerarRelatorioGruposQuimicosSucesso({
          titulo: 'Pedido de impressão salvo.',
          mensagem: 'Seu relatório está sendo gerado.',
        }),
      );
    }
  } catch (e) {
    yield put(
      gerarRelatorioGruposQuimicosErro('Ocorreu um erro ao tentar gerar o relatório. Tente novamente mais tarde.'),
    );
  }
}

function* ordenarGruposQuimicos({ payload }) {
  if (payload.programaId === ProgramaCodigo.GruposQuimicos) {
    yield call(listarTodosGruposQuimicos);
  }
}

function* mudarLimitePaginacao({ payload }) {
  if (payload.programaId === ProgramaCodigo.GruposQuimicos) {
    yield call(listarTodosGruposQuimicos);
  }
}

export function* watchListarGruposQuimicos() {
  yield takeLatest(LISTAR_GRUPOS_QUIMICOS, listarTodosGruposQuimicos);
}

export function* watchFiltrarGruposQuimicos() {
  yield takeLatest(FILTRAR_GRUPOS_QUIMICOS, listarTodosGruposQuimicos);
}

export function* watchRelatorioGruposQuimicos() {
  yield takeLatest(GERAR_RELATORIO_GRUPOS_QUIMICOS, relatorioGruposQuimicos);
}

export function* watchOrdenarGruposQuimicos() {
  yield takeLatest(SET_TABLE_ORDER, ordenarGruposQuimicos);
}

export function* watchLimitePaginacao() {
  yield takeLatest(SET_LIMIT_PAGE, mudarLimitePaginacao);
}

function* gruposQuimicosSaga() {
  yield all([
    fork(watchListarGruposQuimicos),
    fork(watchFiltrarGruposQuimicos),
    fork(watchRelatorioGruposQuimicos),
    fork(watchOrdenarGruposQuimicos),
    fork(watchLimitePaginacao),
  ]);
}

export default gruposQuimicosSaga;
