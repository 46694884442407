import React from 'react';

import SelecaoAsyncBase, { SelectOption } from './SelecaoAsyncBase';
import { obterSafrasAsync } from '../../pages/safras/requests';
import { ISafraConsulta } from '../../pages/safras/interfaces';
import { ISelecao } from '../../interfaces/selecao';

export interface ISelecaoSafra extends ISelecao {
  dataInicial: string;
  dataFinal: string;
}

interface IFilters {
  status?: number[];
  dataLancamento?: Date;
  dataInicial?: Date;
  dataFinal?: Date;
  dataCompetencia?: Date[];
  'propriedades.id'?: number[];
  propriedadesLancamento?: number[];
  culturasLancamento?: number[];
}

interface IProps {
  value?: SelectOption;
  onChange?: (value: SelectOption) => void;
  multiple?: boolean;
  filters?: IFilters;
  autoSelect?: boolean;
  callback?: (value: { result: any[]; total: number }) => void;
}

const SelecaoSafra: React.FC<IProps> = ({ filters, callback, ...props }) => {
  const carregarOpcoes = async (filtro: string) => {
    let busca = filtro;
    let buscarPor = isNaN(parseInt(filtro)) ? 'nome' : 'codigo';

    const { data } = await obterSafrasAsync({
      filtro: {
        buscarPor,
        busca,
        status: [1],
        ...filters,
      },
      ordenacao: {
        campo: 'nome',
        ordem: 'ASC',
      },
    });

    callback && callback(data);

    return data.result.map(formatarParaSelectOption);
  };

  const formatarParaSelectOption = (safra: ISafraConsulta) => ({
    label: safra.codigo.toString() + ' - ' + safra.nome,
    value: safra.id,

    dataInicial: safra.dataInicial,
    dataFinal: safra.dataFinal,
  });

  return (
    <SelecaoAsyncBase
      key={`selecao-safra-${JSON.stringify(filters)}`}
      nomeRecurso="Safras"
      carregarOpcoes={carregarOpcoes}
      {...props}
    />
  );
};

export default SelecaoSafra;