import Swal from 'sweetalert2';
import { filtroPadraoStatus } from '../../../constants/filtros';
import { FILTRAR_TRIBUTACOES_SERVICOS, LISTAR_TRIBUTACOES_SERVICOS, LISTAR_TRIBUTACOES_SERVICOS_ERRO, LISTAR_TRIBUTACOES_SERVICOS_SUCESSO } from './constants';

const INIT_STATE = {
  tributacoesServicos: [],
  filtros: { ...filtroPadraoStatus },
  carregando: false,
};

const TributacoesServicosReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LISTAR_TRIBUTACOES_SERVICOS:
      return { ...state, carregando: true };
    case FILTRAR_TRIBUTACOES_SERVICOS:
      return { ...state, filtros: action.payload, carregando: true };
    case LISTAR_TRIBUTACOES_SERVICOS_SUCESSO:
      return { ...state, tributacoesServicos: action.payload, carregando: false };
    case LISTAR_TRIBUTACOES_SERVICOS_ERRO:
      Swal.fire({
        icon: 'error',
        title: 'Erro ao buscar tributacoes serviços',
        timer: 2000,
        text: action.payload,
      });
      return { ...state, tributacoesServicos: [], carregando: false };
    default:
      return state;
  }
};

export default TributacoesServicosReducer;
