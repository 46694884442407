import {
  FILTRAR_CENTROS_CUSTOS,
  GERAR_RELATORIO_CENTROS_CUSTOS,
  GERAR_RELATORIO_CENTROS_CUSTOS_ERRO,
  GERAR_RELATORIO_CENTROS_CUSTOS_SUCESSO,
  LISTAR_CENTROS_CUSTOS,
  LISTAR_CENTROS_CUSTOS_ERRO,
  LISTAR_CENTROS_CUSTOS_SUCESSO,
} from './constants';

export const obterTodosCentrosCustos = () => ({
  type: LISTAR_CENTROS_CUSTOS,
});

export const obterCentrosCustosSucesso = (CentrosCustos) => ({
  type: LISTAR_CENTROS_CUSTOS_SUCESSO,
  payload: CentrosCustos,
});

export const obterCentrosCustosErro = (mensagem) => ({
  type: LISTAR_CENTROS_CUSTOS_ERRO,
  payload: mensagem,
});

export const filtrarCentrosCustos = (filtros) => ({
  type: FILTRAR_CENTROS_CUSTOS,
  payload: filtros,
});

export const gerarRelatorioCentrosCustos = (payload) => ({
  type: GERAR_RELATORIO_CENTROS_CUSTOS,
  payload: payload,
});

export const gerarRelatorioCentrosCustosSucesso = (alert) => ({
  type: GERAR_RELATORIO_CENTROS_CUSTOS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioCentrosCustosErro = (mensagem) => ({
  type: GERAR_RELATORIO_CENTROS_CUSTOS_ERRO,
  payload: mensagem,
});
