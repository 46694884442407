import api from '../../services/api';
import { AxiosResponse } from 'axios';
import { IPlanoContaCadastro, IPlanoContaConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterPlanoContasAsync = (
  query,
): Promise<
  AxiosResponse<{ result: IPlanoContaConsulta[]; total: number }>
> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `plano-contas${queryStr}`,
    method: 'GET',
  });
};

export const obterPlanoContaAsync = (
  id: number,
): Promise<AxiosResponse<IPlanoContaConsulta>> => {
  return api.request({
    url: `plano-contas/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoPlanoContaAsync = (): Promise<
  AxiosResponse<number>
> => {
  return api.request({
    url: `plano-contas/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarPlanoContasAsync = (
  planoConta: IPlanoContaCadastro,
): Promise<AxiosResponse<IPlanoContaConsulta>> => {
  return api.request({
    url: `plano-contas`,
    data: planoConta,
    method: 'POST',
  });
};

export const atualizarPlanoContasAsync = (
  id: number,
  planoConta: IPlanoContaCadastro,
): Promise<AxiosResponse<IPlanoContaConsulta>> => {
  return api.request({
    url: `plano-contas/${id}`,
    data: planoConta,
    method: 'PATCH',
  });
};

export const removerPlanoContasEmMassaAsync = (
  ids: number[],
): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `plano-contas`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerPlanoContasAsync = (
  id: number,
): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `plano-contas/${id}`,
    method: 'DELETE',
  });
