import * as FeatherIcon from 'react-feather';
import { ISubMenu } from '../../../../interfaces/menu';
import { PermissaoCodigo } from '../../../enums/permissao-codigo.enum';
import { ProgramaCodigo } from '../../../enums/programa-codigo.enum';

export const CadastrosAgroPacking: ISubMenu = {
  name: 'Agro Packing',
  icon: FeatherIcon.CloudRain,
  children: [
    {
      programaCod: ProgramaCodigo.CamarasFria,
      name: 'Câmaras Fria',
      path: '/camaras-fria/listar',
      component: 'listarCamarasFria',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/camaras-fria/listar',
          component: 'listarCamarasFria',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/camaras-fria/criar',
          component: 'camarasFria',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/camaras-fria/editar/:id',
          component: 'camarasFria',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/camaras-fria/visualizar/:id',
          component: 'camarasFria',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.CategoriasProdutosAgro,
      name: 'Categorias de Produtos Agro',
      path: '/categorias-produtos-agro/listar',
      component: 'listarCategoriasProdutosAgro',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/categorias-produtos-agro/listar',
          component: 'listarCategoriasProdutosAgro',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/categorias-produtos-agro/criar',
          component: 'categoriasProdutosAgro',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/categorias-produtos-agro/editar/:id',
          component: 'categoriasProdutosAgro',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/categorias-produtos-agro/visualizar/:id',
          component: 'categoriasProdutosAgro',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.DanosDefeitos,
      name: 'Danos e Defeitos',
      path: '/danos-defeitos/listar',
      component: 'listarDanosDefeitos',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/danos-defeitos/listar',
          component: 'listarDanosDefeitos',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/danos-defeitos/criar',
          component: 'danosDefeitos',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/danos-defeitos/editar/:id',
          component: 'danosDefeitos',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/danos-defeitos/visualizar/:id',
          component: 'danosDefeitos',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.TamanhoProdutosAgro,
      name: 'Tamanhos de Produtos Agro',
      path: '/tamanhos-produtos-agro/listar',
      component: 'listarTamanhoProdutosAgro',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/tamanhos-produtos-agro/listar',
          component: 'listarTamanhoProdutosAgro',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/tamanhos-produtos-agro/criar',
          component: 'tamanhoProdutosAgro',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/tamanhos-produtos-agro/editar/:id',
          component: 'tamanhoProdutosAgro',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/tamanhos-produtos-agro/visualizar/:id',
          component: 'tamanhoProdutosAgro',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.Vasilhames,
      name: 'Vasilhames',
      path: '/vasilhames/listar',
      component: 'listarVasilhames',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/vasilhames/listar',
          component: 'listarVasilhames',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/vasilhames/criar',
          component: 'vasilhames',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/vasilhames/editar/:id',
          component: 'vasilhames',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/vasilhames/visualizar/:id',
          component: 'vasilhames',
        },
      ],
    },
  ],
};
