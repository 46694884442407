import Swal from 'sweetalert2';
import { FILTRAR_RELATORIOS, LISTAR_RELATORIOS, LISTAR_RELATORIOS_ERRO, LISTAR_RELATORIOS_SUCESSO } from './constants';

const INIT_STATE = {
  relatorios: [],
  filtros: {
    pagina: 1,
    limite: 5,
  },
  carregando: false,
};

const Relatorios = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LISTAR_RELATORIOS:
      return { ...state, carregando: true };
    case FILTRAR_RELATORIOS:
      return { ...state, filtros: action.payload.filtros, programCod: action.payload.programCod, carregando: true };
    case LISTAR_RELATORIOS_SUCESSO:
      return { ...state, relatorios: action.payload, carregando: false };
    case LISTAR_RELATORIOS_ERRO:
      Swal.fire({
        icon: 'error',
        title: 'Erro ao buscar relatórios',
        timer: 2000,
        text: action.payload,
      });
      return { ...state, relatorios: [], carregando: false };
    default:
      return state;
  }
};

export default Relatorios;
