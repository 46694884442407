export enum ProgramaCodigo {
  Programas = 1,
  Permissoes = 2,
  Usuarios = 3,
  PerfilDeAcesso = 4,
  Parametros = 5,
  Modulos = 6,
  Solucoes = 7,
  Segmentos = 8,
  Organizacoes = 9,
  Empresas = 10,
  Pais = 11,
  Estado = 12,
  Cidade = 13,
  CNAE = 14,
  NCM = 15,
  CEST = 16,
  CFOP = 17,
  OpcoesCadastrais = 18,
  GruposPessoas = 19,
  Pessoas = 20,
  GruposEconomicosPessoas = 21,
  Revenda = 22,
  SistemasProducao = 23,
  SistemasIrrigacao = 24,
  CultivosProtegidos = 25,
  Culturas = 26,
  PortaEnxertos = 27,
  Variedades = 28,
  VariedadesClones = 29,
  SistemasConducao = 30,
  DepartamentoProduto = 31,
  GrupoProduto = 32,
  SubGrupoProduto = 33,
  ClassesProdutos = 34,
  MarcasProdutos = 35,
  PrincipiosAtivo = 36,
  Alvos = 37,
  EstagiosFenologicos = 38,
  UnidadeMedidaProduto = 39,
  GruposAtividades = 40,
  Atividades = 41,
  Produtos = 42,
  GruposQuimicos = 43,
  CategoriasProdutosAgro = 44,
  TamanhoProdutosAgro = 45,
  TamanhoProdutos = 46,
  CoresProdutos = 47,
  InformacoesNutricionais = 48,
  Propriedades = 49,
  GruposCentrosCustos = 50,
  CentrosCustos = 51,
  ObservacoesAtividades = 52,
  EquipeAtividade = 53,
  LancamentosAtividades = 54,
  Doencas = 55,
  Pragas = 56,
  DanosDefeitos = 57,
  Armadilhas = 58,
  RegistrosClimaticos = 59,
  GruposMaquinasImplementos = 60,
  MaquinasImplementos = 61,
  AtividadesControleProdutividade = 62,
  UnidadesProdutivas = 63,
  RecomendacoesTecnicas = 64,
  PrevisaoColheita = 65,
  TributacoesEstaduais = 66,
  TributacoesFederaisIpi = 67,
  TributacoesFederaisPisCofins = 68,
  Safras = 69,
  ConceitosQualidade = 70,
  Integradores = 71,
  CamarasFria = 72,
  Contas = 73,
  GrupoServico = 74,
  Servicos = 75,
  EntradasSaidasProdutos = 76,
  Depositos = 77,
  VendasColheitas = 78,
  GruposFinanceiros = 79,
  SubGruposFinanceiros = 80,
  LancamentosCustos = 81,
  AtualizadorCustos = 82,
  TiposCobrancas = 83,
  FormasPagamentos = 84,
  ModelosEtiquetas = 85,
  ValoresMaoObra = 86,
  VisitasTecnicas = 87,
  MarcasVeiculos = 88,
  Veiculos = 89,
  OrdensCompra = 90,
  Pedidos = 91,
  ManutencaoMaquinasVeiculos = 92,
  NotasFiscais = 93,
  NaturezaOperacoes = 94,
  Orcamentos = 95,
  NotasEntradas = 96,
  Moedas = 97,
  Cotacoes = 98,
  NotasFiscaisConsumidor = 99,
  Promocoes = 100,
  ListasPrecos = 101,
  CodigosBarrasEAN = 102,
  EntradaFrutas = 103,
  Vasilhames = 104,
  TransferenciasDepositos = 105,
  HistoricosPadrao = 106,
  PlanoContas = 107,
  EtiquetasEntradaFrutas = 108,
  MovimentacoesProdutos = 109,
  ContasReceber = 110,
  ContasPagar = 111,
  CaixasBancos = 112,
  TransferenciasCaixaBancos = 113,
  Configuracoes = 114,
  OcorrenciasFinanceiras = 115,
  MotivosCancelamentosContratos = 116,
  Contratos = 117,
  NotasFiscaisServicos = 118,
  AliquotasImpostosServicos = 119,
  TributacoesServicos = 120,
  MesasComandas= 121,
  SetoresMesas = 122,
  AberturaFechamentoPDV = 123,
  CadernoCampo = 5000,
  RelatoriosEstoque = 5001,
  RelatoriosMonitoramentoCarencia = 5002,
  RelatoriosCustos = 5003,
  RelatoriosLog = 9996,
  PainelSistema = 9997,
  ModelosRelatorios = 9998,
  Preferencias = 9999, //Não tem nos documentos
}
