import {
  FILTRAR_PAISES,
  GERAR_RELATORIO_PAISES,
  GERAR_RELATORIO_PAISES_ERRO,
  GERAR_RELATORIO_PAISES_SUCESSO,
  LISTAR_PAISES,
  LISTAR_PAISES_ERRO,
  LISTAR_PAISES_SUCESSO,
} from './constants';

export const obterTodosPaises = () => ({
  type: LISTAR_PAISES,
});

export const obterPaisesSucesso = (paises) => ({
  type: LISTAR_PAISES_SUCESSO,
  payload: paises,
});

export const obterPaisesErro = (mensagem) => ({
  type: LISTAR_PAISES_ERRO,
  payload: mensagem,
});

export const filtrarPaises = (filtros) => ({
  type: FILTRAR_PAISES,
  payload: filtros,
});
export const gerarRelatorioPaises = (payload) => ({
  type: GERAR_RELATORIO_PAISES,
  payload: payload,
});

export const gerarRelatorioPaisesSucesso = (alert) => ({
  type: GERAR_RELATORIO_PAISES_SUCESSO,
  payload: alert,
});

export const gerarRelatorioPaisesErro = (mensagem) => ({
  type: GERAR_RELATORIO_PAISES_ERRO,
  payload: mensagem,
});
