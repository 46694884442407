import { AxiosResponse } from 'axios';
import api from '../../services/api';
import { INCMCadastro, INCMConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterNCMsAsync = (
  query,
): Promise<AxiosResponse<{ result: INCMConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `ncm${queryStr}`,
    method: 'GET',
  });
};

export const obterNCMAsync = (
  id: number,
): Promise<AxiosResponse<INCMConsulta>> => {
  return api.request({
    url: `ncm/${id}`,
    method: 'GET',
  });
};

export const criarNCMsAsync = (
  ncm: INCMCadastro,
): Promise<AxiosResponse<INCMConsulta>> => {
  return api.request({
    url: `ncm`,
    data: ncm,
    method: 'POST',
  });
};

export const atualizarNCMsAsync = (
  id: number,
  ncm: INCMCadastro,
): Promise<AxiosResponse<INCMConsulta>> => {
  return api.request({
    url: `ncm/${id}`,
    data: ncm,
    method: 'PATCH',
  });
};

export const removerNCMsEmMassaAsync = (
  ids: number[],
): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `ncm`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerNCMsAsync = (
  id: number,
): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `ncm/${id}`,
    method: 'DELETE',
  });
