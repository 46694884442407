import * as FeatherIcon from 'react-feather';

import { IMenu } from '../../interfaces/menu';
import { PermissaoCodigo } from '../enums/permissao-codigo.enum';
import { ProgramaCodigo } from '../enums/programa-codigo.enum';
import { RoleCodigo } from '../enums/role-codigo.enum';

export const TecnicoSolucaoMenus: IMenu = {
  roles: [RoleCodigo.SolucaoTec],
  name: 'Técnico Solução',
  children: [
    {
      programaCod: ProgramaCodigo.PerfilDeAcesso,
      name: 'Perfis De Acesso',
      path: '/perfil-acesso/listar',
      icon: FeatherIcon.UserX,
      component: 'listaPerfilAcesso',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/perfil-acesso/listar',
          component: 'listaPerfilAcesso',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/perfil-acesso/criar',
          component: 'perfilAcesso',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/perfil-acesso/editar/:id',
          component: 'perfilAcesso',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/perfil-acesso/visualizar/:id',
          component: 'perfilAcesso',
        },
      ],
    },
  ],
};
