import {
  LISTAR_UNIDADES_PRODUTIVAS_SUCESSO,
  LISTAR_UNIDADES_PRODUTIVAS_ERRO,
  LISTAR_UNIDADES_PRODUTIVAS,
  FILTRAR_UNIDADES_PRODUTIVAS,
  GERAR_RELATORIO_UNIDADES_PRODUTIVAS,
  GERAR_RELATORIO_UNIDADES_PRODUTIVAS_ERRO,
  GERAR_RELATORIO_UNIDADES_PRODUTIVAS_SUCESSO,
} from './constants';

export const obterTodasUnidadesProdutivas = () => ({
  type: LISTAR_UNIDADES_PRODUTIVAS,
});

export const obterTodasUnidadesProdutivasSucesso = (unidadesProdutivas) => ({
  type: LISTAR_UNIDADES_PRODUTIVAS_SUCESSO,
  payload: unidadesProdutivas,
});

export const obterTodasUnidadesProdutivasErro = (mensagem) => ({
  type: LISTAR_UNIDADES_PRODUTIVAS_ERRO,
  payload: mensagem,
});

export const filtrarUnidadesProdutivas = (filtro) => {
  return {
    type: FILTRAR_UNIDADES_PRODUTIVAS,
    payload: filtro,
  };
};

export const gerarRelatorioUnidadesProdutivas = (payload) => ({
  type: GERAR_RELATORIO_UNIDADES_PRODUTIVAS,
  payload: payload,
});

export const gerarRelatorioUnidadesProdutivasSucesso = (alert) => ({
  type: GERAR_RELATORIO_UNIDADES_PRODUTIVAS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioUnidadesProdutivasErro = (mensagem) => ({
  type: GERAR_RELATORIO_UNIDADES_PRODUTIVAS_ERRO,
  payload: mensagem,
});
