import {
  LISTAR_PERFIL_ACESSO_SUCESSO,
  GERAR_RELATORIO_PERFIL_ACESSO,
  GERAR_RELATORIO_PERFIL_ACESSO_ERRO,
  GERAR_RELATORIO_PERFIL_ACESSO_SUCESSO,
  LISTAR_PERFIL_ACESSO_ERRO,
  LISTAR_PERFIL_ACESSO,
  FILTRAR_PERFIL_ACESSO,
} from './constants';

export const obterTodosPerfilAcesso = () => ({
  type: LISTAR_PERFIL_ACESSO,
});

export const obterPerfilAcessoSucesso = (usuarios) => ({
  type: LISTAR_PERFIL_ACESSO_SUCESSO,
  payload: usuarios,
});

export const obterPerfilAcessoErro = (mensagem) => ({
  type: LISTAR_PERFIL_ACESSO_ERRO,
  payload: mensagem,
});

export const filtrarPerfilAcesso = (filtros) => ({
  type: FILTRAR_PERFIL_ACESSO,
  payload: filtros,
});

export const gerarRelatorioPerfilAcesso = (payload) => ({
  type: GERAR_RELATORIO_PERFIL_ACESSO,
  payload: payload,
});

export const gerarRelatorioPerfilAcessoSucesso = (alert) => ({
  type: GERAR_RELATORIO_PERFIL_ACESSO_SUCESSO,
  payload: alert,
});

export const gerarRelatorioPerfilAcessoErro = (mensagem) => ({
  type: GERAR_RELATORIO_PERFIL_ACESSO_ERRO,
  payload: mensagem,
});
