import Swal from 'sweetalert2';
import { filtroPadraoStatus } from '../../../constants/filtros';
import {
  FILTRAR_CATEGORIAS_PRODUTOS_AGRO,
  GERAR_RELATORIO_CATEGORIAS_PRODUTOS_AGRO,
  GERAR_RELATORIO_CATEGORIAS_PRODUTOS_AGRO_ERRO,
  GERAR_RELATORIO_CATEGORIAS_PRODUTOS_AGRO_SUCESSO,
  LISTAR_CATEGORIAS_PRODUTOS_AGRO,
  LISTAR_CATEGORIAS_PRODUTOS_AGRO_ERRO,
  LISTAR_CATEGORIAS_PRODUTOS_AGRO_SUCESSO,
} from './constants';

const INIT_STATE = {
  categoriasProdutosAgro: [],
  filtros: { ...filtroPadraoStatus },
  carregando: false,
  relatorio: {},
  gerandoRelatorio: false,
};

const CategoriasProdutosAgroReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LISTAR_CATEGORIAS_PRODUTOS_AGRO:
      return { ...state, carregando: true };
    case FILTRAR_CATEGORIAS_PRODUTOS_AGRO:
      return { ...state, filtros: action.payload, carregando: true };
    case LISTAR_CATEGORIAS_PRODUTOS_AGRO_SUCESSO:
      return { ...state, categoriasProdutosAgro: action.payload, carregando: false };
    case LISTAR_CATEGORIAS_PRODUTOS_AGRO_ERRO:
      Swal.fire({
        icon: 'error',
        title: 'Erro ao buscar categorias de produtos agro',
        timer: 2000,
        text: action.payload,
      });
      return { ...state, categoriasProdutosAgro: [], carregando: false };
    case GERAR_RELATORIO_CATEGORIAS_PRODUTOS_AGRO:
      return { ...state, filtros: action.payload.filtros, relatorio: action.payload.relatorio, gerandoRelatorio: true };
    case GERAR_RELATORIO_CATEGORIAS_PRODUTOS_AGRO_SUCESSO:
      if (action.payload) {
        Swal.fire({
          icon: 'success',
          title: action.payload.titulo,
          timer: action?.payload?.timer || 2000,
          text: action.payload.mensagem,
        });
      }
      return { ...state, gerandoRelatorio: false };
    case GERAR_RELATORIO_CATEGORIAS_PRODUTOS_AGRO_ERRO:
      Swal.fire({
        icon: 'error',
        title: 'Falha ao gerar Relatório',
        timer: 2000,
        text: action.payload,
      });
      return { ...state, gerandoRelatorio: false };
    default:
      return state;
  }
};

export default CategoriasProdutosAgroReducer;
