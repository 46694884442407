import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { ProgramaCodigo } from '../../../constants/enums/programa-codigo.enum';
import { SET_LIMIT_PAGE, SET_TABLE_ORDER } from '../../../redux/table/constants';
import { DEFAULT_DOWNLOAD_SUCCESS_MESSAGE, gerarRelatorioRacing } from '../../../services/requests/relatorio';
import { montarFiltroParaApi } from '../../../utils/api-filter';
import { obterMaquinasImplementosAsync } from '../requests';
import {
  gerarRelatorioMaquinasImplementosErro,
  gerarRelatorioMaquinasImplementosSucesso,
  obterMaquinasImplementosErro,
  obterMaquinasImplementosSucesso,
} from './actions';
import {
  FILTRAR_MAQUINAS_IMPLEMENTOS,
  GERAR_RELATORIO_MAQUINAS_IMPLEMENTOS,
  LISTAR_MAQUINAS_IMPLEMENTOS,
} from './constants';

function* listarTodosMaquinasImplementos() {
  try {
    const filtro = yield select((state) => state.MaquinasImplementos.filtros);
    const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.MaquinasImplementos]);

    let query = { filtro: montarFiltroParaApi(filtro) };

    if (preferencias) {
      query.ordenacao = {
        campo: preferencias?.ordenarPor || 'nome',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      };
      query.paginacao = {
        pagina: preferencias?.page || 1,
        limite: preferencias?.limit || 50,
      };
    }

    const { data } = yield call(obterMaquinasImplementosAsync, query);
    yield put(obterMaquinasImplementosSucesso(data));
  } catch (error) {
    yield put(obterMaquinasImplementosErro(error.response?.data?.message || error.message));
  }
}

function* relatorioMaquinasImplementos() {
  const { filtros, relatorio } = yield select((state) => state.MaquinasImplementos);
  const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.MaquinasImplementos]);
  const { modelo, tipo, comFiltro, colunas } = relatorio;
  const columns = colunas?.map((coluna) => coluna.value);
  let query = {};

  if (comFiltro) {
    query = {
      filtro: {
        ...montarFiltroParaApi(filtros),
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
      ordenacao: {
        campo: preferencias?.ordenarPor || 'nome',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      },
    };
  } else {
    query = {
      filtro: {
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
    };
  }

  try {
    const { data, headers } = yield call(gerarRelatorioRacing, ProgramaCodigo.MaquinasImplementos, query, tipo);

    if (headers['content-type'].includes('application/json')) {
      yield put(
        gerarRelatorioMaquinasImplementosSucesso({
          titulo: 'Pedido de impressão salvo.',
          mensagem: 'Seu relatório está sendo gerado.',
        }),
      );
    } else {
      const url = URL.createObjectURL(
        new Blob([data], {
          type: headers['content-type'] || 'application/pdf',
        }),
      );
      if (url) {
        yield put(gerarRelatorioMaquinasImplementosSucesso(DEFAULT_DOWNLOAD_SUCCESS_MESSAGE));
        window.open(url, '_blank');
      }
    }
  } catch (e) {
    yield put(
      gerarRelatorioMaquinasImplementosErro('Ocorreu um erro ao tentar gerar o relatório. Tente novamente mais tarde.'),
    );
  }
}

function* ordenarMaquinasImplementos({ payload }) {
  if (payload.programaId === ProgramaCodigo.MaquinasImplementos) {
    yield call(listarTodosMaquinasImplementos);
  }
}

function* mudarLimitePaginacao({ payload }) {
  if (payload.programaId === ProgramaCodigo.MaquinasImplementos) {
    yield call(listarTodosMaquinasImplementos);
  }
}

export function* watchListarMaquinasImplementos() {
  yield takeLatest(LISTAR_MAQUINAS_IMPLEMENTOS, listarTodosMaquinasImplementos);
}

export function* watchFiltrarMaquinasImplementos() {
  yield takeLatest(FILTRAR_MAQUINAS_IMPLEMENTOS, listarTodosMaquinasImplementos);
}

export function* watchRelatorioMaquinasImplementos() {
  yield takeLatest(GERAR_RELATORIO_MAQUINAS_IMPLEMENTOS, relatorioMaquinasImplementos);
}

export function* watchOrdenarMaquinasImplementos() {
  yield takeLatest(SET_TABLE_ORDER, ordenarMaquinasImplementos);
}

export function* watchLimitePaginacao() {
  yield takeLatest(SET_LIMIT_PAGE, mudarLimitePaginacao);
}

function* maquinasImplementosSaga() {
  yield all([
    fork(watchListarMaquinasImplementos),
    fork(watchFiltrarMaquinasImplementos),
    fork(watchRelatorioMaquinasImplementos),
    fork(watchOrdenarMaquinasImplementos),
    fork(watchLimitePaginacao),
  ]);
}

export default maquinasImplementosSaga;
