import {
  FILTRAR_FORMAS_PAGAMENTOS,
  GERAR_RELATORIO_FORMAS_PAGAMENTOS,
  GERAR_RELATORIO_FORMAS_PAGAMENTOS_ERRO,
  GERAR_RELATORIO_FORMAS_PAGAMENTOS_SUCESSO,
  LISTAR_FORMAS_PAGAMENTOS,
  LISTAR_FORMAS_PAGAMENTOS_ERRO,
  LISTAR_FORMAS_PAGAMENTOS_SUCESSO,
} from './constants';

export const obterTodosFormasPagamentos = () => ({
  type: LISTAR_FORMAS_PAGAMENTOS,
});

export const obterFormasPagamentosSucesso = (FormasPagamentos) => ({
  type: LISTAR_FORMAS_PAGAMENTOS_SUCESSO,
  payload: FormasPagamentos,
});

export const obterFormasPagamentosErro = (mensagem) => ({
  type: LISTAR_FORMAS_PAGAMENTOS_ERRO,
  payload: mensagem,
});

export const filtrarFormasPagamentos = (filtros) => ({
  type: FILTRAR_FORMAS_PAGAMENTOS,
  payload: filtros,
});

export const gerarRelatorioFormasPagamentos = (payload) => ({
  type: GERAR_RELATORIO_FORMAS_PAGAMENTOS,
  payload: payload,
});

export const gerarRelatorioFormasPagamentosSucesso = (alert) => ({
  type: GERAR_RELATORIO_FORMAS_PAGAMENTOS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioFormasPagamentosErro = (mensagem) => ({
  type: GERAR_RELATORIO_FORMAS_PAGAMENTOS_ERRO,
  payload: mensagem,
});