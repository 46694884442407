import {
  FILTRAR_MESAS_COMANDAS,
  GERAR_RELATORIO_MESAS_COMANDAS,
  GERAR_RELATORIO_MESAS_COMANDAS_ERRO,
  GERAR_RELATORIO_MESAS_COMANDAS_SUCESSO,
  LISTAR_MESAS_COMANDAS,
  LISTAR_MESAS_COMANDAS_ERRO,
  LISTAR_MESAS_COMANDAS_SUCESSO,
} from './constants';

export const obterTodosMesasComandas = () => ({
  type: LISTAR_MESAS_COMANDAS,
});

export const obterMesasComandasSucesso = (mesasComandas) => ({
  type: LISTAR_MESAS_COMANDAS_SUCESSO,
  payload: mesasComandas,
});

export const obterMesasComandasErro = (mensagem) => ({
  type: LISTAR_MESAS_COMANDAS_ERRO,
  payload: mensagem,
});

export const filtrarMesasComandas = (filtros) => ({
  type: FILTRAR_MESAS_COMANDAS,
  payload: filtros,
});

export const gerarRelatorioMesasComandas = (payload) => ({
  type: GERAR_RELATORIO_MESAS_COMANDAS,
  payload: payload,
});

export const gerarRelatorioMesasComandasSucesso = (alert) => ({
  type: GERAR_RELATORIO_MESAS_COMANDAS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioMesasComandasErro = (mensagem) => ({
  type: GERAR_RELATORIO_MESAS_COMANDAS_ERRO,
  payload: mensagem,
});
