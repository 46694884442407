import {
  FILTRAR_EQUIPES_ATIVIDADES,
  GERAR_RELATORIO_EQUIPES_ATIVIDADES,
  GERAR_RELATORIO_EQUIPES_ATIVIDADES_ERRO,
  GERAR_RELATORIO_EQUIPES_ATIVIDADES_SUCESSO,
  LISTAR_EQUIPES_ATIVIDADES,
  LISTAR_EQUIPES_ATIVIDADES_ERRO,
  LISTAR_EQUIPES_ATIVIDADES_SUCESSO,
} from './constants';

export const obterTodosEquipesAtividades = () => ({
  type: LISTAR_EQUIPES_ATIVIDADES,
});

export const obterEquipesAtividadesSucesso = (EquipesAtividades) => ({
  type: LISTAR_EQUIPES_ATIVIDADES_SUCESSO,
  payload: EquipesAtividades,
});

export const obterEquipesAtividadesErro = (mensagem) => ({
  type: LISTAR_EQUIPES_ATIVIDADES_ERRO,
  payload: mensagem,
});

export const filtrarEquipesAtividades = (filtros) => ({
  type: FILTRAR_EQUIPES_ATIVIDADES,
  payload: filtros,
});

export const gerarRelatorioEquipesAtividades = (payload) => ({
  type: GERAR_RELATORIO_EQUIPES_ATIVIDADES,
  payload: payload,
});

export const gerarRelatorioEquipesAtividadesSucesso = (alert) => ({
  type: GERAR_RELATORIO_EQUIPES_ATIVIDADES_SUCESSO,
  payload: alert,
});

export const gerarRelatorioEquipesAtividadesErro = (mensagem) => ({
  type: GERAR_RELATORIO_EQUIPES_ATIVIDADES_ERRO,
  payload: mensagem,
});