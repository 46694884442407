import {
  FILTRAR_SISTEMAS_PRODUCAO,
  GERAR_RELATORIO_SISTEMAS_PRODUCAO,
  GERAR_RELATORIO_SISTEMAS_PRODUCAO_ERRO,
  GERAR_RELATORIO_SISTEMAS_PRODUCAO_SUCESSO,
  LISTAR_SISTEMAS_PRODUCAO,
  LISTAR_SISTEMAS_PRODUCAO_ERRO,
  LISTAR_SISTEMAS_PRODUCAO_SUCESSO,
} from './constants';

export const obterTodosSistemasProducao = () => ({
  type: LISTAR_SISTEMAS_PRODUCAO,
});

export const obterSistemasProducaoSucesso = (sistemasProducao) => ({
  type: LISTAR_SISTEMAS_PRODUCAO_SUCESSO,
  payload: sistemasProducao,
});

export const obterSistemasProducaoErro = (mensagem) => ({
  type: LISTAR_SISTEMAS_PRODUCAO_ERRO,
  payload: mensagem,
});

export const filtrarSistemasProducao = (filtros) => ({
  type: FILTRAR_SISTEMAS_PRODUCAO,
  payload: filtros,
});

export const gerarRelatorioSistemasProducao = (payload) => ({
  type: GERAR_RELATORIO_SISTEMAS_PRODUCAO,
  payload: payload,
});

export const gerarRelatorioSistemasProducaoSucesso = (alert) => ({
  type: GERAR_RELATORIO_SISTEMAS_PRODUCAO_SUCESSO,
  payload: alert,
});

export const gerarRelatorioSistemasProducaoErro = (mensagem) => ({
  type: GERAR_RELATORIO_SISTEMAS_PRODUCAO_ERRO,
  payload: mensagem,
});