import { AxiosResponse } from 'axios';

import api from '../../services/api';
import { IGrupoProdutoCadastro, IGrupoProdutoConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterGruposProdutosAsync = (
  query
): Promise<AxiosResponse<{ result: IGrupoProdutoConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `grupos-produtos${queryStr}`,
    method: 'GET',
  });
};

export const obterGrupoProdutoAsync = (id: number): Promise<AxiosResponse<IGrupoProdutoConsulta>> => {
  return api.request({
    url: `grupos-produtos/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoGrupoProdutoAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `grupos-produtos/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarGrupoProdutoAsync = (
  sistemaProducao: IGrupoProdutoCadastro
): Promise<AxiosResponse<IGrupoProdutoConsulta>> => {
  return api.request({
    url: `grupos-produtos`,
    data: sistemaProducao,
    method: 'POST',
  });
};

export const atualizarGrupoProdutoAsync = (
  id: number,
  sistemaProducao: IGrupoProdutoCadastro
): Promise<AxiosResponse<IGrupoProdutoConsulta>> => {
  return api.request({
    url: `grupos-produtos/${id}`,
    data: sistemaProducao,
    method: 'PATCH',
  });
};

export const removerGrupoProdutoEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `grupos-produtos`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerGrupoProdutoAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `grupos-produtos/${id}`,
    method: 'DELETE',
  });
