import Swal from 'sweetalert2';
import { filtroPadraoStatus } from '../../../constants/filtros';
import { FILTRAR_SAFRAS, LISTAR_SAFRAS, LISTAR_SAFRAS_ERRO, LISTAR_SAFRAS_SUCESSO } from './constants';

const INIT_STATE = {
  safras: [],
  filtros: { ...filtroPadraoStatus },
  carregando: false,
};

const SafrasReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LISTAR_SAFRAS:
      return { ...state, carregando: true };
    case FILTRAR_SAFRAS:
      return { ...state, filtros: action.payload, carregando: true };
    case LISTAR_SAFRAS_SUCESSO:
      return { ...state, safras: action.payload, carregando: false };
    case LISTAR_SAFRAS_ERRO:
      Swal.fire({
        icon: 'error',
        title: 'Erro ao buscar safras',
        timer: 2000,
        text: action.payload,
      });
      return { ...state, safras: [], carregando: false };
    default:
      return state;
  }
};

export default SafrasReducer;
