import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { FILTRAR_TRIBUTACOES_FEDERAIS_PIS_COFINS, LISTAR_TRIBUTACOES_FEDERAIS_PIS_COFINS } from './constants';
import { SET_LIMIT_PAGE, SET_TABLE_ORDER } from '../../../redux/table/constants';

import { obterTodasTributacoesFederaisPisCofinsSucesso, obterTodasTributacoesFederaisPisCofinsErro } from './actions';
import { obterTributacaoFederaisPisCofinsAsync } from '../requests';
import { ProgramaCodigo } from '../../../constants/enums/programa-codigo.enum';
import { montarFiltroParaApi } from '../../../utils/api-filter';

function* listarTodasTributacoesFederaisPisCofins() {
  try {
    const filtro = yield select((state) => state.TributacaoFederalPisCofins.filtros);
    const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.TributacoesFederaisPisCofins]);

    let query = { filtro: montarFiltroParaApi(filtro) };

    if (preferencias) {
      query.ordenacao = {
        campo: preferencias?.ordenarPor || 'nome',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      };
      query.paginacao = {
        pagina: preferencias?.page || 1,
        limite: preferencias?.limit || 50,
      };
    }

    const { data } = yield call(obterTributacaoFederaisPisCofinsAsync, query);
    yield put(obterTodasTributacoesFederaisPisCofinsSucesso(data));
  } catch (error) {
    yield put(obterTodasTributacoesFederaisPisCofinsErro(error.response?.data?.message || error.message));
  }
}

function* ordenarTributacoesFederaisPisCofins({ payload }) {
  if (payload.programaId === ProgramaCodigo.TributacoesFederaisPisCofins) {
    yield call(listarTodasTributacoesFederaisPisCofins);
  }
}

function* mudarLimitePaginacao({ payload }) {
  if (payload.programaId === ProgramaCodigo.TributacoesFederaisPisCofins) {
    yield call(listarTodasTributacoesFederaisPisCofins);
  }
}

export function* watchListarTributacoesFederaisPisCofins() {
  yield takeLatest(LISTAR_TRIBUTACOES_FEDERAIS_PIS_COFINS, listarTodasTributacoesFederaisPisCofins);
}

export function* watchFiltrarTributacoesFederaisPisCofins() {
  yield takeLatest(FILTRAR_TRIBUTACOES_FEDERAIS_PIS_COFINS, listarTodasTributacoesFederaisPisCofins);
}

export function* watchOrdenarTributacoesFederaisPisCofins() {
  yield takeLatest(SET_TABLE_ORDER, ordenarTributacoesFederaisPisCofins);
}

export function* watchLimitePaginacao() {
  yield takeLatest(SET_LIMIT_PAGE, mudarLimitePaginacao);
}

function* tributacoesFederaisPisCofinsSaga() {
  yield all([
    fork(watchListarTributacoesFederaisPisCofins),
    fork(watchFiltrarTributacoesFederaisPisCofins),
    fork(watchOrdenarTributacoesFederaisPisCofins),
    fork(watchLimitePaginacao),
  ]);
}

export default tributacoesFederaisPisCofinsSaga;
