import api from '../../services/api';
import { AxiosResponse } from 'axios';
import { IContaCadastro, IContaConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterContasAsync = (
  query,
): Promise<AxiosResponse<{ result: IContaConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `contas${queryStr}`,
    method: 'GET',
  });
};

export const obterContaAsync = (id: number): Promise<AxiosResponse<IContaConsulta>> => {
  return api.request({
    url: `contas/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoContaAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `contas/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarContasAsync = (
  conta: IContaCadastro,
): Promise<AxiosResponse<IContaConsulta>> => {
  return api.request({
    url: `contas`,
    data: conta,
    method: 'POST',
  });
};

export const atualizarContasAsync = (
  id: number,
  conta: IContaCadastro,
): Promise<AxiosResponse<IContaConsulta>> => {
  return api.request({
    url: `contas/${id}`,
    data: conta,
    method: 'PATCH',
  });
};

export const removerContasEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `contas`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerContaAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `contas/${id}`,
    method: 'DELETE',
  });
