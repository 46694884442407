import api from '../../services/api';
import { AxiosResponse } from 'axios';
import { montarQueryString } from '../../utils';
import { ITransferenciaDepositoCadastro, ITransferenciaDepositoConsulta } from './interfaces';

export const obterTransferenciasDepositosAsync = (
  query,
): Promise<AxiosResponse<{ result: ITransferenciaDepositoConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);
  return api.request({
    url: `transferencia-depositos${queryStr}`,
    method: 'GET',
  });
};

export const obterTransferenciaDepositoProdutoAsync = (id: number): Promise<AxiosResponse<ITransferenciaDepositoConsulta>> => {
  return api.request({
    url: `transferencia-depositos/${id}`,
    method: 'GET',
  });
};

export const criarTransferenciasDepositosAsync = (
  entradaSaidaProduto: ITransferenciaDepositoCadastro,
): Promise<AxiosResponse<ITransferenciaDepositoConsulta>> => {
  return api.request({
    url: `transferencia-depositos`,
    data: entradaSaidaProduto,
    method: 'POST',
  });
};

export const atualizarTransferenciasDepositosAsync = (
  id: number,
  entradaSaidaProduto: ITransferenciaDepositoCadastro,
): Promise<AxiosResponse<ITransferenciaDepositoConsulta>> => {
  return api.request({
    url: `transferencia-depositos/${id}`,
    data: entradaSaidaProduto,
    method: 'PATCH',
  });
};

export const removerTransferenciasDepositosEmMassaAsync = (
  ids: number[],
): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `transferencia-depositos`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerTransferenciasDepositosAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `transferencia-depositos/${id}`,
    method: 'DELETE',
  });
