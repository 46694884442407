import { AxiosResponse } from 'axios';

import api from '../../services/api';
import { montarQueryString } from '../../utils';
import { ICulturaCadastro, ICulturaConsulta, ICulturaOrganizacaoConsulta } from './interfaces';

export const obterCulturasAsync = (query): Promise<AxiosResponse<{ result: ICulturaConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `culturas${queryStr}`,
    method: 'GET',
  });
};

export const uploadImagemCulturaAsync = (id: number, imagem: File) => {
  const formData = new FormData();
  formData.append('imagem', imagem);

  return api.request({
    url: `culturas/${id}/upload`,
    data: formData,
    method: 'POST',
  });
};

export const deletarImagemCulturaAsync = (id: number) => {
  return api.request({
    url: `culturas/${id}/deletar-imagem`,
    method: 'PATCH',
  });
};

export const obterCulturaAsync = (id: number): Promise<AxiosResponse<ICulturaConsulta>> => {
  return api.request({
    url: `culturas/${id}`,
    method: 'GET',
  });
};

export const obterConfiguracaoCulturaOrganizacaoAsync = (
  culturaId: number,
  organizacaoId: number,
): Promise<AxiosResponse<ICulturaOrganizacaoConsulta>> => {
  return api.request({
    url: `culturas/${culturaId}/configuracao-organizacao/${organizacaoId}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoCulturaAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `culturas/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarCulturaAsync = (cultura: ICulturaCadastro): Promise<AxiosResponse<ICulturaConsulta>> => {
  return api.request({
    url: `culturas`,
    data: cultura,
    method: 'POST',
  });
};

export const atualizarCulturaAsync = (
  id: number,
  cultura: ICulturaCadastro,
): Promise<AxiosResponse<ICulturaConsulta>> => {
  return api.request({
    url: `culturas/${id}`,
    data: cultura,
    method: 'PATCH',
  });
};

export const removerCulturasEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `culturas`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerCulturaAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `culturas/${id}`,
    method: 'DELETE',
  });
