import { AxiosResponse } from 'axios';
import api from '../../services/api';
import { montarQueryString } from '../../utils';
import { IVariedadeClonesCadastro, IVariedadeClonesConsulta } from './interfaces';

export const obterVariedadesClonesAsync = (
  query,
): Promise<AxiosResponse<{ result: IVariedadeClonesConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `variedades-clones${queryStr}`,
    method: 'GET',
  });
};

export const obterVariedadeClonesAsync = (id: number): Promise<AxiosResponse<IVariedadeClonesConsulta>> => {
  return api.request({
    url: `variedades-clones/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoVariedadeClonesAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `variedades-clones/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const uploadImagemVariedadeCloneAsync = (id: number, imagem: File) => {
  const formData = new FormData();
  formData.append('imagem', imagem);

  return api.request({
    url: `variedades-clones/${id}/upload`,
    data: formData,
    method: 'POST',
  });
};

export const deletarImagemVariedadeCloneAsync = (id: number) => {
  return api.request({
    url: `variedades-clones/${id}/deletar-imagem`,
    method: 'PATCH',
  });
};

export const criarVariedadeClonesAsync = (
  variedadeClones: IVariedadeClonesCadastro,
): Promise<AxiosResponse<IVariedadeClonesConsulta>> => {
  return api.request({
    url: `variedades-clones`,
    data: variedadeClones,
    method: 'POST',
  });
};

export const atualizarVariedadeClonesAsync = (
  id: number,
  variedadeClones: IVariedadeClonesCadastro,
): Promise<AxiosResponse<IVariedadeClonesConsulta>> => {
  return api.request({
    url: `variedades-clones/${id}`,
    data: variedadeClones,
    method: 'PATCH',
  });
};

export const removerVariedadesClonesEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `variedades-clones`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerVariedadeClonesAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `variedades-clones/${id}`,
    method: 'DELETE',
  });
