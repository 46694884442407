import {
  FILTRAR_GRUPOS_ATIVIDADES,
  GERAR_RELATORIO_GRUPOS_ATIVIDADES,
  GERAR_RELATORIO_GRUPOS_ATIVIDADES_ERRO,
  GERAR_RELATORIO_GRUPOS_ATIVIDADES_SUCESSO,
  LISTAR_GRUPOS_ATIVIDADES,
  LISTAR_GRUPOS_ATIVIDADES_ERRO,
  LISTAR_GRUPOS_ATIVIDADES_SUCESSO,
} from './constants';

export const obterTodosGruposAtividades = () => ({
  type: LISTAR_GRUPOS_ATIVIDADES,
});

export const obterGruposAtividadesSucesso = (grupoAtividade) => ({
  type: LISTAR_GRUPOS_ATIVIDADES_SUCESSO,
  payload: grupoAtividade,
});

export const obterGruposAtividadesErro = (mensagem) => ({
  type: LISTAR_GRUPOS_ATIVIDADES_ERRO,
  payload: mensagem,
});

export const filtrarGruposAtividades = (filtros) => ({
  type: FILTRAR_GRUPOS_ATIVIDADES,
  payload: filtros,
});

export const gerarRelatorioGruposAtividades = (payload) => ({
  type: GERAR_RELATORIO_GRUPOS_ATIVIDADES,
  payload: payload,
});

export const gerarRelatorioGruposAtividadesSucesso = (alert) => ({
  type: GERAR_RELATORIO_GRUPOS_ATIVIDADES_SUCESSO,
  payload: alert,
});

export const gerarRelatorioGruposAtividadesErro = (mensagem) => ({
  type: GERAR_RELATORIO_GRUPOS_ATIVIDADES_ERRO,
  payload: mensagem,
});
