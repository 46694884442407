import {
    FILTRAR_ESTAGIOS_FENOLOGICOS,
    GERAR_RELATORIO_ESTAGIOS_FENOLOGICOS,
    GERAR_RELATORIO_ESTAGIOS_FENOLOGICOS_ERRO,
    GERAR_RELATORIO_ESTAGIOS_FENOLOGICOS_SUCESSO,
    LISTAR_ESTAGIOS_FENOLOGICOS,
    LISTAR_ESTAGIOS_FENOLOGICOS_ERRO,
    LISTAR_ESTAGIOS_FENOLOGICOS_SUCESSO,
  } from './constants';
  
  export const obterTodosEstagiosFenologicos = () => ({
    type: LISTAR_ESTAGIOS_FENOLOGICOS,
  });
  
  export const obterEstagiosFenologicosSucesso = (cultivosProtegidos) => ({
    type: LISTAR_ESTAGIOS_FENOLOGICOS_SUCESSO,
    payload: cultivosProtegidos,
  });
  
  export const obterEstagiosFenologicosErro = (mensagem) => ({
    type: LISTAR_ESTAGIOS_FENOLOGICOS_ERRO,
    payload: mensagem,
  });
  
  export const filtrarEstagiosFenologicos = (filtros) => ({
    type: FILTRAR_ESTAGIOS_FENOLOGICOS,
    payload: filtros,
  });

  export const gerarRelatorioEstagiosFenologicos = (payload) => ({
    type: GERAR_RELATORIO_ESTAGIOS_FENOLOGICOS,
    payload: payload,
  });
  
  export const gerarRelatorioEstagiosFenologicosSucesso = (alert) => ({
    type: GERAR_RELATORIO_ESTAGIOS_FENOLOGICOS_SUCESSO,
    payload: alert,
  });
  
  export const gerarRelatorioEstagiosFenologicosErro = (mensagem) => ({
    type: GERAR_RELATORIO_ESTAGIOS_FENOLOGICOS_ERRO,
    payload: mensagem,
  });
  