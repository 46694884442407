import { GERAR_RELATORIO_ESTOQUE, GERAR_RELATORIO_ESTOQUE_ERRO, GERAR_RELATORIO_ESTOQUE_SUCESSO } from './constants';

export const gerarRelatorioEstoque = (payload) => ({
  type: GERAR_RELATORIO_ESTOQUE,
  payload: payload,
});

export const gerarRelatorioEstoqueSucesso = (alert) => ({
  type: GERAR_RELATORIO_ESTOQUE_SUCESSO,
  payload: alert,
});

export const gerarRelatorioEstoqueErro = (mensagem) => ({
  type: GERAR_RELATORIO_ESTOQUE_ERRO,
  payload: mensagem,
});
