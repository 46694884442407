import api from '../../services/api';
import { AxiosResponse } from 'axios';
import { IMaquinaImplementoCadastro, IMaquinaImplementoConsulta } from './interfaces';
import { montarQueryString } from '../../utils';
import { ICentroCustoConsulta } from '../centros-custos/interfaces';

export const obterMaquinasImplementosAsync = (
  query,
): Promise<AxiosResponse<{ result: IMaquinaImplementoConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `maquinas-implementos${queryStr}`,
    method: 'GET',
  });
};

export const obterMaquinaImplementoAsync = (id: number): Promise<AxiosResponse<IMaquinaImplementoConsulta>> => {
  return api.request({
    url: `maquinas-implementos/${id}`,
    method: 'GET',
  });
};

export const obterCentroCustoMaquinaImplementoAsync = (
  maquinaImplementoId: number,
): Promise<AxiosResponse<ICentroCustoConsulta>> => {
  return api.request({
    url: `maquinas-implementos/obter-centro-custo/${maquinaImplementoId}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoMaquinaImplementoAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `maquinas-implementos/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarMaquinasImplementosAsync = (
  maquinaImplemento: IMaquinaImplementoCadastro,
): Promise<AxiosResponse<IMaquinaImplementoConsulta>> => {
  return api.request({
    url: `maquinas-implementos`,
    data: maquinaImplemento,
    method: 'POST',
  });
};

export const atualizarMaquinasImplementosAsync = (
  id: number,
  maquinaImplemento: IMaquinaImplementoCadastro,
): Promise<AxiosResponse<IMaquinaImplementoConsulta>> => {
  return api.request({
    url: `maquinas-implementos/${id}`,
    data: maquinaImplemento,
    method: 'PATCH',
  });
};

export const removerMaquinasImplementosEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `maquinas-implementos`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerMaquinaImplementoAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `maquinas-implementos/${id}`,
    method: 'DELETE',
  });
