import {
  FILTRAR_GRUPOS_ECONOMICOS_PESSOAS,
  GERAR_RELATORIO_GRUPOS_ECONOMICOS_PESSOAS,
  GERAR_RELATORIO_GRUPOS_ECONOMICOS_PESSOAS_ERRO,
  GERAR_RELATORIO_GRUPOS_ECONOMICOS_PESSOAS_SUCESSO,
  LISTAR_GRUPOS_ECONOMICOS_PESSOAS,
  LISTAR_GRUPOS_ECONOMICOS_PESSOAS_ERRO,
  LISTAR_GRUPOS_ECONOMICOS_PESSOAS_SUCESSO,
} from './constants';

export const obterTodosGruposEconomicosPessoas = () => ({
  type: LISTAR_GRUPOS_ECONOMICOS_PESSOAS,
});

export const obterGruposEconomicosPessoasSucesso = (gruposEconomicosPessoas) => ({
  type: LISTAR_GRUPOS_ECONOMICOS_PESSOAS_SUCESSO,
  payload: gruposEconomicosPessoas,
});

export const obterGruposEconomicosPessoasErro = (mensagem) => ({
  type: LISTAR_GRUPOS_ECONOMICOS_PESSOAS_ERRO,
  payload: mensagem,
});

export const filtrarGruposEconomicosPessoas = (filtros) => ({
  type: FILTRAR_GRUPOS_ECONOMICOS_PESSOAS,
  payload: filtros,
});

export const gerarRelatorioGruposEconomicosPessoas = (payload) => ({
  type: GERAR_RELATORIO_GRUPOS_ECONOMICOS_PESSOAS,
  payload: payload,
});

export const gerarRelatorioGruposEconomicosPessoasSucesso = (alert) => ({
  type: GERAR_RELATORIO_GRUPOS_ECONOMICOS_PESSOAS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioGruposEconomicosPessoasErro = (mensagem) => ({
  type: GERAR_RELATORIO_GRUPOS_ECONOMICOS_PESSOAS_ERRO,
  payload: mensagem,
});