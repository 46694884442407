import Swal from 'sweetalert2';
import { filtroPadraoStatus } from '../../../constants/filtros';
import {
  FILTRAR_GRUPO_SERVICO,
  GERAR_RELATORIO_GRUPO_SERVICO,
  GERAR_RELATORIO_GRUPO_SERVICO_ERRO,
  GERAR_RELATORIO_GRUPO_SERVICO_SUCESSO,
  LISTAR_GRUPO_SERVICO,
  LISTAR_GRUPO_SERVICO_ERRO,
  LISTAR_GRUPO_SERVICO_SUCESSO,
} from './constants';

const INIT_STATE = {
  grupoServico: {
    result: [],
    total: 0,
  },
  filtros: { ...filtroPadraoStatus },
  carregando: false,
  relatorio: {},
  gerandoRelatorio: false,
};

const grupoServicoReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LISTAR_GRUPO_SERVICO:
      return { ...state, carregando: true };
    case FILTRAR_GRUPO_SERVICO:
      return { ...state, filtros: action.payload, carregando: true };
    case LISTAR_GRUPO_SERVICO_SUCESSO:
      return { ...state, grupoServico: action.payload, carregando: false };
    case LISTAR_GRUPO_SERVICO_ERRO:
      Swal.fire({
        icon: 'error',
        title: 'Erro ao buscar grupos de serviços',
        timer: 2000,
        text: action.payload,
      });
      return { ...state, grupoServico: [], carregando: false };
    case GERAR_RELATORIO_GRUPO_SERVICO:
      return { ...state, filtros: action.payload.filtros, relatorio: action.payload.relatorio, gerandoRelatorio: true };
    case GERAR_RELATORIO_GRUPO_SERVICO_SUCESSO:
      if (action.payload) {
        Swal.fire({
          icon: 'success',
          title: action.payload.titulo,
          timer: action?.payload?.timer || 2000,
          text: action.payload.mensagem,
        });
      }
      return { ...state, gerandoRelatorio: false };
    case GERAR_RELATORIO_GRUPO_SERVICO_ERRO:
      Swal.fire({
        icon: 'error',
        title: 'Falha ao gerar Relatório',
        timer: 2000,
        text: action.payload,
      });
      return { ...state, gerandoRelatorio: false };
    default:
      return state;
  }
};

export default grupoServicoReducer;
