import {
  FILTRAR_SAFRAS,
  LISTAR_SAFRAS,
  LISTAR_SAFRAS_ERRO,
  LISTAR_SAFRAS_SUCESSO,
} from './constants';

export const obterTodosSafras = () => ({
  type: LISTAR_SAFRAS,
});

export const obterSafrasSucesso = (safras) => ({
  type: LISTAR_SAFRAS_SUCESSO,
  payload: safras,
});

export const obterSafrasErro = (mensagem) => ({
  type: LISTAR_SAFRAS_ERRO,
  payload: mensagem,
});

export const filtrarSafras = (filtros) => ({
  type: FILTRAR_SAFRAS,
  payload: filtros,
});