import React, { useEffect, useState } from 'react';
import { Check, X } from 'react-feather';
import { Modal, ModalHeader, ModalBody, Input, Row, Col, Button, ModalFooter } from 'reactstrap';
import SelecaoPrograma from '../../../components/SelecaoAsync/SelecaoPrograma';
import { apiExceptionHandler } from '../../../helpers/exception-handler';
import { acessoRapidoAsync, vincularAcessoRapidoAsync } from '../requests';
import { Alert } from '../../../helpers/Alert';

const AcessoRapido: React.FC = () => {
  const [acessoRapido, setAcessoRapido] = useState(true);
  const [cor, setCor] = useState('');
  const [programa, setPrograma] = useState<{ label: string; value: number } | any>();
  const [programas, setProgramas] =
    useState<{ programa: { label: string; value: number }; color: string; id?: number }[]>();

  useEffect(() => {
    obterDadosInicias();
    !acessoRapido && (window.location.href = '/dashboard');
  }, [acessoRapido]);

  const obterDadosInicias = async () => {
    const dados = await acessoRapidoAsync();
    setProgramas(
      dados.data.map((e) => ({ programa: { value: e.programa.id, label: e.programa.nome }, color: e.cor, id: e.id })),
    );
  };

  const submit = async () => {
    const programasAcessoRapido = programas
      ? programas.map((e) => ({ programaId: e.programa.value, cor: e.color }))
      : [];
    try {
      await vincularAcessoRapidoAsync(programasAcessoRapido);
      await Alert({
        title: 'Acesso rápido salvo com sucesso!',
        type: 'success',
      });
      setAcessoRapido(false);
    } catch (err) {
      apiExceptionHandler(err);
    }
  };

  const removePrograma = (index) => {
    if (programas) {
      programas.splice(index, 1);
      let programasAcessoRapido = [...programas];
      setProgramas(programasAcessoRapido);
    }
  };

  const acrescentaPrograma = () => {
    if (programas && programa) {
      let programasAcessoRapido = [...programas];
      programasAcessoRapido.push({ color: cor, programa: programa });
      setProgramas(programasAcessoRapido);
    }
  };

  return (
    <Modal isOpen={acessoRapido} toggle={() => setAcessoRapido(false)}>
      <ModalHeader toggle={() => setAcessoRapido(false)}>Acesso Rápido</ModalHeader>
      <ModalBody>
        {programas &&
          programas.map((values, index) => (
            <Row>
              <Col md={8}>
                <SelecaoPrograma disabled value={values.programa} />
              </Col>
              <Col md={2}>
                <Input type="color" value={values.color} disabled={true} style={{ opacity: '50%' }} />
              </Col>
              <Col md={2}>
                <Button
                  color="danger"
                  className="py-1 mr-1"
                  size="sm"
                  style={{ margin: '5px' }}
                  onClick={() => removePrograma(index)}
                >
                  <X size={20} />
                </Button>
              </Col>
            </Row>
          ))}
        <Row>
          <Col md={8}>
            <SelecaoPrograma
              isClearable={false}
              onChange={(value) => value && setPrograma({ label: value.label, value: Number(value.value) })}
            />
          </Col>
          <Col md={2}>
            <Input type="color" onChange={(value) => setCor(value.target.value)} />
          </Col>
          <Col md={2}>
            <Button
              color="info"
              className="py-1 mr-1"
              size="sm"
              style={{ margin: '5px' }}
              onClick={() => acrescentaPrograma()}
            >
              <Check size={20} />
            </Button>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => submit()}>Confirmar</Button>
      </ModalFooter>
    </Modal>
  );
};

export default AcessoRapido;
