import {
  FILTRAR_CFOPS,
  GERAR_RELATORIO_CFOPS,
  GERAR_RELATORIO_CFOPS_ERRO,
  GERAR_RELATORIO_CFOPS_SUCESSO,
  LISTAR_CFOPS,
  LISTAR_CFOPS_ERRO,
  LISTAR_CFOPS_SUCESSO,
} from './constants';

export const obterTodosCFOPs = () => ({
  type: LISTAR_CFOPS,
});

export const obterCFOPsSucesso = (cfop) => ({
  type: LISTAR_CFOPS_SUCESSO,
  payload: cfop,
});

export const obterCFOPsErro = (mensagem) => ({
  type: LISTAR_CFOPS_ERRO,
  payload: mensagem,
});

export const filtrarCFOPs = (filtros) => ({
  type: FILTRAR_CFOPS,
  payload: filtros,
});

export const gerarRelatorioCFOPs = (payload) => ({
  type: GERAR_RELATORIO_CFOPS,
  payload: payload,
});

export const gerarRelatorioCFOPsSucesso = (alert) => ({
  type: GERAR_RELATORIO_CFOPS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioCFOPsErro = (mensagem) => ({
  type: GERAR_RELATORIO_CFOPS_ERRO,
  payload: mensagem,
});
