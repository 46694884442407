import { AxiosResponse } from 'axios';
import api from '../../services/api';
import { ITributacaoEstadualCadastro, ITributacaoEstadualConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterTributacoesEstaduaisAsync = (
  query,
): Promise<AxiosResponse<{ result: ITributacaoEstadualConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `tributacoes-estaduais${queryStr}`,
    method: 'GET',
  });
};

export const obterTributacaoEstadualAsync = (id: number): Promise<AxiosResponse<ITributacaoEstadualConsulta>> => {
  return api.request({
    url: `tributacoes-estaduais/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoTributacaoEstadualAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `tributacoes-estaduais/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarTributacaoEstadualAsync = (
  tributacaoEstadual: ITributacaoEstadualCadastro,
): Promise<AxiosResponse<ITributacaoEstadualConsulta>> => {
  return api.request({
    url: `tributacoes-estaduais`,
    data: tributacaoEstadual,
    method: 'POST',
  });
};

export const atualizarTributacaoEstadualAsync = (
  id: number,
  tributacaoEstadual: ITributacaoEstadualCadastro,
): Promise<AxiosResponse<ITributacaoEstadualConsulta>> => {
  return api.request({
    url: `tributacoes-estaduais/${id}`,
    data: tributacaoEstadual,
    method: 'PATCH',
  });
};

export const removerTributacoesEstaduaisEmMassaAsync = (
  ids: number[],
): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `tributacoes-estaduais`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerTributacaoEstadualAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `tributacoes-estaduais/${id}`,
    method: 'DELETE',
  });
