import Swal from 'sweetalert2';
import { SituacaoContrato } from '../../../constants/enums/situacao-contrato.enum';
import {
  FILTRAR_CONTRATOS,
  GERAR_RELATORIO_CONTRATOS,
  GERAR_RELATORIO_CONTRATOS_ERRO,
  GERAR_RELATORIO_CONTRATOS_SUCESSO,
  LISTAR_CONTRATOS,
  LISTAR_CONTRATOS_ERRO,
  LISTAR_CONTRATOS_SUCESSO,
} from './constants';

const filtroPadraoSituacaoContrato = {
  situacao: [{ value: SituacaoContrato.Ativo, label: 'Ativo' }],
};

const INIT_STATE = {
  contratos: [],
  filtros: { ...filtroPadraoSituacaoContrato },
  carregando: false,
  relatorio: {},
  gerandoRelatorio: false,
};

const Contratos = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LISTAR_CONTRATOS:
      return { ...state, carregando: true };
    case FILTRAR_CONTRATOS:
      return { ...state, filtros: action.payload, carregando: true };
    case LISTAR_CONTRATOS_SUCESSO:
      return { ...state, contratos: action.payload, carregando: false };
    case LISTAR_CONTRATOS_ERRO:
      Swal.fire({
        icon: 'error',
        title: 'Erro ao buscar Contratos',
        timer: 2000,
        text: action.payload,
      });
      return { ...state, contratos: [], carregando: false };
    case GERAR_RELATORIO_CONTRATOS:
      return { ...state, filtros: action.payload.filtros, relatorio: action.payload.relatorio, gerandoRelatorio: true };
    case GERAR_RELATORIO_CONTRATOS_SUCESSO:
      if (action.payload) {
        Swal.fire({
          icon: 'success',
          title: action.payload.titulo,
          timer: action?.payload?.timer || 2000,
          text: action.payload.mensagem,
        });
      }
      return { ...state, gerandoRelatorio: false };
    case GERAR_RELATORIO_CONTRATOS_ERRO:
      Swal.fire({
        icon: 'error',
        title: 'Falha ao gerar Relatório',
        timer: 2000,
        text: action.payload,
      });
      return { ...state, gerandoRelatorio: false };
    default:
      return state;
  }
};

export default Contratos;
