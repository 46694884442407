import {
  FILTRAR_MARCAS_PRODUTOS,
  GERAR_RELATORIO_MARCAS_PRODUTOS,
  GERAR_RELATORIO_MARCAS_PRODUTOS_ERRO,
  GERAR_RELATORIO_MARCAS_PRODUTOS_SUCESSO,
  LISTAR_MARCAS_PRODUTOS,
  LISTAR_MARCAS_PRODUTOS_ERRO,
  LISTAR_MARCAS_PRODUTOS_SUCESSO,
} from './constants';

export const obterTodosMarcasProdutos = () => ({
  type: LISTAR_MARCAS_PRODUTOS,
});

export const obterMarcasProdutosSucesso = (marcasProdutos) => ({
  type: LISTAR_MARCAS_PRODUTOS_SUCESSO,
  payload: marcasProdutos,
});

export const obterMarcasProdutosErro = (mensagem) => ({
  type: LISTAR_MARCAS_PRODUTOS_ERRO,
  payload: mensagem,
});

export const filtrarMarcasProdutos = (filtros) => ({
  type: FILTRAR_MARCAS_PRODUTOS,
  payload: filtros,
});

export const gerarRelatorioMarcasProdutos = (payload) => ({
  type: GERAR_RELATORIO_MARCAS_PRODUTOS,
  payload: payload,
});

export const gerarRelatorioMarcasProdutosSucesso = (alert) => ({
  type: GERAR_RELATORIO_MARCAS_PRODUTOS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioMarcasProdutosErro = (mensagem) => ({
  type: GERAR_RELATORIO_MARCAS_PRODUTOS_ERRO,
  payload: mensagem,
});
