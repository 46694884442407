import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { ProgramaCodigo } from '../../../constants/enums/programa-codigo.enum';
import { SET_LIMIT_PAGE, SET_TABLE_ORDER } from '../../../redux/table/constants';
import { FILTRAR_VEICULOS, GERAR_RELATORIO_VEICULOS, LISTAR_VEICULOS } from './constants';
import { obterVeiculosAsync } from '../requests';
import {
  gerarRelatorioVeiculosErro,
  gerarRelatorioVeiculosSucesso,
  obterVeiculosErro,
  obterVeiculosSucesso,
} from './actions';
import { montarFiltroParaApi } from '../../../utils/api-filter';
import { DEFAULT_DOWNLOAD_SUCCESS_MESSAGE, gerarRelatorioRacing } from '../../../services/requests/relatorio';

function* listarTodosVeiculos() {
  try {
    const filtro = yield select((state) => state.Veiculos.filtros);
    const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.Veiculos]);

    let query = { filtro: montarFiltroParaApi(filtro) };

    if (preferencias) {
      query.ordenacao = {
        campo: preferencias?.ordenarPor || 'modelo',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      };
      query.paginacao = {
        pagina: preferencias?.page || 1,
        limite: preferencias?.limit || 50,
      };
    }

    const { data } = yield call(obterVeiculosAsync, query);
    yield put(obterVeiculosSucesso(data));
  } catch (error) {
    yield put(obterVeiculosErro(error.response?.data?.message || error.message));
  }
}

function* relatorioVeiculos() {
  const { filtros, relatorio } = yield select((state) => state.Veiculos);
  const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.Veiculos]);
  const { modelo, tipo, comFiltro, colunas } = relatorio;
  const columns = colunas?.map((coluna) => coluna.value);
  let query = {};

  if (comFiltro) {
    query = {
      filtro: {
        ...montarFiltroParaApi(filtros),
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
      ordenacao: {
        campo: preferencias?.ordenarPor || 'modelo',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      },
    };
  } else {
    query = {
      filtro: {
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
    };
  }

  try {
    const { data, headers } = yield call(gerarRelatorioRacing, ProgramaCodigo.Veiculos, query, tipo);

    if (headers['content-type'].includes('application/json')) {
      yield put(
        gerarRelatorioVeiculosSucesso({
          titulo: 'Pedido de impressão salvo.',
          mensagem: 'Seu relatório está sendo gerado.',
        }),
      );
    } else {
      const url = URL.createObjectURL(
        new Blob([data], {
          type: headers['content-type'] || 'application/pdf',
        }),
      );

      if (url) {
        yield put(gerarRelatorioVeiculosSucesso(DEFAULT_DOWNLOAD_SUCCESS_MESSAGE));
        window.open(url, '_blank');
      }
    }
  } catch (e) {
    yield put(gerarRelatorioVeiculosErro('Ocorreu um erro ao tentar gerar o relatório. Tente novamente mais tarde.'));
  }
}

function* ordenarVeiculos({ payload }) {
  if (payload.programaId === ProgramaCodigo.Veiculos) {
    yield call(listarTodosVeiculos);
  }
}

function* mudarLimitePaginacao({ payload }) {
  if (payload.programaId === ProgramaCodigo.Veiculos) {
    yield call(listarTodosVeiculos);
  }
}

export function* watchListarVeiculos() {
  yield takeLatest(LISTAR_VEICULOS, listarTodosVeiculos);
}

export function* watchFiltrarVeiculos() {
  yield takeLatest(FILTRAR_VEICULOS, listarTodosVeiculos);
}

export function* watchRelatorioVeiculos() {
  yield takeLatest(GERAR_RELATORIO_VEICULOS, relatorioVeiculos);
}

export function* watchOrdenarVeiculos() {
  yield takeLatest(SET_TABLE_ORDER, ordenarVeiculos);
}

export function* watchLimitePaginacao() {
  yield takeLatest(SET_LIMIT_PAGE, mudarLimitePaginacao);
}

function* veiculosSaga() {
  yield all([
    fork(watchListarVeiculos),
    fork(watchFiltrarVeiculos),
    fork(watchRelatorioVeiculos),
    fork(watchOrdenarVeiculos),
    fork(watchLimitePaginacao),
  ]);
}

export default veiculosSaga;
