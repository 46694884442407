import {
  FILTRAR_DEPARTAMENTO_PRODUTO,
  GERAR_RELATORIO_DEPARTAMENTO_PRODUTO,
  GERAR_RELATORIO_DEPARTAMENTO_PRODUTO_ERRO,
  GERAR_RELATORIO_DEPARTAMENTO_PRODUTO_SUCESSO,
  LISTAR_DEPARTAMENTO_PRODUTO,
  LISTAR_DEPARTAMENTO_PRODUTO_ERRO,
  LISTAR_DEPARTAMENTO_PRODUTO_SUCESSO,
} from './constants';

export const obterTodosDepartamentoProduto = () => ({
  type: LISTAR_DEPARTAMENTO_PRODUTO,
});

export const obterDepartamentoProdutoSucesso = (departamentoProduto) => ({
  type: LISTAR_DEPARTAMENTO_PRODUTO_SUCESSO,
  payload: departamentoProduto,
});

export const obterDepartamentoProdutoErro = (mensagem) => ({
  type: LISTAR_DEPARTAMENTO_PRODUTO_ERRO,
  payload: mensagem,
});

export const filtrarDepartamentoProduto = (filtros) => ({
  type: FILTRAR_DEPARTAMENTO_PRODUTO,
  payload: filtros,
});

export const gerarRelatorioDepartamentoProduto = (payload) => ({
  type: GERAR_RELATORIO_DEPARTAMENTO_PRODUTO,
  payload: payload,
});

export const gerarRelatorioDepartamentoProdutoSucesso = (alert) => ({
  type: GERAR_RELATORIO_DEPARTAMENTO_PRODUTO_SUCESSO,
  payload: alert,
});

export const gerarRelatorioDepartamentoProdutoErro = (mensagem) => ({
  type: GERAR_RELATORIO_DEPARTAMENTO_PRODUTO_ERRO,
  payload: mensagem,
});
