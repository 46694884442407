import {
  FILTRAR_GRUPOS_CENTROS_CUSTOS,
  GERAR_RELATORIO_GRUPOS_CENTROS_CUSTOS,
  GERAR_RELATORIO_GRUPOS_CENTROS_CUSTOS_ERRO,
  GERAR_RELATORIO_GRUPOS_CENTROS_CUSTOS_SUCESSO,
  LISTAR_GRUPOS_CENTROS_CUSTOS,
  LISTAR_GRUPOS_CENTROS_CUSTOS_ERRO,
  LISTAR_GRUPOS_CENTROS_CUSTOS_SUCESSO,
} from './constants';

export const obterTodosGruposCentrosCustos = () => ({
  type: LISTAR_GRUPOS_CENTROS_CUSTOS,
});

export const obterGruposCentrosCustosSucesso = (grupoCentroCusto) => ({
  type: LISTAR_GRUPOS_CENTROS_CUSTOS_SUCESSO,
  payload: grupoCentroCusto,
});

export const obterGruposCentrosCustosErro = (mensagem) => ({
  type: LISTAR_GRUPOS_CENTROS_CUSTOS_ERRO,
  payload: mensagem,
});

export const filtrarGruposCentrosCustos = (filtros) => ({
  type: FILTRAR_GRUPOS_CENTROS_CUSTOS,
  payload: filtros,
});
export const gerarRelatorioGruposCentrosCustos = (payload) => ({
  type: GERAR_RELATORIO_GRUPOS_CENTROS_CUSTOS,
  payload: payload,
});

export const gerarRelatorioGruposCentrosCustosSucesso = (alert) => ({
  type: GERAR_RELATORIO_GRUPOS_CENTROS_CUSTOS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioGruposCentrosCustosErro = (mensagem) => ({
  type: GERAR_RELATORIO_GRUPOS_CENTROS_CUSTOS_ERRO,
  payload: mensagem,
});