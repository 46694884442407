import {
  LISTAR_PERMISSOES_SUCESSO,
  GERAR_RELATORIO_PERMISSOES,
  GERAR_RELATORIO_PERMISSOES_ERRO,
  GERAR_RELATORIO_PERMISSOES_SUCESSO,
  LISTAR_PERMISSOES_ERRO,
  LISTAR_PERMISSOES,
  FILTRAR_PERMISSOES,
} from './constants';

export const obterTodosPermissoes = () => ({
  type: LISTAR_PERMISSOES,
});

export const obterPermissoesSucesso = (segmento) => ({
  type: LISTAR_PERMISSOES_SUCESSO,
  payload: segmento,
});

export const obterPermissoesErro = (mensagem) => ({
  type: LISTAR_PERMISSOES_ERRO,
  payload: mensagem,
});

export const filtrarPermissoes = (f) => {
  return ({
    type: FILTRAR_PERMISSOES,
    payload: f,
  })
};

export const gerarRelatorioPermissoes = (payload) => ({
  type: GERAR_RELATORIO_PERMISSOES,
  payload: payload,
});

export const gerarRelatorioPermissoesSucesso = (alert) => ({
  type: GERAR_RELATORIO_PERMISSOES_SUCESSO,
  payload: alert,
});

export const gerarRelatorioPermissoesErro = (mensagem) => ({
  type: GERAR_RELATORIO_PERMISSOES_ERRO,
  payload: mensagem,
});