import { AxiosResponse } from 'axios';
import api from '../../services/api';
import { IClasseProdutoCadastro, IClasseProdutoConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterClassesProdutosAsync = (
  query
): Promise<AxiosResponse<{ result: IClasseProdutoConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `classes-produtos${queryStr}`,
    method: 'GET',
  });
};

export const obterClasseProdutoAsync = (id: number): Promise<AxiosResponse<IClasseProdutoConsulta>> => {
  return api.request({
    url: `classes-produtos/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoClasseProdutoAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `classes-produtos/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarClasseProdutoAsync = (
  classeProduto: IClasseProdutoCadastro
): Promise<AxiosResponse<IClasseProdutoConsulta>> => {
  return api.request({
    url: `classes-produtos`,
    data: classeProduto,
    method: 'POST',
  });
};

export const atualizarClasseProdutoAsync = (
  id: number,
  classeProduto: IClasseProdutoCadastro
): Promise<AxiosResponse<IClasseProdutoConsulta>> => {
  return api.request({
    url: `classes-produtos/${id}`,
    data: classeProduto,
    method: 'PATCH',
  });
};

export const removerClasseProdutoEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `classes-produtos`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerClasseProdutoAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `classes-produtos/${id}`,
    method: 'DELETE',
  });
