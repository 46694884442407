import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { ProgramaCodigo } from '../../../constants/enums/programa-codigo.enum';
import { SET_LIMIT_PAGE, SET_TABLE_ORDER } from '../../../redux/table/constants';
import { montarFiltroParaApi } from '../../../utils/api-filter';
import { obterNotasEntradasAsync } from '../requests';
import { obterNotasEntradasErro, obterNotasEntradasSucesso } from './actions';
import { FILTRAR_NOTAS_ENTRADAS, LISTAR_NOTAS_ENTRADAS } from './constants';

function* listarTodosNotasEntradas() {
  try {
    const filtro = yield select((state) => state.NotasEntradas.filtros);
    const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.NotasEntradas]);

    let query = { filtro: montarFiltroParaApi(filtro) };

    if (preferencias) {
      query.ordenacao = {
        campo: preferencias?.ordenarPor || 'id',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      };
      query.paginacao = {
        pagina: preferencias?.page || 1,
        limite: preferencias?.limit || 50,
      };
    }

    const { data } = yield call(obterNotasEntradasAsync, query);
    yield put(obterNotasEntradasSucesso(data));
  } catch (error) {
    yield put(obterNotasEntradasErro(error.response?.data?.message || error.message));
  }
}

function* ordenarNotasEntradas({ payload }) {
  if (payload.programaId === ProgramaCodigo.NotasEntradas) {
    yield call(listarTodosNotasEntradas);
  }
}

function* mudarLimitePaginacao({ payload }) {
  if (payload.programaId === ProgramaCodigo.NotasEntradas) {
    yield call(listarTodosNotasEntradas);
  }
}

export function* watchListarNotasEntradas() {
  yield takeLatest(LISTAR_NOTAS_ENTRADAS, listarTodosNotasEntradas);
}

export function* watchFiltrarNotasEntradas() {
  yield takeLatest(FILTRAR_NOTAS_ENTRADAS, listarTodosNotasEntradas);
}

export function* watchOrdenarNotasEntradas() {
  yield takeLatest(SET_TABLE_ORDER, ordenarNotasEntradas);
}

export function* watchLimitePaginacao() {
  yield takeLatest(SET_LIMIT_PAGE, mudarLimitePaginacao);
}

function* notasEntradasSaga() {
  yield all([
    fork(watchListarNotasEntradas),
    fork(watchFiltrarNotasEntradas),
    fork(watchOrdenarNotasEntradas),
    fork(watchLimitePaginacao),
  ]);
}

export default notasEntradasSaga;
