import api from '../../services/api';
import { AxiosResponse } from 'axios';
import { IOpcaoCadastralCadastro, IOpcaoCadastralConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterOpcoesCadastraisAsync = (
  query,
): Promise<
  AxiosResponse<{ result: IOpcaoCadastralConsulta[]; total: number }>
> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `opcoes-cadastrais${queryStr}`,
    method: 'GET',
  });
};

export const obterOpcaoCadastralAsync = (
  id: number,
): Promise<AxiosResponse<IOpcaoCadastralConsulta>> => {
  return api.request({
    url: `opcoes-cadastrais/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoOpcaoCadastralAsync = (): Promise<
  AxiosResponse<number>
> => {
  return api.request({
    url: `opcoes-cadastrais/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarOpcoesCadastraisAsync = (
  opcaoCadastral: IOpcaoCadastralCadastro,
): Promise<AxiosResponse<IOpcaoCadastralConsulta>> => {
  return api.request({
    url: `opcoes-cadastrais`,
    data: opcaoCadastral,
    method: 'POST',
  });
};

export const atualizarOpcoesCadastraisAsync = (
  id: number,
  opcaoCadastral: IOpcaoCadastralCadastro,
): Promise<AxiosResponse<IOpcaoCadastralConsulta>> => {
  return api.request({
    url: `opcoes-cadastrais/${id}`,
    data: opcaoCadastral,
    method: 'PATCH',
  });
};

export const removerOpcoesCadastraisEmMassaAsync = (
  ids: number[],
): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `opcoes-cadastrais`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerOpcoesCadastraisAsync = (
  id: number,
): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `opcoes-cadastrais/${id}`,
    method: 'DELETE',
  });
