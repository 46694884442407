import {
  FILTRAR_REGISTRO_ETIQUETAS_ENTRADA_FRUTAS,
  GERAR_RELATORIO_REGISTRO_ETIQUETAS_ENTRADA_FRUTAS,
  GERAR_RELATORIO_REGISTRO_ETIQUETAS_ENTRADA_FRUTAS_ERRO,
  GERAR_RELATORIO_REGISTRO_ETIQUETAS_ENTRADA_FRUTAS_SUCESSO,
  LISTAR_REGISTRO_ETIQUETAS_ENTRADA_FRUTAS,
  LISTAR_REGISTRO_ETIQUETAS_ENTRADA_FRUTAS_ERRO,
  LISTAR_REGISTRO_ETIQUETAS_ENTRADA_FRUTAS_SUCESSO,
} from './constants';

export const obterTodosRegistroEtiquetasEntradaFrutas = () => ({
  type: LISTAR_REGISTRO_ETIQUETAS_ENTRADA_FRUTAS,
});

export const obterRegistroEtiquetasEntradaFrutasSucesso = (RegistroEtiquetasEntradaFrutas) => ({
  type: LISTAR_REGISTRO_ETIQUETAS_ENTRADA_FRUTAS_SUCESSO,
  payload: RegistroEtiquetasEntradaFrutas,
});

export const obterRegistroEtiquetasEntradaFrutasErro = (mensagem) => ({
  type: LISTAR_REGISTRO_ETIQUETAS_ENTRADA_FRUTAS_ERRO,
  payload: mensagem,
});

export const filtrarRegistroEtiquetasEntradaFrutas = (filtros) => ({
  type: FILTRAR_REGISTRO_ETIQUETAS_ENTRADA_FRUTAS,
  payload: filtros,
});

export const gerarRelatorioRegistroEtiquetasEntradaFrutas = (payload) => ({
  type: GERAR_RELATORIO_REGISTRO_ETIQUETAS_ENTRADA_FRUTAS,
  payload: payload,
});

export const gerarRelatorioRegistroEtiquetasEntradaFrutasSucesso = (alert) => ({
  type: GERAR_RELATORIO_REGISTRO_ETIQUETAS_ENTRADA_FRUTAS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioRegistroEtiquetasEntradaFrutasErro = (mensagem) => ({
  type: GERAR_RELATORIO_REGISTRO_ETIQUETAS_ENTRADA_FRUTAS_ERRO,
  payload: mensagem,
});
