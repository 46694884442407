import {
  FILTRAR_CAMARAS_FRIA,
  GERAR_RELATORIO_CAMARAS_FRIA,
  GERAR_RELATORIO_CAMARAS_FRIA_ERRO,
  GERAR_RELATORIO_CAMARAS_FRIA_SUCESSO,
  LISTAR_CAMARAS_FRIA,
  LISTAR_CAMARAS_FRIA_ERRO,
  LISTAR_CAMARAS_FRIA_SUCESSO,
} from './constants';

export const obterTodosCamarasFria = () => ({
  type: LISTAR_CAMARAS_FRIA,
});

export const obterCamarasFriaSucesso = (camarasFria) => ({
  type: LISTAR_CAMARAS_FRIA_SUCESSO,
  payload: camarasFria,
});

export const obterCamarasFriaErro = (mensagem) => ({
  type: LISTAR_CAMARAS_FRIA_ERRO,
  payload: mensagem,
});

export const filtrarCamarasFria = (filtros) => ({
  type: FILTRAR_CAMARAS_FRIA,
  payload: filtros,
});

export const gerarRelatorioCamarasFria = (payload) => ({
  type: GERAR_RELATORIO_CAMARAS_FRIA,
  payload: payload,
});

export const gerarRelatorioCamarasFriaSucesso = (alert) => ({
  type: GERAR_RELATORIO_CAMARAS_FRIA_SUCESSO,
  payload: alert,
});

export const gerarRelatorioCamarasFriaErro = (mensagem) => ({
  type: GERAR_RELATORIO_CAMARAS_FRIA_ERRO,
  payload: mensagem,
});