import moment from 'moment';

/* monta o objeto da forma que a api trabalha */
export const montarFiltroParaApi = (filtros) => {
  const filtroTratado = { ...filtros };

  /* remove o filtro caso o usuário apague os valores: ['', ''] */
  if (Array.isArray(filtroTratado.id)) {
    if (filtroTratado.id[0] === '' && filtroTratado.id[1] === '') {
      delete filtroTratado.id;
    }
  }
  if (Array.isArray(filtroTratado.codigo)) {
    if (filtroTratado.codigo[0] === '' && filtroTratado.codigo[1] === '') {
      delete filtroTratado.codigo;
    }
  }

  /* os campos de range foi convensionado enviar separado por virgula exemplo '2021-01-01,2021-30-12' */
  if (filtroTratado.dataCriacao) {
    const inicio = moment(filtroTratado.dataCriacao[0]).startOf('day').toISOString() || '';
    const fim = moment(filtroTratado.dataCriacao[1]).endOf('day').toISOString() || '';
    filtroTratado.dataCriacao = `${inicio},${fim}`;
  }
  if (filtroTratado.dataUltimaAlteracao) {
    const inicio = moment(filtroTratado.dataUltimaAlteracao[0]).startOf('day').toISOString() || '';
    const fim = moment(filtroTratado.dataUltimaAlteracao[1]).endOf('day').toISOString() || '';
    filtroTratado.dataUltimaAlteracao = `${inicio},${fim}`;
  }

  if (!filtroTratado.buscarPor || !filtroTratado.busca) {
    delete filtroTratado.buscarPor;
    delete filtroTratado.busca;
  }

  if (filtroTratado.status) filtroTratado.status = filtroTratado.status?.map((i) => i.value);

  /* caso encontre um filtro array de objetos label value (padrao dos nossos selects), usar só o value*/
  Object.keys(filtroTratado).forEach((key) => {
    if (!filtroTratado[key] || filtroTratado[key] === '' || filtroTratado[key]?.length === 0) {
      delete filtroTratado[key];
    } else if (filtroTratado[key]?.label) {
      filtroTratado[key] = filtroTratado[key]?.value;
    } else if (Array.isArray(filtroTratado[key]) && filtroTratado[key][0]?.label) {
      filtroTratado[key] = filtroTratado[key]?.map((i) => i.value);
    }
  });

  return filtroTratado;
};
