import api from '../../services/api';
import { montarQueryString } from '../../utils';
import { IPessoaCadastro, IPessoaConsulta } from './interfaces';

export const obterPessoaPorIdAsync = (id: number) => {
  return api.request<IPessoaConsulta>({
    url: `pessoas/${id}`,
    method: 'GET',
  });
};

export const obterPessoasAsync = (query = {}) => {
  const querystr = montarQueryString(query);
  return api.request<{ result: IPessoaConsulta[]; total: number }>({
    url: `pessoas${querystr}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoPessoaAsync = () => {
  return api.request<number>({
    url: `pessoas/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const cadastrarPessoaAsync = (pessoa: IPessoaCadastro) => {
  return api.request<IPessoaConsulta>({
    url: `pessoas`,
    method: 'POST',
    data: pessoa,
  });
};

export const editarPessoaAsync = (id: number, pessoa: IPessoaCadastro) => {
  return api.request<IPessoaConsulta>({
    url: `pessoas/${id}`,
    method: 'PATCH',
    data: pessoa,
  });
};

export const uploadImagemPessoa = (id: number, imagem: File) => {
  const formData = new FormData();
  formData.append('imagem', imagem);

  return api.request({
    url: `pessoas/${id}/upload`,
    method: 'PATCH',
    data: formData,
  });
};

export const deletarImagemPessoa = (id: number) => {
  return api.request({
    url: `pessoas/${id}/deletar-imagem`,
    method: 'PATCH',
  });
};

export const obterOpcoesPessoasAsync = () => {
  return api.request({
    url: `pessoas/enum/opcoes_pessoa`,
    method: 'GET',
  });
};

export const removerPessoasEmMassaAsync = (ids: number[]) => {
  return api.request<{ affected: number }>({
    url: `pessoas`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerPessoaAsync = (id: number) => {
  return api.request<{ affected: number }>({
    url: `pessoas/${id}`,
    method: 'DELETE',
  });
};
