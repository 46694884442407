import { AxiosResponse } from 'axios';

import api from '../../services/api';
import { ISubGrupoFinanceiroCadastro, ISubGrupoFinanceiroConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterSubGruposFinanceirosAsync = (
  query,
): Promise<AxiosResponse<{ result: ISubGrupoFinanceiroConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);
  return api.request({
    url: `sub-grupos-financeiros${queryStr}`,
    method: 'GET',
  });
};

export const obterSubGrupoFinanceiroAsync = (id: number): Promise<AxiosResponse<ISubGrupoFinanceiroConsulta>> => {
  return api.request({
    url: `sub-grupos-financeiros/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoSubGrupoFinanceiroAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `sub-grupos-financeiros/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarSubGrupoFinanceiroAsync = (
  grupoFinanceiro: ISubGrupoFinanceiroCadastro,
): Promise<AxiosResponse<ISubGrupoFinanceiroConsulta>> => {
  return api.request({
    url: `sub-grupos-financeiros`,
    data: grupoFinanceiro,
    method: 'POST',
  });
};

export const atualizarSubGrupoFinanceiroAsync = (
  id: number,
  grupoFinanceiro: ISubGrupoFinanceiroCadastro,
): Promise<AxiosResponse<ISubGrupoFinanceiroConsulta>> => {
  return api.request({
    url: `sub-grupos-financeiros/${id}`,
    data: grupoFinanceiro,
    method: 'PATCH',
  });
};

export const removerSubGruposFinanceirosEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `sub-grupos-financeiros`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerSubGrupoFinanceiroAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `sub-grupos-financeiros/${id}`,
    method: 'DELETE',
  });
