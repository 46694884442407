import { GERAR_RELATORIO_LOG, GERAR_RELATORIO_LOG_ERRO, GERAR_RELATORIO_LOG_SUCESSO } from './constants';

export const gerarRelatorioLog = (payload) => ({
  type: GERAR_RELATORIO_LOG,
  payload: payload,
});

export const gerarRelatorioLogSucesso = (alert) => ({
  type: GERAR_RELATORIO_LOG_SUCESSO,
  payload: alert,
});

export const gerarRelatorioLogErro = (mensagem) => ({
  type: GERAR_RELATORIO_LOG_ERRO,
  payload: mensagem,
});
