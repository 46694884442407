import * as FeatherIcon from 'react-feather';
import { ISubMenu } from '../../../../interfaces/menu';
import { PermissaoCodigo } from '../../../enums/permissao-codigo.enum';
import { ProgramaCodigo } from '../../../enums/programa-codigo.enum';

export const CadastrosAgroAtividades: ISubMenu = {
  name: 'Agro Atividades',
  icon: FeatherIcon.CloudRain,
  children: [
    {
      programaCod: ProgramaCodigo.Atividades,
      name: 'Atividades',
      path: '/atividades/listar',
      component: 'listarAtividades',
      style: {
        fontWeight: 'bold',
      },
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/atividades/listar',
          component: 'listarAtividades',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/atividades/criar',
          component: 'atividades',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/atividades/editar/:id',
          component: 'atividades',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/atividades/visualizar/:id',
          component: 'atividades',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.GruposAtividades,
      name: 'Grupos de Atividades',
      path: '/grupos-atividades/listar',
      component: 'listarGruposAtividades',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/grupos-atividades/listar',
          component: 'listarGruposAtividades',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/grupos-atividades/criar',
          component: 'gruposAtividades',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/grupos-atividades/editar/:id',
          component: 'gruposAtividades',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/grupos-atividades/visualizar/:id',
          component: 'gruposAtividades',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.ObservacoesAtividades,
      name: 'Observações Atividades',
      path: '/observacoes-atividades/listar',
      component: 'listarObservacoesAtividades',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/observacoes-atividades/listar',
          component: 'listarObservacoesAtividades',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/observacoes-atividades/criar',
          component: 'observacoesAtividades',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/observacoes-atividades/editar/:id',
          component: 'observacoesAtividades',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/observacoes-atividades/visualizar/:id',
          component: 'observacoesAtividades',
        },
      ],
    },
  ],
};