import { montarQueryString } from '../../utils';
import api from '../../services/api';
import { ProgramaCodigo } from '../../constants/enums/programa-codigo.enum';
import { ParametroCodigo } from '../../constants/enums/parametro-codigo.enum';
import { AxiosResponse } from 'axios';

export const obterParametros = (query = {}) => {
  const querystr = montarQueryString(query);
  return api.request({
    url: `parametro${querystr}`,
    method: 'GET',
  });
};

export const obterParametroPorId = (id) => {
  return api.request({
    url: `parametro/por_id/${id}`,
    method: 'GET',
  });
};

export const obterParametroOrganizacao = (parametroId, organizacaoId) => {
  return api.request({
    url: `parametro/por_id/${parametroId}/organizacao/${organizacaoId}`,
    method: 'GET',
  });
};

export const obterParametroEmpresa = (parametroId, empresaId) => {
  return api.request({
    url: `parametro/por_id/${parametroId}/empresa/${empresaId}`,
    method: 'GET',
  });
};

export const obterParametroUsuario = (parametroId, usuarioId) => {
  return api.request({
    url: `parametro/por_id/${parametroId}/usuario/${usuarioId}`,
    method: 'GET',
  });
};

export const obterValorParametroAsync = (
  programaCod: ProgramaCodigo,
  parametroCod: ParametroCodigo
): Promise<AxiosResponse> => {
  return api.request({
    url: `parametro/obter-valor/${parametroCod}/programa/${programaCod}`,
    method: 'GET',
  });
};

export const editarParametro = (parametroId, data) => {
  return api.request({
    url: `parametro/editar/${parametroId}`,
    method: 'PATCH',
    data: data,
  });
};

export const editarParametroOrganizacao = (parametroId, organizacaoId, valor) => {
  return api.request({
    url: `parametro/editar/${parametroId}/organizacao/${organizacaoId}`,
    method: 'PATCH',
    data: { valor },
  });
};

export const editarParametroEmpresa = (parametroId, empresaId, valor) => {
  return api.request({
    url: `parametro/editar/${parametroId}/empresa/${empresaId}`,
    method: 'PATCH',
    data: { valor },
  });
};

export const editarParametroUsuario = (parametroId, usuarioId, valor) => {
  return api.request({
    url: `parametro/editar/${parametroId}/usuario/${usuarioId}`,
    method: 'PATCH',
    data: { valor },
  });
};

export const removerParametroOrganizacaoAsync = (paramOrgId) => {
  return api.request({
    url: `parametro/deletar/organizacao/${paramOrgId}`,
    method: 'DELETE',
  });
};

export const removerParametroEmpresaAsync = (paramEmpId) => {
  return api.request({
    url: `parametro/deletar/empresa/${paramEmpId}`,
    method: 'DELETE',
  });
};

export const removerParametroUsuarioAsync = (paramUsuarioId) => {
  return api.request({
    url: `parametro/deletar/usuario/${paramUsuarioId}`,
    method: 'DELETE',
  });
};
