import { ISubMenu } from '../../../interfaces/menu';
import { PermissaoCodigo } from '../../enums/permissao-codigo.enum';
import { ProgramaCodigo } from '../../enums/programa-codigo.enum';
import { IoFastFood } from 'react-icons/io5';

export const CadastrosFood: ISubMenu = {
    name: 'Food',
    icon: IoFastFood,
    children: [
      {
        programaCod: ProgramaCodigo.MesasComandas,
        name: 'Mesas e Comandas',
        path: '/mesas-comandas/listar',
        component: 'listarMesasComandas',
        children: [
          {
            permissaoCod: PermissaoCodigo.Acesso,
            path: '/mesas-comandas/listar',
            component: 'listarMesasComandas',
          },
          {
            permissaoCod: PermissaoCodigo.Incluir,
            path: '/mesas-comandas/criar',
            component: 'mesasComandas',
          },
          {
            permissaoCod: PermissaoCodigo.Alterar,
            path: '/mesas-comandas/editar/:id',
            component: 'mesasComandas',
          },
          {
            permissaoCod: PermissaoCodigo.Acesso,
            path: '/mesas-comandas/visualizar/:id',
            component: 'mesasComandas',
          },
        ],
      },
      {
        programaCod: ProgramaCodigo.SetoresMesas,
        name: 'Setores de Mesas',
        path: '/setores-mesas/listar',
        component: 'listarSetoresMesas',
        children: [
          {
            permissaoCod: PermissaoCodigo.Acesso,
            path: '/setores-mesas/listar',
            component: 'listarSetoresMesas',
          },
          {
            permissaoCod: PermissaoCodigo.Incluir,
            path: '/setores-mesas/criar',
            component: 'setoresMesas',
          },
          {
            permissaoCod: PermissaoCodigo.Alterar,
            path: '/setores-mesas/editar/:id',
            component: 'setoresMesas',
          },
          {
            permissaoCod: PermissaoCodigo.Acesso,
            path: '/setores-mesas/visualizar/:id',
            component: 'setoresMesas',
          },
        ],
      },
    ],
  };