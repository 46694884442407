// @flow
import { applyMiddleware, compose, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import sagas from './sagas';

export const persistConfig = {
  key: 'domper',
  storage: storage,
  whitelist: ['TableConfig', 'Layout', 'MenuAction', 'Auth'],
  stateReconciler: autoMergeLevel2,
};
const pReducer = persistReducer(persistConfig, reducers);

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

function configureStore(initialState) {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(pReducer, initialState, composeEnhancers(applyMiddleware(...middlewares)));
  const persistor = persistStore(store);
  sagaMiddleware.run(sagas);
  return { store, persistor };
}

const store = configureStore();

export default store;
