import {
  FILTRAR_ARMADILHAS,
  GERAR_RELATORIO_ARMADILHAS,
  GERAR_RELATORIO_ARMADILHAS_ERRO,
  GERAR_RELATORIO_ARMADILHAS_SUCESSO,
  LISTAR_ARMADILHAS,
  LISTAR_ARMADILHAS_ERRO,
  LISTAR_ARMADILHAS_SUCESSO,
} from './constants';

export const obterTodosArmadilhas = () => ({
  type: LISTAR_ARMADILHAS,
});

export const obterArmadilhasSucesso = (armadilhas) => ({
  type: LISTAR_ARMADILHAS_SUCESSO,
  payload: armadilhas,
});

export const obterArmadilhasErro = (mensagem) => ({
  type: LISTAR_ARMADILHAS_ERRO,
  payload: mensagem,
});

export const filtrarArmadilhas = (filtros) => ({
  type: FILTRAR_ARMADILHAS,
  payload: filtros,
});

export const gerarRelatorioArmadilhas = (payload) => ({
  type: GERAR_RELATORIO_ARMADILHAS,
  payload: payload,
});

export const gerarRelatorioArmadilhasSucesso = (alert) => ({
  type: GERAR_RELATORIO_ARMADILHAS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioArmadilhasErro = (mensagem) => ({
  type: GERAR_RELATORIO_ARMADILHAS_ERRO,
  payload: mensagem,
});