import {
  FILTRAR_VISITAS_TECNICAS,
  LISTAR_VISITAS_TECNICAS,
  LISTAR_VISITAS_TECNICAS_ERRO,
  LISTAR_VISITAS_TECNICAS_SUCESSO,
  GERAR_RELATORIO_VISITAS_TECNICAS,
  GERAR_RELATORIO_VISITAS_TECNICAS_SUCESSO,
  GERAR_RELATORIO_VISITAS_TECNICAS_ERRO,
} from './constants';

export const obterTodosVisitasTecnicas = () => ({
  type: LISTAR_VISITAS_TECNICAS,
});

export const obterVisitasTecnicasSucesso = (VisitasTecnicas) => ({
  type: LISTAR_VISITAS_TECNICAS_SUCESSO,
  payload: VisitasTecnicas,
});

export const obterVisitasTecnicasErro = (mensagem) => ({
  type: LISTAR_VISITAS_TECNICAS_ERRO,
  payload: mensagem,
});

export const filtrarVisitasTecnicas = (filtros) => ({
  type: FILTRAR_VISITAS_TECNICAS,
  payload: filtros,
});

export const gerarRelatorioVisitasTecnicas = (payload) => ({
  type: GERAR_RELATORIO_VISITAS_TECNICAS,
  payload: payload,
});

export const gerarRelatorioVisitasTecnicasSucesso = (alert) => ({
  type: GERAR_RELATORIO_VISITAS_TECNICAS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioVisitasTecnicasErro = (mensagem) => ({
  type: GERAR_RELATORIO_VISITAS_TECNICAS_ERRO,
  payload: mensagem,
});
