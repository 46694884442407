import { AxiosResponse } from 'axios';
import { ProgramaCodigo } from '../../constants/enums/programa-codigo.enum';
import api from '../../services/api';
import { montarQueryString } from '../../utils';

export const obterConfiguracoes = (query = {}) => {
  const querystr = montarQueryString(query);
  return api.request({
    url: `configuracao${querystr}`,
    method: 'GET',
  });
};

export const obterConfiguracaoPorId = (id) => {
  return api.request({
    url: `configuracao/por_id/${id}`,
    method: 'GET',
  });
};

export const obterConfiguracaoOrganizacao = (configuracaoId, organizacaoId) => {
  return api.request({
    url: `configuracao/por_id/${configuracaoId}/organizacao/${organizacaoId}`,
    method: 'GET',
  });
};

export const obterConfiguracaoEmpresa = (configuracaoId, empresaId) => {
  return api.request({
    url: `configuracao/por_id/${configuracaoId}/empresa/${empresaId}`,
    method: 'GET',
  });
};

export const obterConfiguracaoUsuario = (configuracaoId, usuarioId) => {
  return api.request({
    url: `configuracao/por_id/${configuracaoId}/usuario/${usuarioId}`,
    method: 'GET',
  });
};

export const obterValorConfiguracaoAsync = (
  programaCod: ProgramaCodigo,
  configuracaoCod: number,
): Promise<AxiosResponse> => {
  return api.request({
    url: `configuracao/obter-valor/${configuracaoCod}/programa/${programaCod}`,
    method: 'GET',
  });
};

export const editarConfiguracao = (configuracaoId, data) => {
  return api.request({
    url: `configuracao/editar/${configuracaoId}`,
    method: 'PATCH',
    data: data,
  });
};

export const editarConfiguracaoOrganizacao = (configuracaoId, organizacaoId, valor) => {
  return api.request({
    url: `configuracao/editar/${configuracaoId}/organizacao/${organizacaoId}`,
    method: 'PATCH',
    data: { valor },
  });
};

export const editarConfiguracaoEmpresa = (configuracaoId, empresaId, valor) => {
  return api.request({
    url: `configuracao/editar/${configuracaoId}/empresa/${empresaId}`,
    method: 'PATCH',
    data: { valor },
  });
};

export const editarConfiguracaoUsuario = (configuracaoId, usuarioId, valor) => {
  return api.request({
    url: `configuracao/editar/${configuracaoId}/usuario/${usuarioId}`,
    method: 'PATCH',
    data: { valor },
  });
};

export const removerConfiguracaoOrganizacaoAsync = (paramOrgId) => {
  return api.request({
    url: `configuracao/deletar/organizacao/${paramOrgId}`,
    method: 'DELETE',
  });
};

export const removerConfiguracaoEmpresaAsync = (paramEmpId) => {
  return api.request({
    url: `configuracao/deletar/empresa/${paramEmpId}`,
    method: 'DELETE',
  });
};

export const removerConfiguracaoUsuarioAsync = (paramUsuarioId) => {
  return api.request({
    url: `configuracao/deletar/usuario/${paramUsuarioId}`,
    method: 'DELETE',
  });
};
