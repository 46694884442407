import {
  GERAR_RELATORIO_PROPRIEDADES,
  GERAR_RELATORIO_PROPRIEDADES_ERRO,
  GERAR_RELATORIO_PROPRIEDADES_SUCESSO,
  LISTAR_PROPRIEDADES_SUCESSO,
  LISTAR_PROPRIEDADES_ERRO,
  LISTAR_PROPRIEDADES,
  FILTRAR_PROPRIEDADES,
} from './constants';

export const obterTodasPropriedades = () => ({
  type: LISTAR_PROPRIEDADES,
});

export const obterTodasPropriedadesSucesso = (propriedades) => ({
  type: LISTAR_PROPRIEDADES_SUCESSO,
  payload: propriedades,
});

export const obterTodasPropriedadesErro = (mensagem) => ({
  type: LISTAR_PROPRIEDADES_ERRO,
  payload: mensagem,
});

export const filtrarPropriedades = (filtro) => {
  return {
    type: FILTRAR_PROPRIEDADES,
    payload: filtro,
  };
};

export const gerarRelatorioPropriedades = (payload) => ({
  type: GERAR_RELATORIO_PROPRIEDADES,
  payload: payload,
});

export const gerarRelatorioPropriedadesSucesso = (alert) => ({
  type: GERAR_RELATORIO_PROPRIEDADES_SUCESSO,
  payload: alert,
});

export const gerarRelatorioPropriedadesErro = (mensagem) => ({
  type: GERAR_RELATORIO_PROPRIEDADES_ERRO,
  payload: mensagem,
});
