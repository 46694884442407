import {
  LISTAR_EMPRESAS_SUCESSO,
  GERAR_RELATORIO_EMPRESAS,
  GERAR_RELATORIO_EMPRESAS_ERRO,
  GERAR_RELATORIO_EMPRESAS_SUCESSO,
  LISTAR_EMPRESAS_ERRO,
  LISTAR_EMPRESAS,
  FILTRAR_EMPRESAS,
} from './constants';

export const obterTodasEmpresas = () => ({
  type: LISTAR_EMPRESAS,
});

export const obterTodasEmpresasSucesso = (segmento) => ({
  type: LISTAR_EMPRESAS_SUCESSO,
  payload: segmento,
});

export const obterTodasEmpresasErro = (mensagem) => ({
  type: LISTAR_EMPRESAS_ERRO,
  payload: mensagem,
});

export const filtrarEmpresas = (f) => {
  return ({
    type: FILTRAR_EMPRESAS,
    payload: f,
  })
};

export const gerarRelatorioEmpresas = (payload) => ({
  type: GERAR_RELATORIO_EMPRESAS,
  payload: payload,
});

export const gerarRelatorioEmpresasSucesso = (alert) => ({
  type: GERAR_RELATORIO_EMPRESAS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioEmpresasErro = (mensagem) => ({
  type: GERAR_RELATORIO_EMPRESAS_ERRO,
  payload: mensagem,
});
