import { AxiosResponse } from 'axios';

import api from '../../services/api';
import { IAberturaFechamentoPDVCadastro, IAberturaFechamentoPDVConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterAberturasFechamentosPDVAsync = (
  query,
): Promise<AxiosResponse<{ result: IAberturaFechamentoPDVConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `abertura-fechamento-pdv${queryStr}`,
    method: 'GET',
  });
};

export const obterAberturaFechamentoPDVAsync = (id: number): Promise<AxiosResponse<IAberturaFechamentoPDVConsulta>> => {
  return api.request({
    url: `abertura-fechamento-pdv/${id}`,
    method: 'GET',
  });
};

export const criarAberturaFechamentoPDVAsync = (
  notaFiscal: IAberturaFechamentoPDVCadastro,
): Promise<AxiosResponse<IAberturaFechamentoPDVConsulta>> => {
  return api.request({
    url: `abertura-fechamento-pdv`,
    data: notaFiscal,
    method: 'POST',
  });
};

export const atualizarAberturaFechamentoPDVAsync = (
  id: number,
  data: IAberturaFechamentoPDVCadastro,
): Promise<AxiosResponse<IAberturaFechamentoPDVConsulta>> => {
  return api.request({
    url: `abertura-fechamento-pdv/${id}`,
    data: data,
    method: 'PATCH',
  });
};

export const removerAberturaFechamentoPDVAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `abertura-fechamento-pdv/${id}`,
    method: 'DELETE',
  });

  export const removerAberturaFechamentoPDVEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
    return api.request({
      url: `abertura-fechamento-pdv`,
      data: { ids },
      method: 'DELETE',
    });
  };
  