import { AxiosResponse } from 'axios';
import api from '../../services/api';
import { IDanoDefeitoCadastro, IDanoDefeitoConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterDanosDefeitosAsync = (
  query
): Promise<AxiosResponse<{ result: IDanoDefeitoConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `danos-defeitos${queryStr}`,
    method: 'GET',
  });
};

export const obterDanoDefeitoAsync = (id: number): Promise<AxiosResponse<IDanoDefeitoConsulta>> => {
  return api.request({
    url: `danos-defeitos/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoDanoDefeitoAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `danos-defeitos/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarDanoDefeitoAsync = (
  danoDefeito: IDanoDefeitoCadastro
): Promise<AxiosResponse<IDanoDefeitoConsulta>> => {
  return api.request({
    url: `danos-defeitos`,
    data: danoDefeito,
    method: 'POST',
  });
};

export const atualizarDanoDefeitoAsync = (
  id: number,
  danoDefeito: IDanoDefeitoCadastro
): Promise<AxiosResponse<IDanoDefeitoConsulta>> => {
  return api.request({
    url: `danos-defeitos/${id}`,
    data: danoDefeito,
    method: 'PATCH',
  });
};

export const removerDanoDefeitoEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `danos-defeitos`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerDanoDefeitoAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `danos-defeitos/${id}`,
    method: 'DELETE',
  });
