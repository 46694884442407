import { Alert } from './Alert';

export const obterDadosIniciaisExceptionHandler = (err) => {
  Alert({
    title: 'Ocorreu um erro ao obter os dados iniciais!',
    text: err?.response?.data?.message || err?.message || '',
    type: 'error',
  });
};

export const salvarExceptionHandler = (err: any, title?: string) => {
  if (err?.errorFields?.length > 0) {
    Alert({
      title: 'Dados inválidos!',
      text: err.errorFields.map((e) => e.errors[0]).join(', '),
      type: 'error',
    });
  } else {
    Alert({
      title: title || 'Ocorreu um erro ao salvar!',
      text: err?.response?.data?.message || err?.message || '',
      type: 'error',
    });
  }
};

export const excluirExceptionHandler = (err) => {
  Alert({
    title: 'Ocorreu um erro ao excluir!',
    text: err?.response?.data?.message || err?.message || '',
    type: 'error',
  });
};

export const apiExceptionHandler = (err, title?: string) => {
  Alert({
    title: title || 'Ocorreu um erro!',
    text: err?.response?.data?.message || err?.message || '',
    type: 'error',
  });
};
