import {
  FILTRAR_CULTURAS,
  GERAR_RELATORIO_CULTURAS,
  GERAR_RELATORIO_CULTURAS_ERRO,
  GERAR_RELATORIO_CULTURAS_SUCESSO,
  LISTAR_CULTURAS,
  LISTAR_CULTURAS_ERRO,
  LISTAR_CULTURAS_SUCESSO,
} from './constants';

export const obterTodosCulturas = () => ({
  type: LISTAR_CULTURAS,
});

export const obterCulturasSucesso = (culturas) => ({
  type: LISTAR_CULTURAS_SUCESSO,
  payload: culturas,
});

export const obterCulturasErro = (mensagem) => ({
  type: LISTAR_CULTURAS_ERRO,
  payload: mensagem,
});

export const filtrarCulturas = (filtros) => ({
  type: FILTRAR_CULTURAS,
  payload: filtros,
});

export const gerarRelatorioCulturas = (payload) => ({
  type: GERAR_RELATORIO_CULTURAS,
  payload: payload,
});

export const gerarRelatorioCulturasSucesso = (alert) => ({
  type: GERAR_RELATORIO_CULTURAS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioCulturasErro = (mensagem) => ({
  type: GERAR_RELATORIO_CULTURAS_ERRO,
  payload: mensagem,
});
