import {
  FILTRAR_ESTADOS,
  GERAR_RELATORIO_ESTADOS,
  GERAR_RELATORIO_ESTADOS_ERRO,
  GERAR_RELATORIO_ESTADOS_SUCESSO,
  LISTAR_ESTADOS,
  LISTAR_ESTADOS_ERRO,
  LISTAR_ESTADOS_SUCESSO,
} from './constants';

export const obterTodosEstados = () => ({
  type: LISTAR_ESTADOS,
});

export const obterEstadosSucesso = (estados) => ({
  type: LISTAR_ESTADOS_SUCESSO,
  payload: estados,
});

export const obterEstadosErro = (mensagem) => ({
  type: LISTAR_ESTADOS_ERRO,
  payload: mensagem,
});

export const filtrarEstados = (filtros) => ({
  type: FILTRAR_ESTADOS,
  payload: filtros,
});

export const gerarRelatorioEstados = (payload) => ({
  type: GERAR_RELATORIO_ESTADOS,
  payload: payload,
});

export const gerarRelatorioEstadosSucesso = (alert) => ({
  type: GERAR_RELATORIO_ESTADOS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioEstadosErro = (mensagem) => ({
  type: GERAR_RELATORIO_ESTADOS_ERRO,
  payload: mensagem,
});
