import * as FeatherIcon from 'react-feather';
import { IMenu } from '../../interfaces/menu';
import { PermissaoCodigo } from '../enums/permissao-codigo.enum';
import { ProgramaCodigo } from '../enums/programa-codigo.enum';
import { RoleCodigo } from '../enums/role-codigo.enum';

export const TecnicoRevendaMenus: IMenu = {
  roles: [RoleCodigo.RevendaTec],
  name: 'Técnico Revenda',
  children: [
    {
      programaCod: ProgramaCodigo.Parametros,
      name: 'Parâmetros',
      path: '/parametros/listar',
      icon: FeatherIcon.Sliders,
      component: 'parametrosListar',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/parametros/listar',
          component: 'parametrosListar',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/parametros/editar-valor/:id',
          component: 'parametrosCadastro',
        },
      ],
    },
  ],
};
