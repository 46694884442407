import { AxiosResponse } from 'axios';

import api from '../../services/api';
import { IUnidadeMedidaProdutoCadastro, IUnidadeMedidaProdutoConsulta } from './interfaces';
import { montarQueryString } from '../../utils';
import { IConversaoColheitaConsulta } from './cadastro/conversoes-colheita/interfaces';

export const obterUnidadesMedidasProdutosAsync = (
  query,
): Promise<AxiosResponse<{ result: IUnidadeMedidaProdutoConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `unidades-medidas-produtos${queryStr}`,
    method: 'GET',
  });
};

export const obterUnidadeMedidaProdutoAsync = (id: number): Promise<AxiosResponse<IUnidadeMedidaProdutoConsulta>> => {
  return api.request({
    url: `unidades-medidas-produtos/${id}`,
    method: 'GET',
  });
};

export const obterConfiguracaoUnidadeMedidaOrganizacaoAsync = (
  unidadeMedidaId: number,
  organizacaoId: number,
): Promise<AxiosResponse<IConversaoColheitaConsulta[]>> => {
  return api.request({
    url: `unidades-medidas-produtos/${unidadeMedidaId}/configuracao-organizacao/${organizacaoId}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoUnidadeMedidaProdutoAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `unidades-medidas-produtos/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarUnidadeMedidaProdutoAsync = (
  sistemaProducao: IUnidadeMedidaProdutoCadastro,
): Promise<AxiosResponse<IUnidadeMedidaProdutoConsulta>> => {
  return api.request({
    url: `unidades-medidas-produtos`,
    data: sistemaProducao,
    method: 'POST',
  });
};

export const atualizarUnidadeMedidaProdutoAsync = (
  id: number,
  sistemaProducao: IUnidadeMedidaProdutoCadastro,
): Promise<AxiosResponse<IUnidadeMedidaProdutoConsulta>> => {
  return api.request({
    url: `unidades-medidas-produtos/${id}`,
    data: sistemaProducao,
    method: 'PATCH',
  });
};

export const removerUnidadeMedidaProdutoEmMassaAsync = (
  ids: number[],
): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `unidades-medidas-produtos`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerUnidadeMedidaProdutoAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `unidades-medidas-produtos/${id}`,
    method: 'DELETE',
  });
