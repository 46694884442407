import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { ProgramaCodigo } from '../../../constants/enums/programa-codigo.enum';
import { SET_LIMIT_PAGE, SET_TABLE_ORDER } from '../../../redux/table/constants';
import { montarFiltroParaApi } from '../../../utils/api-filter';
import { obterModelosEtiquetasAsync } from '../requests';
import { obterModelosEtiquetasErro, obterModelosEtiquetasSucesso } from './actions';
import { FILTRAR_MODELOS_ETIQUETA, LISTAR_MODELOS_ETIQUETA } from './constants';

function* listarTodosModelosEtiquetas() {
  try {
    const filtro = yield select((state) => state.ModelosEtiquetas.filtros);
    const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.ModelosEtiquetas]);

    let query = { filtro: montarFiltroParaApi(filtro) };

    if (preferencias) {
      query.ordenacao = {
        campo: preferencias?.ordenarPor || 'nome',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      };
      query.paginacao = {
        pagina: preferencias?.page || 1,
        limite: preferencias?.limit || 50,
      };
    }

    const { data } = yield call(obterModelosEtiquetasAsync, query);
    yield put(obterModelosEtiquetasSucesso(data));
  } catch (error) {
    yield put(obterModelosEtiquetasErro(error.response?.data?.message || error.message));
  }
}

function* ordenarModelosEtiquetas({ payload }) {
  if (payload.programaId === ProgramaCodigo.ModelosEtiquetas) {
    yield call(listarTodosModelosEtiquetas);
  }
}

function* mudarLimitePaginacao({ payload }) {
  if (payload.programaId === ProgramaCodigo.ModelosEtiquetas) {
    yield call(listarTodosModelosEtiquetas);
  }
}

export function* watchListarModelosEtiquetas() {
  yield takeLatest(LISTAR_MODELOS_ETIQUETA, listarTodosModelosEtiquetas);
}

export function* watchFiltrarModelosEtiquetas() {
  yield takeLatest(FILTRAR_MODELOS_ETIQUETA, listarTodosModelosEtiquetas);
}

export function* watchOrdenarModelosEtiquetas() {
  yield takeLatest(SET_TABLE_ORDER, ordenarModelosEtiquetas);
}

export function* watchLimitePaginacao() {
  yield takeLatest(SET_LIMIT_PAGE, mudarLimitePaginacao);
}

function* modelosEtiquetasSaga() {
  yield all([
    fork(watchListarModelosEtiquetas),
    fork(watchFiltrarModelosEtiquetas),
    fork(watchOrdenarModelosEtiquetas),
    fork(watchLimitePaginacao),
  ]);
}

export default modelosEtiquetasSaga;
