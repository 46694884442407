export const SET_TABLE_PREFERENCE = 'SET_TABLE_PREFERENCE';
export const SET_TABLE_ORDER = 'SET_TABLE_ORDER';
export const SET_TABLE_PREFERENCE_ASYNC = 'SET_TABLE_PREFERENCE_ASYNC';
export const SET_TABLE_ORDER_ASYNC = 'SET_TABLE_ORDER_ASYNC';
export const GET_TABLE_PREFERENCE = 'GET_TABLE_PREFERENCE';
export const GET_TABLE_PREFERENCE_ASYNC = 'GET_TABLE_PREFERENCE_ASYNC';
export const PREFERENCE_READY = 'PREFERENCE_READY';
export const SET_ACTIVE_MENU = 'SET_ACTIVE_MENU';
export const SET_PAGE = 'SET_PAGE';
export const SET_LIMIT_PAGE = 'SET_LIMIT_PAGE';
export const SET_LIMIT_PAGE_ASYNC = 'SET_LIMIT_PAGE_ASYNC';
export const SET_CAMPO_BUSCA_PADRAO = 'SET_CAMPO_BUSCA_PADRAO';
export const SET_CAMPO_BUSCA_PADRAO_ASYNC = 'SET_CAMPO_BUSCA_PADRAO_ASYNC';
