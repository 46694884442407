import {
    FILTRAR_VALORES_MAO_OBRA,
    GERAR_RELATORIO_VALORES_MAO_OBRA,
    GERAR_RELATORIO_VALORES_MAO_OBRA_ERRO,
    GERAR_RELATORIO_VALORES_MAO_OBRA_SUCESSO,
    LISTAR_VALORES_MAO_OBRA,
    LISTAR_VALORES_MAO_OBRA_ERRO,
    LISTAR_VALORES_MAO_OBRA_SUCESSO,
  } from './constants';
  
  export const obterTodosValoresMaoObra = () => ({
    type: LISTAR_VALORES_MAO_OBRA,
  });
  
  export const obterValoresMaoObraSucesso = (valoresMaoObra) => ({
    type: LISTAR_VALORES_MAO_OBRA_SUCESSO,
    payload: valoresMaoObra,
  });
  
  export const obterValoresMaoObraErro = (mensagem) => ({
    type: LISTAR_VALORES_MAO_OBRA_ERRO,
    payload: mensagem,
  });
  
  export const filtrarValoresMaoObra = (filtros) => ({
    type: FILTRAR_VALORES_MAO_OBRA,
    payload: filtros,
  });

  export const gerarRelatorioValoresMaoObra = (payload) => ({
    type: GERAR_RELATORIO_VALORES_MAO_OBRA,
    payload: payload,
  });
  
  export const gerarRelatorioValoresMaoObraSucesso = (alert) => ({
    type: GERAR_RELATORIO_VALORES_MAO_OBRA_SUCESSO,
    payload: alert,
  });
  
  export const gerarRelatorioValoresMaoObraErro = (mensagem) => ({
    type: GERAR_RELATORIO_VALORES_MAO_OBRA_ERRO,
    payload: mensagem,
  });
  