import { AxiosResponse } from 'axios';

import api from '../../services/api';
import { IDadosDestinatario, INotaFiscalServicosCadastro, INotaFiscalServicosConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterNotasFiscaisServicosAsync = (
  query,
): Promise<AxiosResponse<{ result: INotaFiscalServicosConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `notas-fiscais-servicos${queryStr}`,
    method: 'GET',
  });
};

export const obterNotaFiscalServicosAsync = (id: number): Promise<AxiosResponse<any>> => {
  return api.request({
    url: `notas-fiscais-servicos/${id}`,
    method: 'GET',
  });
};

export const buscarDadosDestinatarioAsync = (pessoaId: number) => {
  return api.request<IDadosDestinatario>({
    url: `/notas-fiscais-servicos/buscar-dados-destinatario/${pessoaId}`,
    method: 'GET',
  });
};

export const criarNotaFiscalServicosAsync = (
  notaFiscalServico: INotaFiscalServicosCadastro,
): Promise<AxiosResponse<INotaFiscalServicosConsulta>> => {
  return api.request({
    url: `notas-fiscais-servicos`,
    data: notaFiscalServico,
    method: 'POST',
  });
};

export const atualizarNotaFiscalServicosAsync = (
  id: number,
  notaFiscalServico: INotaFiscalServicosCadastro,
): Promise<AxiosResponse<INotaFiscalServicosConsulta>> => {
  return api.request({
    url: `notas-fiscais-servicos/${id}`,
    data: notaFiscalServico,
    method: 'PATCH',
  });
};

export const removerNotasFiscaisServicosEmMassaAsync = (
  ids: number[],
): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `notas-fiscais-servicos`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerNotaFiscalServicosAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `notas-fiscais-servicos/${id}`,
    method: 'DELETE',
  });
