import * as FeatherIcon from 'react-feather';
import { IMenu } from '../../interfaces/menu';
import { PermissaoCodigo } from '../enums/permissao-codigo.enum';
import { ProgramaCodigo } from '../enums/programa-codigo.enum';
import { RoleCodigo } from '../enums/role-codigo.enum';

export const AdminRevendaMenus: IMenu = {
  roles: [RoleCodigo.RevendaAdmin],
  name: 'Admin Revenda',
  children: [
    {
      programaCod: ProgramaCodigo.Organizacoes,
      name: 'Organizações',
      path: '/organizacoes/listar',
      icon: FeatherIcon.Server,
      component: 'listarOrganizacoes',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/organizacoes/listar',
          component: 'listarOrganizacoes',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/organizacoes/criar',
          component: 'organizacoes',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/organizacoes/editar/:id',
          component: 'organizacoes',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/organizacoes/visualizar/:id',
          component: 'organizacoes',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.Empresas,
      name: 'Empresas',
      path: '/empresas/listar',
      icon: FeatherIcon.Briefcase,
      component: 'empresasLista',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/empresas/listar',
          component: 'empresasLista',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/empresas/criar',
          component: 'empresasCadastro',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/empresas/editar/:id',
          component: 'empresasCadastro',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/empresas/vizualizar/:id',
          component: 'empresasCadastro',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.Usuarios,
      name: 'Usuários',
      path: '/usuarios/listar',
      icon: FeatherIcon.Users,
      component: 'listarUsuarios',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/usuarios/listar',
          component: 'listarUsuarios',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/usuarios/criar',
          component: 'usuarios',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/usuarios/editar/:id',
          component: 'usuarios',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/usuarios/visualizar/:id',
          component: 'usuarios',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/usuarios/replicar/:id',
          component: 'usuarios',
        },
      ],
    },
  ],
};
