import React from 'react';
import { obterVariedadesClonesAsync } from '../../pages/variedades-clones/requests';
import SelecaoAsyncBase, { SelectOption } from './SelecaoAsyncBase';

interface IFilters {
  status?: number[];
  'variedade.id'?: number[];
  'unidadesProdutivasCulturas.id'?: number[];
}

interface SelecaoVariedadeCloneOption {
  value?: SelectOption;
  onChange?: (value: SelectOption) => void;
  multiple?: boolean;
  filters?: IFilters;
  autoSelect?: boolean;
  callback?: (value: { result: any[]; total: number }) => void;
}

const SelecaoVariedadeClone: React.FC<SelecaoVariedadeCloneOption> = ({ filters, callback, ...props }) => {
  const carregarOpcoes = async (text) => {
    let busca = text;
    let buscarPor = isNaN(text) ? 'nome' : 'codigo';

    const { data } = await obterVariedadesClonesAsync({
      filtro: {
        buscarPor,
        busca,
        status: [1],
        ...filters,
      },
      ordenacao: {
        campo: 'nome',
        ordem: 'ASC',
      },
    });

    callback && callback(data);

    return data.result.map(formatarParaSelectOption);
  };

  const formatarParaSelectOption = (oc) => ({
    label: oc.codigo + ' - ' + oc.nome,
    value: oc.id,
  });

  return (
    <SelecaoAsyncBase
      key={`selecao-variedade-clone-${JSON.stringify(filters)}`}
      nomeRecurso="Variedades Clones"
      carregarOpcoes={carregarOpcoes}
      {...props}
    />
  );
};

export default SelecaoVariedadeClone;
