import { AxiosResponse } from 'axios';
import api from '../../services/api';
import { IAlvoCadastro, IAlvoConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterAlvosAsync = (
  query
): Promise<AxiosResponse<{ result: IAlvoConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `alvos${queryStr}`,
    method: 'GET',
  });
};

export const obterAlvoAsync = (id: number): Promise<AxiosResponse<IAlvoConsulta>> => {
  return api.request({
    url: `alvos/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoAlvoAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `alvos/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarAlvoAsync = (
  Alvo: IAlvoCadastro
): Promise<AxiosResponse<IAlvoConsulta>> => {
  return api.request({
    url: `alvos`,
    data: Alvo,
    method: 'POST',
  });
};

export const atualizarAlvoAsync = (
  id: number,
  Alvo: IAlvoCadastro
): Promise<AxiosResponse<IAlvoConsulta>> => {
  return api.request({
    url: `alvos/${id}`,
    data: Alvo,
    method: 'PATCH',
  });
};

export const removerAlvosEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `alvos`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerAlvoAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `alvos/${id}`,
    method: 'DELETE',
  });
