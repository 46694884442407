import { AxiosResponse } from 'axios';

import api from '../../services/api';
import { IGrupoServicoCadastro, IGrupoServicoConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterGruposServicosAsync = (
  query
): Promise<AxiosResponse<{ result: IGrupoServicoConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `grupos-servicos${queryStr}`,
    method: 'GET',
  });
};

export const obterGrupoServicoAsync = (id: number): Promise<AxiosResponse<IGrupoServicoConsulta>> => {
  return api.request({
    url: `grupos-servicos/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoGrupoServicoAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `grupos-servicos/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarGrupoServicoAsync = (
  sistemaProducao: IGrupoServicoCadastro
): Promise<AxiosResponse<IGrupoServicoConsulta>> => {
  return api.request({
    url: `grupos-servicos`,
    data: sistemaProducao,
    method: 'POST',
  });
};

export const atualizarGrupoServicoAsync = (
  id: number,
  sistemaProducao: IGrupoServicoCadastro
): Promise<AxiosResponse<IGrupoServicoConsulta>> => {
  return api.request({
    url: `grupos-servicos/${id}`,
    data: sistemaProducao,
    method: 'PATCH',
  });
};

export const removerGrupoServicoEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `grupos-servicos`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerGrupoServicoAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `grupos-servicos/${id}`,
    method: 'DELETE',
  });
