import {
  FILTRAR_PLANO_CONTAS,
  GERAR_RELATORIO_PLANO_CONTAS,
  GERAR_RELATORIO_PLANO_CONTAS_ERRO,
  GERAR_RELATORIO_PLANO_CONTAS_SUCESSO,
  LISTAR_PLANO_CONTAS,
  LISTAR_PLANO_CONTAS_ERRO,
  LISTAR_PLANO_CONTAS_SUCESSO,
} from './constants';

export const obterTodosPlanoContas = () => ({
  type: LISTAR_PLANO_CONTAS,
});

export const obterPlanoContasSucesso = (planoContas) => ({
  type: LISTAR_PLANO_CONTAS_SUCESSO,
  payload: planoContas,
});

export const obterPlanoContasErro = (mensagem) => ({
  type: LISTAR_PLANO_CONTAS_ERRO,
  payload: mensagem,
});

export const filtrarPlanoContas = (filtros) => ({
  type: FILTRAR_PLANO_CONTAS,
  payload: filtros,
});

export const gerarRelatorioPlanoContas = (payload) => ({
  type: GERAR_RELATORIO_PLANO_CONTAS,
  payload: payload,
});

export const gerarRelatorioPlanoContasSucesso = (alert) => ({
  type: GERAR_RELATORIO_PLANO_CONTAS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioPlanoContasErro = (mensagem) => ({
  type: GERAR_RELATORIO_PLANO_CONTAS_ERRO,
  payload: mensagem,
});