import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { GoogleMap, Marker, StandaloneSearchBox, useLoadScript } from '@react-google-maps/api';

import { Container, InputSearchBox } from './styles';
import { Spinner } from 'reactstrap';
import { googleMapsScripts } from '../../constants/maps';

type MapaComMarcadorProps = {
  coordenadaPropriedade: google.maps.LatLngLiteral | null;
  podeCriarNovaMarcacao: boolean;
  alterarCoordenadaPropriedade: (coordenada: google.maps.LatLng) => void;
};

const estiloContainerMapa = {
  width: '100%',
  height: '100%',
};

const centroInicial = {
  lat: 0,
  lng: 0,
};

let searchBox: google.maps.places.SearchBox;

export const MapaComMarcador = memo(
  ({ coordenadaPropriedade, podeCriarNovaMarcacao, alterarCoordenadaPropriedade }: MapaComMarcadorProps) => {
    const [coordenadasMarcador, setCoordenadasMarcador] = useState<
      google.maps.LatLngLiteral | google.maps.LatLng | null
    >(null);
    const [centroMapa, setCentroMapa] = useState(centroInicial);
    const [zoomMapa, setZoomMapa] = useState(2);

    const { isLoaded: scriptGoogleMapsEstaCarregado } = useLoadScript(googleMapsScripts);

    const opcoesMapa = useMemo(() => {
      if (scriptGoogleMapsEstaCarregado) {
        return {
          mapTypeId: google.maps.MapTypeId.HYBRID,
          streetViewControl: false,
        };
      }

      return {};
    }, [scriptGoogleMapsEstaCarregado]);

    const lidarComCliqueNoMapa = useCallback(
      (event: google.maps.MapMouseEvent) => {
        if (event.latLng && podeCriarNovaMarcacao) {
          setCoordenadasMarcador(event.latLng);
          alterarCoordenadaPropriedade(event.latLng);
        }
      },
      [podeCriarNovaMarcacao, alterarCoordenadaPropriedade],
    );

    useEffect(() => {
      if (coordenadaPropriedade) {
        setCoordenadasMarcador(coordenadaPropriedade);
        setCentroMapa(coordenadaPropriedade);
        setZoomMapa(12);
      }
    }, [coordenadaPropriedade]);

    const onPlacesChanged = () => {
      const localizacao = searchBox?.getPlaces()?.[0]?.geometry?.location;
      const lat = Number(localizacao?.lat());
      const lng = Number(localizacao?.lng());

      setCentroMapa({
        lat: lat,
        lng: lng,
      });
      setZoomMapa(12);
    };

    return (
      <Container>
        {scriptGoogleMapsEstaCarregado ? (
          <GoogleMap
            mapContainerStyle={estiloContainerMapa}
            zoom={zoomMapa}
            center={centroMapa}
            onClick={lidarComCliqueNoMapa}
            options={opcoesMapa}
          >
            {podeCriarNovaMarcacao && (
              <StandaloneSearchBox
                onLoad={(ref) => {
                  searchBox = ref;
                }}
                onPlacesChanged={onPlacesChanged}
              >
                <InputSearchBox type="text" placeholder="Pesquise no mapa" />
              </StandaloneSearchBox>
            )}
            {coordenadasMarcador && <Marker position={coordenadasMarcador} />}
          </GoogleMap>
        ) : (
          <Spinner />
        )}
      </Container>
    );
  },
);
