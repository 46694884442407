// @flow
import { Cookies } from 'react-cookie';

/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
  const token = getToken();
  if (!token) {
    return false;
  }

  return true;

  // if (decoded.exp < currentTime) {
  //   return false;
  // } else {
  //   return true;
  // }
};

/**
 * Returns the logged in user
 */
const getToken = () => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return token ? (typeof token == 'string' ? token : null) : null;
};

const getLoggedInUser = () => {
  const cookies = new Cookies();
  const user = cookies.get('user');
  return user ? (typeof user == 'object' ? user : null) : null;
};

const setLoggedInUser = (user, token) => {
  const cookies = new Cookies();
  cookies.set('user', JSON.stringify(user), { path: '/' });
  token && cookies.set('token', token, { path: '/' });
  cookies.set('permissions', JSON.stringify([]), { path: '/' });
  localStorage.setItem('permissions', JSON.stringify([]));
};

const setToken = (token) => {
  const cookies = new Cookies();
  token && cookies.set('token', token, { path: '/' });
  cookies.set('permissions', JSON.stringify([]), { path: '/' });
  localStorage.setItem('permissions', JSON.stringify([]));
};

const setUserPermissions = (programas) => {
  localStorage.setItem('permissions', JSON.stringify(programas));
};

const getUserPermissions = () => {
  const permissions = JSON.parse(localStorage.getItem('permissions'));
  return permissions || [];
};

const setLogoutUser = () => {
  const cookies = new Cookies();
  cookies.remove('user', { path: '/' });
  cookies.remove('token', { path: '/' });
  cookies.remove('refresh', { path: '/' });
  cookies.remove('configs', { path: '/' });
  cookies.remove('permissions', { path: '/' });
  localStorage.removeItem('permissions');
};

export {
  isUserAuthenticated,
  getLoggedInUser,
  setLoggedInUser,
  setLogoutUser,
  setUserPermissions,
  getUserPermissions,
  getToken,
  setToken,
};
