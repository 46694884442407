import { AxiosResponse } from 'axios';
import api from '../../services/api';
import { ICFOPCadastro, ICFOPConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterCFOPsAsync = (query): Promise<AxiosResponse<{ result: ICFOPConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `cfop${queryStr}`,
    method: 'GET',
  });
};

export const obterCFOPAsync = (id: number): Promise<AxiosResponse<ICFOPConsulta>> => {
  return api.request({
    url: `cfop/${id}`,
    method: 'GET',
  });
};

export const criarCFOPAsync = (cfop: ICFOPCadastro): Promise<AxiosResponse<ICFOPConsulta>> => {
  return api.request({
    url: `cfop`,
    data: cfop,
    method: 'POST',
  });
};

export const atualizarCFOPAsync = (id: number, cfop: ICFOPCadastro): Promise<AxiosResponse<ICFOPConsulta>> => {
  return api.request({
    url: `cfop/${id}`,
    data: cfop,
    method: 'PATCH',
  });
};

export const removerCFOPsEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `cfop`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerCFOPAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `cfop/${id}`,
    method: 'DELETE',
  });
