import { FileText } from 'react-feather';
import { IMenu } from '../../interfaces/menu';
import { PermissaoCodigo } from '../enums/permissao-codigo.enum';
import { ProgramaCodigo } from '../enums/programa-codigo.enum';
import { RoleCodigo } from '../enums/role-codigo.enum';

export const CustosMenus: IMenu = {
  roles: [RoleCodigo.ClienteUser, RoleCodigo.ClienteAdmin],
  name: 'Custos',
  children: [
    {
      programaCod: ProgramaCodigo.AtualizadorCustos,
      name: 'Atualizador de Custos',
      path: '/atualizador-custos',
      component: 'atualizadorCustos',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/atualizador-custos',
          component: 'atualizadorCustos',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.LancamentosCustos,
      name: 'Lançamentos de Custos',
      path: '/lancamentos-custos/listar',
      component: 'listarLancamentosCustos',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/lancamentos-custos/listar',
          component: 'listarLancamentosCustos',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/lancamentos-custos/criar',
          component: 'lancamentosCustos',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/lancamentos-custos/editar/:id',
          component: 'lancamentosCustos',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/lancamentos-custos/visualizar/:id',
          component: 'lancamentosCustos',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.ManutencaoMaquinasVeiculos,
      name: 'Manutenções e Abastecimentos de Máquinas e Veículos',
      path: '/manutencao-maquinas-veiculos/listar',
      component: 'listarManutencaoMaquinasVeiculos',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/manutencao-maquinas-veiculos/listar',
          component: 'listarManutencaoMaquinasVeiculos',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/manutencao-maquinas-veiculos/criar',
          component: 'manutencaoMaquinasVeiculos',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/manutencao-maquinas-veiculos/editar/:id',
          component: 'manutencaoMaquinasVeiculos',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/manutencao-maquinas-veiculos/visualizar/:id',
          component: 'manutencaoMaquinasVeiculos',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.ValoresMaoObra,
      name: 'Valores de Mão de Obra',
      path: '/valores-mao-obra/listar',
      component: 'listarValoresMaoObra',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/valores-mao-obra/listar',
          component: 'listarValoresMaoObra',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/valores-mao-obra/criar',
          component: 'valoresMaoObra',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/valores-mao-obra/editar/:id',
          component: 'valoresMaoObra',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/valores-mao-obra/visualizar/:id',
          component: 'valoresMaoObra',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.RelatoriosCustos,
      name: 'Relatórios',
      path: '/custos/relatorios',
      icon: FileText,
      component: 'relatoriosCustos',
    },
  ],
};
