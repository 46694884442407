import { AxiosResponse } from 'axios';

import api from '../../services/api';
import { IRevendaCadastro, IRevendaConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterRevendasAsync = (
  query,
): Promise<AxiosResponse<{ result: IRevendaConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `revenda/todos${queryStr}`,
    method: 'GET',
  });
};

export const obterRevendaAsync = (
  id: number,
): Promise<AxiosResponse<IRevendaConsulta>> => {
  return api.request({
    url: `revenda/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoRevendaAsync = (): Promise<
  AxiosResponse<number>
> => {
  return api.request({
    url: `revenda/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarRevendaAsync = (
  revenda: IRevendaCadastro,
): Promise<AxiosResponse<IRevendaConsulta>> => {
  return api.request({
    url: `revenda`,
    data: revenda,
    method: 'POST',
  });
};

export const atualizarRevendaAsync = (
  id: number,
  revenda: IRevendaCadastro,
): Promise<AxiosResponse<IRevendaConsulta>> => {
  return api.request({
    url: `revenda/${id}`,
    data: revenda,
    method: 'PATCH',
  });
};

export const removerRevendaEmMassaAsync = (
  ids: number[],
): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `revenda`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerRevendaAsync = (
  id: number,
): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `revenda/${id}`,
    method: 'DELETE',
  });
