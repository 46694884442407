import { FileText } from 'react-feather';
import { IMenu } from '../../../interfaces/menu';
import { PermissaoCodigo } from '../../enums/permissao-codigo.enum';
import { ProgramaCodigo } from '../../enums/programa-codigo.enum';
import { RoleCodigo } from '../../enums/role-codigo.enum';

export const CadernoCampoMenus: IMenu = {
  roles: [RoleCodigo.ClienteUser, RoleCodigo.ClienteAdmin],
  name: 'Caderno de Campo',
  children: [
    {
      programaCod: ProgramaCodigo.LancamentosAtividades,
      name: 'Lançamentos de Atividades',
      path: '/lancamentos-atividades/listar',
      component: 'listarLancamentosAtividades',
      style: {
        fontWeight: 'bold',
      },
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/lancamentos-atividades/listar',
          component: 'listarLancamentosAtividades',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/lancamentos-atividades/criar',
          component: 'lancamentosAtividades',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/lancamentos-atividades/editar/:id',
          component: 'lancamentosAtividades',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/lancamentos-atividades/visualizar/:id',
          component: 'lancamentosAtividades',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/lancamentos-atividades/replicar/:id',
          component: 'lancamentosAtividades',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/lancamentos-atividades/executar-recomendacao/:recomendacaoId/status/:statusRecomendacao',
          component: 'lancamentosAtividades',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/lancamentos-atividades/etiqueta/:lancamentoAtividadeId/:colheitaId',
          component: 'etiquetaLancamentosAtividades',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/lancamentos-atividades/etiqueta/:lancamentoAtividadeId',
          component: 'etiquetaLancamentosAtividades',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/lancamentos-atividades/etiqueta-personalizada/:lancamentoAtividadeId',
          component: 'etiquetaPersonalizadaLancamentosAtividades',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.PrevisaoColheita,
      name: 'Previsões de Colheita',
      path: '/previsoes-colheita/listar',
      component: 'listarPrevisoesColheita',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/previsoes-colheita/listar',
          component: 'listarPrevisoesColheita',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/previsoes-colheita/criar',
          component: 'previsoesColheita',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/previsoes-colheita/editar/:id',
          component: 'previsoesColheita',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/previsoes-colheita/visualizar/:id',
          component: 'previsoesColheita',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.RecomendacoesTecnicas,
      name: 'Recomendações Técnicas',
      path: '/recomendacoes-tecnicas/listar',
      component: 'listarRecomendacoesTecnicas',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/recomendacoes-tecnicas/listar',
          component: 'listarRecomendacoesTecnicas',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/recomendacoes-tecnicas/criar',
          component: 'recomendacoesTecnicas',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/recomendacoes-tecnicas/editar/:id',
          component: 'recomendacoesTecnicas',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/recomendacoes-tecnicas/visualizar/:id',
          component: 'recomendacoesTecnicas',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/recomendacoes-tecnicas/replicar/:id',
          component: 'recomendacoesTecnicas',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.RegistrosClimaticos,
      name: 'Registros Climáticos',
      path: '/registros-climaticos/listar',
      component: 'listarRegistrosClimaticos',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/registros-climaticos/listar',
          component: 'listarRegistrosClimaticos',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/registros-climaticos/criar',
          component: 'registrosClimaticos',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/registros-climaticos/editar/:id',
          component: 'registrosClimaticos',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/registros-climaticos/visualizar/:id',
          component: 'registrosClimaticos',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/registros-climaticos/replicar/:id',
          component: 'registrosClimaticos',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.ValoresMaoObra,
      name: 'Valores de Mão de Obra',
      path: '/valores-mao-obra/listar',
      component: 'listarValoresMaoObra',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/valores-mao-obra/listar',
          component: 'listarValoresMaoObra',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/valores-mao-obra/criar',
          component: 'valoresMaoObra',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/valores-mao-obra/editar/:id',
          component: 'valoresMaoObra',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/valores-mao-obra/visualizar/:id',
          component: 'valoresMaoObra',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.VisitasTecnicas,
      name: 'Visitas Técnicas',
      path: '/visitas-tecnicas/listar',
      component: 'listarVisitasTecnicas',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/visitas-tecnicas/listar',
          component: 'listarVisitasTecnicas',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/visitas-tecnicas/criar',
          component: 'visitasTecnicas',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/visitas-tecnicas/editar/:id',
          component: 'visitasTecnicas',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/visitas-tecnicas/visualizar/:id',
          component: 'visitasTecnicas',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.CadernoCampo,
      name: 'Relatórios',
      icon: FileText,
      children: [
        {
          programaCod: ProgramaCodigo.CadernoCampo,
          name: 'Caderno de Campo',
          path: '/relatorios/caderno-campo',
          component: 'listarCadernoCampo',
        },
        {
          programaCod: ProgramaCodigo.RelatoriosMonitoramentoCarencia,
          name: 'Monitoramento de Carência',
          path: '/relatorios/monitoramento-carencia',
          component: 'relatoriosMonitoramentoCarencia',
        },
      ],
    },
  ],
};
