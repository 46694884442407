import { AxiosResponse } from 'axios';

import api from '../../services/api';
import { IMesaComandaCadastro, IMesaComandaConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterMesasComandasAsync = (
  query
): Promise<AxiosResponse<{ result: IMesaComandaConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `mesas-comandas${queryStr}`,
    method: 'GET',
  });
};

export const obterMesaComandaAsync = (id: number): Promise<AxiosResponse<IMesaComandaConsulta>> => {
  return api.request({
    url: `mesas-comandas/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoMesaComandaAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `mesas-comandas/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarMesaComandaAsync = (
  mesaComanda: IMesaComandaCadastro
): Promise<AxiosResponse<IMesaComandaConsulta>> => {
  return api.request({
    url: `mesas-comandas`,
    data: mesaComanda,
    method: 'POST',
  });
};

export const atualizarMesaComandaAsync = (
  id: number,
  mesaComanda: IMesaComandaCadastro
): Promise<AxiosResponse<IMesaComandaConsulta>> => {
  return api.request({
    url: `mesas-comandas/${id}`,
    data: mesaComanda,
    method: 'PATCH',
  });
};

export const removerMesasComandasEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `mesas-comandas`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerMesaComandaAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `mesas-comandas/${id}`,
    method: 'DELETE',
  });
