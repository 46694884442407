import { AxiosResponse } from 'axios';
import api from '../../services/api';
import { IMovimentacaoProdutoConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterMovimentacoesProdutosAsync = (
  query,
): Promise<AxiosResponse<{ result: IMovimentacaoProdutoConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `movimentacoes-produtos${queryStr}`,
    method: 'GET',
  });
};

export const obterMovimentacaoProdutoAsync = (id: number): Promise<AxiosResponse<IMovimentacaoProdutoConsulta>> => {
  return api.request({
    url: `movimentacoes-produtos/${id}`,
    method: 'GET',
  });
};
