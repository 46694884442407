import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { ProgramaCodigo } from '../../../constants/enums/programa-codigo.enum';
import { SET_LIMIT_PAGE, SET_TABLE_ORDER } from '../../../redux/table/constants';
import { FILTRAR_RELATORIOS, LISTAR_RELATORIOS } from './constants';
import { obterRelatoriosAsync } from '../requests';
import { obterRelatoriosErro, obterRelatoriosSucesso } from './actions';
import { montarFiltroParaApi } from '../../../utils/api-filter';

function* listarTodosRelatorios({ payload: { programCod } }) {
  try {
    const filtro = yield select((state) => state.Relatorios.filtros);
    const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.Relatorio]);
    const programCod = yield select((state) => state.Relatorios.programCod);
    

    let query = { filtro: montarFiltroParaApi(filtro) };

    if (preferencias) {
      query.paginacao = {
        pagina: filtro?.pagina || 1,
        limite: filtro?.limite || 50,
      };
    }

    query.ordenacao = {
      campo: 'id',
      ordem: 'DESC',
    };

    const { data } = yield call(obterRelatoriosAsync, query, programCod);
    yield put(obterRelatoriosSucesso(data));
  } catch (error) {
    yield put(obterRelatoriosErro(error.response?.data?.message || error.message));
  }
}

function* ordenarRelatorios({ payload }) {
  if (payload.programaId === ProgramaCodigo.Relatorio) {
    yield call(listarTodosRelatorios);
  }
}

function* mudarLimitePaginacao({ payload }) {
  if (payload.programaId === ProgramaCodigo.Relatorio) {
    yield call(listarTodosRelatorios);
  }
}

export function* watchListarRelatorios() {
  yield takeLatest(LISTAR_RELATORIOS, listarTodosRelatorios);
}

export function* watchFiltrarRelatorios() {
  yield takeLatest(FILTRAR_RELATORIOS, listarTodosRelatorios);
}

export function* watchOrdenarRelatorios() {
  yield takeLatest(SET_TABLE_ORDER, ordenarRelatorios);
}

export function* watchLimitePaginacao() {
  yield takeLatest(SET_LIMIT_PAGE, mudarLimitePaginacao);
}

function* relatorioSaga() {
  yield all([
    fork(watchListarRelatorios),
    fork(watchFiltrarRelatorios),
    fork(watchOrdenarRelatorios),
    fork(watchLimitePaginacao),
  ]);
}

export default relatorioSaga;
