import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { FILTRAR_PESSOAS, GERAR_RELATORIO_PESSOAS, LISTAR_PESSOAS } from './constants';

import { ProgramaCodigo } from '../../../constants/enums/programa-codigo.enum';
import { SET_LIMIT_PAGE, SET_TABLE_ORDER } from '../../../redux/table/constants';
import { DEFAULT_DOWNLOAD_SUCCESS_MESSAGE, gerarRelatorioRacing } from '../../../services/requests/relatorio';
import { montarFiltroParaApi } from '../../../utils/api-filter';
import { obterPessoasAsync } from '../requests';
import {
  gerarRelatorioTodasPessoasErro,
  gerarRelatorioTodasPessoasSucesso,
  obterTodasPessoasErro,
  obterTodasPessoasSucesso,
} from './actions';

function* listarTodasPessoas() {
  try {
    const filtro = yield select((state) => state.Pessoas.filtros);
    const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.Pessoas]);
    let query = { filtro: montarFiltroParaApi(filtro) };

    if (preferencias) {
      query.ordenacao = {
        campo: preferencias?.ordenarPor || 'id',
        ordem: preferencias?.ordem?.toUpperCase() || 'DESC',
      };
      query.paginacao = {
        pagina: preferencias?.page || 1,
        limite: preferencias?.limit || 50,
      };
    }

    const { data } = yield call(obterPessoasAsync, query);
    yield put(obterTodasPessoasSucesso(data));
  } catch (error) {
    console.error(error);
    yield put(obterTodasPessoasErro(error.response?.data?.message || error.message));
  }
}

function* relatorioPessoas() {
  const { filtros, relatorio } = yield select((state) => state.Pessoas);
  const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.Pessoas]);
  const { modelo, tipo, comFiltro, colunas } = relatorio;
  const columns = colunas?.map((coluna) => coluna.value);
  let query = {};

  if (comFiltro) {
    query = {
      filtro: {
        ...montarFiltroParaApi(filtros),
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
      ordenacao: {
        campo: preferencias?.ordenarPor || 'id',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      },
    };
  } else {
    query = {
      filtro: {
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
    };
  }

  try {
    const { data, headers } = yield call(gerarRelatorioRacing, ProgramaCodigo.Pessoas, query, tipo);

    const contentType = headers['content-type'];

    if (!contentType.includes('application/json')) {
      const url = URL.createObjectURL(
        new Blob([data], {
          type: headers['content-type'] || 'application/pdf',
        }),
      );

      if (url) {
        yield put(gerarRelatorioTodasPessoasSucesso(DEFAULT_DOWNLOAD_SUCCESS_MESSAGE));
        window.open(url, '_blank');
      }
    } else {
      yield put(
        gerarRelatorioTodasPessoasSucesso({
          titulo: 'Pedido de impressão salvo.',
          mensagem: 'Seu relatório está sendo gerado.',
        }),
      );
    }
  } catch (e) {
    yield put(
      gerarRelatorioTodasPessoasErro('Ocorreu um erro ao tentar gerar o relatório. Tente novamente mais tarde.'),
    );
  }
}

function* ordenarPessoas({ payload }) {
  if (payload.programaId === ProgramaCodigo.Pessoas) {
    yield call(listarTodasPessoas);
  }
}

function* mudarLimitePaginacao({ payload }) {
  if (payload.programaId === ProgramaCodigo.PerfilDeAcesso) {
    yield call(listarTodasPessoas);
  }
}

export function* watchListarPessoas() {
  yield takeLatest(LISTAR_PESSOAS, listarTodasPessoas);
}

export function* watchFiltrarPessoas() {
  yield takeLatest(FILTRAR_PESSOAS, listarTodasPessoas);
}

export function* watchRelatorioPessoas() {
  yield takeLatest(GERAR_RELATORIO_PESSOAS, relatorioPessoas);
}

export function* watchOrdenarPessoas() {
  yield takeLatest(SET_TABLE_ORDER, ordenarPessoas);
}

export function* watchLimitePaginacao() {
  yield takeLatest(SET_LIMIT_PAGE, mudarLimitePaginacao);
}

function* pessoasSaga() {
  yield all([
    fork(watchListarPessoas),
    fork(watchFiltrarPessoas),
    fork(watchRelatorioPessoas),
    fork(watchOrdenarPessoas),
    fork(watchLimitePaginacao),
  ]);
}

export default pessoasSaga;
