import { AxiosResponse } from 'axios';

import api from '../../services/api';
import { ICodigoBarraEANCadastro, ICodigoBarraEANConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterCodigosBarrasEANAsync = (
  query,
): Promise<AxiosResponse<{ result: ICodigoBarraEANConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `codigos-barras-ean${queryStr}`,
    method: 'GET',
  });
};

export const obterCodigoBarraEANAsync = (id: number): Promise<AxiosResponse<ICodigoBarraEANConsulta>> => {
  return api.request({
    url: `codigos-barras-ean/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoCodigoBarraEANAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `codigos-barras-ean/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarCodigoBarraEANAsync = (
  codigoBarraEAN: ICodigoBarraEANCadastro,
): Promise<AxiosResponse<ICodigoBarraEANConsulta>> => {
  return api.request({
    url: `codigos-barras-ean`,
    data: codigoBarraEAN,
    method: 'POST',
  });
};

export const atualizarCodigoBarraEANAsync = (
  id: number,
  codigoBarraEAN: ICodigoBarraEANCadastro,
): Promise<AxiosResponse<ICodigoBarraEANConsulta>> => {
  return api.request({
    url: `codigos-barras-ean/${id}`,
    data: codigoBarraEAN,
    method: 'PATCH',
  });
};

export const removerCodigosBarrasEANEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `codigos-barras-ean`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerCodigoBarraEANAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `codigos-barras-ean/${id}`,
    method: 'DELETE',
  });
