import api from '../../services/api';
import { AxiosResponse } from 'axios';
import { IArmadilhaCadastro, IArmadilhaConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterArmadilhasAsync = (
  query,
): Promise<
  AxiosResponse<{ result: IArmadilhaConsulta[]; total: number }>
> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `armadilhas${queryStr}`,
    method: 'GET',
  });
};

export const obterArmadilhaAsync = (
  id: number,
): Promise<AxiosResponse<IArmadilhaConsulta>> => {
  return api.request({
    url: `armadilhas/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoArmadilhaAsync = (): Promise<
  AxiosResponse<number>
> => {
  return api.request({
    url: `armadilhas/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarArmadilhasAsync = (
  armadilha: IArmadilhaCadastro,
): Promise<AxiosResponse<IArmadilhaConsulta>> => {
  return api.request({
    url: `armadilhas`,
    data: armadilha,
    method: 'POST',
  });
};

export const atualizarArmadilhasAsync = (
  id: number,
  armadilha: IArmadilhaCadastro,
): Promise<AxiosResponse<IArmadilhaConsulta>> => {
  return api.request({
    url: `armadilhas/${id}`,
    data: armadilha,
    method: 'PATCH',
  });
};

export const removerArmadilhasEmMassaAsync = (
  ids: number[],
): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `armadilhas`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerArmadilhasAsync = (
  id: number,
): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `armadilhas/${id}`,
    method: 'DELETE',
  });
