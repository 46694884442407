import React from 'react';
import { MedidaArea } from '../../constants/enums/medida-area.enum';
import { TipoCultura } from '../../constants/enums/tipo-cultura.enum';
import { VisualizacaoCulturaPrevisaoColheita } from '../../constants/enums/visualizacao-cultura-previsao-colheita.enum';
import { VisualizacaoCultura } from '../../constants/enums/visualizacao-cultura.enum';
import { ISelecao } from '../../interfaces/selecao';
import { ICulturaConsulta, ICulturaOrganizacaoConsulta } from '../../pages/culturas/interfaces';
import { obterCulturasAsync } from '../../pages/culturas/requests';
import SelecaoAsyncBase, { SelectOption } from './SelecaoAsyncBase';

interface IFilters {
  status?: number[];
  area?: MedidaArea;
  'propriedade.id'?: number[];
  'unidadeProdutiva.id'?: number[];
}

interface ISelecaoCulturaOption {
  value?: SelectOption;
  onChange?: (value: SelectOption) => void;
  multiple?: boolean;
  filters?: IFilters;
  autoSelect?: boolean;
  callback?: (value: { result: any[]; total: number }) => void;
  autoFocus?: boolean;
}

export interface ISelecaoCultura extends ISelecao {
  tipoCultura: TipoCultura;
  variedade: VisualizacaoCultura;
  variedadeClone: VisualizacaoCultura;
  portaEnxerto: VisualizacaoCultura;
  sistemaProducao: VisualizacaoCultura;
  sistemaIrrigacao: VisualizacaoCultura;
  cultivoProtegido: VisualizacaoCultura;
  sistemaConducao: VisualizacaoCultura;
  area: MedidaArea;
  numeroPlantas: VisualizacaoCultura;
  anoPlantio: VisualizacaoCultura;
  espacamentoEntreLinhas: VisualizacaoCultura;
  espacamentoEntrePlantas: VisualizacaoCultura;
  configuracaoPrevisaoColheita: { previsaoColheita: VisualizacaoCulturaPrevisaoColheita }[] | undefined;
  culturaOrganizacao?: ICulturaOrganizacaoConsulta[];
}

const SelecaoCultura: React.FC<ISelecaoCulturaOption> = ({ filters, callback, ...props }) => {
  const carregarOpcoes = async (filtro: string) => {
    let busca = filtro;
    let buscarPor = isNaN(parseInt(filtro)) ? 'nome' : 'codigo';

    const { data } = await obterCulturasAsync({
      filtro: {
        buscarPor,
        busca,
        status: [1],
        ...filters,
      },
      ordenacao: {
        campo: 'nome',
        ordem: 'ASC',
      },
    });

    callback && callback(data);

    return data.result.map(formatarParaSelectOption);
  };

  const formatarParaSelectOption = (cultura: ICulturaConsulta) => ({
    label: cultura.codigo + ' - ' + cultura.nome,
    value: cultura.id,

    tipoCultura: cultura.tipoCultura,
    variedade: cultura.variedade,
    variedadeClone: cultura.variedadeClone,
    portaEnxerto: cultura.portaEnxerto,
    sistemaProducao: cultura.sistemaProducao,
    sistemaIrrigacao: cultura.sistemaIrrigacao,
    cultivoProtegido: cultura.cultivoProtegido,
    sistemaConducao: cultura.sistemaConducao,
    area: cultura.area,
    numeroPlantas: cultura.numeroPlantas,
    anoPlantio: cultura.anoPlantio,
    espacamentoEntreLinhas: cultura.espacamentoEntreLinhas,
    espacamentoEntrePlantas: cultura.espacamentoEntrePlantas,
    configuracaoPrevisaoColheita: cultura.configuracaoPrevisaoColheita,
    culturaOrganizacao: cultura.culturaOrganizacao,
  });

  return (
    <SelecaoAsyncBase
      key={`selecao-cultura-${JSON.stringify(filters)}`}
      nomeRecurso="Culturas"
      carregarOpcoes={carregarOpcoes}
      {...props}
    />
  );
};

export default SelecaoCultura;
