import { 
  FILTRAR_CIDADES, 
  GERAR_RELATORIO_CIDADES,
  GERAR_RELATORIO_CIDADES_ERRO,
  GERAR_RELATORIO_CIDADES_SUCESSO,
  LISTAR_CIDADES, 
  LISTAR_CIDADES_ERRO, 
  LISTAR_CIDADES_SUCESSO } from './constants';

export const obterTodosCidades = () => ({
  type: LISTAR_CIDADES,
});

export const obterCidadesSucesso = (cidades) => ({
  type: LISTAR_CIDADES_SUCESSO,
  payload: cidades,
});

export const obterCidadesErro = (mensagem) => ({
  type: LISTAR_CIDADES_ERRO,
  payload: mensagem,
});

export const filtrarCidades = (filtros) => ({
  type: FILTRAR_CIDADES,
  payload: filtros,
});

export const gerarRelatorioCidades = (payload) => ({
  type: GERAR_RELATORIO_CIDADES,
  payload: payload,
});

export const gerarRelatorioCidadesSucesso = (alert) => ({
  type: GERAR_RELATORIO_CIDADES_SUCESSO,
  payload: alert,
});

export const gerarRelatorioCidadesErro = (mensagem) => ({
  type: GERAR_RELATORIO_CIDADES_ERRO,
  payload: mensagem,
});
