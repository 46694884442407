import {
  FILTRAR_COTACOES,
  GERAR_RELATORIO_COTACOES,
  GERAR_RELATORIO_COTACOES_ERRO,
  GERAR_RELATORIO_COTACOES_SUCESSO,
  LISTAR_COTACOES,
  LISTAR_COTACOES_ERRO,
  LISTAR_COTACOES_SUCESSO,
} from './constants';

export const obterTodosCotacoes = () => ({
  type: LISTAR_COTACOES,
});

export const obterCotacoesSucesso = (Cotacoes) => ({
  type: LISTAR_COTACOES_SUCESSO,
  payload: Cotacoes,
});

export const obterCotacoesErro = (mensagem) => ({
  type: LISTAR_COTACOES_ERRO,
  payload: mensagem,
});

export const filtrarCotacoes = (filtros) => ({
  type: FILTRAR_COTACOES,
  payload: filtros,
});

export const gerarRelatorioCotacoes = (payload) => ({
  type: GERAR_RELATORIO_COTACOES,
  payload: payload,
});

export const gerarRelatorioCotacoesSucesso = (alert) => ({
  type: GERAR_RELATORIO_COTACOES_SUCESSO,
  payload: alert,
});

export const gerarRelatorioCotacoesErro = (mensagem) => ({
  type: GERAR_RELATORIO_COTACOES_ERRO,
  payload: mensagem,
});
