import * as FeatherIcon from 'react-feather';
import { ISubMenu } from '../../../../interfaces/menu';
import { PermissaoCodigo } from '../../../enums/permissao-codigo.enum';
import { ProgramaCodigo } from '../../../enums/programa-codigo.enum';

export const CadastrosAgroFitossanidade: ISubMenu = {
  name: 'Agro Fitossanidade',
  icon: FeatherIcon.CloudRain,
  children: [
    {
      programaCod: ProgramaCodigo.Alvos,
      name: 'Alvos',
      path: '/alvos/listar',
      component: 'listarAlvos',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/alvos/listar',
          component: 'listarAlvos',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/alvos/criar',
          component: 'alvos',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/alvos/editar/:id',
          component: 'alvos',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/alvos/visualizar/:id',
          component: 'alvos',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.Armadilhas,
      name: 'Armadilhas',
      path: '/armadilhas/listar',
      component: 'listarArmadilhas',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/armadilhas/listar',
          component: 'listarArmadilhas',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/armadilhas/criar',
          component: 'armadilhas',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/armadilhas/editar/:id',
          component: 'armadilhas',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/armadilhas/visualizar/:id',
          component: 'armadilhas',
        },
      ],
    },
  ],
};
