import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FiCamera, FiCameraOff } from 'react-icons/fi';

import { Camera } from '../Camera';
import ImageUpload from '../ImagemUpload';
import * as S from './styles';

type UploadImagemComSelfieProps = {
  isReadOnly: boolean;
  savedUserImage?: string;
  setUserImageFile: Dispatch<SetStateAction<File | undefined | null>>;
};

export const UploadImagemComSelfie = ({ isReadOnly, savedUserImage, setUserImageFile }: UploadImagemComSelfieProps) => {
  const [hasActiveCamera, setHasActiveCamera] = useState(false);
  const [imageURL, setImageURL] = useState(savedUserImage);
  const [hasUserMedia, setHasUserMedia] = useState(false);

  useEffect(() => {
    const getUserMedia = async () => {
      const userMedia = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: false,
      });
      setHasUserMedia(userMedia.active);
    };

    if (!isReadOnly) {
      getUserMedia();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <S.Container>
      {hasActiveCamera ? (
        <Camera setImageURL={setImageURL} setHasActiveCamera={setHasActiveCamera} setUserImageFile={setUserImageFile} />
      ) : (
        <ImageUpload tamanho={[380,380]} apenasLeitura={isReadOnly} imagemInicial={imageURL} changeFile={setUserImageFile} />
      )}

      {!hasActiveCamera && !isReadOnly && (
        <S.Button isActive={hasUserMedia} disabled={!hasUserMedia} onClick={() => setHasActiveCamera(true)}>
          {hasUserMedia ? (
            <>
              <FiCamera size={24} color="#fff" />
              Acessar a câmera
            </>
          ) : (
            <>
              <FiCameraOff size={24} color="#fff" />
              Nenhuma webcam foi encontrada
            </>
          )}
        </S.Button>
      )}
      {!hasUserMedia && !isReadOnly && (
        <S.HelpMessage>
          *Verifique se você possui uma webcam conectada no dispositivo atual ou então se você autorizou o uso da mesma
          nesta plataforma.
        </S.HelpMessage>
      )}
    </S.Container>
  );
};
