import { AxiosResponse } from 'axios';

import api from '../../services/api';
import { IDadosDestinatario, INotaFiscalCadastro, INotaFiscalConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterNotasFiscaisAsync = (
  query,
): Promise<AxiosResponse<{ result: INotaFiscalConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `notas-fiscais${queryStr}`,
    method: 'GET',
  });
};

export const obterNotaFiscalAsync = (id: number): Promise<AxiosResponse<any>> => {
  return api.request({
    url: `notas-fiscais/${id}`,
    method: 'GET',
  });
};

export const buscarDadosDestinatarioAsync = (pessoaId: number) => {
  return api.request<IDadosDestinatario>({
    url: `/notas-fiscais/buscar-dados-destinatario/${pessoaId}`,
    method: 'GET',
  });
};

export const criarNotaFiscalAsync = (notaFiscal: INotaFiscalCadastro): Promise<AxiosResponse<INotaFiscalConsulta>> => {
  return api.request({
    url: `notas-fiscais`,
    data: notaFiscal,
    method: 'POST',
  });
};

export const emitirNotaFiscalAsync = (id: number): Promise<AxiosResponse<void>> => {
  return api.request({
    url: `notas-fiscais/emitir/${id}`,
    method: 'POST',
  });
};

export const atualizarNotaFiscalAsync = (
  id: number,
  notaFiscal: INotaFiscalCadastro,
): Promise<AxiosResponse<INotaFiscalConsulta>> => {
  return api.request({
    url: `notas-fiscais/${id}`,
    data: notaFiscal,
    method: 'PATCH',
  });
};

export const removerNotasFiscaisEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `notas-fiscais`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerNotaFiscalAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `notas-fiscais/${id}`,
    method: 'DELETE',
  });
