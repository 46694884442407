import { 
  FILTRAR_CEST, 
  GERAR_RELATORIO_CEST,
  GERAR_RELATORIO_CEST_ERRO,
  GERAR_RELATORIO_CEST_SUCESSO,
  LISTAR_CEST, 
  LISTAR_CEST_ERRO, 
  LISTAR_CEST_SUCESSO } from './constants';

export const obterTodosCEST = () => ({
  type: LISTAR_CEST,
});

export const obterCESTSucesso = (cest) => ({
  type: LISTAR_CEST_SUCESSO,
  payload: cest,
});

export const obterCESTErro = (mensagem) => ({
  type: LISTAR_CEST_ERRO,
  payload: mensagem,
});

export const filtrarCEST = (filtros) => ({
  type: FILTRAR_CEST,
  payload: filtros,
});

export const gerarRelatorioCEST = (payload) => ({
  type: GERAR_RELATORIO_CEST,
  payload: payload,
});

export const gerarRelatorioCESTSucesso = (alert) => ({
  type: GERAR_RELATORIO_CEST_SUCESSO,
  payload: alert,
});

export const gerarRelatorioCESTErro = (mensagem) => ({
  type: GERAR_RELATORIO_CEST_ERRO,
  payload: mensagem,
});