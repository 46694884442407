import api from '../../services/api';
import { montarQueryString } from '../../utils';
import { IVendasColheitasCadastro } from './interfaces';

export const obterVendasColheitasAsync = (query) => {
  const queryStr = montarQueryString(query);
  
  return api.request({
    url: `vendas-colheitas${queryStr}`,
    method: 'GET',
  });
};

export const obterVendaColheitaAsync = (id: number) => {
  return api.request({
    url: `vendas-colheitas/${id}`,
    method: 'GET',
  });
};

export const criarVendaColheitaAsync = (vendaColheita: IVendasColheitasCadastro) => {
  return api.request({
    url: `vendas-colheitas`,
    data: vendaColheita,
    method: 'POST',
  });
};

export const atualizarVendaColheitaAsync = (id: number, vendaColheita: IVendasColheitasCadastro) => {
  return api.request({
    url: `vendas-colheitas/${id}`,
    data: vendaColheita,
    method: 'PATCH',
  });
};

export const removerVendasColheitasEmMassaAsync = (ids: number[]) => {
  return api.request({
    url: `vendas-colheitas`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerVendaColheitaAsync = (id: number) =>
  api.request({
    url: `vendas-colheitas/${id}`,
    method: 'DELETE',
  });
