import {
  FILTRAR_CNAES,
  GERAR_RELATORIO_CNAES,
  GERAR_RELATORIO_CNAES_ERRO,
  GERAR_RELATORIO_CNAES_SUCESSO,
  LISTAR_CNAES,
  LISTAR_CNAES_ERRO,
  LISTAR_CNAES_SUCESSO,
} from './constants';

export const obterTodosCNAEs = () => ({
  type: LISTAR_CNAES,
});

export const obterCNAEsSucesso = (cnaes) => ({
  type: LISTAR_CNAES_SUCESSO,
  payload: cnaes,
});

export const obterCNAEsErro = (mensagem) => ({
  type: LISTAR_CNAES_ERRO,
  payload: mensagem,
});

export const filtrarCNAEs = (filtros) => ({
  type: FILTRAR_CNAES,
  payload: filtros,
});

export const gerarRelatorioCNAEs = (payload) => ({
  type: GERAR_RELATORIO_CNAES,
  payload: payload,
});

export const gerarRelatorioCNAEsSucesso = (alert) => ({
  type: GERAR_RELATORIO_CNAES_SUCESSO,
  payload: alert,
});

export const gerarRelatorioCNAEsErro = (mensagem) => ({
  type: GERAR_RELATORIO_CNAES_ERRO,
  payload: mensagem,
});