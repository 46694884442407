import {
  FILTRAR_CORES_PRODUTOS,
  GERAR_RELATORIO_CORES_PRODUTOS,
  GERAR_RELATORIO_CORES_PRODUTOS_ERRO,
  GERAR_RELATORIO_CORES_PRODUTOS_SUCESSO,
  LISTAR_CORES_PRODUTOS,
  LISTAR_CORES_PRODUTOS_ERRO,
  LISTAR_CORES_PRODUTOS_SUCESSO,
} from './constants';

export const obterTodosCoresProdutos = () => ({
  type: LISTAR_CORES_PRODUTOS,
});

export const obterCoresProdutosSucesso = (corProduto) => ({
  type: LISTAR_CORES_PRODUTOS_SUCESSO,
  payload: corProduto,
});

export const obterCoresProdutosErro = (mensagem) => ({
  type: LISTAR_CORES_PRODUTOS_ERRO,
  payload: mensagem,
});

export const filtrarCoresProdutos = (filtros) => ({
  type: FILTRAR_CORES_PRODUTOS,
  payload: filtros,
});

export const gerarRelatorioCoresProdutos = (payload) => ({
  type: GERAR_RELATORIO_CORES_PRODUTOS,
  payload: payload,
});

export const gerarRelatorioCoresProdutosSucesso = (alert) => ({
  type: GERAR_RELATORIO_CORES_PRODUTOS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioCoresProdutosErro = (mensagem) => ({
  type: GERAR_RELATORIO_CORES_PRODUTOS_ERRO,
  payload: mensagem,
});
