import { AxiosResponse } from 'axios';

import api from '../../services/api';
import { ICorProdutoCadastro, ICorProdutoConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterCoresProdutosAsync = (
  query,
): Promise<AxiosResponse<{ result: ICorProdutoConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `cores-produtos${queryStr}`,
    method: 'GET',
  });
};

export const obterCorProdutoAsync = (id: number): Promise<AxiosResponse<ICorProdutoConsulta>> => {
  return api.request({
    url: `cores-produtos/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoCorProdutoAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `cores-produtos/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarCorProdutoAsync = (corProduto: ICorProdutoCadastro): Promise<AxiosResponse<ICorProdutoConsulta>> => {
  return api.request({
    url: `cores-produtos`,
    data: corProduto,
    method: 'POST',
  });
};

export const atualizarCorProdutoAsync = (
  id: number,
  corProduto: ICorProdutoCadastro,
): Promise<AxiosResponse<ICorProdutoConsulta>> => {
  return api.request({
    url: `cores-produtos/${id}`,
    data: corProduto,
    method: 'PATCH',
  });
};

export const removerCoresProdutosEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `cores-produtos`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerCorProdutoAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `cores-produtos/${id}`,
    method: 'DELETE',
  });
