import {
  FILTRAR_CONFIGURACOES,
  GERAR_RELATORIO_CONFIGURACOES,
  GERAR_RELATORIO_CONFIGURACOES_ERRO,
  GERAR_RELATORIO_CONFIGURACOES_SUCESSO,
  LISTAR_CONFIGURACOES,
  LISTAR_CONFIGURACOES_ERRO,
  LISTAR_CONFIGURACOES_SUCESSO,
} from './constants';

export const obterTodosConfiguracoes = () => ({
  type: LISTAR_CONFIGURACOES,
});

export const obterConfiguracoesSucesso = (configuracoes) => ({
  type: LISTAR_CONFIGURACOES_SUCESSO,
  payload: configuracoes,
});

export const obterConfiguracoesErro = (mensagem) => ({
  type: LISTAR_CONFIGURACOES_ERRO,
  payload: mensagem,
});

export const filtrarConfiguracoes = (filtros) => ({
  type: FILTRAR_CONFIGURACOES,
  payload: filtros,
});

export const gerarRelatorioConfiguracoes = (payload) => ({
  type: GERAR_RELATORIO_CONFIGURACOES,
  payload: payload,
});

export const gerarRelatorioConfiguracoesSucesso = (alert) => ({
  type: GERAR_RELATORIO_CONFIGURACOES_SUCESSO,
  payload: alert,
});

export const gerarRelatorioConfiguracoesErro = (mensagem) => ({
  type: GERAR_RELATORIO_CONFIGURACOES_ERRO,
  payload: mensagem,
});
