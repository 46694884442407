import api from '../../services/api';
import { montarQueryString } from '../../utils';
import { IIntegradoresCadastro } from './interfaces';

export const obterIntegradoresAsync = (query) => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `integradores${queryStr}`,
    method: 'GET',
  });
};

export const obterIntegradorAsync = (id: number) => {
  return api.request({
    url: `integradores/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoIntegradorAsync = () => {
  return api.request({
    url: `integradores/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarIntegradorAsync = (Integrador: IIntegradoresCadastro) => {
  return api.request({
    url: `integradores`,
    data: Integrador,
    method: 'POST',
  });
};

export const atualizarIntegradorAsync = (id: number, Integrador: IIntegradoresCadastro) => {
  return api.request({
    url: `integradores/${id}`,
    data: Integrador,
    method: 'PATCH',
  });
};

export const removerIntegradoresEmMassaAsync = (ids: number[]) => {
  return api.request({
    url: `integradores`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerIntegradorAsync = (id: number) =>
  api.request({
    url: `integradores/${id}`,
    method: 'DELETE',
  });
