import { AxiosResponse } from 'axios';
import api from '../../services/api';
import { ITributacaoServicoCadastro, ITributacaoServicoConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterTributacoesServicosAsync = (
  query,
): Promise<AxiosResponse<{ result: ITributacaoServicoConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `tributacoes-servicos${queryStr}`,
    method: 'GET',
  });
};

export const obterTributacaoServicoAsync = (id: number): Promise<AxiosResponse<ITributacaoServicoConsulta>> => {
  return api.request({
    url: `tributacoes-servicos/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoTributacaoServicoAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `tributacoes-servicos/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarTributacaoServicoAsync = (
  tributacaoServico: ITributacaoServicoCadastro,
): Promise<AxiosResponse<ITributacaoServicoConsulta>> => {
  return api.request({
    url: `tributacoes-servicos`,
    data: tributacaoServico,
    method: 'POST',
  });
};

export const atualizarTributacaoServicoAsync = (
  id: number,
  tributacaoServico: ITributacaoServicoCadastro,
): Promise<AxiosResponse<ITributacaoServicoConsulta>> => {
  return api.request({
    url: `tributacoes-servicos/${id}`,
    data: tributacaoServico,
    method: 'PATCH',
  });
};

export const removerTributacoesServicosEmMassaAsync = (
  ids: number[],
): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `tributacoes-servicos`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerTributacaoServicoAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `tributacoes-servicos/${id}`,
    method: 'DELETE',
  });
