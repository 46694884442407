import { AxiosResponse } from 'axios';

import api from '../../services/api';
import { IContaPagarCadastro, IContaPagarConsulta } from './interfaces';
import { montarQueryString } from '../../utils';
import { IContaPagarPagamentoCadastro } from './cadastro/pagamento/interfaces';
import { IBaixaPorValorTitulo } from '../../components/Financeiro/BaixaPorValor/interfaces';
import { IBaixaMultiplaCadastro } from '../../components/Financeiro/BaixaMultipla/interfaces';
import { IReplicacaoTituloParcela, IReplicacaoTituloQuery } from '../../components/Financeiro/Replicar/interfaces';
import { IContaPagarOcorrenciaCadastro } from './cadastro/ocorrencia-financeira/interfaces';
import { IBaixaRapidaDto } from '../../components/Financeiro/BaixaRapida/interfaces';
import { ISubgruposBaixaConsulta } from '../../components/Financeiro/SubgruposBaixa/interfaces';

export const obterContasPagarAsync = (
  query,
): Promise<AxiosResponse<{ result: IContaPagarConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);
  return api.request({
    url: `contas-pagar${queryStr}`,
    method: 'GET',
  });
};

export const obterContaPagarAsync = (id: number): Promise<AxiosResponse<IContaPagarConsulta>> => {
  return api.request({
    url: `contas-pagar/${id}`,
    method: 'GET',
  });
};

export const criarContaPagarAsync = (contaPagar: IContaPagarCadastro): Promise<AxiosResponse<IContaPagarConsulta>> => {
  return api.request({
    url: `contas-pagar`,
    data: contaPagar,
    method: 'POST',
  });
};

export const atualizarContaPagarAsync = (
  id: number,
  contaPagar: IContaPagarCadastro,
): Promise<AxiosResponse<IContaPagarConsulta>> => {
  return api.request({
    url: `contas-pagar/${id}`,
    data: contaPagar,
    method: 'PATCH',
  });
};

export const removerContasPagarEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `contas-pagar`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerContaPagarAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `contas-pagar/${id}`,
    method: 'DELETE',
  });

export const contaPagarPagamentosAsync = (
  id: number,
  pagamento: IContaPagarPagamentoCadastro[],
): Promise<AxiosResponse<void>> => {
  return api.request({
    url: `contas-pagar/pagamento/${id}`,
    data: pagamento,
    method: 'PATCH',
  });
};

export const obterPagamentosAsync = (id: number): Promise<AxiosResponse<IContaPagarConsulta>> => {
  return api.request({
    url: `contas-pagar/obter-pagamentos/${id}`,
    method: 'GET',
  });
};

export const obterSubgruposBaixaContasPagarAsync = (): Promise<AxiosResponse<ISubgruposBaixaConsulta>> => {
  return api.request({
    url: `contas-pagar/obter-subgrupos-baixa`,
    method: 'GET',
  });
};

export const contaPagarOcorrenciasAsync = (
  id: number,
  ocorrencia: IContaPagarOcorrenciaCadastro[],
): Promise<AxiosResponse<void>> => {
  return api.request({
    url: `contas-pagar/ocorrencia/${id}`,
    data: ocorrencia,
    method: 'PATCH',
  });
};

export const baixaMultiplaContasPagar = (pagamentos: IBaixaMultiplaCadastro[]): Promise<AxiosResponse<void>> => {
  return api.request({
    url: `contas-pagar/baixa-multipla`,
    data: pagamentos,
    method: 'PATCH',
  });
};

export const baixaRapidaContasPagar = (dto: IBaixaRapidaDto): Promise<AxiosResponse<void>> => {
  return api.request({
    url: `contas-pagar/baixa-rapida`,
    data: dto,
    method: 'PATCH',
  });
};

export const baixaPorValorContasPagar = (dto: IBaixaPorValorTitulo): Promise<AxiosResponse<void>> => {
  return api.request({
    url: `contas-pagar/baixa-por-valor`,
    data: dto,
    method: 'PATCH',
  });
};

export const montarReplicacaoContasPagar = (
  id: number,
  query: IReplicacaoTituloQuery,
): Promise<AxiosResponse<IReplicacaoTituloParcela[]>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `contas-pagar/montar-replicacao/${id}${queryStr}`,
    method: 'GET',
  });
};

export const replicarContaPagarAsync = (id: string, dto: IReplicacaoTituloParcela[]): Promise<AxiosResponse<void>> => {
  return api.request({
    url: `contas-pagar/replicar/${id}`,
    data: dto,
    method: 'POST',
  });
};
