import {
    FILTRAR_VASILHAMES,
    LISTAR_VASILHAMES,
    LISTAR_VASILHAMES_ERRO,
    LISTAR_VASILHAMES_SUCESSO,
    GERAR_RELATORIO_VASILHAMES,
    GERAR_RELATORIO_VASILHAMES_SUCESSO,
    GERAR_RELATORIO_VASILHAMES_ERRO,
  } from './constants';
  
  export const obterTodosVasilhames = () => ({
    type: LISTAR_VASILHAMES,
  });
  
  export const obterVasilhamesSucesso = (vasilhames) => ({
    type: LISTAR_VASILHAMES_SUCESSO,
    payload: vasilhames,
  });
  
  export const obterVasilhamesErro = (mensagem) => ({
    type: LISTAR_VASILHAMES_ERRO,
    payload: mensagem,
  });
  
  export const filtrarVasilhames = (filtros) => ({
    type: FILTRAR_VASILHAMES,
    payload: filtros,
  });
  
  export const gerarRelatorioVasilhames = (payload) => ({
    type: GERAR_RELATORIO_VASILHAMES,
    payload: payload,
  });
  
  export const gerarRelatorioVasilhamesSucesso = (alert) => ({
    type: GERAR_RELATORIO_VASILHAMES_SUCESSO,
    payload: alert,
  });
  
  export const gerarRelatorioVasilhamesErro = (mensagem) => ({
    type: GERAR_RELATORIO_VASILHAMES_ERRO,
    payload: mensagem,
  });
  