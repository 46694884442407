import { AxiosResponse } from 'axios';

import api from '../../services/api';
import { IDadosPessoa, INotaEntradaCadastro, INotaEntradaConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterNotasEntradasAsync = (
  query,
): Promise<AxiosResponse<{ result: INotaEntradaConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `notas-entradas${queryStr}`,
    method: 'GET',
  });
};

export const obterNotaEntradaAsync = (id: number): Promise<AxiosResponse<any>> => {
  return api.request({
    url: `notas-entradas/${id}`,
    method: 'GET',
  });
};

export const buscarDadosPessoaAsync = (pessoaId: number) => {
  return api.request<IDadosPessoa>({
    url: `/notas-entradas/buscar-dados-pessoa/${pessoaId}`,
    method: 'GET',
  });
};

export const criarNotaEntradaAsync = (notaEntrada: INotaEntradaCadastro): Promise<AxiosResponse<INotaEntradaConsulta>> => {
  return api.request({
    url: `notas-entradas`,
    data: notaEntrada,
    method: 'POST',
  });
};

export const atualizarNotaEntradaAsync = (
  id: number,
  notaEntrada: INotaEntradaCadastro,
): Promise<AxiosResponse<INotaEntradaConsulta>> => {
  return api.request({
    url: `notas-entradas/${id}`,
    data: notaEntrada,
    method: 'PATCH',
  });
};

export const removerNotasEntradasEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `notas-entradas`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerNotaEntradaAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `notas-entradas/${id}`,
    method: 'DELETE',
  });
