import * as FeatherIcon from 'react-feather';
import { IMenu } from '../../interfaces/menu';
import { PermissaoCodigo } from '../enums/permissao-codigo.enum';
import { ProgramaCodigo } from '../enums/programa-codigo.enum';
import { RoleCodigo } from '../enums/role-codigo.enum';

export const TecnicoDomperMenus: IMenu = {
  roles: [RoleCodigo.DomperTec],
  name: 'Técnico Domper',
  children: [
    {
      programaCod: ProgramaCodigo.Modulos,
      name: 'Módulos',
      path: '/modulos/listar',
      icon: FeatherIcon.Package,
      component: 'listarModulos',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/modulos/listar',
          component: 'listarModulos',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/modulos/criar',
          component: 'modulos',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/modulos/editar/:id',
          component: 'modulos',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/modulos/visualizar/:id',
          component: 'modulos',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/modulos/replicar/:id',
          component: 'modulos',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.Programas,
      name: 'Programas',
      path: '/programas/listar',
      icon: FeatherIcon.Box,
      component: 'programasListar',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/programas/listar',
          component: 'programasListar',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/programas/visualizar/:id',
          component: 'programas',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.Permissoes,
      name: 'Permissões',
      path: '/permissoes/listar',
      icon: FeatherIcon.Slash,
      component: 'permissoesLista',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/permissoes/listar',
          component: 'permissoesLista',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/permissoes/visualizar/:id',
          component: 'permissoes',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.Parametros,
      name: 'Parâmetros',
      path: '/parametros/listar',
      icon: FeatherIcon.Sliders,
      component: 'parametrosListar',
      children: [
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/parametros/editar-valor/:id',
          component: 'parametrosCadastro',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/parametros/listar',
          component: 'parametrosListar',
        },
      ],
    },
  ],
};
