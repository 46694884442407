import {
    FILTRAR_PORTA_ENXERTOS,
    GERAR_RELATORIO_PORTA_ENXERTOS,
    GERAR_RELATORIO_PORTA_ENXERTOS_ERRO,
    GERAR_RELATORIO_PORTA_ENXERTOS_SUCESSO,
    LISTAR_PORTA_ENXERTOS,
    LISTAR_PORTA_ENXERTOS_ERRO,
    LISTAR_PORTA_ENXERTOS_SUCESSO,
  } from './constants';
  
  export const obterTodosPortaEnxertos = () => ({
    type: LISTAR_PORTA_ENXERTOS,
  });
  
  export const obterPortaEnxertosSucesso = (portaEnxertos) => ({
    type: LISTAR_PORTA_ENXERTOS_SUCESSO,
    payload: portaEnxertos,
  });
  
  export const obterPortaEnxertosErro = (mensagem) => ({
    type: LISTAR_PORTA_ENXERTOS_ERRO,
    payload: mensagem,
  });
  
  export const filtrarPortaEnxertos = (filtros) => ({
    type: FILTRAR_PORTA_ENXERTOS,
    payload: filtros,
  });
  
  export const gerarRelatorioPortaEnxertos = (payload) => ({
    type: GERAR_RELATORIO_PORTA_ENXERTOS,
    payload: payload,
  });
  
  export const gerarRelatorioPortaEnxertosSucesso = (alert) => ({
    type: GERAR_RELATORIO_PORTA_ENXERTOS_SUCESSO,
    payload: alert,
  });
  
  export const gerarRelatorioPortaEnxertosErro = (mensagem) => ({
    type: GERAR_RELATORIO_PORTA_ENXERTOS_ERRO,
    payload: mensagem,
  });