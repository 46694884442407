import {
  FILTRAR_CODIGOS_BARRAS_EAN,
  GERAR_RELATORIO_CODIGOS_BARRAS_EAN,
  GERAR_RELATORIO_CODIGOS_BARRAS_EAN_ERRO,
  GERAR_RELATORIO_CODIGOS_BARRAS_EAN_SUCESSO,
  LISTAR_CODIGOS_BARRAS_EAN,
  LISTAR_CODIGOS_BARRAS_EAN_ERRO,
  LISTAR_CODIGOS_BARRAS_EAN_SUCESSO,
} from './constants';

export const obterTodosCodigosBarrasEAN = () => ({
  type: LISTAR_CODIGOS_BARRAS_EAN,
});

export const obterCodigosBarrasEANSucesso = (codigosBarrasEAN) => ({
  type: LISTAR_CODIGOS_BARRAS_EAN_SUCESSO,
  payload: codigosBarrasEAN,
});

export const obterCodigosBarrasEANErro = (mensagem) => ({
  type: LISTAR_CODIGOS_BARRAS_EAN_ERRO,
  payload: mensagem,
});

export const filtrarCodigosBarrasEAN = (filtros) => ({
  type: FILTRAR_CODIGOS_BARRAS_EAN,
  payload: filtros,
});

export const gerarRelatorioCodigosBarrasEAN = (payload) => ({
  type: GERAR_RELATORIO_CODIGOS_BARRAS_EAN,
  payload: payload,
});

export const gerarRelatorioCodigosBarrasEANSucesso = (alert) => ({
  type: GERAR_RELATORIO_CODIGOS_BARRAS_EAN_SUCESSO,
  payload: alert,
});

export const gerarRelatorioCodigosBarrasEANErro = (mensagem) => ({
  type: GERAR_RELATORIO_CODIGOS_BARRAS_EAN_ERRO,
  payload: mensagem,
});
