import { AxiosResponse } from 'axios';
import api from '../../services/api';
import { montarQueryString } from '../../utils';
import { IValorMaoObraCadastro, IValorMaoObraConsulta } from './interfaces';

export const obterValoresMaoObraAsync = (
  query,
): Promise<AxiosResponse<{ result: IValorMaoObraConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `valoresMaoObra${queryStr}`,
    method: 'GET',
  });
};

export const obterValorMaoObraAsync = (id: number): Promise<AxiosResponse<IValorMaoObraConsulta>> => {
  return api.request({
    url: `valoresMaoObra/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoValorMaoObraAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `valoresMaoObra/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarValorMaoObraAsync = (
  valorMaoObra: IValorMaoObraCadastro,
): Promise<AxiosResponse<IValorMaoObraConsulta>> => {
  return api.request({
    url: `valoresMaoObra`,
    data: valorMaoObra,
    method: 'POST',
  });
};

export const atualizarValorMaoObraAsync = (
  id: number,
  valorMaoObra: IValorMaoObraCadastro,
): Promise<AxiosResponse<IValorMaoObraConsulta>> => {
  return api.request({
    url: `valoresMaoObra/${id}`,
    data: valorMaoObra,
    method: 'PATCH',
  });
};

export const removerValorMaoObraEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `valoresMaoObra`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerValorMaoObraAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `valoresMaoObra/${id}`,
    method: 'DELETE',
  });
