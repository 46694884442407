import {
  FILTRAR_CONTAS_PAGAR,
  GERAR_RELATORIO_CONTAS_PAGAR,
  GERAR_RELATORIO_CONTAS_PAGAR_ERRO,
  GERAR_RELATORIO_CONTAS_PAGAR_SUCESSO,
  LISTAR_CONTAS_PAGAR,
  LISTAR_CONTAS_PAGAR_ERRO,
  LISTAR_CONTAS_PAGAR_SUCESSO,
} from './constants';

export const obterTodosContasPagar = () => ({
  type: LISTAR_CONTAS_PAGAR,
});

export const obterContasPagarSucesso = (contasPagar) => ({
  type: LISTAR_CONTAS_PAGAR_SUCESSO,
  payload: contasPagar,
});

export const obterContasPagarErro = (mensagem) => ({
  type: LISTAR_CONTAS_PAGAR_ERRO,
  payload: mensagem,
});

export const filtrarContasPagar = (filtros) => ({
  type: FILTRAR_CONTAS_PAGAR,
  payload: filtros,
});

export const gerarRelatorioContasPagar = (payload) => ({
  type: GERAR_RELATORIO_CONTAS_PAGAR,
  payload: payload,
});

export const gerarRelatorioContasPagarSucesso = (alert) => ({
  type: GERAR_RELATORIO_CONTAS_PAGAR_SUCESSO,
  payload: alert,
});

export const gerarRelatorioContasPagarErro = (mensagem) => ({
  type: GERAR_RELATORIO_CONTAS_PAGAR_ERRO,
  payload: mensagem,
});