/* eslint-disable no-template-curly-in-string */
import { FormProvider } from 'rc-field-form';
import React from 'react';
import { useSelector } from 'react-redux';

import { RevendaInfoProvider } from './context/RevendaInfo';
import { ThemeProvider } from './layouts/ThemeProvider';
import { State } from './redux/reducers';
import Routes from './routes/Routes';

// Themes

// default
import './assets/scss/theme.scss';

// dark
// import './assets/scss/theme-dark.scss';

// rtl
// import './assets/scss/theme-rtl.scss';

export const validateMessages = {
  required: '${label} é obrigatório',
  whitespace: '${label} não pode ser vazio',
  types: {
    string: '${label} não é uma string válida',
    number: '${label} não é um número válido',
    date: '${label} não é uma data válida',
    boolean: '${label} não é um boolean válido',
    integer: '${label} não é um inteiro válido',
    float: '${label} não é um decimal válido',
    email: 'Email inválido',
    url: '${label} não é uma url válida',
    object: '${label} é inválido',
  },
  string: {
    len: '${label} deve ter exatamente ${len} caracteres',
    min: '${label} não pode ser menor que ${min} caracteres',
    max: '${label} não pode ser maior que ${max} caracteres',
    range: '${label} deve ter entre ${min} e ${max} caracteres',
  },
  number: {
    len: '${label} deve ser igual a ${len}',
    min: '${label} não pode ser menor que ${min}',
    max: '${label} não pode ser maior que ${max}',
    range: '${label} deve estar entre ${min} e ${max}',
  },
  date: {
    format: "'${label}' é um formato de data inválido",
    parse: "'${label}' não pode ser convertido para data",
    invalid: "'${label}' é uma data inválida",
  },
  pattern: {
    mismatch: '${label} possui um formato inválido',
  },
};

/**
 * Main app component
 */

const App = () => {
  const { coresTema } = useSelector((state: State) => state.Layout);

  return (
    <ThemeProvider theme={coresTema} id="wrapper">
      <FormProvider validateMessages={validateMessages}>
        <RevendaInfoProvider>
          <Routes />
        </RevendaInfoProvider>
      </FormProvider>
    </ThemeProvider>
  );
};

export default App;
