import * as FeatherIcon from 'react-feather';
import { IMenu } from '../../interfaces/menu';
import { PermissaoCodigo } from '../enums/permissao-codigo.enum';
import { ProgramaCodigo } from '../enums/programa-codigo.enum';
import { RoleCodigo } from '../enums/role-codigo.enum';

export const AdminDomperMenus: IMenu = {
  roles: [RoleCodigo.DomperAdmin],
  name: 'Admin Domper',
  children: [
    {
      programaCod: ProgramaCodigo.Integradores,
      name: 'Integradores',
      path: '/integradores/listar',
      icon: FeatherIcon.Code,
      component: 'listarIntegradores',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/integradores/listar',
          component: 'listarIntegradores',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/integradores/criar',
          component: 'integradores',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/integradores/editar/:id',
          component: 'integradores',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/integradores/visualizar/:id',
          component: 'integradores',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.Modulos,
      name: 'Módulos',
      path: '/modulos/listar',
      icon: FeatherIcon.Package,
      component: 'listarModulos',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/modulos/listar',
          component: 'listarModulos',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/modulos/criar',
          component: 'modulos',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/modulos/editar/:id',
          component: 'modulos',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/modulos/visualizar/:id',
          component: 'modulos',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/modulos/replicar/:id',
          component: 'modulos',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.PainelSistema,
      name: 'Painel do Sistema',
      path: '/painel-sistema',
      icon: FeatherIcon.Layout,
      component: 'listarPainelSistema',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/painel-sistema',
          component: 'listarPainelSistema',
        },
        // {
        //   permissaoCod: PermissaoCodigo.Incluir,
        //   path: '/painel-sistema/criar',
        //   component: 'painelSistema',
        // },
        // {
        //   permissaoCod: PermissaoCodigo.Alterar,
        //   path: '/painel-sistema/editar/:id',
        //   component: 'painelSistema',
        // },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/painel-sistema/visualizar/:id',
          component: 'painelSistema',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.Solucoes,
      name: 'Soluções',
      path: '/solucoes/listar',
      icon: FeatherIcon.Layers,
      component: 'listarSolucoes',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/solucoes/listar',
          component: 'listarSolucoes',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/solucoes/criar',
          component: 'solucoes',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/solucoes/editar/:id',
          component: 'solucoes',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/solucoes/visualizar/:id',
          component: 'solucoes',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/solucoes/replicar/:id',
          component: 'solucoes',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.Segmentos,
      name: 'Segmentos',
      path: '/segmentos/listar',
      icon: FeatherIcon.Grid,
      component: 'segmentosLista',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/segmentos/listar',
          component: 'segmentosLista',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/segmentos/criar',
          component: 'segmentosCadastro',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/segmentos/editar/:id',
          component: 'segmentosCadastro',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/segmentos/visualizar/:id',
          component: 'segmentosCadastro',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.Revenda,
      name: 'Revendas',
      path: '/revenda/listar',
      icon: FeatherIcon.Briefcase,
      component: 'listarRevenda',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/revenda/listar',
          component: 'listarRevenda',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/revenda/criar',
          component: 'revenda',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/revenda/editar/:id',
          component: 'revenda',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/revenda/visualizar/:id',
          component: 'revenda',
        },
      ],
    },
  ],
};
