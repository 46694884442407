import {
  FILTRAR_ALVOS,
  GERAR_RELATORIO_ALVOS,
  GERAR_RELATORIO_ALVOS_ERRO,
  GERAR_RELATORIO_ALVOS_SUCESSO,
  LISTAR_ALVOS,
  LISTAR_ALVOS_ERRO,
  LISTAR_ALVOS_SUCESSO,
} from './constants';

export const obterTodosAlvos = () => ({
  type: LISTAR_ALVOS,
});

export const obterAlvosSucesso = (Alvos) => ({
  type: LISTAR_ALVOS_SUCESSO,
  payload: Alvos,
});

export const obterAlvosErro = (mensagem) => ({
  type: LISTAR_ALVOS_ERRO,
  payload: mensagem,
});

export const filtrarAlvos = (filtros) => ({
  type: FILTRAR_ALVOS,
  payload: filtros,
});

export const gerarRelatorioAlvos = (payload) => ({
  type: GERAR_RELATORIO_ALVOS,
  payload: payload,
});

export const gerarRelatorioAlvosSucesso = (alert) => ({
  type: GERAR_RELATORIO_ALVOS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioAlvosErro = (mensagem) => ({
  type: GERAR_RELATORIO_ALVOS_ERRO,
  payload: mensagem,
});
