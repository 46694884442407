import { AxiosResponse } from 'axios';
import api from '../../services/api';
import { IFormasPagamentosCadastro, IFormasPagamentosConsulta, IParcela } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterFormasPagamentosAsync = (
  query,
): Promise<AxiosResponse<{ result: IFormasPagamentosConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);
  return api.request({
    url: `formas-pagamentos${queryStr}`,
    method: 'GET',
  });
};

export const obterFormaPagamentoAsync = (id: number): Promise<AxiosResponse<IFormasPagamentosConsulta>> => {
  return api.request({
    url: `formas-pagamentos/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoFormaPagamentoAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `formas-pagamentos/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarFormaPagamentoAsync = (
  formaPagamento: IFormasPagamentosCadastro,
): Promise<AxiosResponse<IFormasPagamentosConsulta>> => {
  return api.request({
    url: `formas-pagamentos`,
    data: formaPagamento,
    method: 'POST',
  });
};

export const atualizarFormasPagamentosAsync = (
  id: number,
  formaPagamento: IFormasPagamentosCadastro,
): Promise<AxiosResponse<IFormasPagamentosConsulta>> => {
  return api.request({
    url: `formas-pagamentos/${id}`,
    data: formaPagamento,
    method: 'PATCH',
  });
};

export const removerFormasPagamentosEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `formas-pagamentos`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerFormasPagamentosAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `formas-pagamentos/${id}`,
    method: 'DELETE',
  });

export const gerarParcelas = (query: {
  valorTotal: number;
  dataOrcamento: string;
  formaPagamentoId: number;
}): Promise<AxiosResponse<IParcela[]>> => {
  const queryStr = montarQueryString(query);
  return api.request({
    url: `formas-pagamentos/gerarParcelas${queryStr}`,
    method: 'GET',
  });
};
