import { ITableState } from '../../redux/table/reducers';
import api from '../api';

export const obterPreferenceAsync = (programaId: number) =>
  api.request({
    url: `preferencia/${programaId}`,
    method: 'GET',
  });

export const salvarPreferenceAsync = (programaId: number, state: ITableState) =>
  api.request({
    url: `preferencia/${programaId}`,
    method: 'POST',
    data: {
      ...state,
    },
  });
