import { AxiosResponse } from 'axios';
import qs from 'qs';
import api from '../../services/api';
import { IModuloCadastro, IModuloConsulta } from './interfaces';

export const criarModulo = (modulo: IModuloCadastro): Promise<AxiosResponse<IModuloConsulta>> =>
  api.request({
    url: `modulo`,
    method: 'POST',
    data: modulo,
  });

export const atualizarModulo = (modulo: IModuloCadastro): Promise<AxiosResponse<IModuloConsulta>> =>
  api.request({
    url: `modulo/${modulo.id}`,
    method: 'PATCH',
    data: modulo,
  });

export const obterModuloPorId = (id: number): Promise<AxiosResponse<IModuloConsulta>> =>
  api.request({
    url: `modulo/${id}`,
    method: 'GET',
  });

export const obterModulosAsync = (query): Promise<AxiosResponse<{ result: IModuloConsulta[]; total: number }>> => {
  let querystr = '';
  if (query) querystr = '?' + qs.stringify(query);

  return api.request({
    url: `modulo${querystr}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoModuloAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `modulo/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const excluirModulosAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `modulo/${id}`,
    method: 'DELETE',
  });

export const excluirModulosEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `modulo`,
    data: { ids },
    method: 'DELETE',
  });
};

export const vincularProgramasModuloAsync = ({
  idModulo,
  codigos,
}: {
  idModulo: number;
  codigos: number[];
}): Promise<AxiosResponse> =>
  api.request({
    url: `modulo/vincular_programas/${idModulo}`,
    method: 'PATCH',
    data: codigos,
  });
