import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardBody, Col, Row, Spinner, Table } from 'reactstrap';

import { Container, ContainerMapa, ContainerTexto, Content } from './styles';
import { obterRastreabilidadeAgroAsync } from './requests';
import { apiExceptionHandler } from '../../../helpers/exception-handler';
import { MapaComMarcador } from '../../../components/MapaComMarcador';
import { formatarDecimal, formatDate } from '../../../utils/format';
import { IRastreabilidadeAgroConsulta } from './interfaces';
import { MedidaArea } from '../../../constants/enums/medida-area.enum';

interface IParams {
  colheitaId?: string;
}

const RastreabilidadeAgro: React.FC = () => {
  const { colheitaId } = useParams<IParams>();

  const [carregando, setCarregando] = useState(false);
  const [rastreabilidade, setRastreabilidade] = useState<IRastreabilidadeAgroConsulta>();
  const configCultura = rastreabilidade?.cultura?.culturaOrganizacao?.length
    ? rastreabilidade?.cultura?.culturaOrganizacao?.[0]
    : rastreabilidade?.cultura;

  useEffect(() => {
    const buscarDados = async () => {
      try {
        setCarregando(true);

        if (colheitaId) {
          const { data } = await obterRastreabilidadeAgroAsync(+colheitaId);

          setRastreabilidade(data);
        }
      } catch (error) {
        apiExceptionHandler(error);
      } finally {
        setCarregando(false);
      }
    };

    buscarDados();
  }, [colheitaId]);

  return (
    <Container carregando={carregando}>
      {carregando ? (
        <Spinner />
      ) : !rastreabilidade ? (
        <h6>Não encontrado</h6>
      ) : (
        <Content>
          <h1>{rastreabilidade?.revenda?.nome}</h1>

          <Card>
            <CardBody>
              <Row>
                <Col xl={6}>
                  <img src={rastreabilidade?.cultura?.imagem} alt="Cultura" width="100%" height="auto" />
                </Col>

                <Col xl={6}>
                  <h3>{rastreabilidade?.cultura?.nome}</h3>
                  <p>{rastreabilidade?.cultura?.observacao}</p>
                </Col>
              </Row>
            </CardBody>
          </Card>

          {(rastreabilidade?.variedadeClone || rastreabilidade?.variedade) && (
            <Card>
              <CardBody>
                <Row>
                  <Col xl={6}>
                    <h3>
                      {rastreabilidade?.variedadeClone
                        ? rastreabilidade?.variedadeClone?.nome
                        : rastreabilidade?.variedade?.nome}
                    </h3>
                    <p>
                      {rastreabilidade?.variedadeClone
                        ? rastreabilidade?.variedadeClone?.observacao
                        : rastreabilidade?.variedade?.observacao}
                    </p>
                  </Col>

                  <Col xl={6}>
                    <img
                      src={
                        rastreabilidade?.variedadeClone
                          ? rastreabilidade?.variedadeClone?.imagem
                          : rastreabilidade?.variedade?.imagem
                      }
                      alt="Variedade"
                      width="100%"
                      height="auto"
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          )}

          <Card>
            <CardBody>
              <h3>Local</h3>

              <Row style={{ marginTop: '1rem' }}>
                <Col xl={6}>
                  <img src={rastreabilidade?.propriedade?.imagem} alt="Propriedade" width="100%" height="auto" />
                </Col>

                <Col xl={6}>
                  <ContainerTexto>
                    <h5>Nome:</h5>
                    <span>{rastreabilidade?.propriedade?.nome}</span>
                  </ContainerTexto>

                  <ContainerTexto>
                    <h5>Telefones: </h5>
                    <span>{rastreabilidade?.propriedade?.fones?.map((telefone) => telefone.fone)?.join(', ')}</span>
                  </ContainerTexto>

                  <ContainerTexto>
                    <h5>Endereço:</h5>
                    <span>
                      {(rastreabilidade?.propriedade?.enderecos?.[0]?.logradouro || '') +
                        ', ' +
                        (rastreabilidade?.propriedade?.enderecos?.[0]?.numero || '')}
                    </span>
                  </ContainerTexto>

                  <ContainerTexto>
                    <h5>Bairro:</h5>
                    <span>{rastreabilidade?.propriedade?.enderecos?.[0]?.bairro}</span>
                  </ContainerTexto>

                  <ContainerTexto>
                    <h5>CEP:</h5>
                    <span>{rastreabilidade?.propriedade?.enderecos?.[0]?.CEP}</span>
                  </ContainerTexto>

                  <ContainerTexto>
                    <h5>Cidade:</h5>
                    <span>
                      {(rastreabilidade?.propriedade?.enderecos?.[0]?.cidade?.nome || '') +
                        ' - ' +
                        (rastreabilidade?.propriedade?.enderecos?.[0]?.cidade?.estado?.sigla || '')}
                    </span>
                  </ContainerTexto>
                </Col>
              </Row>
              <br />
              <Row>
                <Col xl={6}>
                  <h3>Produtores</h3>

                  <Table responsive>
                    <thead>
                      <tr style={{ textAlign: 'center' }}>
                        <th>Nome</th>
                        <th>CPF/CNPJ</th>
                        <th>IE</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rastreabilidade?.propriedade?.pessoas?.map((produtor, index) => (
                        <tr key={index}>
                          <td>{produtor.pessoa.razaoSocial}</td>
                          <td style={{ textAlign: 'center' }}>{produtor.pessoa?.documentos?.[0]?.documento}</td>
                          <td style={{ textAlign: 'center' }}>{produtor.ie}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>

                <Col xl={6}>
                  <ContainerMapa>
                    <MapaComMarcador
                      coordenadaPropriedade={{
                        lat: Number(rastreabilidade?.propriedade?.latitude),
                        lng: Number(rastreabilidade?.propriedade?.longitude),
                      }}
                      podeCriarNovaMarcacao={false}
                      alterarCoordenadaPropriedade={() => {}}
                    />
                  </ContainerMapa>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <Row>
                <Col>
                  <h3>Lote: {rastreabilidade?.lote?.lote}</h3>

                  <ContainerTexto>
                    <h5>Data Colheita: </h5>
                    <span>{formatDate(rastreabilidade?.lote?.dataColheita || '')}</span>
                  </ContainerTexto>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <Row>
                <Col>
                  <h3>Cultivos</h3>

                  <Table responsive>
                    <thead>
                      <tr style={{ textAlign: 'center' }}>
                        <th>Nome</th>
                        <th>Plantio</th>
                        <th>{`Área Total ${
                          configCultura?.area
                            ? '(' + (configCultura?.area === MedidaArea.MetroQuadrado ? 'm²' : 'ha') + ')'
                            : ''
                        } `}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rastreabilidade?.cultivos?.map((cultivo, index) => (
                        <tr key={index}>
                          <td>{cultivo.nome}</td>
                          <td style={{ textAlign: 'center' }}>{cultivo.anoPlantio || ''}</td>
                          <td style={{ textAlign: 'right' }}>{formatarDecimal(cultivo.area, 3)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Content>
      )}
    </Container>
  );
};

export default RastreabilidadeAgro;
