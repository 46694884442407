import { IMenu } from '../../../interfaces/menu';
import { RoleCodigo } from '../../enums/role-codigo.enum';
import { CadastrosAgroAtividades } from './agro/atividades';
import { CadastrosAgroCulturas } from './agro/culturas';
import { CadastrosAgroFitossanidade } from './agro/fitossanidade';
import { CadastrosAgroPacking } from './agro/packing';
import { CadastrosAgroPropriedades } from './agro/propriedades';
import { CadastrosContabil } from './contabil';
import { CadastrosFinanceiros } from './financeiro';
import { CadastrosFiscais } from './fiscais';
import { CadastrosFood } from './food';
import { CadastrosLocalidades } from './localidades';
import { CadastrosPessoas } from './pessoas';
import { CadastrosProdutos } from './produtos';
import { CadastrosServicos } from './servicos';
import { CadastrosVeiculos } from './veiculos';

export const CadastrosMenus: IMenu = {
  roles: [RoleCodigo.ClienteUser, RoleCodigo.SolucaoTec, RoleCodigo.ClienteAdmin],
  name: 'Cadastros',
  children: [
    CadastrosAgroAtividades,
    CadastrosAgroCulturas,
    CadastrosAgroFitossanidade,
    CadastrosAgroPacking,
    CadastrosAgroPropriedades,
    CadastrosContabil,
    CadastrosFinanceiros,
    CadastrosFiscais,
    CadastrosFood,
    CadastrosLocalidades,
    CadastrosPessoas,
    CadastrosProdutos,
    CadastrosServicos,
    CadastrosVeiculos,
    
  ],
};
