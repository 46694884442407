import api from '../../services/api';
import { AxiosResponse } from 'axios';
import { ICamaraFriaCadastro, ICamaraFriaConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterCamarasFriaAsync = (
  query,
): Promise<
  AxiosResponse<{ result: ICamaraFriaConsulta[]; total: number }>
> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `camarasFria${queryStr}`,
    method: 'GET',
  });
};

export const obterCamaraFriaAsync = (
  id: number,
): Promise<AxiosResponse<ICamaraFriaConsulta>> => {
  return api.request({
    url: `camarasFria/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoCamaraFriaAsync = (): Promise<
  AxiosResponse<number>
> => {
  return api.request({
    url: `camarasFria/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarCamarasFriaAsync = (
  camaraFria: ICamaraFriaCadastro,
): Promise<AxiosResponse<ICamaraFriaConsulta>> => {
  return api.request({
    url: `camarasFria`,
    data: camaraFria,
    method: 'POST',
  });
};

export const atualizarCamarasFriaAsync = (
  id: number,
  camaraFria: ICamaraFriaCadastro,
): Promise<AxiosResponse<ICamaraFriaConsulta>> => {
  return api.request({
    url: `camarasFria/${id}`,
    data: camaraFria,
    method: 'PATCH',
  });
};

export const removerCamarasFriaEmMassaAsync = (
  ids: number[],
): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `camarasFria`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerCamarasFriaAsync = (
  id: number,
): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `camarasFria/${id}`,
    method: 'DELETE',
  });
