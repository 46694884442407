import {
  FILTRAR_TRANSFERENCIAS_CAIXA_BANCOS,
  GERAR_RELATORIO_TRANSFERENCIAS_CAIXA_BANCOS,
  GERAR_RELATORIO_TRANSFERENCIAS_CAIXA_BANCOS_ERRO,
  GERAR_RELATORIO_TRANSFERENCIAS_CAIXA_BANCOS_SUCESSO,
  LISTAR_TRANSFERENCIAS_CAIXA_BANCOS,
  LISTAR_TRANSFERENCIAS_CAIXA_BANCOS_ERRO,
  LISTAR_TRANSFERENCIAS_CAIXA_BANCOS_SUCESSO,
} from './constants';

export const obterTodosTransferenciasCaixaBancos = () => ({
  type: LISTAR_TRANSFERENCIAS_CAIXA_BANCOS,
});

export const obterTransferenciasCaixaBancosSucesso = (transferenciasCaixaBancos) => ({
  type: LISTAR_TRANSFERENCIAS_CAIXA_BANCOS_SUCESSO,
  payload: transferenciasCaixaBancos,
});

export const obterTransferenciasCaixaBancosErro = (mensagem) => ({
  type: LISTAR_TRANSFERENCIAS_CAIXA_BANCOS_ERRO,
  payload: mensagem,
});

export const filtrarTransferenciasCaixaBancos = (filtros) => ({
  type: FILTRAR_TRANSFERENCIAS_CAIXA_BANCOS,
  payload: filtros,
});

export const gerarRelatorioTransferenciasCaixaBancos = (payload) => ({
  type: GERAR_RELATORIO_TRANSFERENCIAS_CAIXA_BANCOS,
  payload: payload,
});

export const gerarRelatorioTransferenciasCaixaBancosSucesso = (alert) => ({
  type: GERAR_RELATORIO_TRANSFERENCIAS_CAIXA_BANCOS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioTransferenciasCaixaBancosErro = (mensagem) => ({
  type: GERAR_RELATORIO_TRANSFERENCIAS_CAIXA_BANCOS_ERRO,
  payload: mensagem,
});