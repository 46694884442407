import {
  FILTRAR_GRUPOS_PESSOAS,
  GERAR_RELATORIO_GRUPOS_PESSOAS,
  GERAR_RELATORIO_GRUPOS_PESSOAS_ERRO,
  GERAR_RELATORIO_GRUPOS_PESSOAS_SUCESSO,
  LISTAR_GRUPOS_PESSOAS,
  LISTAR_GRUPOS_PESSOAS_ERRO,
  LISTAR_GRUPOS_PESSOAS_SUCESSO,
} from './constants';

export const obterTodosGruposPessoas = () => ({
  type: LISTAR_GRUPOS_PESSOAS,
});

export const obterGruposPessoasSucesso = (gruposPessoas) => ({
  type: LISTAR_GRUPOS_PESSOAS_SUCESSO,
  payload: gruposPessoas,
});

export const obterGruposPessoasErro = (mensagem) => ({
  type: LISTAR_GRUPOS_PESSOAS_ERRO,
  payload: mensagem,
});

export const filtrarGruposPessoas = (filtros) => ({
  type: FILTRAR_GRUPOS_PESSOAS,
  payload: filtros,
});
export const gerarRelatorioGruposPessoas = (payload) => ({
  type: GERAR_RELATORIO_GRUPOS_PESSOAS,
  payload: payload,
});

export const gerarRelatorioGruposPessoasSucesso = (alert) => ({
  type: GERAR_RELATORIO_GRUPOS_PESSOAS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioGruposPessoasErro = (mensagem) => ({
  type: GERAR_RELATORIO_GRUPOS_PESSOAS_ERRO,
  payload: mensagem,
});