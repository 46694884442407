import {
    FILTRAR_VARIEDADES,
    GERAR_RELATORIO_VARIEDADES,
    GERAR_RELATORIO_VARIEDADES_ERRO,
    GERAR_RELATORIO_VARIEDADES_SUCESSO,
    LISTAR_VARIEDADES,
    LISTAR_VARIEDADES_ERRO,
    LISTAR_VARIEDADES_SUCESSO,
  } from './constants';
  
  export const obterTodosVariedades = () => ({
    type: LISTAR_VARIEDADES,
  });
  
  export const obterVariedadesSucesso = (variedades) => ({
    type: LISTAR_VARIEDADES_SUCESSO,
    payload: variedades,
  });
  
  export const obterVariedadesErro = (mensagem) => ({
    type: LISTAR_VARIEDADES_ERRO,
    payload: mensagem,
  });
  
  export const filtrarVariedades = (filtros) => ({
    type: FILTRAR_VARIEDADES,
    payload: filtros,
  });

  export const gerarRelatorioVariedades = (payload) => ({
    type: GERAR_RELATORIO_VARIEDADES,
    payload: payload,
  });
  
  export const gerarRelatorioVariedadesSucesso = (alert) => ({
    type: GERAR_RELATORIO_VARIEDADES_SUCESSO,
    payload: alert,
  });
  
  export const gerarRelatorioVariedadesErro = (mensagem) => ({
    type: GERAR_RELATORIO_VARIEDADES_ERRO,
    payload: mensagem,
  });
  