import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { ProgramaCodigo } from '../../../constants/enums/programa-codigo.enum';
import { SET_LIMIT_PAGE, SET_TABLE_ORDER } from '../../../redux/table/constants';
import { DEFAULT_DOWNLOAD_SUCCESS_MESSAGE, gerarRelatorioRacing } from '../../../services/requests/relatorio';
import { montarFiltroParaApi } from '../../../utils/api-filter';
import { obterCultivosProtegidosAsync } from '../requests';
import {
  gerarRelatorioCultivosProtegidosErro,
  gerarRelatorioCultivosProtegidosSucesso,
  obterCultivosProtegidosErro,
  obterCultivosProtegidosSucesso,
} from './actions';
import {
  FILTRAR_CULTIVOS_PROTEGIDOS,
  GERAR_RELATORIO_CULTIVOS_PROTEGIDOS,
  LISTAR_CULTIVOS_PROTEGIDOS,
} from './constants';

function* listarTodosCultivosProtegidos() {
  try {
    const filtro = yield select((state) => state.CultivosProtegidos.filtros);
    const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.CultivosProtegidos]);

    let query = { filtro: montarFiltroParaApi(filtro) };

    if (preferencias) {
      query.ordenacao = {
        campo: preferencias?.ordenarPor || 'nome',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      };
      query.paginacao = {
        pagina: preferencias?.page || 1,
        limite: preferencias?.limit || 50,
      };
    }

    const { data } = yield call(obterCultivosProtegidosAsync, query);
    yield put(obterCultivosProtegidosSucesso(data));
  } catch (error) {
    yield put(obterCultivosProtegidosErro(error.response?.data?.message || error.message));
  }
}

function* relatorioCultivosProtegidos() {
  const { filtros, relatorio } = yield select((state) => state.CultivosProtegidos);
  const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.CultivosProtegidos]);
  const { modelo, tipo, comFiltro, colunas } = relatorio;
  const columns = colunas?.map((coluna) => coluna.value);
  let query = {};

  if (comFiltro) {
    query = {
      filtro: {
        ...montarFiltroParaApi(filtros),
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
      ordenacao: {
        campo: preferencias?.ordenarPor || 'nome',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      },
    };
  } else {
    query = {
      filtro: {
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
    };
  }

  try {
    const { data, headers } = yield call(gerarRelatorioRacing, ProgramaCodigo.CultivosProtegidos, query, tipo);

    const contentType = headers['content-type'];

    if (!contentType.includes('application/json')) {
      const url = URL.createObjectURL(
        new Blob([data], {
          type: contentType || 'application/pdf',
        }),
      );

      if (url) {
        yield put(gerarRelatorioCultivosProtegidosSucesso(DEFAULT_DOWNLOAD_SUCCESS_MESSAGE));
        window.open(url, '_blank');
      }
    } else {
      yield put(
        gerarRelatorioCultivosProtegidosSucesso({
          titulo: 'Pedido de impressão salvo.',
          mensagem: 'Seu relatório está sendo gerado.',
        }),
      );
    }
  } catch (e) {
    yield put(
      gerarRelatorioCultivosProtegidosErro('Ocorreu um erro ao tentar gerar o relatório. Tente novamente mais tarde.'),
    );
  }
}

function* ordenarCultivosProtegidos({ payload }) {
  if (payload.programaId === ProgramaCodigo.CultivosProtegidos) {
    yield call(listarTodosCultivosProtegidos);
  }
}

function* mudarLimitePaginacao({ payload }) {
  if (payload.programaId === ProgramaCodigo.CultivosProtegidos) {
    yield call(listarTodosCultivosProtegidos);
  }
}

export function* watchListarCultivosProtegidos() {
  yield takeLatest(LISTAR_CULTIVOS_PROTEGIDOS, listarTodosCultivosProtegidos);
}

export function* watchFiltrarCultivosProtegidos() {
  yield takeLatest(FILTRAR_CULTIVOS_PROTEGIDOS, listarTodosCultivosProtegidos);
}

export function* watchRelatorioCultivosProtegidos() {
  yield takeLatest(GERAR_RELATORIO_CULTIVOS_PROTEGIDOS, relatorioCultivosProtegidos);
}

export function* watchOrdenarCultivosProtegidos() {
  yield takeLatest(SET_TABLE_ORDER, ordenarCultivosProtegidos);
}

export function* watchLimitePaginacao() {
  yield takeLatest(SET_LIMIT_PAGE, mudarLimitePaginacao);
}

function* cultivosProtegidosSaga() {
  yield all([
    fork(watchListarCultivosProtegidos),
    fork(watchFiltrarCultivosProtegidos),
    fork(watchRelatorioCultivosProtegidos),
    fork(watchOrdenarCultivosProtegidos),
    fork(watchLimitePaginacao),
  ]);
}

export default cultivosProtegidosSaga;
