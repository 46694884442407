import {
  FILTRAR_ORDENS_COMPRA,
  GERAR_RELATORIO_ORDENS_COMPRA,
  GERAR_RELATORIO_ORDENS_COMPRA_ERRO,
  GERAR_RELATORIO_ORDENS_COMPRA_SUCESSO,
  LISTAR_ORDENS_COMPRA,
  LISTAR_ORDENS_COMPRA_ERRO,
  LISTAR_ORDENS_COMPRA_SUCESSO,
} from './constants';

export const obterTodosOrdensCompra = () => ({
  type: LISTAR_ORDENS_COMPRA,
});

export const obterOrdensCompraSucesso = (ordensCompra) => ({
  type: LISTAR_ORDENS_COMPRA_SUCESSO,
  payload: ordensCompra,
});

export const obterOrdensCompraErro = (mensagem) => ({
  type: LISTAR_ORDENS_COMPRA_ERRO,
  payload: mensagem,
});

export const filtrarOrdensCompra = (filtros) => ({
  type: FILTRAR_ORDENS_COMPRA,
  payload: filtros,
});

export const gerarRelatorioOrdensCompra = (payload) => ({
  type: GERAR_RELATORIO_ORDENS_COMPRA,
  payload: payload,
});

export const gerarRelatorioOrdensCompraSucesso = (alert) => ({
  type: GERAR_RELATORIO_ORDENS_COMPRA_SUCESSO,
  payload: alert,
});

export const gerarRelatorioOrdensCompraErro = (mensagem) => ({
  type: GERAR_RELATORIO_ORDENS_COMPRA_ERRO,
  payload: mensagem,
});