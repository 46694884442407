import React, { ReactElement } from 'react';
import FormRc, { FormProps } from 'rc-field-form';
import { Field } from 'rc-field-form';
import { FieldProps } from 'rc-field-form/es/Field';
import { Error } from './styled';
import { Button, Col, Label } from 'reactstrap';
import { BiCopy } from 'react-icons/bi';
import { FiAlertTriangle } from 'react-icons/fi';
import { AiOutlineInfoCircle } from 'react-icons/ai';

export interface FormFieldProps {
  label?: string;
  children: ReactElement;
  hidden?: boolean;
  style?: React.CSSProperties;
  responsivity?: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
  };
  onPlusClick?: Function;
  onCopyClick?: Function;
  additionalLabelElement?: ReactElement;
  additionalInputElement?: ReactElement;
  removeInitialErrorContainer?: boolean;
  colStyle?: React.CSSProperties;
  warning?: string;
  info?: string;
}

export const FormCustomContext = React.createContext<{
  disabled: boolean;
}>({ disabled: true });

export const FormField: React.FC<FormFieldProps & FieldProps> = ({
  children,
  label = '',
  hidden = false,
  responsivity,
  onPlusClick,
  onCopyClick,
  additionalLabelElement,
  removeInitialErrorContainer = false,
  additionalInputElement,
  colStyle,
  warning,
  info,
  ...props
}) => {
  const required = !!props.rules?.find((r: any) => r?.required === true);
  const formContext = React.useContext(FormCustomContext);
  const globalDisabled = formContext?.disabled;

  return (
    <Col {...responsivity} style={colStyle}>
      {label && (
        <Label
          style={{
            whiteSpace: 'nowrap',
            width: '100%',
            ...(required ? { fontWeight: 800 } : { fontWeight: 'normal' }),
          }}
        >
          {label}
          {additionalLabelElement && React.cloneElement(additionalLabelElement)}
        </Label>
      )}
      <Field messageVariables={{ label }} {...props}>
        {(control, meta) => (
          <div style={props.style}>
            <div style={{ display: 'flex', width: '100%', height: '100%' }}>
              {/* injeta uma boolean error no input e o label */}
              <div style={{ width: onPlusClick && !globalDisabled ? 'calc(100% - 42px)' : '100%' }}>
                {React.cloneElement(children, {
                  invalid: meta.errors.length > 0,
                  disabled: children.props.disabled ?? globalDisabled,
                  hidden,
                  ...control,
                })}
              </div>
              {onPlusClick && !globalDisabled && (
                <Button style={{ width: 40, marginLeft: 2 }} onClick={() => onPlusClick()}>
                  +
                </Button>
              )}
              {onCopyClick && !globalDisabled && (
                <Button style={{ width: 40, marginLeft: 2 }} onClick={() => onCopyClick()}>
                  <BiCopy></BiCopy>
                </Button>
              )}
              {additionalInputElement && React.cloneElement(additionalInputElement)}
            </div>
            {info && (
              <span style={{ color: 'blue' }}>
                <AiOutlineInfoCircle></AiOutlineInfoCircle> {info}
              </span>
            )}
            {warning && !globalDisabled && (
              <span style={{ color: 'orange' }}>
                <FiAlertTriangle></FiAlertTriangle> {warning}
              </span>
            )}
            {/* exibe os erros em baixo do input */}
            {!hidden && meta.errors.map((error, i) => <Error key={i}>{error}</Error>)}
            {/* apenas para manter o mesmo espaçamento */}
            {!hidden && !removeInitialErrorContainer && meta.errors.length === 0 && <Error />}
          </div>
        )}
      </Field>
    </Col>
  );
};

export interface CustomFormProps {
  disabled?: boolean;
}

const Form: React.FC<FormProps & CustomFormProps> = ({ children, disabled, ...props }) => (
  <FormCustomContext.Provider value={{ disabled: !!disabled }}>
    <FormRc {...props}>{children}</FormRc>
  </FormCustomContext.Provider>
);

export { Form, Label };
