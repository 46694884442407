import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { ProgramaCodigo } from '../../../constants/enums/programa-codigo.enum';
import { FILTRAR_NATUREZA_OPERACOES, GERAR_RELATORIO_NATUREZA_OPERACOES, LISTAR_NATUREZA_OPERACOES } from './constants';
import { obterNaturezaOperacoesAsync } from '../requests';
import {
  gerarRelatorioNaturezaOperacoesErro,
  gerarRelatorioNaturezaOperacoesSucesso,
  obterNaturezaOperacoesErro,
  obterNaturezaOperacoesSucesso,
} from './actions';
import { SET_LIMIT_PAGE, SET_TABLE_ORDER } from '../../../redux/table/constants';
import { montarFiltroParaApi } from '../../../utils/api-filter';
import { DEFAULT_DOWNLOAD_SUCCESS_MESSAGE, gerarRelatorioRacing } from '../../../services/requests/relatorio';

function* listarTodosNaturezaOperacoes() {
  try {
    const filtro = yield select((state) => state.NaturezaOperacoes.filtros);
    const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.NaturezaOperacoes]);

    let query = { filtro: montarFiltroParaApi(filtro) };

    if (preferencias) {
      query.ordenacao = {
        campo: preferencias?.ordenarPor || 'nome',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      };
      query.paginacao = {
        pagina: preferencias?.page || 1,
        limite: preferencias?.limit || 50,
      };
    }

    const { data } = yield call(obterNaturezaOperacoesAsync, query);
    yield put(obterNaturezaOperacoesSucesso(data));
  } catch (error) {
    yield put(obterNaturezaOperacoesErro(error.response?.data?.message || error.message));
  }
}

function* relatorioNaturezaOperacoes() {
  const { filtros, relatorio } = yield select((state) => state.NaturezaOperacoes);
  const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.NaturezaOperacoes]);
  const { modelo, tipo, comFiltro, colunas } = relatorio;
  const columns = colunas?.map((coluna) => coluna.value);
  let query = {};

  if (comFiltro) {
    query = {
      filtro: {
        ...montarFiltroParaApi(filtros),
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
      ordenacao: {
        campo: preferencias?.ordenarPor || 'nome',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      },
    };
  } else {
    query = {
      filtro: {
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
    };
  }

  try {
    const { data, headers } = yield call(gerarRelatorioRacing, ProgramaCodigo.NaturezaOperacoes, query, tipo);

    if (headers['content-type'].includes('application/json')) {
      yield put(
        gerarRelatorioNaturezaOperacoesSucesso({
          titulo: 'Pedido de impressão salvo.',
          mensagem: 'Seu relatório está sendo gerado.',
        }),
      );
    } else {
      const url = URL.createObjectURL(
        new Blob([data], {
          type: headers['content-type'] || 'application/pdf',
        }),
      );

      if (url) {
        yield put(gerarRelatorioNaturezaOperacoesSucesso(DEFAULT_DOWNLOAD_SUCCESS_MESSAGE));
        window.open(url, '_blank');
      }
    }
  } catch (e) {
    yield put(
      gerarRelatorioNaturezaOperacoesErro('Ocorreu um erro ao tentar gerar o relatório. Tente novamente mais tarde.'),
    );
  }
}

function* ordenarNaturezaOperacoes({ payload }) {
  if (payload.programaId === ProgramaCodigo.NaturezaOperacoes) {
    yield call(listarTodosNaturezaOperacoes);
  }
}

function* mudarLimitePaginacao({ payload }) {
  if (payload.programaId === ProgramaCodigo.NaturezaOperacoes) {
    yield call(listarTodosNaturezaOperacoes);
  }
}

export function* watchListarNaturezaOperacoes() {
  yield takeLatest(LISTAR_NATUREZA_OPERACOES, listarTodosNaturezaOperacoes);
}

export function* watchFiltrarNaturezaOperacoes() {
  yield takeLatest(FILTRAR_NATUREZA_OPERACOES, listarTodosNaturezaOperacoes);
}

export function* watchRelatorioNaturezaOperacoes() {
  yield takeLatest(GERAR_RELATORIO_NATUREZA_OPERACOES, relatorioNaturezaOperacoes);
}

export function* watchOrdenarNaturezaOperacoes() {
  yield takeLatest(SET_TABLE_ORDER, ordenarNaturezaOperacoes);
}

export function* watchLimitePaginacao() {
  yield takeLatest(SET_LIMIT_PAGE, mudarLimitePaginacao);
}

function* naturezaOperacoesSaga() {
  yield all([
    fork(watchListarNaturezaOperacoes),
    fork(watchFiltrarNaturezaOperacoes),
    fork(watchRelatorioNaturezaOperacoes),
    fork(watchOrdenarNaturezaOperacoes),
    fork(watchLimitePaginacao),
  ]);
}

export default naturezaOperacoesSaga;
