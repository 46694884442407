import { AxiosResponse } from 'axios';

import api from '../../services/api';
import { ITipoCobrancaCadastro, ITipoCobrancaConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterTiposCobrancasAsync = (
  query,
): Promise<AxiosResponse<{ result: ITipoCobrancaConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `tipos-cobrancas${queryStr}`,
    method: 'GET',
  });
};

export const obterTipoCobrancaAsync = (id: number): Promise<AxiosResponse<ITipoCobrancaConsulta>> => {
  return api.request({
    url: `tipos-cobrancas/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoTipoCobrancaAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `tipos-cobrancas/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarTipoCobrancaAsync = (
  tipoCobranca: ITipoCobrancaCadastro,
): Promise<AxiosResponse<ITipoCobrancaConsulta>> => {
  return api.request({
    url: `tipos-cobrancas`,
    data: tipoCobranca,
    method: 'POST',
  });
};

export const atualizarTipoCobrancaAsync = (
  id: number,
  tipoCobranca: ITipoCobrancaCadastro,
): Promise<AxiosResponse<ITipoCobrancaConsulta>> => {
  return api.request({
    url: `tipos-cobrancas/${id}`,
    data: tipoCobranca,
    method: 'PATCH',
  });
};

export const removerTiposCobrancasEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `tipos-cobrancas`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerTipoCobrancaAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `tipos-cobrancas/${id}`,
    method: 'DELETE',
  });
