import React from 'react';

import SelecaoAsyncBase, { SelectOption } from './SelecaoAsyncBase';
import { IUnidadeProdutivaConsulta } from '../../pages/unidades-produtivas/interfaces';
import { obterUnidadesProdutivasAsync } from '../../pages/unidades-produtivas/requests';

interface IFilters {
  status?: number[];
  'propriedade.id'?: number[];
}

interface IProps {
  value?: SelectOption;
  onChange?: (value: SelectOption) => void;
  multiple?: boolean;
  filters?: IFilters;
}

const SelecaoUnidadeProdutiva: React.FC<IProps> = ({ filters, ...props }) => {
  const carregarOpcoes = async (filtro: string) => {
    let busca = filtro;
    let buscarPor = isNaN(parseInt(filtro)) ? 'nome' : 'codigo';

    const { data } = await obterUnidadesProdutivasAsync({
      filtro: {
        buscarPor,
        busca,
        ...filters,
      },
      ordenacao: {
        campo: 'nome',
        ordem: 'ASC',
      },
    });

    return data.result.map(formatarParaSelectOption);
  };

  const formatarParaSelectOption = (unidadeProdutiva: IUnidadeProdutivaConsulta) => ({
    label: unidadeProdutiva.codigo + ' - ' + unidadeProdutiva.nome,
    value: unidadeProdutiva.id,
  });
  return (
    <SelecaoAsyncBase
      key={`selecao-unidade-produtiva-${JSON.stringify(filters)}`}
      nomeRecurso="Unidades Produtivas"
      carregarOpcoes={carregarOpcoes}
      {...props}
    />
  );
};

export default SelecaoUnidadeProdutiva;
