import * as FeatherIcon from 'react-feather';
import { ISubMenu } from '../../../../interfaces/menu';
import { PermissaoCodigo } from '../../../enums/permissao-codigo.enum';
import { ProgramaCodigo } from '../../../enums/programa-codigo.enum';

export const CadastrosAgroCulturas: ISubMenu = {
  name: 'Agro Culturas',
  icon: FeatherIcon.CloudRain,
  children: [
    {
      programaCod: ProgramaCodigo.Culturas,
      name: 'Culturas',
      path: '/culturas/listar',
      component: 'listarCulturas',
      style: {
        fontWeight: 'bold',
      },
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/culturas/listar',
          component: 'listarCulturas',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/culturas/criar',
          component: 'culturas',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/culturas/editar/:id',
          component: 'culturas',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/culturas/visualizar/:id',
          component: 'culturas',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.CultivosProtegidos,
      name: 'Cultivos Protegidos',
      path: '/cultivos-protegidos/listar',
      component: 'listarCultivosProtegidos',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/cultivos-protegidos/listar',
          component: 'listarCultivosProtegidos',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/cultivos-protegidos/criar',
          component: 'cultivosProtegidos',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/cultivos-protegidos/editar/:id',
          component: 'cultivosProtegidos',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/cultivos-protegidos/visualizar/:id',
          component: 'cultivosProtegidos',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.EstagiosFenologicos,
      name: 'Estágios Fenológicos',
      path: '/estagios-fenologicos/listar',
      component: 'listarEstagiosFenologicos',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/estagios-fenologicos/listar',
          component: 'listarEstagiosFenologicos',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/estagios-fenologicos/criar',
          component: 'estagiosFenologicos',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/estagios-fenologicos/editar/:id',
          component: 'estagiosFenologicos',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/estagios-fenologicos/visualizar/:id',
          component: 'estagiosFenologicos',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.PortaEnxertos,
      name: 'Porta Enxertos',
      path: '/porta-enxertos/listar',
      component: 'listarPortaEnxertos',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/porta-enxertos/listar',
          component: 'listarPortaEnxertos',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/porta-enxertos/criar',
          component: 'portaEnxertos',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/porta-enxertos/editar/:id',
          component: 'portaEnxertos',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/porta-enxertos/visualizar/:id',
          component: 'portaEnxertos',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.SistemasConducao,
      name: 'Sistemas de Condução',
      path: '/sistemas-conducao/listar',
      component: 'listarSistemasConducao',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/sistemas-conducao/listar',
          component: 'listarSistemasConducao',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/sistemas-conducao/criar',
          component: 'sistemasConducao',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/sistemas-conducao/editar/:id',
          component: 'sistemasConducao',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/sistemas-conducao/visualizar/:id',
          component: 'sistemasConducao',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.SistemasIrrigacao,
      name: 'Sistemas de Irrigação',
      path: '/sistemas-irrigacao/listar',
      component: 'listarSistemasIrrigacao',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/sistemas-irrigacao/listar',
          component: 'listarSistemasIrrigacao',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/sistemas-irrigacao/criar',
          component: 'sistemasIrrigacao',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/sistemas-irrigacao/editar/:id',
          component: 'sistemasIrrigacao',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/sistemas-irrigacao/visualizar/:id',
          component: 'sistemasIrrigacao',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.SistemasProducao,
      name: 'Sistemas de Produção',
      path: '/sistemas-producao/listar',
      component: 'listarSistemasProducao',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/sistemas-producao/listar',
          component: 'listarSistemasProducao',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/sistemas-producao/criar',
          component: 'sistemasProducao',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/sistemas-producao/editar/:id',
          component: 'sistemasProducao',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/sistemas-producao/visualizar/:id',
          component: 'sistemasProducao',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.Variedades,
      name: 'Variedades',
      path: '/variedades/listar',
      component: 'listarVariedades',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/variedades/listar',
          component: 'listarVariedades',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/variedades/criar',
          component: 'variedades',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/variedades/editar/:id',
          component: 'variedades',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/variedades/visualizar/:id',
          component: 'variedades',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.VariedadesClones,
      name: 'Variedades Clones',
      path: '/variedades-clones/listar',
      component: 'listarVariedadesClones',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/variedades-clones/listar',
          component: 'listarVariedadesClones',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/variedades-clones/criar',
          component: 'variedadesClones',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/variedades-clones/editar/:id',
          component: 'variedadesClones',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/variedades-clones/visualizar/:id',
          component: 'variedadesClones',
        },
      ],
    },
  ],
};