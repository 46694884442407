import { CampoFiltro } from '../components/PartialPages/Filtro';
import { DateRangeInputValidator, NumberRangeInputValidator } from '../utils/validators';

export const filtrosPadroes: CampoFiltro[] = [
  {
    name: 'id',
    label: 'Id Inicial e Final',
    col: {
      md: 6,
      sm: 12,
    },
    type: 'rangenumber',
    rules: [NumberRangeInputValidator],
  },
  {
    name: 'codigo',
    label: 'Código Inicial e Final',
    col: {
      md: 6,
      sm: 12,
    },
    type: 'rangenumber',
    rules: [NumberRangeInputValidator],
  },
  {
    name: 'dataCriacao',
    label: 'Criado Em',
    col: {
      md: 6,
      sm: 12,
    },
    type: 'dataintervalo',
    rules: [DateRangeInputValidator],
  },
  {
    name: 'dataUltimaAlteracao',
    label: 'Alterado Em',
    col: {
      md: 6,
      sm: 12,
    },
    type: 'dataintervalo',
    rules: [DateRangeInputValidator],
  },
];

export const filtroPadraoStatus = {
  status: [{ value: 1, label: 'Ativo' }],
};
