import React from 'react';
import { ISelecao } from '../../interfaces/selecao';
import { IDepositoConsulta } from '../../pages/depositos/interfaces';
import { IPropriedadePessoaConsulta } from '../../pages/propriedades/cadastro/pessoas/interfaces';
import { IPropriedadeConsulta } from '../../pages/propriedades/interfaces';
import { obterPropriedadesAsync } from '../../pages/propriedades/requests';
import SelecaoAsyncBase, { SelectOption } from './SelecaoAsyncBase';
import { TipoPropriedade } from '../../constants/enums/tipo-propriedade.enum';

interface IFilters {
  status?: number[];
  'empresa.id'?: number | number[];
  'deposito.id'?: number[];
  naoFiltarEmpresaLogada?: boolean;
  tipo?: TipoPropriedade[];
}

interface ISelecaoPropriedadeOption {
  value?: SelectOption;
  onChange?: (value: any[]) => void;
  disabled?: boolean;
  multiple?: boolean;
  filters?: IFilters;
  autoFocus?: boolean;
  autoSelect?: boolean;
  callback?: (value: { result: any[]; total: number }) => void;
  showSelectAllOption?: boolean;
}

export interface ISelecaoPropriedade extends ISelecao {
  latitude?: string;
  longitude?: string;
  deposito?: IDepositoConsulta;
  pessoas?: IPropriedadePessoaConsulta[];
}

const SelecaoPropriedade: React.FC<ISelecaoPropriedadeOption> = ({
  filters,
  value,
  callback,
  showSelectAllOption = false,
  ...props
}) => {
  const carregarOpcoes = async (filtro: string) => {
    let busca = filtro;
    let buscarPor = isNaN(parseInt(filtro)) ? 'nome' : 'codigo';

    const { data } = await obterPropriedadesAsync({
      filtro: {
        buscarPor,
        busca,
        status: [1],
        ...filters,
      },
      ordenacao: {
        campo: 'nome',
        ordem: 'ASC',
      },
    });

    callback && callback(data);

    return data.result.map(formatarParaSelectOption);
  };

  const formatarParaSelectOption = (propriedade: IPropriedadeConsulta) => ({
    label: propriedade.codigo + ' - ' + propriedade.nome,
    value: propriedade.id,

    latitude: propriedade.latitude,
    longitude: propriedade.longitude,
    deposito: propriedade.deposito,
    empresaId: propriedade?.empresa?.id,
    pessoas: propriedade.pessoas,
  });

  return (
    <SelecaoAsyncBase
      key={`selecao-propriedade-${JSON.stringify(filters)}`}
      showSelectAllOption={showSelectAllOption}
      autoFocus={props.autoFocus}
      nomeRecurso="Propriedades"
      carregarOpcoes={carregarOpcoes}
      value={value}
      {...props}
    />
  );
};

export default SelecaoPropriedade;
