import { 
  LISTAR_SOLUCOES_SUCESSO, 
  GERAR_RELATORIO_SOLUCOES,
  GERAR_RELATORIO_SOLUCOES_ERRO,
  GERAR_RELATORIO_SOLUCOES_SUCESSO,
  LISTAR_SOLUCOES_ERRO, 
  LISTAR_SOLUCOES, 
  FILTRAR_SOLUCOES } from './constants';

export const obterTodosSolucoes = () => ({
  type: LISTAR_SOLUCOES,
});

export const obterSolucoesSucesso = (solucoes) => ({
  type: LISTAR_SOLUCOES_SUCESSO,
  payload: solucoes,
});

export const obterSolucoesErro = (mensagem) => ({
  type: LISTAR_SOLUCOES_ERRO,
  payload: mensagem,
});

export const filtrarSolucoes = (filtros) => ({
  type: FILTRAR_SOLUCOES,
  payload: filtros,
});

export const gerarRelatorioSolucoes = (payload) => ({
  type: GERAR_RELATORIO_SOLUCOES,
  payload: payload,
});

export const gerarRelatorioSolucoesSucesso = (alert) => ({
  type: GERAR_RELATORIO_SOLUCOES_SUCESSO,
  payload: alert,
});

export const gerarRelatorioSolucoesErro = (mensagem) => ({
  type: GERAR_RELATORIO_SOLUCOES_ERRO,
  payload: mensagem,
});
