import {
  FILTRAR_PREVISOES_COLHEITA,
  LISTAR_PREVISOES_COLHEITA,
  LISTAR_PREVISOES_COLHEITA_ERRO,
  LISTAR_PREVISOES_COLHEITA_SUCESSO,
  GERAR_RELATORIO_PREVISOES_COLHEITA,
  GERAR_RELATORIO_PREVISOES_COLHEITA_SUCESSO,
  GERAR_RELATORIO_PREVISOES_COLHEITA_ERRO,
} from './constants';

export const obterTodosPrevisoesColheita = () => ({
  type: LISTAR_PREVISOES_COLHEITA,
});

export const obterPrevisoesColheitaSucesso = (previsoesColheita) => ({
  type: LISTAR_PREVISOES_COLHEITA_SUCESSO,
  payload: previsoesColheita,
});

export const obterPrevisoesColheitaErro = (mensagem) => ({
  type: LISTAR_PREVISOES_COLHEITA_ERRO,
  payload: mensagem,
});

export const filtrarPrevisoesColheita = (filtros) => ({
  type: FILTRAR_PREVISOES_COLHEITA,
  payload: filtros,
});

export const gerarRelatorioPrevisoesColheita = (payload) => ({
  type: GERAR_RELATORIO_PREVISOES_COLHEITA,
  payload: payload,
});

export const gerarRelatorioPrevisoesColheitaSucesso = (alert) => ({
  type: GERAR_RELATORIO_PREVISOES_COLHEITA_SUCESSO,
  payload: alert,
});

export const gerarRelatorioPrevisoesColheitaErro = (mensagem) => ({
  type: GERAR_RELATORIO_PREVISOES_COLHEITA_ERRO,
  payload: mensagem,
});
