import { AxiosResponse } from 'axios';
import api from '../../services/api';
import { IEstagiosFenologicosCadastro, IEstagiosFenologicosConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterEstagiosFenologicosAsync = (
  query
): Promise<AxiosResponse<{ result: IEstagiosFenologicosConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `estagios-fenologicos${queryStr}`,
    method: 'GET',
  });
};

export const obterEstagioFenologicoAsync = (id: number): Promise<AxiosResponse<IEstagiosFenologicosConsulta>> => {
  return api.request({
    url: `estagios-fenologicos/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoEstagioFenologicoAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `estagios-fenologicos/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarEstagioFenologicoAsync = (
  estagioFenologico: IEstagiosFenologicosCadastro
): Promise<AxiosResponse<IEstagiosFenologicosConsulta>> => {
  return api.request({
    url: `estagios-fenologicos`,
    data: estagioFenologico,
    method: 'POST',
  });
};

export const atualizarEstagioFenologicoAsync = (
  id: number,
  estagioFenologico: IEstagiosFenologicosCadastro
): Promise<AxiosResponse<IEstagiosFenologicosConsulta>> => {
  return api.request({
    url: `estagios-fenologicos/${id}`,
    data: estagioFenologico,
    method: 'PATCH',
  });
};

export const removerEstagioFenologicoEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `estagios-fenologicos`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerEstagioFenologicoAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `estagios-fenologicos/${id}`,
    method: 'DELETE',
  });
