import { montarQueryString } from '../../utils';
import api from '../../services/api';
import { AxiosResponse } from 'axios';
import { IProgramaConsulta } from '../programas/interfaces';

interface PerfilAcessoEdicao {
  nome: string;
  codigo: number;
  observacao: string;
  programaPermissoes: {
    programa: number;
    permissoes: string[];
  }[];
}

export const obterPerfisAcesso = (query = {}) => {
  const querystr = montarQueryString(query);
  return api.request({
    url: `perfil-acesso${querystr}`,
    method: 'GET',
  });
};

export const obterProgramasEmpresaAsync = (): Promise<AxiosResponse<IProgramaConsulta[]>> => {
  return api.request({
    url: `perfil-acesso/programas-empresa`,
    method: 'GET',
  });
};

export const obterProgramasSolucoesAsync = (): Promise<AxiosResponse<IProgramaConsulta[]>> => {
  return api.request({
    url: `perfil-acesso/programas-solucoes`,
    method: 'GET',
  });
};

export const gerarProximoCodigoPerfilAcessoAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `perfil-acesso/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const cadastrarPerfilAcesso = (perfilAcesso: PerfilAcessoEdicao) => {
  return api.request({
    url: `perfil-acesso`,
    data: perfilAcesso,
    method: 'POST',
  });
};

export const editarPerfilAcesso = (id: number, perfilAcesso: PerfilAcessoEdicao) => {
  return api.request({
    url: `perfil-acesso/${id}`,
    data: perfilAcesso,
    method: 'PATCH',
  });
};

export const obterPerfilAcesso = (id: number) => {
  return api.request({
    url: `perfil-acesso/${id}`,
    method: 'GET',
  });
};

export const removerPerfilDeAcessoAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `perfil-acesso/${id}`,
    method: 'DELETE',
  });
};

export const removerPerfisDeAcessoEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `perfil-acesso`,
    method: 'DELETE',
    data: { ids },
  });
};
