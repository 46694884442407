import { AxiosResponse } from 'axios';

import api from '../../services/api';
import { IGrupoCentroCustoCadastro, IGrupoCentroCustoConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterGruposCentrosCustosAsync = (
  query,
): Promise<AxiosResponse<{ result: IGrupoCentroCustoConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `grupos-centros-custos${queryStr}`,
    method: 'GET',
  });
};

export const obterGrupoCentroCustoAsync = (id: number): Promise<AxiosResponse<IGrupoCentroCustoConsulta>> => {
  return api.request({
    url: `grupos-centros-custos/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoGrupoCentroCustoAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `grupos-centros-custos/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarGrupoCentroCustoAsync = (
  grupoCentroCusto: IGrupoCentroCustoCadastro,
): Promise<AxiosResponse<IGrupoCentroCustoConsulta>> => {
  return api.request({
    url: `grupos-centros-custos`,
    data: grupoCentroCusto,
    method: 'POST',
  });
};

export const atualizarGrupoCentroCustoAsync = (
  id: number,
  grupoCentroCusto: IGrupoCentroCustoCadastro,
): Promise<AxiosResponse<IGrupoCentroCustoConsulta>> => {
  return api.request({
    url: `grupos-centros-custos/${id}`,
    data: grupoCentroCusto,
    method: 'PATCH',
  });
};

export const removerGruposCentrosCustosEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `grupos-centros-custos`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerGrupoCentroCustoAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `grupos-centros-custos/${id}`,
    method: 'DELETE',
  });
