import { AxiosResponse } from 'axios';
import api from '../../services/api';
import { IPortaEnxertoCadastro, IPortaEnxertoConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterPortaEnxertosAsync = (
  query
): Promise<AxiosResponse<{ result: IPortaEnxertoConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `porta-enxertos${queryStr}`,
    method: 'GET',
  });
};

export const obterPortaEnxertoAsync = (id: number): Promise<AxiosResponse<IPortaEnxertoConsulta>> => {
  return api.request({
    url: `porta-enxertos/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoPortaEnxertoAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `porta-enxertos/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarPortaEnxertoAsync = (
  portaEnxerto: IPortaEnxertoCadastro
): Promise<AxiosResponse<IPortaEnxertoConsulta>> => {
  return api.request({
    url: `porta-enxertos`,
    data: portaEnxerto,
    method: 'POST',
  });
};

export const atualizarPortaEnxertoAsync = (
  id: number,
  portaEnxerto: IPortaEnxertoCadastro
): Promise<AxiosResponse<IPortaEnxertoConsulta>> => {
  return api.request({
    url: `porta-enxertos/${id}`,
    data: portaEnxerto,
    method: 'PATCH',
  });
};

export const removerPortaEnxertoEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `porta-enxertos`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerPortaEnxertoAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `porta-enxertos/${id}`,
    method: 'DELETE',
  });
