import * as FeatherIcon from 'react-feather';
import { ISubMenu } from '../../../../interfaces/menu';
import { PermissaoCodigo } from '../../../enums/permissao-codigo.enum';
import { ProgramaCodigo } from '../../../enums/programa-codigo.enum';

export const CadastrosAgroPropriedades: ISubMenu = {
  name: 'Agro Propriedades',
  icon: FeatherIcon.CloudRain,
  children: [
    {
      programaCod: ProgramaCodigo.Propriedades,
      name: 'Propriedades',
      path: '/propriedades/listar',
      style: {
        fontWeight: 'bold',
      },
      component: 'listarPropriedades',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/propriedades/listar',
          component: 'listarPropriedades',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/propriedades/criar',
          component: 'propriedades',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/propriedades/editar/:id',
          component: 'propriedades',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/propriedades/visualizar/:id',
          component: 'propriedades',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.UnidadesProdutivas,
      name: 'Unidades Produtivas',
      path: '/unidades-produtivas/listar',
      style: {
        fontWeight: 'bold',
      },
      component: 'listarUnidadesProdutivas',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/unidades-produtivas/listar',
          component: 'listarUnidadesProdutivas',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/unidades-produtivas/criar',
          component: 'unidadesProdutivas',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/unidades-produtivas/editar/:id',
          component: 'unidadesProdutivas',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/unidades-produtivas/visualizar/:id',
          component: 'unidadesProdutivas',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.AtividadesControleProdutividade,
      name: 'Atividades Controle de Produtividade',
      path: '/atividades-controle-produtividade/listar',
      component: 'listarAtividadesControleProdutividade',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/atividades-controle-produtividade/listar',
          component: 'listarAtividadesControleProdutividade',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/atividades-controle-produtividade/criar',
          component: 'atividadesControleProdutividade',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/atividades-controle-produtividade/editar/:id',
          component: 'atividadesControleProdutividade',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/atividades-controle-produtividade/visualizar/:id',
          component: 'atividadesControleProdutividade',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.CentrosCustos,
      name: 'Centros de Custos',
      path: '/centros-custos/listar',
      component: 'listarCentrosCustos',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/centros-custos/listar',
          component: 'listarCentrosCustos',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/centros-custos/criar',
          component: 'centrosCustos',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/centros-custos/editar/:id',
          component: 'centrosCustos',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/centros-custos/visualizar/:id',
          component: 'centrosCustos',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.ConceitosQualidade,
      name: 'Conceitos de Qualidade',
      path: '/conceitos-qualidade/listar',
      component: 'listarConceitosQualidade',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/conceitos-qualidade/listar',
          component: 'listarConceitosQualidade',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/conceitos-qualidade/criar',
          component: 'conceitosQualidade',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/conceitos-qualidade/editar/:id',
          component: 'conceitosQualidade',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/conceitos-qualidade/visualizar/:id',
          component: 'conceitosQualidade',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.EquipeAtividade,
      name: 'Equipes de Atividades',
      path: '/equipes-atividades/listar',
      component: 'listarEquipesAtividades',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/equipes-atividades/listar',
          component: 'listarEquipesAtividades',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/equipes-atividades/criar',
          component: 'equipesAtividades',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/equipes-atividades/editar/:id',
          component: 'equipesAtividades',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/equipes-atividades/visualizar/:id',
          component: 'equipesAtividades',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.GruposCentrosCustos,
      name: 'Grupos de Centros de Custos',
      path: '/grupos-centros-custos/listar',
      component: 'listarGruposCentrosCustos',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/grupos-centros-custos/listar',
          component: 'listarGruposCentrosCustos',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/grupos-centros-custos/criar',
          component: 'gruposCentrosCustos',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/grupos-centros-custos/editar/:id',
          component: 'gruposCentrosCustos',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/grupos-centros-custos/visualizar/:id',
          component: 'gruposCentrosCustos',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.GruposMaquinasImplementos,
      name: 'Grupos de Máquinas e Implementos',
      path: '/grupos-maquinas-implementos/listar',
      component: 'listarGruposMaquinasImplementos',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/grupos-maquinas-implementos/listar',
          component: 'listarGruposMaquinasImplementos',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/grupos-maquinas-implementos/criar',
          component: 'gruposMaquinasImplementos',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/grupos-maquinas-implementos/editar/:id',
          component: 'gruposMaquinasImplementos',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/grupos-maquinas-implementos/visualizar/:id',
          component: 'gruposMaquinasImplementos',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.MaquinasImplementos,
      name: 'Máquinas e Implementos',
      path: '/maquinas-implementos/listar',
      component: 'listarMaquinasImplementos',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/maquinas-implementos/listar',
          component: 'listarMaquinasImplementos',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/maquinas-implementos/criar',
          component: 'maquinasImplementos',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/maquinas-implementos/editar/:id',
          component: 'maquinasImplementos',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/maquinas-implementos/visualizar/:id',
          component: 'maquinasImplementos',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.Safras,
      name: 'Safras',
      path: '/safras/listar',
      component: 'listarSafras',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/safras/listar',
          component: 'listarSafras',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/safras/criar',
          component: 'safras',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/safras/editar/:id',
          component: 'safras',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/safras/visualizar/:id',
          component: 'safras',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/safras/replicar/:id',
          component: 'safras',
        },
      ],
    },
  ],
};
