import { AxiosResponse } from 'axios';
import api from '../../services/api';
import { montarQueryString } from '../../utils';
import { IOrganizacaoCadastro, IOrganizacaoConsulta } from './interfaces';

export const obterOrganizacao = (id): Promise<AxiosResponse<IOrganizacaoConsulta>> => {
  return api.request({
    url: `organizacao/${id}`,
    method: 'GET',
  });
};

export const obterOrganizacoesAsync = (
  query = {},
): Promise<AxiosResponse<{ result: IOrganizacaoConsulta[]; total: number }>> => {
  const querystr = montarQueryString(query);
  return api.request({
    url: `organizacao${querystr}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoOrganizacaoAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `organizacao/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const adicionarUsuarioNaOrganizacao = (organizacaoId, usuarioId) => {
  return api.request({
    url: `/organizacao/adicionar_usuario/${organizacaoId}/${usuarioId}`,
    method: 'PATCH',
  });
};

export const criarOrganizacao = (data: Partial<IOrganizacaoCadastro>): Promise<AxiosResponse<IOrganizacaoConsulta>> =>
  api.request({
    url: `organizacao`,
    method: 'POST',
    data,
  });

export const atualizarOrganizacao = (
  data: Partial<IOrganizacaoCadastro>,
): Promise<AxiosResponse<IOrganizacaoConsulta>> =>
  api.request({
    url: `organizacao/${data.id}`,
    method: 'PATCH',
    data,
  });

export const salvarLogoOrganizacaoAsync = (idOrg: number, file: File) => {
  let formData = new FormData();
  formData.append('file', file);

  return api.request({
    url: `organizacao/${idOrg}/logo`,
    method: 'POST',
    data: formData,
  });
};

export const deletarLogoOrganizacaoAsync = async (idOrg: number) => {
  return api.request({
    url: `organizacao/${idOrg}/deletar-logo`,
    method: 'PATCH',
  });
};

export const removerOrganizacao = (id: number): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `organizacao/${id}`,
    method: 'DELETE',
  });
};

export const removerOrganizacoesEmMassa = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `organizacao`,
    method: 'DELETE',
    data: { ids },
  });
};
