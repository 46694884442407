import api from '../../services/api';
import { AxiosResponse } from 'axios';
import { IOrdemCompraCadastro, IOrdemCompraConsulta, IOrdemCompraProdutoConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterOrdensCompraAsync = (
  query,
): Promise<AxiosResponse<{ result: IOrdemCompraConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `ordensCompra${queryStr}`,
    method: 'GET',
  });
};

export const obterOrdemCompraAsync = (id: number): Promise<AxiosResponse<any>> => {
  return api.request({
    url: `ordensCompra/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoOrdemCompraAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `ordensCompra/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarOrdensCompraAsync = (
  ordemCompra: IOrdemCompraCadastro,
): Promise<AxiosResponse<IOrdemCompraConsulta>> => {
  return api.request({
    url: `ordensCompra`,
    data: ordemCompra,
    method: 'POST',
  });
};

export const atualizarOrdensCompraAsync = (
  id: number,
  ordemCompra: IOrdemCompraCadastro,
): Promise<AxiosResponse<IOrdemCompraConsulta>> => {
  return api.request({
    url: `ordensCompra/${id}`,
    data: ordemCompra,
    method: 'PATCH',
  });
};

export const removerOrdensCompraEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `ordensCompra`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerOrdensCompraAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `ordensCompra/${id}`,
    method: 'DELETE',
  });

export const obterItensOrdensCompra = (query: {
  ordensComprasIds?: number[];
}): Promise<AxiosResponse<IOrdemCompraProdutoConsulta[]>> => {
  const queryStr = montarQueryString(query);
  return api.request({
    url: `ordensCompra/obter-itens-faturamento${queryStr}`,
    method: 'GET',
  });
};

export const aprovarDocumentoAsync = (id: number) => {
  return api.request<void>({
    url: `ordensCompra/marcar-aprovado/${id}`,
    method: 'PATCH',
  });
};

export const naoAprovarDocumentoAsync = (id: number) => {
  return api.request<void>({
    url: `ordensCompra/marcar-nao-aprovado/${id}`,
    method: 'PATCH',
  });
};