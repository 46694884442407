import * as FeatherIcon from 'react-feather';
import { ISubMenu } from '../../../interfaces/menu';
import { PermissaoCodigo } from '../../enums/permissao-codigo.enum';
import { ProgramaCodigo } from '../../enums/programa-codigo.enum';

export const CadastrosContabil: ISubMenu = {
  name: 'Contábil',
  icon: FeatherIcon.BarChart2,
  children: [
    {
      programaCod: ProgramaCodigo.HistoricosPadrao,
      name: 'Históricos Padrão',
      path: '/historicos-padrao/listar',
      component: 'listarHistoricosPadrao',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/historicos-padrao/listar',
          component: 'listarHistoricosPadrao',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/historicos-padrao/criar',
          component: 'historicosPadrao',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/historicos-padrao/editar/:id',
          component: 'historicosPadrao',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/historicos-padrao/visualizar/:id',
          component: 'historicosPadrao',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/historicos-padrao/replicar/:id',
          component: 'historicosPadrao',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.PlanoContas,
      name: 'Plano de Contas',
      path: '/plano-contas/listar',
      component: 'listarPlanoContas',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/plano-contas/listar',
          component: 'listarPlanoContas',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/plano-contas/criar',
          component: 'planoContas',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/plano-contas/editar/:id',
          component: 'planoContas',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/plano-contas/visualizar/:id',
          component: 'planoContas',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/plano-contas/replicar/:id',
          component: 'planoContas',
        },
      ],
    },
  ],
};
