import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { ProgramaCodigo } from '../../../constants/enums/programa-codigo.enum';
import { DEFAULT_DOWNLOAD_SUCCESS_MESSAGE, gerarRelatorioRacing } from '../../../services/requests/relatorio';
import { montarFiltroParaApi } from '../../../utils/api-filter';
import { gerarRelatorioCustosErro, gerarRelatorioCustosSucesso } from './actions';
import { GERAR_RELATORIO_CUSTOS } from './constants';

function* relatorioCustos() {
  const { filtros, relatorio } = yield select((state) => state.RelatoriosCustos);
  const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.RelatoriosCustos]);
  const { modelo, tipo, colunas } = relatorio;
  const columns = colunas?.map((coluna) => coluna.value);
  let query = {};

  query = {
    filtro: {
      ...montarFiltroParaApi(filtros),
      colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
      tipo,
      modelo,
    },
    ordenacao: {
      campo: preferencias?.ordenarPor || 'id',
      ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
    },
  };

  try {
    const { data, headers } = yield call(gerarRelatorioRacing, ProgramaCodigo.RelatoriosCustos, query, tipo);

    if (headers['content-type'].includes('application/json')) {
      yield put(
        gerarRelatorioCustosSucesso({
          titulo: 'Pedido de impressão salvo.',
          mensagem: 'Seu relatório está sendo gerado.',
        }),
      );
    } else {
      const url = URL.createObjectURL(
        new Blob([data], {
          type: headers['content-type'] || 'application/pdf',
        }),
      );

      if (url) {
        yield put(gerarRelatorioCustosSucesso(DEFAULT_DOWNLOAD_SUCCESS_MESSAGE));
        window.open(url, '_blank');
      }
    }
  } catch (e) {
    const error = yield e?.response?.data?.text();
    const errorObj = error ? JSON.parse(`${error}`) : undefined;

    yield put(
      gerarRelatorioCustosErro(
        errorObj?.message || 'Ocorreu um erro ao tentar gerar o relatório. Tente novamente mais tarde.',
      ),
    );
  }
}

export function* watchRelatorioCustos() {
  yield takeLatest(GERAR_RELATORIO_CUSTOS, relatorioCustos);
}

function* relatoriosCustosSaga() {
  yield all([fork(watchRelatorioCustos)]);
}

export default relatoriosCustosSaga;
