import {
  FILTRAR_ATIVIDADES_CONTROLE_PRODUTIVIDADE,
  GERAR_RELATORIO_ATIVIDADES_CONTROLE_PRODUTIVIDADE,
  GERAR_RELATORIO_ATIVIDADES_CONTROLE_PRODUTIVIDADE_ERRO,
  GERAR_RELATORIO_ATIVIDADES_CONTROLE_PRODUTIVIDADE_SUCESSO,
  LISTAR_ATIVIDADES_CONTROLE_PRODUTIVIDADE,
  LISTAR_ATIVIDADES_CONTROLE_PRODUTIVIDADE_ERRO,
  LISTAR_ATIVIDADES_CONTROLE_PRODUTIVIDADE_SUCESSO,
} from './constants';

export const obterTodosAtividadesControleProdutividade = () => ({
  type: LISTAR_ATIVIDADES_CONTROLE_PRODUTIVIDADE,
});

export const obterAtividadesControleProdutividadeSucesso = (AtividadesControleProdutividade) => ({
  type: LISTAR_ATIVIDADES_CONTROLE_PRODUTIVIDADE_SUCESSO,
  payload: AtividadesControleProdutividade,
});

export const obterAtividadesControleProdutividadeErro = (mensagem) => ({
  type: LISTAR_ATIVIDADES_CONTROLE_PRODUTIVIDADE_ERRO,
  payload: mensagem,
});

export const filtrarAtividadesControleProdutividade = (filtros) => ({
  type: FILTRAR_ATIVIDADES_CONTROLE_PRODUTIVIDADE,
  payload: filtros,
});

export const gerarRelatorioAtividadesControleProdutividade = (payload) => ({
  type: GERAR_RELATORIO_ATIVIDADES_CONTROLE_PRODUTIVIDADE,
  payload: payload,
});

export const gerarRelatorioAtividadesControleProdutividadeSucesso = (alert) => ({
  type: GERAR_RELATORIO_ATIVIDADES_CONTROLE_PRODUTIVIDADE_SUCESSO,
  payload: alert,
});

export const gerarRelatorioAtividadesControleProdutividadeErro = (mensagem) => ({
  type: GERAR_RELATORIO_ATIVIDADES_CONTROLE_PRODUTIVIDADE_ERRO,
  payload: mensagem,
});
