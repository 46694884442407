import { AxiosResponse } from 'axios';
import api from '../../services/api';
import { IMoedaCadastro, IMoedaConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterMoedasAsync = (query): Promise<AxiosResponse<{ result: IMoedaConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `moedas${queryStr}`,
    method: 'GET',
  });
};

export const obterMoedaAsync = (id: number): Promise<AxiosResponse<IMoedaConsulta>> => {
  return api.request({
    url: `moedas/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoMoedaAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `moedas/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarMoedaAsync = (Moeda: IMoedaCadastro): Promise<AxiosResponse<IMoedaConsulta>> => {
  return api.request({
    url: `moedas`,
    data: Moeda,
    method: 'POST',
  });
};

export const atualizarMoedaAsync = (id: number, Moeda: IMoedaCadastro): Promise<AxiosResponse<IMoedaConsulta>> => {
  return api.request({
    url: `moedas/${id}`,
    data: Moeda,
    method: 'PATCH',
  });
};

export const removerMoedasEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `moedas`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerMoedaAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `moedas/${id}`,
    method: 'DELETE',
  });
