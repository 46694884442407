import { AxiosResponse } from 'axios';

import api from '../../services/api';
import { ICESTCadastro, ICESTConsulta } from '../cest/interface';
import { montarQueryString } from '../../utils';

export const obterCESTsAsync = (query): Promise<AxiosResponse<{ result: ICESTConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `cest${queryStr}`,
    method: 'GET',
  });
};

export const obterCESTAsync = (id: number): Promise<AxiosResponse<ICESTConsulta>> => {
  return api.request({
    url: `cest/${id}`,
    method: 'GET',
  });
};

export const criarCESTAsync = (cest: ICESTCadastro): Promise<AxiosResponse<ICESTConsulta>> => {
  return api.request({
    url: `cest`,
    data: cest,
    method: 'POST',
  });
};

export const atualizarCESTAsync = (id: number, cest: ICESTCadastro): Promise<AxiosResponse<ICESTConsulta>> => {
  return api.request({
    url: `cest/${id}`,
    data: cest,
    method: 'PATCH',
  });
};

export const removerCESTsEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `cest`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerCESTAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `cest/${id}`,
    method: 'DELETE',
  });
