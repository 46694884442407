import React, { useRef, useState } from 'react';

import { AiOutlineClose } from 'react-icons/ai';
import paisagem from '../../assets/images/paisagem.png';
import { Row } from '../../styles/global';
import { ButtonRemove, Container, Imagem, ImagemContainer, ImagemIcone } from './styles';
interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  changeFile?: (file?: File | null) => void;
  tamanho: Array<number>;
  imagemInicial?: string;
  apenasLeitura: boolean;
}

const ImagemUpload: React.FC<Props> = ({ changeFile, tamanho, imagemInicial, apenasLeitura, ...props }) => {
  const imageRef = useRef<HTMLInputElement>(null);
  const [preview, setPreview] = useState<string | null>(imagemInicial ?? null);

  const handleClick = () => {
    if (apenasLeitura || preview) return;

    imageRef.current?.click();
  };

  const handleFile = (e) => {
    const file = e.target.files[0];

    if (file) {
      handleLoadFile(file);
      changeFile && changeFile(file);
    }
  };

  const handleLoadFile = (file) => {
    const reader = new FileReader();

    reader.onloadend = (e) => {
      setPreview(reader.result as string);
    };

    reader.readAsDataURL(file);
  };

  const removerImagem = () => {
    if (!preview) return;

    setPreview(null);
    changeFile && changeFile(null);

    // Limpa o valor do input de arquivo
    if (imageRef.current) {
      imageRef.current.value = '';
    }
  };

  return (
    <>
      <input {...props} accept="image/*" type="file" hidden ref={imageRef} onChange={handleFile} />
      <Container apenasLeitura={apenasLeitura} onClick={handleClick} tamanho={tamanho[0]}>
        {preview ? (
          <>
            <Imagem src={preview} tamanho={tamanho[0]} apenasLeitura={apenasLeitura} />
            {!apenasLeitura && (
              <ButtonRemove onClick={removerImagem} type="button">
                <AiOutlineClose color="#FFF" fontWeight={800} size={18} />
              </ButtonRemove>
            )}
          </>
        ) : (
          <ImagemContainer apenasLeitura={apenasLeitura} tamanho={tamanho[0]}>
            <ImagemIcone apenasLeitura={apenasLeitura} src={paisagem} tamanho={tamanho[0]} />
          </ImagemContainer>
        )}

        <Row justify="center" width={`${tamanho[0]}px`}>
          <span>{`${tamanho[0]}x${tamanho[1]}`}</span>
        </Row>
      </Container>
    </>
  );
};

export default ImagemUpload;
