import {
  FILTRAR_ALIQUOTAS_IMPOSTOS_SERVICOS,
  GERAR_RELATORIO_ALIQUOTAS_IMPOSTOS_SERVICOS,
  GERAR_RELATORIO_ALIQUOTAS_IMPOSTOS_SERVICOS_ERRO,
  GERAR_RELATORIO_ALIQUOTAS_IMPOSTOS_SERVICOS_SUCESSO,
  LISTAR_ALIQUOTAS_IMPOSTOS_SERVICOS,
  LISTAR_ALIQUOTAS_IMPOSTOS_SERVICOS_ERRO,
  LISTAR_ALIQUOTAS_IMPOSTOS_SERVICOS_SUCESSO,
} from './constants';

export const obterTodosAliquotasImpostosServicos = () => ({
  type: LISTAR_ALIQUOTAS_IMPOSTOS_SERVICOS,
});

export const obterAliquotasImpostosServicosSucesso = (aliquotasImpostosServicos) => ({
  type: LISTAR_ALIQUOTAS_IMPOSTOS_SERVICOS_SUCESSO,
  payload: aliquotasImpostosServicos,
});

export const obterAliquotasImpostosServicosErro = (mensagem) => ({
  type: LISTAR_ALIQUOTAS_IMPOSTOS_SERVICOS_ERRO,
  payload: mensagem,
});

export const filtrarAliquotasImpostosServicos = (filtros) => ({
  type: FILTRAR_ALIQUOTAS_IMPOSTOS_SERVICOS,
  payload: filtros,
});

export const gerarRelatorioAliquotasImpostosServicos = (payload) => ({
  type: GERAR_RELATORIO_ALIQUOTAS_IMPOSTOS_SERVICOS,
  payload: payload,
});

export const gerarRelatorioAliquotasImpostosServicosSucesso = (alert) => ({
  type: GERAR_RELATORIO_ALIQUOTAS_IMPOSTOS_SERVICOS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioAliquotasImpostosServicosErro = (mensagem) => ({
  type: GERAR_RELATORIO_ALIQUOTAS_IMPOSTOS_SERVICOS_ERRO,
  payload: mensagem,
});
