import { AxiosResponse } from 'axios';
import api from '../../services/api';
import { montarQueryString } from '../../utils';
import { IEntradaFrutasCadastro, IEntradaFrutasConsulta } from './interfaces';

export const obterEntradasFrutasAsync = (
  query,
): Promise<AxiosResponse<{ result: IEntradaFrutasConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `entrada-frutas${queryStr}`,
    method: 'GET',
  });
};

export const obterEntradaFrutasPorIdAsync = (id: number): Promise<AxiosResponse<IEntradaFrutasConsulta>> => {
  return api.request({
    url: `entrada-frutas/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoEntradaFrutasAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `entrada-frutas/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarEntradaFrutasAsync = (
  entradaFrutas: IEntradaFrutasCadastro,
): Promise<AxiosResponse<IEntradaFrutasConsulta>> => {
  return api.request({
    url: `entrada-frutas`,
    data: entradaFrutas,
    method: 'POST',
  });
};

export const atualizarEntradaFrutasAsync = (
  id: number,
  entradaFrutas: IEntradaFrutasCadastro,
): Promise<AxiosResponse<IEntradaFrutasConsulta>> => {
  return api.request({
    url: `entrada-frutas/${id}`,
    data: entradaFrutas,
    method: 'PATCH',
  });
};

export const removerEntradaFrutasEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `entrada-frutas`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerEntradaFrutasAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `entrada-frutas/${id}`,
    method: 'DELETE',
  });
