import {
  FILTRAR_TRIBUTACOES_SERVICOS,
  LISTAR_TRIBUTACOES_SERVICOS,
  LISTAR_TRIBUTACOES_SERVICOS_ERRO,
  LISTAR_TRIBUTACOES_SERVICOS_SUCESSO,
} from './constants';

export const obterTodosTributacoesServicos = () => ({
  type: LISTAR_TRIBUTACOES_SERVICOS,
});

export const obterTributacoesServicosSucesso = (tributacoesServicos) => ({
  type: LISTAR_TRIBUTACOES_SERVICOS_SUCESSO,
  payload: tributacoesServicos,
});

export const obterTributacoesServicosErro = (mensagem) => ({
  type: LISTAR_TRIBUTACOES_SERVICOS_ERRO,
  payload: mensagem,
});

export const filtrarTributacoesServicos = (filtros) => ({
  type: FILTRAR_TRIBUTACOES_SERVICOS,
  payload: filtros,
});
