import {
    FILTRAR_SISTEMAS_CONDUCAO,
    GERAR_RELATORIO_SISTEMAS_CONDUCAO,
    GERAR_RELATORIO_SISTEMAS_CONDUCAO_ERRO,
    GERAR_RELATORIO_SISTEMAS_CONDUCAO_SUCESSO,
    LISTAR_SISTEMAS_CONDUCAO,
    LISTAR_SISTEMAS_CONDUCAO_ERRO,
    LISTAR_SISTEMAS_CONDUCAO_SUCESSO,
  } from './constants';
  
  export const obterTodosSistemasConducao = () => ({
    type: LISTAR_SISTEMAS_CONDUCAO,
  });
  
  export const obterSistemasConducaoSucesso = (sistemasConducao) => ({
    type: LISTAR_SISTEMAS_CONDUCAO_SUCESSO,
    payload: sistemasConducao,
  });
  
  export const obterSistemasConducaoErro = (mensagem) => ({
    type: LISTAR_SISTEMAS_CONDUCAO_ERRO,
    payload: mensagem,
  });
  
  export const filtrarSistemasConducao = (filtros) => ({
    type: FILTRAR_SISTEMAS_CONDUCAO,
    payload: filtros,
  });

  export const gerarRelatorioSistemasConducao = (payload) => ({
    type: GERAR_RELATORIO_SISTEMAS_CONDUCAO,
    payload: payload,
  });
  
  export const gerarRelatorioSistemasConducaoSucesso = (alert) => ({
    type: GERAR_RELATORIO_SISTEMAS_CONDUCAO_SUCESSO,
    payload: alert,
  });
  
  export const gerarRelatorioSistemasConducaoErro = (mensagem) => ({
    type: GERAR_RELATORIO_SISTEMAS_CONDUCAO_ERRO,
    payload: mensagem,
  });