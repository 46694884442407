import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { ProgramaCodigo } from '../../../constants/enums/programa-codigo.enum';
import { SET_LIMIT_PAGE, SET_TABLE_ORDER } from '../../../redux/table/constants';
import { DEFAULT_DOWNLOAD_SUCCESS_MESSAGE, gerarRelatorioRacing } from '../../../services/requests/relatorio';
import { montarFiltroParaApi } from '../../../utils/api-filter';
import { obterCNAEsAsync } from '../requests';
import { gerarRelatorioCNAEsErro, gerarRelatorioCNAEsSucesso, obterCNAEsErro, obterCNAEsSucesso } from './actions';
import { FILTRAR_CNAES, GERAR_RELATORIO_CNAES, LISTAR_CNAES } from './constants';

function* listarTodosCNAEs() {
  try {
    const filtro = yield select((state) => state.CNAEs.filtros);
    const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.CNAE]);

    let query = { filtro: montarFiltroParaApi(filtro) };

    if (preferencias) {
      query.ordenacao = {
        campo: preferencias?.ordenarPor || 'nome',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      };
      query.paginacao = {
        pagina: preferencias?.page || 1,
        limite: preferencias?.limit || 50,
      };
    }

    const { data } = yield call(obterCNAEsAsync, query);
    yield put(obterCNAEsSucesso(data));
  } catch (error) {
    yield put(obterCNAEsErro(error.response?.data?.message || error.message));
  }
}

function* relatorioCNAEs() {
  const { filtros, relatorio } = yield select((state) => state.CNAEs);
  const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.CNAE]);
  const { modelo, tipo, comFiltro, colunas } = relatorio;
  const columns = colunas?.map((coluna) => coluna.value);
  let query = {};

  if (comFiltro) {
    query = {
      filtro: {
        ...montarFiltroParaApi(filtros),
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
      ordenacao: {
        campo: preferencias?.ordenarPor || 'nome',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      },
    };
  } else {
    query = {
      filtro: {
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
    };
  }

  try {
    const { data, headers } = yield call(gerarRelatorioRacing, ProgramaCodigo.CNAE, query, tipo);

    if (headers['content-type'].includes('application/json')) {
      yield put(
        gerarRelatorioCNAEsSucesso({
          titulo: 'Pedido de impressão salvo.',
          mensagem: 'Seu relatório está sendo gerado.',
        }),
      );
    } else {
      const url = URL.createObjectURL(
        new Blob([data], {
          type: headers['content-type'] || 'application/pdf',
        }),
      );

      if (url) {
        yield put(gerarRelatorioCNAEsSucesso(DEFAULT_DOWNLOAD_SUCCESS_MESSAGE));
        window.open(url, '_blank');
      }
    }
  } catch (e) {
    yield put(gerarRelatorioCNAEsErro('Ocorreu um erro ao tentar gerar o relatório. Tente novamente mais tarde.'));
  }
}

function* ordenarCNAEs({ payload }) {
  if (payload.programaId === ProgramaCodigo.CNAE) {
    yield call(listarTodosCNAEs);
  }
}

function* mudarLimitePaginacao({ payload }) {
  if (payload.programaId === ProgramaCodigo.CNAE) {
    yield call(listarTodosCNAEs);
  }
}

export function* watchListarCNAEs() {
  yield takeLatest(LISTAR_CNAES, listarTodosCNAEs);
}

export function* watchFiltrarCNAEs() {
  yield takeLatest(FILTRAR_CNAES, listarTodosCNAEs);
}

export function* watchRelatorioCNAEs() {
  yield takeLatest(GERAR_RELATORIO_CNAES, relatorioCNAEs);
}

export function* watchOrdenarCNAEs() {
  yield takeLatest(SET_TABLE_ORDER, ordenarCNAEs);
}

export function* watchLimitePaginacao() {
  yield takeLatest(SET_LIMIT_PAGE, mudarLimitePaginacao);
}

function* CNAEsSaga() {
  yield all([
    fork(watchListarCNAEs),
    fork(watchFiltrarCNAEs),
    fork(watchRelatorioCNAEs),
    fork(watchOrdenarCNAEs),
    fork(watchLimitePaginacao),
  ]);
}

export default CNAEsSaga;
