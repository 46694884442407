import {
  FILTRAR_SISTEMAS_IRRIGACAO,
  GERAR_RELATORIO_SISTEMAS_IRRIGACAO,
  GERAR_RELATORIO_SISTEMAS_IRRIGACAO_ERRO,
  GERAR_RELATORIO_SISTEMAS_IRRIGACAO_SUCESSO,
  LISTAR_SISTEMAS_IRRIGACAO,
  LISTAR_SISTEMAS_IRRIGACAO_ERRO,
  LISTAR_SISTEMAS_IRRIGACAO_SUCESSO,
} from './constants';

export const obterTodosSistemasIrrigacao = () => ({
  type: LISTAR_SISTEMAS_IRRIGACAO,
});

export const obterSistemasIrrigacaoSucesso = (sistemasIrrigacao) => ({
  type: LISTAR_SISTEMAS_IRRIGACAO_SUCESSO,
  payload: sistemasIrrigacao,
});

export const obterSistemasIrrigacaoErro = (mensagem) => ({
  type: LISTAR_SISTEMAS_IRRIGACAO_ERRO,
  payload: mensagem,
});

export const filtrarSistemasIrrigacao = (filtros) => ({
  type: FILTRAR_SISTEMAS_IRRIGACAO,
  payload: filtros,
});

export const gerarRelatorioSistemasIrrigacao = (payload) => ({
  type: GERAR_RELATORIO_SISTEMAS_IRRIGACAO,
  payload: payload,
});

export const gerarRelatorioSistemasIrrigacaoSucesso = (alert) => ({
  type: GERAR_RELATORIO_SISTEMAS_IRRIGACAO_SUCESSO,
  payload: alert,
});

export const gerarRelatorioSistemasIrrigacaoErro = (mensagem) => ({
  type: GERAR_RELATORIO_SISTEMAS_IRRIGACAO_ERRO,
  payload: mensagem,
});
