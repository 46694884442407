import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { ProgramaCodigo } from '../../../constants/enums/programa-codigo.enum';
import { FILTRAR_TRIBUTACOES_ESTADUAIS, LISTAR_TRIBUTACOES_ESTADUAIS } from './constants';
import { obterTributacoesEstaduaisAsync } from '../requests';
import { obterTributacoesEstaduaisErro, obterTributacoesEstaduaisSucesso } from './actions';
import { SET_LIMIT_PAGE, SET_TABLE_ORDER } from '../../../redux/table/constants';
import { montarFiltroParaApi } from '../../../utils/api-filter';

function* listarTodosTributacoesEstaduais() {
  try {
    const filtro = yield select((state) => (state.TributacoesEstaduais.filtros));
    const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.TributacoesEstaduais]);
    
    let query = { filtro: montarFiltroParaApi(filtro) };

    if (preferencias) {
      query.ordenacao = {
        campo: preferencias?.ordenarPor || 'nome',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      };
      query.paginacao = {
        pagina: preferencias?.page || 1,
        limite: preferencias?.limit || 50,
      };
    }

    const { data } = yield call(obterTributacoesEstaduaisAsync, query);
    yield put(obterTributacoesEstaduaisSucesso(data));
  } catch (error) {
    yield put(obterTributacoesEstaduaisErro(error.response?.data?.message || error.message));
  }
}

function* ordenarTributacoesEstaduais({ payload }) {
  if (payload.programaId === ProgramaCodigo.TributacoesEstaduais) {
    yield call(listarTodosTributacoesEstaduais);
  }
}

function* mudarLimitePaginacao({ payload }) {
  if (payload.programaId === ProgramaCodigo.TributacoesEstaduais) {
    yield call(listarTodosTributacoesEstaduais);
  }
}

export function* watchListarTributacoesEstaduais() {
  yield takeLatest(LISTAR_TRIBUTACOES_ESTADUAIS, listarTodosTributacoesEstaduais);
}

export function* watchFiltrarTributacoesEstaduais() {
  yield takeLatest(FILTRAR_TRIBUTACOES_ESTADUAIS, listarTodosTributacoesEstaduais);
}

export function* watchOrdenarTributacoesEstaduais() {
  yield takeLatest(SET_TABLE_ORDER, ordenarTributacoesEstaduais);
}

export function* watchLimitePaginacao() {
  yield takeLatest(SET_LIMIT_PAGE, mudarLimitePaginacao);
}

function* tributacoesEstaduaisSaga() {
  yield all([fork(watchListarTributacoesEstaduais), fork(watchFiltrarTributacoesEstaduais), fork(watchOrdenarTributacoesEstaduais), fork(watchLimitePaginacao)]);
}

export default tributacoesEstaduaisSaga;
