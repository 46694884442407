import Swal from 'sweetalert2';
import {
  FILTRAR_PROMOCOES,
  GERAR_RELATORIO_PROMOCOES,
  GERAR_RELATORIO_PROMOCOES_ERRO,
  GERAR_RELATORIO_PROMOCOES_SUCESSO,
  LISTAR_PROMOCOES,
  LISTAR_PROMOCOES_ERRO,
  LISTAR_PROMOCOES_SUCESSO,
} from './constants';

const INIT_STATE = {
  itens: [],
  filtros: {},
  carregando: false,
  relatorio: {},
  gerandoRelatorio: false,
};

const SegmentoReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LISTAR_PROMOCOES:
      return { ...state, carregando: true };
    case FILTRAR_PROMOCOES:
      return {
        ...state,
        filtros: action.payload,
        carregando: true,
      };
    case LISTAR_PROMOCOES_SUCESSO:
      return {
        ...state,
        itens: action.payload,
        carregando: false,
      };
    case LISTAR_PROMOCOES_ERRO:
      Swal.fire({
        icon: 'error',
        title: 'Erro ao buscar promoções',
        timer: 2000,
        text: action.payload,
      });
      return { ...state, itens: [], carregando: false };
    case GERAR_RELATORIO_PROMOCOES:
      return {
        ...state,
        filtros: action.payload.filtros,
        relatorio: action.payload.relatorio,
        gerandoRelatorio: true,
      };
    case GERAR_RELATORIO_PROMOCOES_SUCESSO:
      if (action.payload) {
        Swal.fire({
          icon: 'success',
          title: action.payload.titulo,
          timer: action?.payload?.timer || 2000,
          text: action.payload.mensagem,
        });
      }
      return { ...state, gerandoRelatorio: false };
    case GERAR_RELATORIO_PROMOCOES_ERRO:
      Swal.fire({
        icon: 'error',
        title: 'Falha ao gerar Relatório',
        timer: 2000,
        text: action.payload,
      });
      return { ...state, gerandoRelatorio: false };
    default:
      return state;
  }
};

export default SegmentoReducer;
