import {
  FILTRAR_NOTAS_FISCAIS_CONSUMIDOR,
  GERAR_RELATORIO_NOTAS_FISCAIS_CONSUMIDOR,
  GERAR_RELATORIO_NOTAS_FISCAIS_CONSUMIDOR_ERRO,
  GERAR_RELATORIO_NOTAS_FISCAIS_CONSUMIDOR_SUCESSO,
  LISTAR_NOTAS_FISCAIS_CONSUMIDOR,
  LISTAR_NOTAS_FISCAIS_CONSUMIDOR_ERRO,
  LISTAR_NOTAS_FISCAIS_CONSUMIDOR_SUCESSO,
} from './constants';

export const obterTodosNotasFiscaisConsumidor = () => ({
  type: LISTAR_NOTAS_FISCAIS_CONSUMIDOR,
});

export const obterNotasFiscaisConsumidorSucesso = (notasFiscaisConsumidor) => ({
  type: LISTAR_NOTAS_FISCAIS_CONSUMIDOR_SUCESSO,
  payload: notasFiscaisConsumidor,
});

export const obterNotasFiscaisConsumidorErro = (mensagem) => ({
  type: LISTAR_NOTAS_FISCAIS_CONSUMIDOR_ERRO,
  payload: mensagem,
});

export const filtrarNotasFiscaisConsumidor = (filtros) => ({
  type: FILTRAR_NOTAS_FISCAIS_CONSUMIDOR,
  payload: filtros,
});
export const gerarRelatorioNotasFiscaisConsumidor = (payload) => ({
  type: GERAR_RELATORIO_NOTAS_FISCAIS_CONSUMIDOR,
  payload: payload,
});

export const gerarRelatorioNotasFiscaisConsumidorSucesso = (alert) => ({
  type: GERAR_RELATORIO_NOTAS_FISCAIS_CONSUMIDOR_SUCESSO,
  payload: alert,
});

export const gerarRelatorioNotasFiscaisConsumidorErro = (mensagem) => ({
  type: GERAR_RELATORIO_NOTAS_FISCAIS_CONSUMIDOR_ERRO,
  payload: mensagem,
});
