import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { ProgramaCodigo } from '../../../constants/enums/programa-codigo.enum';
import { FILTRAR_TIPOS_COBRANCAS, GERAR_RELATORIO_TIPOS_COBRANCAS, LISTAR_TIPOS_COBRANCAS } from './constants';
import { obterTiposCobrancasAsync } from '../requests';
import {
  gerarRelatorioTiposCobrancasErro,
  gerarRelatorioTiposCobrancasSucesso,
  obterTiposCobrancasErro,
  obterTiposCobrancasSucesso,
} from './actions';
import { SET_LIMIT_PAGE, SET_TABLE_ORDER } from '../../../redux/table/constants';
import { montarFiltroParaApi } from '../../../utils/api-filter';
import { DEFAULT_DOWNLOAD_SUCCESS_MESSAGE, gerarRelatorioRacing } from '../../../services/requests/relatorio';

function* listarTodosTiposCobrancas() {
  try {
    const filtro = yield select((state) => state.TiposCobrancas.filtros);
    const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.TiposCobrancas]);

    let query = { filtro: montarFiltroParaApi(filtro) };

    if (preferencias) {
      query.ordenacao = {
        campo: preferencias?.ordenarPor || 'nome',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      };
      query.paginacao = {
        pagina: preferencias?.page || 1,
        limite: preferencias?.limit || 50,
      };
    }

    const { data } = yield call(obterTiposCobrancasAsync, query);
    yield put(obterTiposCobrancasSucesso(data));
  } catch (error) {
    yield put(obterTiposCobrancasErro(error.response?.data?.message || error.message));
  }
}

function* relatorioTiposCobrancas() {
  const { filtros, relatorio } = yield select((state) => state.TiposCobrancas);
  const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.TiposCobrancas]);
  const { modelo, tipo, comFiltro, colunas } = relatorio;
  const columns = colunas?.map((coluna) => coluna.value);
  let query = {};

  if (comFiltro) {
    query = {
      filtro: {
        ...montarFiltroParaApi(filtros),
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
      ordenacao: {
        campo: preferencias?.ordenarPor || 'nome',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      },
    };
  } else {
    query = {
      filtro: {
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
    };
  }

  try {
    const { data, headers } = yield call(gerarRelatorioRacing, ProgramaCodigo.TiposCobrancas, query, tipo);

    if (headers['content-type'].includes('application/json')) {
      yield put(
        gerarRelatorioTiposCobrancasSucesso({
          titulo: 'Pedido de impressão salvo.',
          mensagem: 'Seu relatório está sendo gerado.',
        }),
      );
    } else {
      const url = URL.createObjectURL(
        new Blob([data], {
          type: headers['content-type'] || 'application/pdf',
        }),
      );

      if (url) {
        yield put(gerarRelatorioTiposCobrancasSucesso(DEFAULT_DOWNLOAD_SUCCESS_MESSAGE));
        window.open(url, '_blank');
      }
    }
  } catch (e) {
    yield put(
      gerarRelatorioTiposCobrancasErro('Ocorreu um erro ao tentar gerar o relatório. Tente novamente mais tarde.'),
    );
  }
}

function* ordenarTiposCobrancas({ payload }) {
  if (payload.programaId === ProgramaCodigo.TiposCobrancas) {
    yield call(listarTodosTiposCobrancas);
  }
}

function* mudarLimitePaginacao({ payload }) {
  if (payload.programaId === ProgramaCodigo.TiposCobrancas) {
    yield call(listarTodosTiposCobrancas);
  }
}

export function* watchListarTiposCobrancas() {
  yield takeLatest(LISTAR_TIPOS_COBRANCAS, listarTodosTiposCobrancas);
}

export function* watchFiltrarTiposCobrancas() {
  yield takeLatest(FILTRAR_TIPOS_COBRANCAS, listarTodosTiposCobrancas);
}

export function* watchRelatorioTiposCobrancas() {
  yield takeLatest(GERAR_RELATORIO_TIPOS_COBRANCAS, relatorioTiposCobrancas);
}

export function* watchOrdenarTiposCobrancas() {
  yield takeLatest(SET_TABLE_ORDER, ordenarTiposCobrancas);
}

export function* watchLimitePaginacao() {
  yield takeLatest(SET_LIMIT_PAGE, mudarLimitePaginacao);
}

function* tiposCobrancasSaga() {
  yield all([
    fork(watchListarTiposCobrancas),
    fork(watchFiltrarTiposCobrancas),
    fork(watchRelatorioTiposCobrancas),
    fork(watchOrdenarTiposCobrancas),
    fork(watchLimitePaginacao),
  ]);
}

export default tiposCobrancasSaga;
