import {
  FILTRAR_MOEDAS,
  GERAR_RELATORIO_MOEDAS,
  GERAR_RELATORIO_MOEDAS_ERRO,
  GERAR_RELATORIO_MOEDAS_SUCESSO,
  LISTAR_MOEDAS,
  LISTAR_MOEDAS_ERRO,
  LISTAR_MOEDAS_SUCESSO,
} from './constants';

export const obterTodosMoedas = () => ({
  type: LISTAR_MOEDAS,
});

export const obterMoedasSucesso = (Moedas) => ({
  type: LISTAR_MOEDAS_SUCESSO,
  payload: Moedas,
});

export const obterMoedasErro = (mensagem) => ({
  type: LISTAR_MOEDAS_ERRO,
  payload: mensagem,
});

export const filtrarMoedas = (filtros) => ({
  type: FILTRAR_MOEDAS,
  payload: filtros,
});

export const gerarRelatorioMoedas = (payload) => ({
  type: GERAR_RELATORIO_MOEDAS,
  payload: payload,
});

export const gerarRelatorioMoedasSucesso = (alert) => ({
  type: GERAR_RELATORIO_MOEDAS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioMoedasErro = (mensagem) => ({
  type: GERAR_RELATORIO_MOEDAS_ERRO,
  payload: mensagem,
});
