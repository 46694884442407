import { 
  LISTAR_MODULOS_SUCESSO,
  GERAR_RELATORIO_MODULOS,
  GERAR_RELATORIO_MODULOS_ERRO,
  GERAR_RELATORIO_MODULOS_SUCESSO,
  LISTAR_MODULOS_ERRO, 
  LISTAR_MODULOS, 
  FILTRAR_MODULOS } from './constants';

export const obterTodosModulos = () => ({
  type: LISTAR_MODULOS,
});

export const obterModulosSucesso = (modulos) => ({
  type: LISTAR_MODULOS_SUCESSO,
  payload: modulos,
});

export const obterModulosErro = (mensagem) => ({
  type: LISTAR_MODULOS_ERRO,
  payload: mensagem,
});

export const filtrarModulos = (filtros) => ({
  type: FILTRAR_MODULOS,
  payload: filtros,
});

export const gerarRelatorioModulos = (payload) => ({
  type: GERAR_RELATORIO_MODULOS,
  payload: payload,
});

export const gerarRelatorioModulosSucesso = (alert) => ({
  type: GERAR_RELATORIO_MODULOS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioModulosErro = (mensagem) => ({
  type: GERAR_RELATORIO_MODULOS_ERRO,
  payload: mensagem,
});
