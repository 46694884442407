import api from '../../services/api';
import { AxiosResponse } from 'axios';
import { ISetorMesaCadastro, ISetorMesaConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterSetoresMesasAsync = (
  query,
): Promise<
  AxiosResponse<{ result: ISetorMesaConsulta[]; total: number }>
> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `setores-mesas${queryStr}`,
    method: 'GET',
  });
};

export const obterSetorMesaAsync = (
  id: number,
): Promise<AxiosResponse<ISetorMesaConsulta>> => {
  return api.request({
    url: `setores-mesas/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoSetorMesaAsync = (): Promise<
  AxiosResponse<number>
> => {
  return api.request({
    url: `setores-mesas/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarSetoresMesasAsync = (
  setorMesa: ISetorMesaCadastro,
): Promise<AxiosResponse<ISetorMesaConsulta>> => {
  return api.request({
    url: `setores-mesas`,
    data: setorMesa,
    method: 'POST',
  });
};

export const atualizarSetoresMesasAsync = (
  id: number,
  setorMesa: ISetorMesaCadastro,
): Promise<AxiosResponse<ISetorMesaConsulta>> => {
  return api.request({
    url: `setores-mesas/${id}`,
    data: setorMesa,
    method: 'PATCH',
  });
};

export const removerSetoresMesasEmMassaAsync = (
  ids: number[],
): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `setores-mesas`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerSetoresMesasAsync = (
  id: number,
): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `setores-mesas/${id}`,
    method: 'DELETE',
  });
