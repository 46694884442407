import * as ReactIcons from 'react-icons/ai';
import { ISubMenu } from '../../../interfaces/menu';
import { PermissaoCodigo } from '../../enums/permissao-codigo.enum';
import { ProgramaCodigo } from '../../enums/programa-codigo.enum';

export const CadastrosVeiculos: ISubMenu = {
  name: 'Veículos',
  icon: ReactIcons.AiFillCar,
  children: [
    {
      programaCod: ProgramaCodigo.Veiculos,
      name: 'Veículos',
      style: {
        fontWeight: 'bold',
      },
      path: '/veiculos/listar',
      component: 'listarVeiculos',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/veiculos/listar',
          component: 'listarVeiculos',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/veiculos/criar',
          component: 'veiculos',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/veiculos/editar/:id',
          component: 'veiculos',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/veiculos/visualizar/:id',
          component: 'veiculos',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.MarcasVeiculos,
      name: 'Marcas de Veículos',
      path: '/marcas-veiculos/listar',
      component: 'listarMarcasVeiculos',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/marcas-veiculos/listar',
          component: 'listarMarcasVeiculos',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/marcas-veiculos/criar',
          component: 'marcasVeiculos',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/marcas-veiculos/editar/:id',
          component: 'marcasVeiculos',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/marcas-veiculos/visualizar/:id',
          component: 'marcasVeiculos',
        },
      ],
    },
  ],
};
