import { LISTAR_PESSOAS_SUCESSO, 
  GERAR_RELATORIO_PESSOAS,
  GERAR_RELATORIO_PESSOAS_ERRO,
  GERAR_RELATORIO_PESSOAS_SUCESSO,
  LISTAR_PESSOAS_ERRO, 
  LISTAR_PESSOAS, 
  FILTRAR_PESSOAS } from './constants';

export const obterTodasPessoas = () => ({
  type: LISTAR_PESSOAS,
});

export const obterTodasPessoasSucesso = (segmento) => ({
  type: LISTAR_PESSOAS_SUCESSO,
  payload: segmento,
});

export const obterTodasPessoasErro = (mensagem) => ({
  type: LISTAR_PESSOAS_ERRO,
  payload: mensagem,
});

export const filtrarPessoas = (f) => {
  return {
    type: FILTRAR_PESSOAS,
    payload: f,
  };
};

export const gerarRelatorioTodasPessoas = (payload) => ({
  type: GERAR_RELATORIO_PESSOAS,
  payload: payload,
});

export const gerarRelatorioTodasPessoasSucesso = (alert) => ({
  type: GERAR_RELATORIO_PESSOAS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioTodasPessoasErro = (mensagem) => ({
  type: GERAR_RELATORIO_PESSOAS_ERRO,
  payload: mensagem,
});