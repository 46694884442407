/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_FAILED = 'FORGET_PASSWORD_FAILED';
//--
export const UPDATE_USER = 'UPDATE_USER';

export const OBTER_ORGANIZACOES_AUTORIZADAS = 'OBTER_ORGANIZACOES_AUTORIZADAS';
export const SETAR_ORGANIZACOES_AUTORIZADAS = 'SETAR_ORGANIZACOES_AUTORIZADAS';
export const SELECIONAR_ORGANIZACAO = 'SELECIONAR_ORGANIZACAO';

export const OBTER_SEGMENTOS_AUTORIZADOS = 'OBTER_SEGMENTOS_AUTORIZADOS';
export const SETAR_SEGMENTOS_AUTORIZADOS = 'SETAR_SEGMENTOS_AUTORIZADOS';
export const SELECIONAR_SEGMENTO = 'SELECIONAR_SEGMENTO';

export const OBTER_EMPRESAS_AUTORIZADAS = 'OBTER_EMPRESAS_AUTORIZADAS';
export const SETAR_EMPRESAS_AUTORIZADAS = 'SETAR_EMPRESAS_AUTORIZADAS';
export const SELECIONAR_EMPRESA = 'SELECIONAR_EMPRESA';
export const OBTER_MODULOS_PERMISSOES = 'OBTER_MODULOS_PERMISSOES';
export const SETAR_PROGRAMAS_PERMISSOES = 'SETAR_PROGRAMAS_PERMISSOES';

export const PRIMEIRO_ACESSO_TROCAR_SENHA = 'PRIMEIRO_ACESSO_TROCAR_SENHA';
export const SELECIONAR_SOLUCAO_ID = 'SELECIONAR_SOLUCAO_ID';

export const OBTER_PARAMETROS_REDUX = 'OBTER_PARAMETROS_REDUX';
export const SETAR_PARAMETROS_REDUX = 'SETAR_PARAMETROS_REDUX';
