import { AxiosResponse } from 'axios';

import api from '../../services/api';
import { IRelatorioConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterRelatoriosAsync = (
  query = {},
  programaCod,
): Promise<AxiosResponse<{ result: IRelatorioConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `relatorios/${programaCod}${queryStr}`,
    method: 'GET',
  });
};

export const obterRelatorioAsync = (id: number): Promise<AxiosResponse<IRelatorioConsulta>> => {
  return api.request({
    url: `relatorios/${id}`,
    method: 'GET',
  });
};
