import {
  FILTRAR_TRIBUTACOES_FEDERAIS_IPI,
  LISTAR_TRIBUTACOES_FEDERAIS_IPI,
  LISTAR_TRIBUTACOES_FEDERAIS_IPI_ERRO,
  LISTAR_TRIBUTACOES_FEDERAIS_IPI_SUCESSO,
} from './constants';

export const obterTodosTributacoesFederaisIpi = () => ({
  type: LISTAR_TRIBUTACOES_FEDERAIS_IPI,
});

export const obterTributacoesFederaisIpiSucesso = (tributacoesFederaisIpi) => ({
  type: LISTAR_TRIBUTACOES_FEDERAIS_IPI_SUCESSO,
  payload: tributacoesFederaisIpi,
});

export const obterTributacoesFederaisIpiErro = (mensagem) => ({
  type: LISTAR_TRIBUTACOES_FEDERAIS_IPI_ERRO,
  payload: mensagem,
});

export const filtrarTributacoesFederaisIpi = (filtros) => ({
  type: FILTRAR_TRIBUTACOES_FEDERAIS_IPI,
  payload: filtros,
});
