import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { ProgramaCodigo } from '../../../constants/enums/programa-codigo.enum';
import { FILTRAR_TRIBUTACOES_FEDERAIS_IPI, LISTAR_TRIBUTACOES_FEDERAIS_IPI } from './constants';
import { obterTributacoesFederaisIpiAsync } from '../requests';
import { obterTributacoesFederaisIpiErro, obterTributacoesFederaisIpiSucesso } from './actions';
import { SET_LIMIT_PAGE, SET_TABLE_ORDER } from '../../../redux/table/constants';
import { montarFiltroParaApi } from '../../../utils/api-filter';

function* listarTodosTributacoesFederaisIpi() {
  try {
    const filtro = yield select((state) => state.TributacoesFederaisIpi.filtros);
    const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.TributacoesFederaisIpi]);

    let query = { filtro: montarFiltroParaApi(filtro) };

    if (preferencias) {
      query.ordenacao = {
        campo: preferencias?.ordenarPor || 'nome',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      };
      query.paginacao = {
        pagina: preferencias?.page || 1,
        limite: preferencias?.limit || 50,
      };
    }

    const { data } = yield call(obterTributacoesFederaisIpiAsync, query);
    yield put(obterTributacoesFederaisIpiSucesso(data));
  } catch (error) {
    yield put(obterTributacoesFederaisIpiErro(error.response?.data?.message || error.message));
  }
}

function* ordenarTributacoesFederais({ payload }) {
  if (payload.programaId === ProgramaCodigo.TributacoesFederaisIpi) {
    yield call(listarTodosTributacoesFederaisIpi);
  }
}

function* mudarLimitePaginacao({ payload }) {
  if (payload.programaId === ProgramaCodigo.TributacoesFederaisIpi) {
    yield call(listarTodosTributacoesFederaisIpi);
  }
}

export function* watchListarTributacoesFederais() {
  yield takeLatest(LISTAR_TRIBUTACOES_FEDERAIS_IPI, listarTodosTributacoesFederaisIpi);
}

export function* watchFiltrarTributacoesFederais() {
  yield takeLatest(FILTRAR_TRIBUTACOES_FEDERAIS_IPI, listarTodosTributacoesFederaisIpi);
}

export function* watchOrdenarTributacoesFederais() {
  yield takeLatest(SET_TABLE_ORDER, ordenarTributacoesFederais);
}

export function* watchLimitePaginacao() {
  yield takeLatest(SET_LIMIT_PAGE, mudarLimitePaginacao);
}

function* tributacoesFederaisIpiSaga() {
  yield all([fork(watchListarTributacoesFederais), fork(watchFiltrarTributacoesFederais), fork(watchOrdenarTributacoesFederais), fork(watchLimitePaginacao)]);
}

export default tributacoesFederaisIpiSaga;
