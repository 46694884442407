import { IMenu } from '../../interfaces/menu';
import { PermissaoCodigo } from '../enums/permissao-codigo.enum';
import { ProgramaCodigo } from '../enums/programa-codigo.enum';
import { RoleCodigo } from '../enums/role-codigo.enum';
import { FileText } from 'react-feather';

export const EstoqueMenus: IMenu = {
  roles: [RoleCodigo.ClienteUser, RoleCodigo.ClienteAdmin],
  name: 'Estoque',
  children: [
    {
      programaCod: ProgramaCodigo.EntradasSaidasProdutos,
      name: 'Entradas e Saídas de Produtos',
      path: '/entradas-saidas-produtos/listar',
      component: 'listarEntradaSaidaProdutos',
      style: {
        fontWeight: 'bold',
      },
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/entradas-saidas-produtos/listar',
          component: 'listarEntradaSaidaProdutos',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/entradas-saidas-produtos/criar',
          component: 'entradaSaidaProdutos',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/entradas-saidas-produtos/editar/:id',
          component: 'entradaSaidaProdutos',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/entradas-saidas-produtos/visualizar/:id',
          component: 'entradaSaidaProdutos',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.MovimentacoesProdutos,
      name: 'Movimentações de Produtos',
      path: '/movimentacao-produtos/listar',
      component: 'listarMovimentacoesProdutos',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/movimentacao-produtos/listar',
          component: 'listarMovimentacoesProdutos',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/movimentacao-produtos/visualizar/:id',
          component: 'movimentacoesProdutos',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.TransferenciasDepositos,
      name: 'Transferências entre Depósitos',
      path: '/transferencia-depositos/listar',
      component: 'listarTransferenciaDepositos',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/transferencia-depositos/listar',
          component: 'listarTransferenciaDepositos',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/transferencia-depositos/criar',
          component: 'transferenciaDepositos',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/transferencia-depositos/editar/:id',
          component: 'transferenciaDepositos',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/transferencia-depositos/visualizar/:id',
          component: 'transferenciaDepositos',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.RelatoriosEstoque,
      name: 'Relatórios',
      path: '/estoque/relatorios',
      icon: FileText,
      component: 'relatoriosEstoque',
    },
  ],
};
