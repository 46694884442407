import {
  FILTRAR_CAIXAS_BANCOS,
  GERAR_RELATORIO_CAIXAS_BANCOS,
  GERAR_RELATORIO_CAIXAS_BANCOS_ERRO,
  GERAR_RELATORIO_CAIXAS_BANCOS_SUCESSO,
  LISTAR_CAIXAS_BANCOS,
  LISTAR_CAIXAS_BANCOS_ERRO,
  LISTAR_CAIXAS_BANCOS_SUCESSO,
} from './constants';

export const obterTodosCaixasBancos = () => ({
  type: LISTAR_CAIXAS_BANCOS,
});

export const obterCaixasBancosSucesso = (caixasBancos) => ({
  type: LISTAR_CAIXAS_BANCOS_SUCESSO,
  payload: caixasBancos,
});

export const obterCaixasBancosErro = (mensagem) => ({
  type: LISTAR_CAIXAS_BANCOS_ERRO,
  payload: mensagem,
});

export const filtrarCaixasBancos = (filtros) => ({
  type: FILTRAR_CAIXAS_BANCOS,
  payload: filtros,
});

export const gerarRelatorioCaixasBancos = (payload) => ({
  type: GERAR_RELATORIO_CAIXAS_BANCOS,
  payload: payload,
});

export const gerarRelatorioCaixasBancosSucesso = (alert) => ({
  type: GERAR_RELATORIO_CAIXAS_BANCOS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioCaixasBancosErro = (mensagem) => ({
  type: GERAR_RELATORIO_CAIXAS_BANCOS_ERRO,
  payload: mensagem,
});
