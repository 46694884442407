import * as FeatherIcon from 'react-feather';
import { ISubMenu } from '../../../interfaces/menu';
import { PermissaoCodigo } from '../../enums/permissao-codigo.enum';
import { ProgramaCodigo } from '../../enums/programa-codigo.enum';

export const CadastrosFinanceiros: ISubMenu = {
  name: 'Financeiro',
  icon: FeatherIcon.DollarSign,
  children: [
    {
      programaCod: ProgramaCodigo.Contas,
      name: 'Contas',
      path: '/contas/listar',
      component: 'listarContas',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/contas/listar',
          component: 'listarContas',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/contas/criar',
          component: 'contas',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/contas/editar/:id',
          component: 'contas',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/contas/visualizar/:id',
          component: 'contas',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.Cotacoes,
      name: 'Cotações',
      path: '/cotacoes/listar',
      component: 'listarCotacoes',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/cotacoes/listar',
          component: 'listarCotacoes',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/cotacoes/criar',
          component: 'cotacoes',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/cotacoes/editar/:id',
          component: 'cotacoes',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/cotacoes/visualizar/:id',
          component: 'cotacoes',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.FormasPagamentos,
      name: 'Formas de Pagamentos',
      path: '/formas-pagamentos/listar',
      component: 'listarFormasPagamentos',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/formas-pagamentos/listar',
          component: 'listarFormasPagamentos',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/formas-pagamentos/criar',
          component: 'formasPagamentos',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/formas-pagamentos/editar/:id',
          component: 'formasPagamentos',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/formas-pagamentos/visualizar/:id',
          component: 'formasPagamentos',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.GruposFinanceiros,
      name: 'Grupos Financeiros',
      path: '/grupos-financeiros/listar',
      component: 'listarGruposFinanceiros',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/grupos-financeiros/listar',
          component: 'listarGruposFinanceiros',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/grupos-financeiros/criar',
          component: 'gruposFinanceiros',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/grupos-financeiros/editar/:id',
          component: 'gruposFinanceiros',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/grupos-financeiros/visualizar/:id',
          component: 'gruposFinanceiros',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.Moedas,
      name: 'Moedas',
      path: '/moedas/listar',
      component: 'listarMoedas',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/moedas/listar',
          component: 'listarMoedas',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/moedas/criar',
          component: 'moedas',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/moedas/editar/:id',
          component: 'moedas',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/moedas/visualizar/:id',
          component: 'moedas',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.MotivosCancelamentosContratos,
      name: ' Motivos de Cancelamento de Contratos',
      path: '/motivos-cancelamentos-contratos/listar',
      component: 'listarMotivosCancelamentosContratos',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/motivos-cancelamentos-contratos/listar',
          component: 'listarMotivosCancelamentosContratos',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/motivos-cancelamentos-contratos/criar',
          component: 'motivosCancelamentosContratos',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/motivos-cancelamentos-contratos/editar/:id',
          component: 'motivosCancelamentosContratos',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/motivos-cancelamentos-contratos/visualizar/:id',
          component: 'motivosCancelamentosContratos',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.OcorrenciasFinanceiras,
      name: 'Tipos de Ocorrências Financeiras',
      path: '/ocorrencias-financeiras/listar',
      component: 'listarOcorrenciasFinanceiras',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/ocorrencias-financeiras/listar',
          component: 'listarOcorrenciasFinanceiras',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/ocorrencias-financeiras/criar',
          component: 'ocorrenciasFinanceiras',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/ocorrencias-financeiras/editar/:id',
          component: 'ocorrenciasFinanceiras',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/ocorrencias-financeiras/visualizar/:id',
          component: 'ocorrenciasFinanceiras',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.SubGruposFinanceiros,
      name: 'Subgrupos Financeiros',
      path: '/sub-grupos-financeiros/listar',
      component: 'listarSubGruposFinanceiros',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/sub-grupos-financeiros/listar',
          component: 'listarSubGruposFinanceiros',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/sub-grupos-financeiros/criar',
          component: 'subGruposFinanceiros',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/sub-grupos-financeiros/editar/:id',
          component: 'subGruposFinanceiros',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/sub-grupos-financeiros/visualizar/:id',
          component: 'subGruposFinanceiros',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.TiposCobrancas,
      name: 'Tipos de Cobrança',
      path: '/tipos-cobrancas/listar',
      component: 'listarTiposCobrancas',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/tipos-cobrancas/listar',
          component: 'listarTiposCobrancas',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/tipos-cobrancas/criar',
          component: 'tiposCobrancas',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/tipos-cobrancas/editar/:id',
          component: 'tiposCobrancas',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/tipos-cobrancas/visualizar/:id',
          component: 'tiposCobrancas',
        },
      ],
    },
  ],
};
