import { AxiosResponse } from 'axios';

import api from '../../services/api';
import { IObservacaoAtividadeCadastro, IObservacaoAtividadeConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterObservacoesAtividadesAsync = (
  query
): Promise<AxiosResponse<{ result: IObservacaoAtividadeConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `observacoes-atividades${queryStr}`,
    method: 'GET',
  });
};

export const obterObservacaoAtividadeAsync = (id: number): Promise<AxiosResponse<IObservacaoAtividadeConsulta>> => {
  return api.request({
    url: `observacoes-atividades/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoObservacaoAtividadeAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `observacoes-atividades/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarObservacaoAtividadeAsync = (
  ObservacaoAtividade: IObservacaoAtividadeCadastro
): Promise<AxiosResponse<IObservacaoAtividadeConsulta>> => {
  return api.request({
    url: `observacoes-atividades`,
    data: ObservacaoAtividade,
    method: 'POST',
  });
};

export const atualizarObservacaoAtividadeAsync = (
  id: number,
  ObservacaoAtividade: IObservacaoAtividadeCadastro
): Promise<AxiosResponse<IObservacaoAtividadeConsulta>> => {
  return api.request({
    url: `observacoes-atividades/${id}`,
    data: ObservacaoAtividade,
    method: 'PATCH',
  });
};

export const removerObservacoesAtividadesEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `observacoes-atividades`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerObservacaoAtividadeAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `observacoes-atividades/${id}`,
    method: 'DELETE',
  });
