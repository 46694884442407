import { AxiosResponse } from 'axios';

import api from '../../services/api';
import {
  IGrupoEconomicoPessoaCadastro,
  IGrupoEconomicoPessoaConsulta,
} from './interfaces';
import { montarQueryString } from '../../utils';

export const obterGruposEconomicosPessoasAsync = (
  query,
): Promise<
  AxiosResponse<{ result: IGrupoEconomicoPessoaConsulta[]; total: number }>
> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `grupos-economicos-pessoas${queryStr}`,
    method: 'GET',
  });
};

export const obterGrupoEconomicoPessoaAsync = (
  id: number,
): Promise<AxiosResponse<IGrupoEconomicoPessoaConsulta>> => {
  return api.request({
    url: `grupos-economicos-pessoas/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoGrupoEconomicoPessoaAsync = (): Promise<
  AxiosResponse<number>
> => {
  return api.request({
    url: `grupos-economicos-pessoas/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarGrupoEconomicoPessoaAsync = (
  grupoEconomicoPessoa: IGrupoEconomicoPessoaCadastro,
): Promise<AxiosResponse<IGrupoEconomicoPessoaConsulta>> => {
  return api.request({
    url: `grupos-economicos-pessoas`,
    data: grupoEconomicoPessoa,
    method: 'POST',
  });
};

export const atualizarGrupoEconomicoPessoaAsync = (
  id: number,
  grupoEconomicoPessoa: IGrupoEconomicoPessoaCadastro,
): Promise<AxiosResponse<IGrupoEconomicoPessoaConsulta>> => {
  return api.request({
    url: `grupos-economicos-pessoas/${id}`,
    data: grupoEconomicoPessoa,
    method: 'PATCH',
  });
};

export const removerGruposEconomicosPessoasEmMassaAsync = (
  ids: number[],
): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `grupos-economicos-pessoas`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerGrupoEconomicoPessoaAsync = (
  id: number,
): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `grupos-economicos-pessoas/${id}`,
    method: 'DELETE',
  });
