import { AxiosResponse } from 'axios';

import api from '../../services/api';
import { IDepartamentoProdutoCadastro, IDepartamentoProdutoConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterDepartamentosProdutosAsync = (
  query
): Promise<AxiosResponse<{ result: IDepartamentoProdutoConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `departamentos-produtos${queryStr}`,
    method: 'GET',
  });
};

export const obterDepartamentoProdutoAsync = (id: number): Promise<AxiosResponse<IDepartamentoProdutoConsulta>> => {
  return api.request({
    url: `departamentos-produtos/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoDepartamentoProdutoAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `departamentos-produtos/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarDepartamentoProdutoAsync = (
  sistemaProducao: IDepartamentoProdutoCadastro
): Promise<AxiosResponse<IDepartamentoProdutoConsulta>> => {
  return api.request({
    url: `departamentos-produtos`,
    data: sistemaProducao,
    method: 'POST',
  });
};

export const atualizarDepartamentoProdutoAsync = (
  id: number,
  sistemaProducao: IDepartamentoProdutoCadastro
): Promise<AxiosResponse<IDepartamentoProdutoConsulta>> => {
  return api.request({
    url: `departamentos-produtos/${id}`,
    data: sistemaProducao,
    method: 'PATCH',
  });
};

export const removerDepartamentoProdutoEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `departamentos-produtos`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerDepartamentoProdutoAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `departamentos-produtos/${id}`,
    method: 'DELETE',
  });
