import { IMenu } from '../../interfaces/menu';
import { PermissaoCodigo } from '../enums/permissao-codigo.enum';
import { ProgramaCodigo } from '../enums/programa-codigo.enum';
import { RoleCodigo } from '../enums/role-codigo.enum';

export const ComprasMenus: IMenu = {
  roles: [RoleCodigo.ClienteUser, RoleCodigo.ClienteAdmin],
  name: 'Compras',
  children: [
    {
      programaCod: ProgramaCodigo.NotasEntradas,
      name: 'Notas de Entrada',
      path: '/notas-entradas/listar',
      component: 'listarNotasEntradas',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/notas-entradas/listar',
          component: 'listarNotasEntradas',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/notas-entradas/criar',
          component: 'notasEntradas',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/notas-entradas/editar/:id',
          component: 'notasEntradas',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/notas-entradas/visualizar/:id',
          component: 'notasEntradas',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.OrdensCompra,
      name: 'Ordens de Compra',
      path: '/ordens-compra/listar',
      component: 'listarOrdensCompra',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/ordens-compra/listar',
          component: 'listarOrdensCompra',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/ordens-compra/criar',
          component: 'ordensCompra',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/ordens-compra/editar/:id',
          component: 'ordensCompra',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/ordens-compra/visualizar/:id',
          component: 'ordensCompra',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/ordens-compra/imprimir/:id',
          component: 'ordensCompraImprimir',
        },
      ],
    },
  ],
};
