import {
  FILTRAR_OCORRENCIAS_FINANCEIRAS,
  GERAR_RELATORIO_OCORRENCIAS_FINANCEIRAS,
  GERAR_RELATORIO_OCORRENCIAS_FINANCEIRAS_ERRO,
  GERAR_RELATORIO_OCORRENCIAS_FINANCEIRAS_SUCESSO,
  LISTAR_OCORRENCIAS_FINANCEIRAS,
  LISTAR_OCORRENCIAS_FINANCEIRAS_ERRO,
  LISTAR_OCORRENCIAS_FINANCEIRAS_SUCESSO,
} from './constants';

export const obterTodosOcorrenciasFinanceiras = () => ({
  type: LISTAR_OCORRENCIAS_FINANCEIRAS,
});

export const obterOcorrenciasFinanceirasSucesso = (ocorrenciasFinanceiras) => ({
  type: LISTAR_OCORRENCIAS_FINANCEIRAS_SUCESSO,
  payload: ocorrenciasFinanceiras,
});

export const obterOcorrenciasFinanceirasErro = (mensagem) => ({
  type: LISTAR_OCORRENCIAS_FINANCEIRAS_ERRO,
  payload: mensagem,
});

export const filtrarOcorrenciasFinanceiras = (filtros) => ({
  type: FILTRAR_OCORRENCIAS_FINANCEIRAS,
  payload: filtros,
});

export const gerarRelatorioOcorrenciasFinanceiras = (payload) => ({
  type: GERAR_RELATORIO_OCORRENCIAS_FINANCEIRAS,
  payload: payload,
});

export const gerarRelatorioOcorrenciasFinanceirasSucesso = (alert) => ({
  type: GERAR_RELATORIO_OCORRENCIAS_FINANCEIRAS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioOcorrenciasFinanceirasErro = (mensagem) => ({
  type: GERAR_RELATORIO_OCORRENCIAS_FINANCEIRAS_ERRO,
  payload: mensagem,
});