import {
  FILTRAR_DANOS_DEFEITOS,
  GERAR_RELATORIO_DANOS_DEFEITOS,
  GERAR_RELATORIO_DANOS_DEFEITOS_ERRO,
  GERAR_RELATORIO_DANOS_DEFEITOS_SUCESSO,
  LISTAR_DANOS_DEFEITOS,
  LISTAR_DANOS_DEFEITOS_ERRO,
  LISTAR_DANOS_DEFEITOS_SUCESSO,
} from './constants';

export const obterTodosDanosDefeitos = () => ({
  type: LISTAR_DANOS_DEFEITOS,
});

export const obterDanosDefeitosSucesso = (danosDefeitos) => ({
  type: LISTAR_DANOS_DEFEITOS_SUCESSO,
  payload: danosDefeitos,
});

export const obterDanosDefeitosErro = (mensagem) => ({
  type: LISTAR_DANOS_DEFEITOS_ERRO,
  payload: mensagem,
});

export const filtrarDanosDefeitos = (filtros) => ({
  type: FILTRAR_DANOS_DEFEITOS,
  payload: filtros,
});

export const gerarRelatorioDanosDefeitos = (payload) => ({
  type: GERAR_RELATORIO_DANOS_DEFEITOS,
  payload: payload,
});

export const gerarRelatorioDanosDefeitosSucesso = (alert) => ({
  type: GERAR_RELATORIO_DANOS_DEFEITOS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioDanosDefeitosErro = (mensagem) => ({
  type: GERAR_RELATORIO_DANOS_DEFEITOS_ERRO,
  payload: mensagem,
});
