import { IMenu } from '../../interfaces/menu';
import { PermissaoCodigo } from '../enums/permissao-codigo.enum';
import { ProgramaCodigo } from '../enums/programa-codigo.enum';
import { RoleCodigo } from '../enums/role-codigo.enum';

export const VendasMenus: IMenu = {
  roles: [RoleCodigo.ClienteUser, RoleCodigo.ClienteAdmin, RoleCodigo.SolucaoTec],
  name: 'Vendas',
  children: [
    {
      programaCod: ProgramaCodigo.CodigosBarrasEAN,
      name: 'Códigos de Barras EAN',
      path: '/codigos-barras-ean/listar',
      component: 'listarCodigosBarrasEAN',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/codigos-barras-ean/listar',
          component: 'listarCodigosBarrasEAN',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/codigos-barras-ean/criar',
          component: 'codigosBarrasEAN',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/codigos-barras-ean/editar/:id',
          component: 'codigosBarrasEAN',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/codigos-barras-ean/visualizar/:id',
          component: 'codigosBarrasEAN',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.ModelosEtiquetas,
      name: 'Modelos de Etiquetas',
      path: '/modelo-etiqueta/listar',
      component: 'listarModeloEtiqueta',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/modelo-etiqueta/listar',
          component: 'listarModeloEtiqueta',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/modelo-etiqueta/criar',
          component: 'modeloEtiqueta',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/modelo-etiqueta/editar/:id',
          component: 'modeloEtiqueta',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/modelo-etiqueta/visualizar/:id',
          component: 'modeloEtiqueta',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/modelo-etiqueta/replicar/:id',
          component: 'modeloEtiqueta',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.Orcamentos,
      name: 'Orçamentos',
      path: '/orcamentos/listar',
      component: 'listarOrcamentos',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/orcamentos/listar',
          component: 'listarOrcamentos',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/orcamentos/criar',
          component: 'orcamentos',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/faturamento',
          component: 'faturar',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/desistencia',
          component: 'desistencia',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/orcamentos/editar/:id',
          component: 'orcamentos',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/orcamentos/visualizar/:id',
          component: 'orcamentos',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/orcamentos/replicar/:id',
          component: 'orcamentos',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/orcamentos/imprimir/:id',
          component: 'orcamentosImprimir',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.Pedidos,
      name: 'Pedidos',
      path: '/pedidos/listar',
      component: 'listarPedidos',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/pedidos/listar',
          component: 'listarPedidos',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/pedidos/criar',
          component: 'pedidos',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/pedidos/editar/:id',
          component: 'pedidos',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/pedidos/visualizar/:id',
          component: 'pedidos',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/pedidos/replicar/:id',
          component: 'pedidos',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/pedidos/imprimir/:id',
          component: 'pedidosImprimir',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.VendasColheitas,
      name: 'Vendas Colheitas',
      path: '/vendas-colheitas/listar',
      component: 'listarVendasColheitas',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/vendas-colheitas/listar',
          component: 'listarVendasColheitas',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/vendas-colheitas/criar',
          component: 'VendasColheitas',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/vendas-colheitas/editar/:id',
          component: 'VendasColheitas',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/vendas-colheitas/visualizar/:id',
          component: 'VendasColheitas',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/vendas-colheitas/etiqueta-personalizada/:lancamentoAtividadeId/:colheitaId',
          component: 'etiquetaPersonalizadaLancamentosAtividades',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/vendas-colheitas/etiqueta-personalizada/:vendaColheitaId',
          component: 'etiquetaPersonalizadaLancamentosAtividades',
        },
      ],
    },
  ],
};
