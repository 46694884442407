import styled, { css } from 'styled-components'

type ButtonProps = {
  isActive?: boolean;
}
 
export const Container = styled.div`
  width: 380px;
`

export const Button = styled.button<ButtonProps>`
  display: block;
  width: fit-content;
  padding: 0.5rem 1rem;
  color: #fff;
  border: none;
  outline: none;
  width: 100%;
  border-radius: 0.3rem;
  background-color: var(--primary); 
  margin-top: 1rem;
  padding: 0.75rem 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;

  ${({ isActive }) => !isActive && css`
    opacity: 0.5;
    cursor: not-allowed;
  `}
`

export const HelpMessage = styled.p`
  margin-top: 8px;
`