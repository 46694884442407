import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { ProgramaCodigo } from '../../../constants/enums/programa-codigo.enum';
import { SET_LIMIT_PAGE, SET_TABLE_ORDER } from '../../../redux/table/constants';
import { FILTRAR_SAFRAS, LISTAR_SAFRAS } from './constants';
import { obterSafrasAsync } from '../requests';
import { obterSafrasErro, obterSafrasSucesso } from './actions';
import { montarFiltroParaApi } from '../../../utils/api-filter';

function* listarTodosSafras() {
  try {
    const filtro = yield select((state) => state.Safras.filtros);
    const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.Safras]);

    let query = { filtro: montarFiltroParaApi(filtro) };

    if (preferencias) {
      query.ordenacao = {
        campo: preferencias?.ordenarPor || 'nome',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      };
      query.paginacao = {
        pagina: preferencias?.page || 1,
        limite: preferencias?.limit || 50,
      };
    }

    const { data } = yield call(obterSafrasAsync, query);
    yield put(obterSafrasSucesso(data));
  } catch (error) {
    yield put(obterSafrasErro(error.response?.data?.message || error.message));
  }
}

function* ordenarSafras({ payload }) {
  if (payload.programaId === ProgramaCodigo.Safras) {
    yield call(listarTodosSafras);
  }
}

function* mudarLimitePaginacao({ payload }) {
  if (payload.programaId === ProgramaCodigo.Safras) {
    yield call(listarTodosSafras);
  }
}

export function* watchListarSafras() {
  yield takeLatest(LISTAR_SAFRAS, listarTodosSafras);
}

export function* watchFiltrarSafras() {
  yield takeLatest(FILTRAR_SAFRAS, listarTodosSafras);
}

export function* watchOrdenarSafras() {
  yield takeLatest(SET_TABLE_ORDER, ordenarSafras);
}

export function* watchLimitePaginacao() {
  yield takeLatest(SET_LIMIT_PAGE, mudarLimitePaginacao);
}

function* safrasSaga() {
  yield all([
    fork(watchListarSafras),
    fork(watchFiltrarSafras),
    fork(watchOrdenarSafras),
    fork(watchLimitePaginacao),
  ]);
}

export default safrasSaga;
