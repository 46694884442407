import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import { connect } from 'react-redux';

import { isUserAuthenticated, getUserPermissions } from '../helpers/authUtils';
import * as layoutConstants from '../constants/layout';
import { allFlattenRoutes as routes, publicRoutes } from './index';

// Lazy loading and code splitting -
// Derieved idea from https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
const loading = () => <div></div>;

// All layouts/containers
const AuthLayout = Loadable({
  loader: () => import('../layouts/Auth'),
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
  loading,
});

const VerticalLayout = Loadable({
  loader: () => import('../layouts/Vertical'),
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
  loading,
});

const HorizontalLayout = Loadable({
  loader: () => import('../layouts/Horizontal'),
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
  loading,
});

const PrimeiroAcessoLayout = Loadable({
  loader: () => import('../pages/auth/PrimeiroAcesso'),
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
  loading,
});

const SelectCompany = Loadable({
  loader: () => import('../pages/auth/SelecionarEmpresa'),
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
  loading,
});

class Routes extends Component {
  // returns the layout
  getLayout = () => {
    const usuarioAutenticado = isUserAuthenticated();
    if (!usuarioAutenticado || !this.props?.user?.id) return AuthLayout;

    if (this.props?.user?.primeiroAcesso) return PrimeiroAcessoLayout;

    if (getUserPermissions().length === 0) return SelectCompany;

    let layoutCls = VerticalLayout;

    switch (this.props.layout.layoutType) {
      case layoutConstants.LAYOUT_HORIZONTAL:
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };

  render() {
    const Layout = this.getLayout();
    // rendering the router with layout
    return (
      <BrowserRouter>
        <Switch>
          {publicRoutes.map((route, index) => {
            return !route.children ? (
              <Route key={index} path={route.path} exact={route.exact} component={route.component} />
            ) : null;
          })}

          <Layout {...this.props}>
            <Switch>
              {routes().map((route, index) => {
                return !route.children ? (
                  <route.route
                    key={index}
                    path={route.path}
                    roles={route.roles}
                    exact={route.exact}
                    component={route.component}
                  ></route.route>
                ) : null;
              })}
            </Switch>
          </Layout>
        </Switch>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
    user: state.Auth.user,
  };
};

export default connect(mapStateToProps, null)(Routes);
