import {
  FILTRAR_PROMOCOES,
  GERAR_RELATORIO_PROMOCOES,
  GERAR_RELATORIO_PROMOCOES_ERRO,
  GERAR_RELATORIO_PROMOCOES_SUCESSO,
  LISTAR_PROMOCOES,
  LISTAR_PROMOCOES_ERRO,
  LISTAR_PROMOCOES_SUCESSO,
} from './constants';

export const obterTodosPromocoes = () => ({
  type: LISTAR_PROMOCOES,
});

export const obterPromocoesSucesso = (segmento) => ({
  type: LISTAR_PROMOCOES_SUCESSO,
  payload: segmento,
});

export const obterPromocoesErro = (mensagem) => ({
  type: LISTAR_PROMOCOES_ERRO,
  payload: mensagem,
});

export const filtrarPromocoes = (f) => {
  return {
    type: FILTRAR_PROMOCOES,
    payload: f,
  };
};

export const gerarRelatorioPromocoes = (payload) => ({
  type: GERAR_RELATORIO_PROMOCOES,
  payload: payload,
});

export const gerarRelatorioPromocoesSucesso = (alert) => ({
  type: GERAR_RELATORIO_PROMOCOES_SUCESSO,
  payload: alert,
});

export const gerarRelatorioPromocoesErro = (mensagem) => ({
  type: GERAR_RELATORIO_PROMOCOES_ERRO,
  payload: mensagem,
});
