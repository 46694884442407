import Swal from 'sweetalert2';
import { filtroPadraoStatus } from '../../../constants/filtros';
import { FILTRAR_TRIBUTACOES_ESTADUAIS, LISTAR_TRIBUTACOES_ESTADUAIS, LISTAR_TRIBUTACOES_ESTADUAIS_ERRO, LISTAR_TRIBUTACOES_ESTADUAIS_SUCESSO } from './constants';

const INIT_STATE = {
  tributacoesEstaduais: [],
  filtros: { ...filtroPadraoStatus },
  carregando: false,
};

const TributacoesEstaduaisReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LISTAR_TRIBUTACOES_ESTADUAIS:
      return { ...state, carregando: true };
    case FILTRAR_TRIBUTACOES_ESTADUAIS:
      return { ...state, filtros: action.payload, carregando: true };
    case LISTAR_TRIBUTACOES_ESTADUAIS_SUCESSO:
      return { ...state, tributacoesEstaduais: action.payload, carregando: false };
    case LISTAR_TRIBUTACOES_ESTADUAIS_ERRO:
      Swal.fire({
        icon: 'error',
        title: 'Erro ao buscar tributacoes estaduais',
        timer: 2000,
        text: action.payload,
      });
      return { ...state, tributacoesEstaduais: [], carregando: false };
    default:
      return state;
  }
};

export default TributacoesEstaduaisReducer;
