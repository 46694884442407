import { IOptions } from '../../components/Tabela';
import {
  PREFERENCE_READY,
  SET_TABLE_PREFERENCE,
  SET_ACTIVE_MENU,
  SET_TABLE_ORDER,
  SET_LIMIT_PAGE,
  SET_CAMPO_BUSCA_PADRAO,
  SET_PAGE,
} from './constants';

export interface ITableState {
  programId?: {
    ordenarPor: string;
    ordem: string;
    campos: IOptions[];
    page: number;
    limit: number;
    buscarPor: string;
  };
}

const INIT_STATE: ITableState = {};
const INIT_STATE2 = {
  activeMenu: 0,
};

const TableColumns = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_TABLE_PREFERENCE:
      const dados = action.payload;
      const novo = {
        [dados.id]: {
          ...dados.preferencias,
        },
      };

      return { ...state, ...novo };

    case SET_TABLE_ORDER:
      const { programaId, order } = action.payload;
      const novaOrdem = {
        [programaId]: {
          ...order,
        },
      };
      return { ...state, ...novaOrdem };

    case SET_PAGE:
      const novaPagina = {
        [action.payload.programaId]: {
          ...state[action.payload.programaId],
          page: action.payload.page,
        },
      };

      return { ...state, ...novaPagina };  

    case SET_LIMIT_PAGE:
      const novaPaginacao = {
        [action.payload.programaId]: {
          ...state[action.payload.programaId],
          limit: action.payload.limit,
          page: action.payload.page,
        },
      };

      return { ...state, ...novaPaginacao };

    case SET_CAMPO_BUSCA_PADRAO:
      const novoCampo = {
        [action.payload.programaId]: {
          ...state[action.payload.programaId],
          buscarPor: action.payload.campo,
        },
      };

      return { ...state, ...novoCampo };

    case PREFERENCE_READY:
      return { ...state };
    default:
      return state;
  }
};

export const MenuAction = (state = INIT_STATE2, action) => {
  switch (action.type) {
    case SET_ACTIVE_MENU:
      return { ...state, activeMenu: action.payload };
    default:
      return state;
  }
};

export default TableColumns;
