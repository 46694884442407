import {
  FILTRAR_MANUTENCAO_MAQUINAS_VEICULOS,
  LISTAR_MANUTENCAO_MAQUINAS_VEICULOS,
  LISTAR_MANUTENCAO_MAQUINAS_VEICULOS_ERRO,
  LISTAR_MANUTENCAO_MAQUINAS_VEICULOS_SUCESSO,
  GERAR_RELATORIO_MANUTENCAO_MAQUINAS_VEICULOS,
  GERAR_RELATORIO_MANUTENCAO_MAQUINAS_VEICULOS_SUCESSO,
  GERAR_RELATORIO_MANUTENCAO_MAQUINAS_VEICULOS_ERRO,
} from './constants';

export const obterTodosManutencaoMaquinasVeiculos = () => ({
  type: LISTAR_MANUTENCAO_MAQUINAS_VEICULOS,
});

export const obterManutencaoMaquinasVeiculosSucesso = (ManutencaoMaquinasVeiculos) => ({
  type: LISTAR_MANUTENCAO_MAQUINAS_VEICULOS_SUCESSO,
  payload: ManutencaoMaquinasVeiculos,
});

export const obterManutencaoMaquinasVeiculosErro = (mensagem) => ({
  type: LISTAR_MANUTENCAO_MAQUINAS_VEICULOS_ERRO,
  payload: mensagem,
});

export const filtrarManutencaoMaquinasVeiculos = (filtros) => ({
  type: FILTRAR_MANUTENCAO_MAQUINAS_VEICULOS,
  payload: filtros,
});

export const gerarRelatorioManutencaoMaquinasVeiculos = (payload) => ({
  type: GERAR_RELATORIO_MANUTENCAO_MAQUINAS_VEICULOS,
  payload: payload,
});

export const gerarRelatorioManutencaoMaquinasVeiculosSucesso = (alert) => ({
  type: GERAR_RELATORIO_MANUTENCAO_MAQUINAS_VEICULOS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioManutencaoMaquinasVeiculosErro = (mensagem) => ({
  type: GERAR_RELATORIO_MANUTENCAO_MAQUINAS_VEICULOS_ERRO,
  payload: mensagem,
});
