import { AxiosResponse } from 'axios';

import api from '../../services/api';
import { IContaReceberCadastro, IContaReceberConsulta } from './interfaces';
import { montarQueryString } from '../../utils';
import { IContaReceberPagamentoCadastro } from './cadastro/pagamento/interfaces';
import { IBaixaPorValorTitulo } from '../../components/Financeiro/BaixaPorValor/interfaces';
import { IBaixaMultiplaCadastro } from '../../components/Financeiro/BaixaMultipla/interfaces';
import { IReplicacaoTituloParcela, IReplicacaoTituloQuery } from '../../components/Financeiro/Replicar/interfaces';
import { IContaReceberOcorrenciaCadastro } from './cadastro/ocorrencia-financeira/interfaces';
import { IBaixaRapidaDto } from '../../components/Financeiro/BaixaRapida/interfaces';
import { ISubgruposBaixaConsulta } from '../../components/Financeiro/SubgruposBaixa/interfaces';

export const obterContasReceberAsync = (
  query,
): Promise<AxiosResponse<{ result: IContaReceberConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);
  return api.request({
    url: `contas-receber${queryStr}`,
    method: 'GET',
  });
};

export const obterContaReceberAsync = (id: number): Promise<AxiosResponse<IContaReceberConsulta>> => {
  return api.request({
    url: `contas-receber/${id}`,
    method: 'GET',
  });
};

export const criarContaReceberAsync = (
  contaReceber: IContaReceberCadastro,
): Promise<AxiosResponse<IContaReceberConsulta>> => {
  return api.request({
    url: `contas-receber`,
    data: contaReceber,
    method: 'POST',
  });
};

export const atualizarContaReceberAsync = (
  id: number,
  contaReceber: IContaReceberCadastro,
): Promise<AxiosResponse<IContaReceberConsulta>> => {
  return api.request({
    url: `contas-receber/${id}`,
    data: contaReceber,
    method: 'PATCH',
  });
};

export const removerContasReceberEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `contas-receber`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerContaReceberAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `contas-receber/${id}`,
    method: 'DELETE',
  });

export const contaReceberPagamentosAsync = (
  id: number,
  pagamento: IContaReceberPagamentoCadastro[],
): Promise<AxiosResponse<void>> => {
  return api.request({
    url: `contas-receber/pagamento/${id}`,
    data: pagamento,
    method: 'PATCH',
  });
};

export const obterPagamentosAsync = (id: number): Promise<AxiosResponse<IContaReceberConsulta>> => {
  return api.request({
    url: `contas-receber/obter-pagamentos/${id}`,
    method: 'GET',
  });
};

export const obterSubgruposBaixaContasReceberAsync = (): Promise<AxiosResponse<ISubgruposBaixaConsulta>> => {
  return api.request({
    url: `contas-receber/obter-subgrupos-baixa`,
    method: 'GET',
  });
};

export const contaReceberOcorrenciasAsync = (
  id: number,
  ocorrencia: IContaReceberOcorrenciaCadastro[],
): Promise<AxiosResponse<void>> => {
  return api.request({
    url: `contas-receber/ocorrencia/${id}`,
    data: ocorrencia,
    method: 'PATCH',
  });
};

export const baixaMultiplaContasReceber = (pagamentos: IBaixaMultiplaCadastro[]): Promise<AxiosResponse<void>> => {
  return api.request({
    url: `contas-receber/baixa-multipla`,
    data: pagamentos,
    method: 'PATCH',
  });
};

export const baixaRapidaContasReceber = (dto: IBaixaRapidaDto): Promise<AxiosResponse<void>> => {
  return api.request({
    url: `contas-receber/baixa-rapida`,
    data: dto,
    method: 'PATCH',
  });
};

export const baixaPorValorContasReceber = (dto: IBaixaPorValorTitulo): Promise<AxiosResponse<void>> => {
  return api.request({
    url: `contas-receber/baixa-por-valor`,
    data: dto,
    method: 'PATCH',
  });
};

export const montarReplicacaoContasReceber = (
  id: number,
  query: IReplicacaoTituloQuery,
): Promise<AxiosResponse<IReplicacaoTituloParcela[]>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `contas-receber/montar-replicacao/${id}${queryStr}`,
    method: 'GET',
  });
};

export const replicarContaReceberAsync = (
  id: string,
  dto: IReplicacaoTituloParcela[],
): Promise<AxiosResponse<void>> => {
  return api.request({
    url: `contas-receber/replicar/${id}`,
    data: dto,
    method: 'POST',
  });
};
