import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { SET_LIMIT_PAGE, SET_TABLE_ORDER } from '../../../redux/table/constants';
import {
  FILTRAR_UNIDADES_PRODUTIVAS,
  GERAR_RELATORIO_UNIDADES_PRODUTIVAS,
  LISTAR_UNIDADES_PRODUTIVAS,
} from './constants';

import { ProgramaCodigo } from '../../../constants/enums/programa-codigo.enum';
import { DEFAULT_DOWNLOAD_SUCCESS_MESSAGE, gerarRelatorioRacing } from '../../../services/requests/relatorio';
import { montarFiltroParaApi } from '../../../utils/api-filter';
import { obterUnidadesProdutivasAsync } from '../requests';
import {
  gerarRelatorioUnidadesProdutivasErro,
  gerarRelatorioUnidadesProdutivasSucesso,
  obterTodasUnidadesProdutivasErro,
  obterTodasUnidadesProdutivasSucesso,
} from './actions';

function* listarTodasUnidadesProdutivas() {
  try {
    const filtro = yield select((state) => state.UnidadesProdutivas.filtros);
    const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.UnidadesProdutivas]);

    let query = { filtro: montarFiltroParaApi(filtro) };

    if (preferencias) {
      query.ordenacao = {
        campo: preferencias?.ordenarPor || 'nome',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      };
      query.paginacao = {
        pagina: preferencias?.page || 1,
        limite: preferencias?.limit || 50,
      };
    }

    const { data } = yield call(obterUnidadesProdutivasAsync, query);
    yield put(obterTodasUnidadesProdutivasSucesso(data));
  } catch (error) {
    yield put(obterTodasUnidadesProdutivasErro(error.response?.data?.message || error.message));
  }
}

function* relatorioUnidadesProdutivas() {
  const { filtros, relatorio } = yield select((state) => state.UnidadesProdutivas);
  const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.UnidadesProdutivas]);
  const { modelo, tipo, comFiltro, colunas } = relatorio;
  const columns = colunas?.map((coluna) => coluna.value);
  let query = {};

  if (comFiltro) {
    query = {
      filtro: {
        ...montarFiltroParaApi(filtros),
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
      ordenacao: {
        campo: preferencias?.ordenarPor || 'nome',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      },
    };
  } else {
    query = {
      filtro: {
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
    };
  }

  try {
    const { data, headers } = yield call(gerarRelatorioRacing, ProgramaCodigo.UnidadesProdutivas, query, tipo);
    const contentType = headers['content-type'];
    if (!contentType.includes('application/json')) {
      const url = URL.createObjectURL(
        new Blob([data], {
          type: contentType || 'application/pdf',
        }),
      );

      if (url) {
        yield put(gerarRelatorioUnidadesProdutivasSucesso(DEFAULT_DOWNLOAD_SUCCESS_MESSAGE));
        window.open(url, '_blank');
      }
    } else {
      yield put(
        gerarRelatorioUnidadesProdutivasSucesso({
          titulo: 'Pedido de impressão salvo.',
          mensagem: 'Seu relatório está sendo gerado.',
        }),
      );
    }
  } catch (e) {
    yield put(
      gerarRelatorioUnidadesProdutivasErro('Ocorreu um erro ao tentar gerar o relatório. Tente novamente mais tarde.'),
    );
  }
}

function* ordenarUnidadesProdutivas({ payload }) {
  if (payload.programaId === ProgramaCodigo.UnidadesProdutivas) {
    yield call(listarTodasUnidadesProdutivas);
  }
}

function* mudarLimitePaginacao({ payload }) {
  if (payload.programaId === ProgramaCodigo.UnidadesProdutivas) {
    yield call(listarTodasUnidadesProdutivas);
  }
}

export function* watchListarUnidadesProdutivas() {
  yield takeLatest(LISTAR_UNIDADES_PRODUTIVAS, listarTodasUnidadesProdutivas);
}

export function* watchFiltrarUnidadesProdutivas() {
  yield takeLatest(FILTRAR_UNIDADES_PRODUTIVAS, listarTodasUnidadesProdutivas);
}

export function* watchOrdenarUnidadesProdutivas() {
  yield takeLatest(SET_TABLE_ORDER, ordenarUnidadesProdutivas);
}

export function* watchLimitePaginacao() {
  yield takeLatest(SET_LIMIT_PAGE, mudarLimitePaginacao);
}

export function* watchRelatorioGruposAtividades() {
  yield takeLatest(GERAR_RELATORIO_UNIDADES_PRODUTIVAS, relatorioUnidadesProdutivas);
}

function* unidadesProdutivasSaga() {
  yield all([
    fork(watchListarUnidadesProdutivas),
    fork(watchFiltrarUnidadesProdutivas),
    fork(watchOrdenarUnidadesProdutivas),
    fork(watchLimitePaginacao),
    fork(watchRelatorioGruposAtividades),
  ]);
}

export default unidadesProdutivasSaga;
