import api from '../../services/api';
import { montarQueryString } from '../../utils';
import { IUnidadeProdutivaConsulta } from '../unidades-produtivas/interfaces';
import { IPropriedadeCadastro, IPropriedadeConsulta } from './interfaces';

export const obterPropriedadesAsync = (query = {}) => {
  const querystr = montarQueryString(query);

  return api.request<{ result: IPropriedadeConsulta[]; total: number }>({
    url: `propriedades${querystr}`,
    method: 'GET',
  });
};

export const obterPropriedadesParaIntegrador = (dto: { organizacaoId: number; empresasIds: number[] }) => {
  const querystr = montarQueryString(dto);

  const a = api.request<IPropriedadeConsulta[]>({
    url: `propriedades/propriedades-integrador${querystr}`,
    method: 'GET',
  });
  return a;
};

export const obterPropriedadesParaUsuario = (query = {}) => {
  const querystr = montarQueryString(query);

  return api.request<IPropriedadeConsulta[]>({
    url: `propriedades/propriedades-usuario${querystr}`,
    method: 'GET',
  });
};

export const uploadImagemPropriedadeAsync = (id: number, imagem: File) => {
  const formData = new FormData();
  formData.append('imagem', imagem);

  return api.request({
    url: `propriedades/${id}/upload`,
    data: formData,
    method: 'POST',
  });
};
export const deletarImagemPropriedadeAsync = (id: number) => {
  return api.request({
    url: `propriedades/${id}/deletar-imagem`,
    method: 'PATCH',
  });
};

export const obterPropriedadeAsync = (id: number) => {
  return api.request<IPropriedadeConsulta>({
    url: `propriedades/${id}`,
    method: 'GET',
  });
};

export const obterCoordenadasCultivosAbertosAsync = (id: number) => {
  return api.request<IUnidadeProdutivaConsulta[]>({
    url: `propriedades/coordenadas-cultivos-abertos/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoPropriedadeAsync = () => {
  return api.request<number>({
    url: `propriedades/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarPropriedadeAsync = (propriedade: IPropriedadeCadastro) => {
  return api.request<IPropriedadeConsulta>({
    url: `propriedades`,
    method: 'POST',
    data: propriedade,
  });
};

export const atualizarPropriedadeAsync = (id: number, propriedade: IPropriedadeCadastro) => {
  return api.request<IPropriedadeConsulta>({
    url: `propriedades/${id}`,
    method: 'PATCH',
    data: propriedade,
  });
};

export const removerPropriedadesEmMassaAsync = (ids: number[]) => {
  return api.request<{ affected: number }>({
    url: `propriedades`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerPropriedadeAsync = (id: number) =>
  api.request<{ affected: number }>({
    url: `propriedades/${id}`,
    method: 'DELETE',
  });
