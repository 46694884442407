import { AxiosResponse } from 'axios';

import api from '../../services/api';
import { ITamanhoProdutosCadastro, ITamanhoProdutosConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterTamanhoProdutosAsync = (
  query,
): Promise<AxiosResponse<{ result: ITamanhoProdutosConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `tamanho-produtos${queryStr}`,
    method: 'GET',
  });
};

export const obterTamanhoProdutoAsync = (id: number): Promise<AxiosResponse<ITamanhoProdutosConsulta>> => {
  
  return api.request({
    url: `tamanho-produtos/${isNaN(id) ? '' : id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoTamanhoProdutosAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `tamanho-produtos/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarTamanhoProdutosAsync = (TamanhoProdutos: ITamanhoProdutosCadastro): Promise<AxiosResponse<ITamanhoProdutosConsulta>> => {
  return api.request({
    url: `tamanho-produtos`,
    data: TamanhoProdutos,
    method: 'POST',
  });
};

export const atualizarTamanhoProdutosAsync = (
  id: number,
  TamanhoProdutos: ITamanhoProdutosCadastro,
): Promise<AxiosResponse<ITamanhoProdutosConsulta>> => {
  return api.request({
    url: `tamanho-produtos/${id}`,
    data: TamanhoProdutos,
    method: 'PATCH',
  });
};

export const removerTamanhoProdutosEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `tamanho-produtos`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerTamanhoProdutosAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `tamanho-produtos/${id}`,
    method: 'DELETE',
  });
