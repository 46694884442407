import { AxiosResponse } from 'axios';
import api from '../../services/api';
import { IRegistroEtiquetasEntradaFrutaCadastro, IRegistroEtiquetasEntradaFrutaConsulta } from './interfaces';
import { montarQueryString } from '../../utils';
import { IEtiquetaRastreabilidade } from './etiqueta-personalizada/interfaces';

export const obterRegistroEtiquetasEntradaFrutasAsync = (
  query
): Promise<AxiosResponse<{ result: IRegistroEtiquetasEntradaFrutaConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `etiquetas-entrada-frutas${queryStr}`,
    method: 'GET',
  });
};

export const obterRegistroEtiquetasEntradaFrutaAsync = (id: number): Promise<AxiosResponse<IRegistroEtiquetasEntradaFrutaConsulta>> => {
  return api.request({
    url: `etiquetas-entrada-frutas/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoRegistroEtiquetasEntradaFrutaAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `etiquetas-entrada-frutas/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarRegistroEtiquetasEntradaFrutaAsync = (
  RegistroEtiquetasEntradaFruta: IRegistroEtiquetasEntradaFrutaCadastro
): Promise<AxiosResponse<IRegistroEtiquetasEntradaFrutaConsulta>> => {
  return api.request({
    url: `etiquetas-entrada-frutas`,
    data: RegistroEtiquetasEntradaFruta,
    method: 'POST',
  });
};

export const atualizarRegistroEtiquetasEntradaFrutaAsync = (
  id: number,
  RegistroEtiquetasEntradaFruta: IRegistroEtiquetasEntradaFrutaCadastro
): Promise<AxiosResponse<IRegistroEtiquetasEntradaFrutaConsulta>> => {
  return api.request({
    url: `etiquetas-entrada-frutas/${id}`,
    data: RegistroEtiquetasEntradaFruta,
    method: 'PATCH',
  });
};

export const removerRegistroEtiquetasEntradaFrutasEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `etiquetas-entrada-frutas`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerRegistroEtiquetasEntradaFrutaAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `etiquetas-entrada-frutas/${id}`,
    method: 'DELETE',
  });

  export const gerarEtiquetaRastreabilidadePersonalizadoAsync = (dadosEtiqueta: IEtiquetaRastreabilidade) => {
    return api.request<Blob>({
      url: `etiquetas/entrada-frutas`,
      method: 'POST',
      headers: {
        responseType: 'arraybuffer',
        dataType: 'blob',
      },
      responseType: 'blob',
      data: dadosEtiqueta,
    });
  };
  