import {
  FILTRAR_GRUPOS_FINANCEIROS,
  GERAR_RELATORIO_GRUPOS_FINANCEIROS,
  GERAR_RELATORIO_GRUPOS_FINANCEIROS_ERRO,
  GERAR_RELATORIO_GRUPOS_FINANCEIROS_SUCESSO,
  LISTAR_GRUPOS_FINANCEIROS,
  LISTAR_GRUPOS_FINANCEIROS_ERRO,
  LISTAR_GRUPOS_FINANCEIROS_SUCESSO,
} from './constants';

export const obterTodosGruposFinanceiros = () => ({
  type: LISTAR_GRUPOS_FINANCEIROS,
});

export const obterGruposFinanceirosSucesso = (gruposFinanceiros) => ({
  type: LISTAR_GRUPOS_FINANCEIROS_SUCESSO,
  payload: gruposFinanceiros,
});

export const obterGruposFinanceirosErro = (mensagem) => ({
  type: LISTAR_GRUPOS_FINANCEIROS_ERRO,
  payload: mensagem,
});

export const filtrarGruposFinanceiros = (filtros) => ({
  type: FILTRAR_GRUPOS_FINANCEIROS,
  payload: filtros,
});

export const gerarRelatorioGruposFinanceiros = (payload) => ({
  type: GERAR_RELATORIO_GRUPOS_FINANCEIROS,
  payload: payload,
});

export const gerarRelatorioGruposFinanceirosSucesso = (alert) => ({
  type: GERAR_RELATORIO_GRUPOS_FINANCEIROS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioGruposFinanceirosErro = (mensagem) => ({
  type: GERAR_RELATORIO_GRUPOS_FINANCEIROS_ERRO,
  payload: mensagem,
});