import { AxiosResponse } from 'axios';
import api from '../../services/api';
import { ICNAECadastro, ICNAEConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterCNAEsAsync = (query): Promise<AxiosResponse<{ result: ICNAEConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `cnae${queryStr}`,
    method: 'GET',
  });
};

export const obterCNAEAsync = (id: number): Promise<AxiosResponse<ICNAEConsulta>> => {
  return api.request({
    url: `cnae/${id}`,
    method: 'GET',
  });
};

export const criarCNAEAsync = (cnae: ICNAECadastro): Promise<AxiosResponse<ICNAEConsulta>> => {
  return api.request({
    url: `cnae`,
    data: cnae,
    method: 'POST',
  });
};

export const atualizarCNAEAsync = (id: number, cnae: ICNAECadastro): Promise<AxiosResponse<ICNAEConsulta>> => {
  return api.request({
    url: `cnae/${id}`,
    data: cnae,
    method: 'PATCH',
  });
};

export const removerCNAEsEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `cnae`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerCNAEAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `cnae/${id}`,
    method: 'DELETE',
  });
