import api from '../../services/api';
import { montarQueryString } from '../../utils';
import { IManutencaoMaquinasVeiculosCadastro } from './interfaces';

export const obterManutencaoMaquinasVeiculosAsync = (query) => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `manutencao-maquinas-veiculos${queryStr}`,
    method: 'GET',
  });
};

export const obterManutencaoMaquinaVeiculoAsync = (id: number) => {
  return api.request({
    url: `manutencao-maquinas-veiculos/${id}`,
    method: 'GET',
  });
};

export const criarManutencaoMaquinaVeiculoAsync = (ManutencaoMaquinaVeiculo: IManutencaoMaquinasVeiculosCadastro) => {
  return api.request({
    url: `manutencao-maquinas-veiculos`,
    data: ManutencaoMaquinaVeiculo,
    method: 'POST',
  });
};

export const atualizarManutencaoMaquinaVeiculoAsync = (
  id: number,
  ManutencaoMaquinaVeiculo: IManutencaoMaquinasVeiculosCadastro,
) => {
  return api.request({
    url: `manutencao-maquinas-veiculos/${id}`,
    data: ManutencaoMaquinaVeiculo,
    method: 'PATCH',
  });
};

export const removerManutencaoMaquinasVeiculosEmMassaAsync = (ids: number[]) => {
  return api.request({
    url: `manutencao-maquinas-veiculos`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerManutencaoMaquinaVeiculoAsync = (id: number) =>
  api.request({
    url: `manutencao-maquinas-veiculos/${id}`,
    method: 'DELETE',
  });
