import { AxiosResponse } from 'axios';
import api from '../../services/api';
import { IAtividadeControleProdutividadeCadastro, IAtividadeControleProdutividadeConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterAtividadesControleProdutividadeAsync = (
  query
): Promise<AxiosResponse<{ result: IAtividadeControleProdutividadeConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `atividades-controle-produtividade${queryStr}`,
    method: 'GET',
  });
};

export const obterAtividadeControleProdutividadeAsync = (id: number): Promise<AxiosResponse<IAtividadeControleProdutividadeConsulta>> => {
  return api.request({
    url: `atividades-controle-produtividade/${id}`,
    method: 'GET',
  });
};

export const criarAtividadeControleProdutividadeAsync = (
  AtividadeControleProdutividade: IAtividadeControleProdutividadeCadastro
): Promise<AxiosResponse<IAtividadeControleProdutividadeConsulta>> => {
  return api.request({
    url: `atividades-controle-produtividade`,
    data: AtividadeControleProdutividade,
    method: 'POST',
  });
};

export const atualizarAtividadeControleProdutividadeAsync = (
  id: number,
  AtividadeControleProdutividade: IAtividadeControleProdutividadeCadastro
): Promise<AxiosResponse<IAtividadeControleProdutividadeConsulta>> => {
  return api.request({
    url: `atividades-controle-produtividade/${id}`,
    data: AtividadeControleProdutividade,
    method: 'PATCH',
  });
};

export const removerAtividadesControleProdutividadeEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `atividades-controle-produtividade`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerAtividadeControleProdutividadeAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `atividades-controle-produtividade/${id}`,
    method: 'DELETE',
  });
