import { AxiosResponse } from 'axios';
import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import defaultBg from '../assets/images/auth-bg.jpg';
import logo from '../assets/images/logo.png';
import api from '../services/api';
type InfoRevendaType = {
  titulo?: string;
  descricao?: string;
  imagemGrande?: string;
  imagemPequena?: string;
};

interface RevendaInfoDataContext {
  info: InfoRevendaType;
  loadingRevendaInfo: boolean;
}

const RevendaInfoDataContext = createContext<RevendaInfoDataContext>({} as RevendaInfoDataContext);

export const useRevendaInfo = () => useContext(RevendaInfoDataContext);

interface Props {
  children: ReactNode;
}
export const getConfigAsync = (host: string): Promise<AxiosResponse> => {
  return api.request({
    url: `revenda/config/${host}`,
    method: 'GET',
  });
};

export function RevendaInfoProvider({ children }: Props) {
  const hostname = document.location.hostname;

  const [loadingRevendaInfo, setLoadingRevendaInfo] = useState(false);
  const [info, setInfo] = useState({
    imagemPequena: logo,
    imagemGrande: defaultBg,
    titulo: 'Domper ERP',
    descricao: 'Você elevará o nível de organização e de produtividade de sua empresa.',
  });
  const atualizarInformacoes = useCallback(async () => {
    setLoadingRevendaInfo(true);

    try {
      const { data: newConfig } = await getConfigAsync(hostname);

      setInfo((lastState) => ({
        imagemPequena: newConfig.imagemPequena || lastState.imagemPequena,
        imagemGrande: newConfig.imagemGrande || lastState.imagemGrande,
        titulo: newConfig.titulo || lastState.titulo,
        descricao: newConfig.descricao || lastState.descricao,
      }));
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingRevendaInfo(false);
    }
  }, [hostname]);

  useEffect(() => {
    document.title = info.titulo || 'Domper ERP';
  }, [info.titulo]);

  useEffect(() => {
    atualizarInformacoes();
  }, [atualizarInformacoes]);

  return (
    <RevendaInfoDataContext.Provider value={{ loadingRevendaInfo, info }}>{children}</RevendaInfoDataContext.Provider>
  );
}
