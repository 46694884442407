import styled from 'styled-components';

interface Tamanho {
  tamanho: number;
  apenasLeitura: boolean;
}

export const Container = styled.div<Tamanho>`
  cursor: ${({ apenasLeitura }) => (apenasLeitura ? 'default' : 'pointer')};
  width: 100%;
  display: flex;
  flex-direction: column;
  width: ${({ tamanho }) => tamanho}px;
  z-index: 0;
`;

export const ImagemContainer = styled.div<Tamanho>`
  background-color: rgb(230, 230, 230);
  width: 100%;
  height: ${({ tamanho }) => tamanho}px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const ButtonRemove = styled.button`
  background: var(--danger);

  display: flex;
  align-items: center;
  justify-content: center;

  border: 0;
  outline: none;
  width: 100%;
  border-radius: 0.3rem;
  margin-top: 1rem;
  padding: 0.75rem 0;

  color: #fff;
  font-weight: bold;

  transition: all 0.2s;

  &:hover {
    opacity: 0.8;
    background: #ff5c75;
  }
`;

export const Imagem = styled.img<Tamanho>`
  width: 100%;
  height: ${({ tamanho }) => tamanho}px;
  object-fit: cover;
  border-radius: 8px;
`;

export const ImagemIcone = styled.img<Tamanho>`
  width: 100%;
  max-width: ${({ tamanho }) => (tamanho / 200) * 80}px;
  height: ${({ tamanho }) => (tamanho / 200) * 80}px;
`;
