import { AxiosResponse } from 'axios';
import qs from 'qs';
import api from '../../services/api';
import { IProgramaConsulta } from './interfaces';

export const obterProgramaAsync = (id: number): Promise<AxiosResponse<IProgramaConsulta>> =>
  api.request({
    url: `programa/${id}`,
    method: 'GET',
  });

export const obterProgramasAsync = (query): Promise<AxiosResponse<{ result: IProgramaConsulta[]; total: number }>> => {
  let querystr = '';
  if (query) querystr = '?' + qs.stringify(query);

  return api.request({
    url: `programa${querystr}`,
    method: 'GET',
  });
};
