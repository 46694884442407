import {
  FILTRAR_VENDAS_COLHEITAS,
  LISTAR_VENDAS_COLHEITAS,
  LISTAR_VENDAS_COLHEITAS_ERRO,
  LISTAR_VENDAS_COLHEITAS_SUCESSO,
  GERAR_RELATORIO_VENDAS_COLHEITAS,
  GERAR_RELATORIO_VENDAS_COLHEITAS_SUCESSO,
  GERAR_RELATORIO_VENDAS_COLHEITAS_ERRO
} from './constants';

export const obterTodosVendasColheitas = () => ({
  type: LISTAR_VENDAS_COLHEITAS,
});

export const obterVendasColheitasSucesso = (VendasColheitas) => ({
  type: LISTAR_VENDAS_COLHEITAS_SUCESSO,
  payload: VendasColheitas,
});

export const obterVendasColheitasErro = (mensagem) => ({
  type: LISTAR_VENDAS_COLHEITAS_ERRO,
  payload: mensagem,
});

export const filtrarVendasColheitas = (filtros) => ({
  type: FILTRAR_VENDAS_COLHEITAS,
  payload: filtros,
});

export const gerarRelatorioVendasColheitas = (payload) => ({
  type: GERAR_RELATORIO_VENDAS_COLHEITAS,
  payload: payload,
});

export const gerarRelatorioVendasColheitasSucesso = (alert) => ({
  type: GERAR_RELATORIO_VENDAS_COLHEITAS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioVendasColheitasErro = (mensagem) => ({
  type: GERAR_RELATORIO_VENDAS_COLHEITAS_ERRO,
  payload: mensagem,
});