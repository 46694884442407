import api from '../../services/api';
import { ISegmentoCadastro, ISegmentoConsulta } from './interfaces';
import { montarQueryString } from '../../utils';
import { AxiosResponse } from 'axios';

export const obterSegmentoAsync = (
  id: number,
): Promise<AxiosResponse<ISegmentoConsulta>> => {
  return api.request({
    url: `segmento/${id}`,
    method: 'GET',
  });
};

export const obterSegmentosAsync = (
  query = {},
): Promise<AxiosResponse<{ result: ISegmentoConsulta[]; total: number }>> => {
  const querystr = montarQueryString(query);
  return api.request({
    url: `segmento${querystr}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoSegmentoAsync = (): Promise<
  AxiosResponse<number>
> => {
  return api.request({
    url: `segmento/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const cadastrarSegmentoAsync = (
  segmento: ISegmentoCadastro,
): Promise<AxiosResponse<ISegmentoConsulta>> => {
  return api.request({
    url: `segmento`,
    method: 'POST',
    data: segmento,
  });
};

export const editarSegmentoAsync = (
  id: number,
  segmento: ISegmentoCadastro,
): Promise<AxiosResponse<ISegmentoConsulta>> => {
  return api.request({
    url: `segmento/${id}`,
    method: 'PATCH',
    data: segmento,
  });
};

export const removerSegmentosEmMassaAsync = (
  ids: number[],
): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `segmento`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerSegmentoAsync = (
  id: number,
): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `segmento/${id}`,
    method: 'DELETE',
  });
};

export const vincularProgramasSegmentoAsync = (
  id: number,
  programasIds: number[],
): Promise<AxiosResponse<ISegmentoConsulta>> => {
  return api.request({
    url: `segmento/${id}/vincular_programas`,
    method: 'PATCH',
    data: { programasIds },
  });
};
