import {
  FILTRAR_DEPOSITOS,
  GERAR_RELATORIO_DEPOSITOS,
  GERAR_RELATORIO_DEPOSITOS_ERRO,
  GERAR_RELATORIO_DEPOSITOS_SUCESSO,
  LISTAR_DEPOSITOS,
  LISTAR_DEPOSITOS_ERRO,
  LISTAR_DEPOSITOS_SUCESSO,
} from './constants';

export const obterTodosDepositos = () => ({
  type: LISTAR_DEPOSITOS,
});

export const obterDepositosSucesso = (depositos) => ({
  type: LISTAR_DEPOSITOS_SUCESSO,
  payload: depositos,
});

export const obterDepositosErro = (mensagem) => ({
  type: LISTAR_DEPOSITOS_ERRO,
  payload: mensagem,
});

export const filtrarDepositos = (filtros) => ({
  type: FILTRAR_DEPOSITOS,
  payload: filtros,
});

export const gerarRelatorioDepositos = (payload) => ({
  type: GERAR_RELATORIO_DEPOSITOS,
  payload: payload,
});

export const gerarRelatorioDepositosSucesso = (alert) => ({
  type: GERAR_RELATORIO_DEPOSITOS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioDepositosErro = (mensagem) => ({
  type: GERAR_RELATORIO_DEPOSITOS_ERRO,
  payload: mensagem,
});