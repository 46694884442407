import {
  FILTRAR_MOTIVOS_CANCELAMENTOS_CONTRATOS,
  GERAR_RELATORIO_MOTIVOS_CANCELAMENTOS_CONTRATOS,
  GERAR_RELATORIO_MOTIVOS_CANCELAMENTOS_CONTRATOS_ERRO,
  GERAR_RELATORIO_MOTIVOS_CANCELAMENTOS_CONTRATOS_SUCESSO,
  LISTAR_MOTIVOS_CANCELAMENTOS_CONTRATOS,
  LISTAR_MOTIVOS_CANCELAMENTOS_CONTRATOS_ERRO,
  LISTAR_MOTIVOS_CANCELAMENTOS_CONTRATOS_SUCESSO,
} from './constants';

export const obterTodosMotivosCancelamentosContratos = () => ({
  type: LISTAR_MOTIVOS_CANCELAMENTOS_CONTRATOS,
});

export const obterMotivosCancelamentosContratosSucesso = (motivosCancelamentosContratos) => ({
  type: LISTAR_MOTIVOS_CANCELAMENTOS_CONTRATOS_SUCESSO,
  payload: motivosCancelamentosContratos,
});

export const obterMotivosCancelamentosContratosErro = (mensagem) => ({
  type: LISTAR_MOTIVOS_CANCELAMENTOS_CONTRATOS_ERRO,
  payload: mensagem,
});

export const filtrarMotivosCancelamentosContratos = (filtros) => ({
  type: FILTRAR_MOTIVOS_CANCELAMENTOS_CONTRATOS,
  payload: filtros,
});

export const gerarRelatorioMotivosCancelamentosContratos = (payload) => ({
  type: GERAR_RELATORIO_MOTIVOS_CANCELAMENTOS_CONTRATOS,
  payload: payload,
});

export const gerarRelatorioMotivosCancelamentosContratosSucesso = (alert) => ({
  type: GERAR_RELATORIO_MOTIVOS_CANCELAMENTOS_CONTRATOS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioMotivosCancelamentosContratosErro = (mensagem) => ({
  type: GERAR_RELATORIO_MOTIVOS_CANCELAMENTOS_CONTRATOS_ERRO,
  payload: mensagem,
});