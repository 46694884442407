import { AxiosResponse } from 'axios';
import api from '../../services/api';
import { IModeloRelatorioCadastro, IModeloRelatorioConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterModelosRelatoriosAsync = (
  query,
): Promise<AxiosResponse<{ result: IModeloRelatorioConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `modelos-relatorios${queryStr}`,
    method: 'GET',
  });
};

export const obterModeloRelatorioAsync = (id: number): Promise<AxiosResponse<IModeloRelatorioConsulta>> => {
  return api.request({
    url: `modelos-relatorios/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoModeloRelatorioAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `modelos-relatorios/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarModeloRelatorioAsync = (
  ModeloRelatorio: IModeloRelatorioCadastro,
): Promise<AxiosResponse<IModeloRelatorioConsulta>> => {
  return api.request({
    url: `modelos-relatorios`,
    data: ModeloRelatorio,
    method: 'POST',
  });
};

export const atualizarModeloRelatorioAsync = (
  id: number,
  ModeloRelatorio: IModeloRelatorioCadastro,
): Promise<AxiosResponse<IModeloRelatorioConsulta>> => {
  return api.request({
    url: `modelos-relatorios/${id}`,
    data: ModeloRelatorio,
    method: 'PATCH',
  });
};

export const removerModelosRelatoriosEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `modelos-relatorios`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerModeloRelatorioAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `modelos-relatorios/${id}`,
    method: 'DELETE',
  });

export const gerarEtiquetaAsync = (dadosEtiqueta: any) => {
  return api.request<Blob>({
    url: `modelos/relatorio`,
    method: 'POST',
    headers: {
      responseType: 'arraybuffer',
      dataType: 'blob',
    },
    responseType: 'blob',
    data: dadosEtiqueta,
  });
};

export const gerarEtiquetaTesteAsync = (dadosEtiqueta: any) => {
  return api.request<Blob>({
    url: `modelos/relatorio-teste`,
    method: 'POST',
    headers: {
      responseType: 'arraybuffer',
      dataType: 'blob',
    },
    responseType: 'blob',
    data: dadosEtiqueta,
  });
};
