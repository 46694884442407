import {
  FILTRAR_REVENDA,
  LISTAR_REVENDA,
  LISTAR_REVENDA_ERRO,
  LISTAR_REVENDA_SUCESSO,
} from './constants';

export const obterTodosRevenda = () => ({
  type: LISTAR_REVENDA,
});

export const obterRevendaSucesso = (revenda) => ({
  type: LISTAR_REVENDA_SUCESSO,
  payload: revenda,
});

export const obterRevendaErro = (mensagem) => ({
  type: LISTAR_REVENDA_ERRO,
  payload: mensagem,
});

export const filtrarRevenda = (filtros) => ({
  type: FILTRAR_REVENDA,
  payload: filtros,
});
