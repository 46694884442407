import { AxiosResponse } from 'axios';

import api from '../../services/api';
import { montarQueryString } from '../../utils';
import { ICaixaBancoCadastro, ICaixaBancoConsulta } from './interfaces';

export const obterCaixasBancosAsync = (
  query,
): Promise<AxiosResponse<{ result: ICaixaBancoConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);
  return api.request({
    url: `caixa-bancos${queryStr}`,
    method: 'GET',
  });
};

export const obterCaixaBancoAsync = (id: number): Promise<AxiosResponse<ICaixaBancoConsulta>> => {
  return api.request({
    url: `caixa-bancos/${id}`,
    method: 'GET',
  });
};

export const obterHistoricoPessoaCaixaBancoAsync = (
  pessoaId: number,
): Promise<AxiosResponse<{ historico: string }[]>> => {
  return api.request({
    url: `caixa-bancos/historico/${pessoaId}`,
    method: 'GET',
  });
};

export const criarCaixaBancoAsync = (caixaBanco: ICaixaBancoCadastro): Promise<AxiosResponse<ICaixaBancoConsulta>> => {
  return api.request({
    url: `caixa-bancos`,
    data: caixaBanco,
    method: 'POST',
  });
};

export const atualizarCaixaBancoAsync = (
  id: number,
  caixaBanco: ICaixaBancoCadastro,
): Promise<AxiosResponse<ICaixaBancoConsulta>> => {
  return api.request({
    url: `caixa-bancos/${id}`,
    data: caixaBanco,
    method: 'PATCH',
  });
};

export const removerCaixasBancosEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `caixa-bancos`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerCaixaBancoAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `caixa-bancos/${id}`,
    method: 'DELETE',
  });
