import {
  FILTRAR_ATIVIDADES,
  GERAR_RELATORIO_ATIVIDADES,
  GERAR_RELATORIO_ATIVIDADES_ERRO,
  GERAR_RELATORIO_ATIVIDADES_SUCESSO,
  LISTAR_ATIVIDADES,
  LISTAR_ATIVIDADES_ERRO,
  LISTAR_ATIVIDADES_SUCESSO,
} from './constants';

export const obterTodosAtividades = () => ({
  type: LISTAR_ATIVIDADES,
});

export const obterAtividadesSucesso = (Atividades) => ({
  type: LISTAR_ATIVIDADES_SUCESSO,
  payload: Atividades,
});

export const obterAtividadesErro = (mensagem) => ({
  type: LISTAR_ATIVIDADES_ERRO,
  payload: mensagem,
});

export const filtrarAtividades = (filtros) => ({
  type: FILTRAR_ATIVIDADES,
  payload: filtros,
});

export const gerarRelatorioAtividades = (payload) => ({
  type: GERAR_RELATORIO_ATIVIDADES,
  payload: payload,
});

export const gerarRelatorioAtividadesSucesso = (alert) => ({
  type: GERAR_RELATORIO_ATIVIDADES_SUCESSO,
  payload: alert,
});

export const gerarRelatorioAtividadesErro = (mensagem) => ({
  type: GERAR_RELATORIO_ATIVIDADES_ERRO,
  payload: mensagem,
});
