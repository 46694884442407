import {
  FILTRAR_TAMANHO_PRODUTOS,
  GERAR_RELATORIO_TAMANHO_PRODUTOS,
  GERAR_RELATORIO_TAMANHO_PRODUTOS_ERRO,
  GERAR_RELATORIO_TAMANHO_PRODUTOS_SUCESSO,
  LISTAR_TAMANHO_PRODUTOS,
  LISTAR_TAMANHO_PRODUTOS_ERRO,
  LISTAR_TAMANHO_PRODUTOS_SUCESSO,
} from './constants';

export const obterTodosTamanhoProdutos = () => ({
  type: LISTAR_TAMANHO_PRODUTOS,
});

export const obterTamanhoProdutosSucesso = (tamanhoProduto) => ({
  type: LISTAR_TAMANHO_PRODUTOS_SUCESSO,
  payload: tamanhoProduto,
});

export const obterTamanhoProdutosErro = (mensagem) => ({
  type: LISTAR_TAMANHO_PRODUTOS_ERRO,
  payload: mensagem,
});

export const filtrarTamanhoProdutos = (filtros) => ({
  type: FILTRAR_TAMANHO_PRODUTOS,
  payload: filtros,
});

export const gerarRelatorioTamanhoProdutos = (payload) => ({
  type: GERAR_RELATORIO_TAMANHO_PRODUTOS,
  payload: payload,
});

export const gerarRelatorioTamanhoProdutosSucesso = (alert) => ({
  type: GERAR_RELATORIO_TAMANHO_PRODUTOS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioTamanhoProdutosErro = (mensagem) => ({
  type: GERAR_RELATORIO_TAMANHO_PRODUTOS_ERRO,
  payload: mensagem,
});