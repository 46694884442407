import {
  GERAR_RELATORIO_PRODUTO,
  GERAR_RELATORIO_PRODUTO_ERRO,
  GERAR_RELATORIO_PRODUTO_SUCESSO,
  LISTAR_PRODUTO,
  LISTAR_PRODUTO_ERRO,
  LISTAR_PRODUTO_SUCESSO,
  FILTRAR_PRODUTO,
} from './constants';

export const obterTodosProduto = () => ({
  type: LISTAR_PRODUTO,
});

export const obterProdutoSucesso = (produtos) => ({
  type: LISTAR_PRODUTO_SUCESSO,
  payload: produtos,
});

export const obterProdutoErro = (mensagem) => ({
  type: LISTAR_PRODUTO_ERRO,
  payload: mensagem,
});

export const filtrarProduto = (filtros) => ({
  type: FILTRAR_PRODUTO,
  payload: filtros,
});

export const gerarRelatorioProdutos = (payload) => ({
  type: GERAR_RELATORIO_PRODUTO,
  payload: payload,
});

export const gerarRelatorioProdutosSucesso = (alert) => ({
  type: GERAR_RELATORIO_PRODUTO_SUCESSO,
  payload: alert,
});

export const gerarRelatorioProdutosErro = (mensagem) => ({
  type: GERAR_RELATORIO_PRODUTO_ERRO,
  payload: mensagem,
});