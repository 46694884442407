import { AxiosResponse } from 'axios';

import api from '../../services/api';
import { IMarcaVeiculoCadastro, IMarcaVeiculoConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterMarcasVeiculosAsync = (
  query
): Promise<AxiosResponse<{ result: IMarcaVeiculoConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `marcas-veiculos${queryStr}`,
    method: 'GET',
  });
};

export const obterMarcaVeiculoAsync = (id: number): Promise<AxiosResponse<IMarcaVeiculoConsulta>> => {
  return api.request({
    url: `marcas-veiculos/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoMarcaVeiculoAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `marcas-veiculos/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarMarcaVeiculoAsync = (
  marcaVeiculo: IMarcaVeiculoCadastro
): Promise<AxiosResponse<IMarcaVeiculoConsulta>> => {
  return api.request({
    url: `marcas-veiculos`,
    data: marcaVeiculo,
    method: 'POST',
  });
};

export const atualizarMarcaVeiculoAsync = (
  id: number,
  marcaVeiculo: IMarcaVeiculoCadastro
): Promise<AxiosResponse<IMarcaVeiculoConsulta>> => {
  return api.request({
    url: `marcas-veiculos/${id}`,
    data: marcaVeiculo,
    method: 'PATCH',
  });
};

export const removerMarcasVeiculosEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `marcas-veiculos`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerMarcaVeiculoAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `marcas-veiculos/${id}`,
    method: 'DELETE',
  });
