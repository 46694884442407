import React from 'react';
import { obterGruposAtividadesAsync } from '../../pages/grupos-atividades/requests';
import SelecaoAsyncBase, { SelectOption } from './SelecaoAsyncBase';

interface IFilters {
  status?: number[];
}

interface SelecaoGrupoAtividadeOption {
  value?: SelectOption;
  onChange?: (value: SelectOption) => void;
  multiple?: boolean;
  filters?: IFilters;
}

const SelecaoGrupoAtividade: React.FC<SelecaoGrupoAtividadeOption> = ({ filters, ...props }) => {
  const carregarGrupoAtividade = async (text) => {
    const busca = text;
    const buscarPor = isNaN(text) ? 'nome' : 'codigo';

    const { data } = await obterGruposAtividadesAsync({
      filtro: {
        buscarPor,
        busca,
        status: [1],
        ...filters,
      },
      ordenacao: {
        campo: 'nome',
        ordem: 'ASC',
      },
    });

    return data.result.map(formatarParaSelectOption);
  };

  const formatarParaSelectOption = (oc) => ({
    label: oc.codigo + ' - ' + oc.nome,
    value: oc.id,
  });

  return <SelecaoAsyncBase nomeRecurso="Grupo de Atividade" carregarOpcoes={carregarGrupoAtividade} {...props} />;
};

export default SelecaoGrupoAtividade;
