import {
  FILTRAR_UNIDADE_MEDIDA_PRODUTO,
  GERAR_RELATORIO_UNIDADE_MEDIDA_PRODUTO,
  GERAR_RELATORIO_UNIDADE_MEDIDA_PRODUTO_ERRO,
  GERAR_RELATORIO_UNIDADE_MEDIDA_PRODUTO_SUCESSO,
  LISTAR_UNIDADE_MEDIDA_PRODUTO,
  LISTAR_UNIDADE_MEDIDA_PRODUTO_ERRO,
  LISTAR_UNIDADE_MEDIDA_PRODUTO_SUCESSO,
} from './constants';

export const obterTodosUnidadeMedidaProduto = () => ({
  type: LISTAR_UNIDADE_MEDIDA_PRODUTO,
});

export const obterUnidadeMedidaProdutoSucesso = (UnidadeMedidaProduto) => ({
  type: LISTAR_UNIDADE_MEDIDA_PRODUTO_SUCESSO,
  payload: UnidadeMedidaProduto,
});

export const obterUnidadeMedidaProdutoErro = (mensagem) => ({
  type: LISTAR_UNIDADE_MEDIDA_PRODUTO_ERRO,
  payload: mensagem,
});

export const filtrarUnidadeMedidaProduto = (filtros) => ({
  type: FILTRAR_UNIDADE_MEDIDA_PRODUTO,
  payload: filtros,
});

export const gerarRelatorioUnidadeMedidaProduto = (payload) => ({
  type: GERAR_RELATORIO_UNIDADE_MEDIDA_PRODUTO,
  payload: payload,
});

export const gerarRelatorioUnidadeMedidaProdutoSucesso = (alert) => ({
  type: GERAR_RELATORIO_UNIDADE_MEDIDA_PRODUTO_SUCESSO,
  payload: alert,
});

export const gerarRelatorioUnidadeMedidaProdutoErro = (mensagem) => ({
  type: GERAR_RELATORIO_UNIDADE_MEDIDA_PRODUTO_ERRO,
  payload: mensagem,
});