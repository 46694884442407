import { AxiosResponse } from 'axios';

import api from '../../services/api';
import { IEstadoCadastro, IEstadoConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterEstadosAsync = (query): Promise<AxiosResponse<{ result: IEstadoConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `estado${queryStr}`,
    method: 'GET',
  });
};

export const obterEstadoAsync = (id: number): Promise<AxiosResponse<IEstadoConsulta>> => {
  return api.request({
    url: `estado/${id}`,
    method: 'GET',
  });
};

export const obterEstadoPorCodigoIBGEAsync = (codigoIBGE: number | string): Promise<AxiosResponse<IEstadoConsulta>> => {
  return api.request({
    url: `estado/obter-por-codigoIBGE/${codigoIBGE}`,
    method: 'GET',
  });
};

export const criarEstadoAsync = (estado: IEstadoCadastro): Promise<AxiosResponse<IEstadoConsulta>> => {
  return api.request({
    url: `estado`,
    data: estado,
    method: 'POST',
  });
};

export const atualizarEstadoAsync = (id: number, estado: IEstadoCadastro): Promise<AxiosResponse<IEstadoConsulta>> => {
  return api.request({
    url: `estado/${id}`,
    data: estado,
    method: 'PATCH',
  });
};

export const removerEstadosEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `estado`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerEstadoAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `estado/${id}`,
    method: 'DELETE',
  });
