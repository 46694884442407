import {
  FILTRAR_INTEGRADORES,
  LISTAR_INTEGRADORES,
  LISTAR_INTEGRADORES_ERRO,
  LISTAR_INTEGRADORES_SUCESSO,
} from './constants';

export const obterTodosIntegradores = () => ({
  type: LISTAR_INTEGRADORES,
});

export const obterIntegradoresSucesso = (Integradores) => ({
  type: LISTAR_INTEGRADORES_SUCESSO,
  payload: Integradores,
});

export const obterIntegradoresErro = (mensagem) => ({
  type: LISTAR_INTEGRADORES_ERRO,
  payload: mensagem,
});

export const filtrarIntegradores = (filtros) => ({
  type: FILTRAR_INTEGRADORES,
  payload: filtros,
});