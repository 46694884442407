import {
    LISTAR_USUARIOS_SUCESSO,
    GERAR_RELATORIO_USUARIOS,
    GERAR_RELATORIO_USUARIOS_ERRO,
    GERAR_RELATORIO_USUARIOS_SUCESSO,
    LISTAR_USUARIOS_ERRO,
    LISTAR_USUARIOS,
    FILTRAR_USUARIOS,
} from './constants';

export const obterTodosUsuarios = () => ({
    type: LISTAR_USUARIOS,
})

export const obterUsuariosSucesso = (usuarios) => ({
    type: LISTAR_USUARIOS_SUCESSO,
    payload: usuarios,
});

export const obterUsuariosErro = (mensagem) => ({
    type: LISTAR_USUARIOS_ERRO,
    payload: mensagem,
})

export const filtrarUsuarios = (filtros) => ({
    type: FILTRAR_USUARIOS,
    payload: filtros,
})

export const gerarRelatorioUsuarios = (payload) => ({
    type: GERAR_RELATORIO_USUARIOS,
    payload: payload,
  });
  
  export const gerarRelatorioUsuariosSucesso = (alert) => ({
    type: GERAR_RELATORIO_USUARIOS_SUCESSO,
    payload: alert,
  });
  
  export const gerarRelatorioUsuariosErro = (mensagem) => ({
    type: GERAR_RELATORIO_USUARIOS_ERRO,
    payload: mensagem,
  });