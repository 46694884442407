import { AxiosResponse } from 'axios';

import api from '../../services/api';
import { IConceitoQualidadeCadastro, IConceitoQualidadeConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterConceitosQualidadeAsync = (
  query,
): Promise<AxiosResponse<{ result: IConceitoQualidadeConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `conceitos-qualidade${queryStr}`,
    method: 'GET',
  });
};

export const obterConceitoQualidadeAsync = (id: number): Promise<AxiosResponse<IConceitoQualidadeConsulta>> => {
  return api.request({
    url: `conceitos-qualidade/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoConceitoQualidadeAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `conceitos-qualidade/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarConceitoQualidadeAsync = (
  conceitoQualidade: IConceitoQualidadeCadastro,
): Promise<AxiosResponse<IConceitoQualidadeConsulta>> => {
  return api.request({
    url: `conceitos-qualidade`,
    data: conceitoQualidade,
    method: 'POST',
  });
};

export const atualizarConceitoQualidadeAsync = (
  id: number,
  conceitoQualidade: IConceitoQualidadeCadastro,
): Promise<AxiosResponse<IConceitoQualidadeConsulta>> => {
  return api.request({
    url: `conceitos-qualidade/${id}`,
    data: conceitoQualidade,
    method: 'PATCH',
  });
};

export const removerConceitosQualidadeEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `conceitos-qualidade`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerConceitoQualidadeAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `conceitos-qualidade/${id}`,
    method: 'DELETE',
  });
