import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { FILTRAR_ENTRADA_FRUTAS, LISTAR_ENTRADA_FRUTAS } from './constants';

import { ProgramaCodigo } from '../../../constants/enums/programa-codigo.enum';
import { SET_LIMIT_PAGE, SET_TABLE_ORDER } from '../../../redux/table/constants';
import { montarFiltroParaApi } from '../../../utils/api-filter';
import { obterEntradasFrutasAsync } from '../requests';
import { obterEntradaFrutasErro, obterEntradaFrutasSucesso } from './actions';

function* listarTodosEntradaFrutas() {
  try {
    const filtro = yield select((state) => state.EntradaFrutas.filtros);
    const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.EntradaFrutas]);

    let query = { filtro: montarFiltroParaApi(filtro) };

    if (preferencias) {
      query.ordenacao = {
        campo: preferencias?.ordenarPor || 'id',
        ordem: preferencias?.ordem?.toUpperCase() || 'DESC',
      };
      query.paginacao = {
        pagina: preferencias?.page || 1,
        limite: preferencias?.limit || 50,
      };
    }
    const { data } = yield call(obterEntradasFrutasAsync, query);

    yield put(obterEntradaFrutasSucesso(data));
  } catch (error) {
    console.error(error);
    yield put(obterEntradaFrutasErro(error.response?.data?.message || error.message));
  }
}

function* ordenarEntradaFrutas({ payload }) {
  if (payload.programaId === ProgramaCodigo.EntradaFrutas) {
    yield call(listarTodosEntradaFrutas);
  }
}

function* mudarLimitePaginacao({ payload }) {
  if (payload.programaId === ProgramaCodigo.PerfilDeAcesso) {
    yield call(listarTodosEntradaFrutas);
  }
}

export function* watchListarEntradaFrutas() {
  yield takeLatest(LISTAR_ENTRADA_FRUTAS, listarTodosEntradaFrutas);
}

export function* watchFiltrarEntradaFrutas() {
  yield takeLatest(FILTRAR_ENTRADA_FRUTAS, listarTodosEntradaFrutas);
}

export function* watchOrdenarEntradaFrutas() {
  yield takeLatest(SET_TABLE_ORDER, ordenarEntradaFrutas);
}

export function* watchLimitePaginacao() {
  yield takeLatest(SET_LIMIT_PAGE, mudarLimitePaginacao);
}

function* EntradaFrutasSaga() {
  yield all([
    fork(watchListarEntradaFrutas),
    fork(watchFiltrarEntradaFrutas),
    fork(watchOrdenarEntradaFrutas),
    fork(watchLimitePaginacao),
  ]);
}

export default EntradaFrutasSaga;
