import {
  FILTRAR_LANCAMENTOS_CUSTOS,
  GERAR_RELATORIO_LANCAMENTOS_CUSTOS,
  GERAR_RELATORIO_LANCAMENTOS_CUSTOS_ERRO,
  GERAR_RELATORIO_LANCAMENTOS_CUSTOS_SUCESSO,
  LISTAR_LANCAMENTOS_CUSTOS,
  LISTAR_LANCAMENTOS_CUSTOS_ERRO,
  LISTAR_LANCAMENTOS_CUSTOS_SUCESSO,
} from './constants';

export const obterTodosLancamentosCustos = () => ({
  type: LISTAR_LANCAMENTOS_CUSTOS,
});

export const obterLancamentosCustosSucesso = (lancamentosCustos) => ({
  type: LISTAR_LANCAMENTOS_CUSTOS_SUCESSO,
  payload: lancamentosCustos,
});

export const obterLancamentosCustosErro = (mensagem) => ({
  type: LISTAR_LANCAMENTOS_CUSTOS_ERRO,
  payload: mensagem,
});

export const filtrarLancamentosCustos = (filtros) => ({
  type: FILTRAR_LANCAMENTOS_CUSTOS,
  payload: filtros,
});

export const gerarRelatorioLancamentosCustos = (payload) => ({
  type: GERAR_RELATORIO_LANCAMENTOS_CUSTOS,
  payload: payload,
});

export const gerarRelatorioLancamentosCustosSucesso = (alert) => ({
  type: GERAR_RELATORIO_LANCAMENTOS_CUSTOS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioLancamentosCustosErro = (mensagem) => ({
  type: GERAR_RELATORIO_LANCAMENTOS_CUSTOS_ERRO,
  payload: mensagem,
});
