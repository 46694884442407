import { AxiosResponse } from 'axios';
import api from '../../services/api';
import { IPedidoCadastro, IPedidoConsulta, IPedidosProdutoConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterPedidosAsync = (query): Promise<AxiosResponse<{ result: IPedidoConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `pedidos${queryStr}`,
    method: 'GET',
  });
};

export const obterPedidoAsync = (id: number): Promise<AxiosResponse<any>> => {
  return api.request({
    url: `pedidos/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoPedidoAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `pedidos/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarPedidoAsync = (Pedido: IPedidoCadastro): Promise<AxiosResponse<IPedidoConsulta>> => {
  return api.request({
    url: `pedidos`,
    data: Pedido,
    method: 'POST',
  });
};

export const atualizarPedidoAsync = (id: number, Pedido: IPedidoCadastro): Promise<AxiosResponse<IPedidoConsulta>> => {
  return api.request({
    url: `pedidos/${id}`,
    data: Pedido,
    method: 'PATCH',
  });
};

export const desistenciaPedidoAsync = (
  produtos: { id: number; quantidadeDesistida: number }[],
): Promise<AxiosResponse> => {
  return api.request({
    url: `pedidos/desistencia`,
    data: produtos,
    method: 'PATCH',
  });
};

export const removerPedidosEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `pedidos`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerPedidoAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `pedidos/${id}`,
    method: 'DELETE',
  });

export const obterItensPedidos = (query: {
  pedidosIds?: number[];
}): Promise<AxiosResponse<IPedidosProdutoConsulta[]>> => {
  const queryStr = montarQueryString(query);
  return api.request({
    url: `pedidos/obter-itens-faturamento${queryStr}`,
    method: 'GET',
  });
};

export const obterItensDesistencia = (query: {
  pedidosIds?: number[];
}): Promise<AxiosResponse<{ produtos: IPedidosProdutoConsulta[] }>> => {
  const queryStr = montarQueryString(query);
  return api.request({
    url: `pedidos/obter-itens-desistencia${queryStr}`,
    method: 'GET',
  });
};
