import {
  FILTRAR_GRUPOS_QUIMICOS,
  GERAR_RELATORIO_GRUPOS_QUIMICOS,
  GERAR_RELATORIO_GRUPOS_QUIMICOS_ERRO,
  GERAR_RELATORIO_GRUPOS_QUIMICOS_SUCESSO,
  LISTAR_GRUPOS_QUIMICOS,
  LISTAR_GRUPOS_QUIMICOS_ERRO,
  LISTAR_GRUPOS_QUIMICOS_SUCESSO,
} from './constants';

export const obterTodosGruposQuimicos = () => ({
  type: LISTAR_GRUPOS_QUIMICOS,
});

export const obterGruposQuimicosSucesso = (gruposQuimicos) => ({
  type: LISTAR_GRUPOS_QUIMICOS_SUCESSO,
  payload: gruposQuimicos,
});

export const obterGruposQuimicosErro = (mensagem) => ({
  type: LISTAR_GRUPOS_QUIMICOS_ERRO,
  payload: mensagem,
});

export const filtrarGruposQuimicos = (filtros) => ({
  type: FILTRAR_GRUPOS_QUIMICOS,
  payload: filtros,
});
export const gerarRelatorioGruposQuimicos = (payload) => ({
  type: GERAR_RELATORIO_GRUPOS_QUIMICOS,
  payload: payload,
});

export const gerarRelatorioGruposQuimicosSucesso = (alert) => ({
  type: GERAR_RELATORIO_GRUPOS_QUIMICOS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioGruposQuimicosErro = (mensagem) => ({
  type: GERAR_RELATORIO_GRUPOS_QUIMICOS_ERRO,
  payload: mensagem,
});