import {
  FILTRAR_CONTAS_RECEBER,
  GERAR_RELATORIO_CONTAS_RECEBER,
  GERAR_RELATORIO_CONTAS_RECEBER_ERRO,
  GERAR_RELATORIO_CONTAS_RECEBER_SUCESSO,
  LISTAR_CONTAS_RECEBER,
  LISTAR_CONTAS_RECEBER_ERRO,
  LISTAR_CONTAS_RECEBER_SUCESSO,
} from './constants';

export const obterTodosContasReceber = () => ({
  type: LISTAR_CONTAS_RECEBER,
});

export const obterContasReceberSucesso = (contasReceber) => ({
  type: LISTAR_CONTAS_RECEBER_SUCESSO,
  payload: contasReceber,
});

export const obterContasReceberErro = (mensagem) => ({
  type: LISTAR_CONTAS_RECEBER_ERRO,
  payload: mensagem,
});

export const filtrarContasReceber = (filtros) => ({
  type: FILTRAR_CONTAS_RECEBER,
  payload: filtros,
});

export const gerarRelatorioContasReceber = (payload) => ({
  type: GERAR_RELATORIO_CONTAS_RECEBER,
  payload: payload,
});

export const gerarRelatorioContasReceberSucesso = (alert) => ({
  type: GERAR_RELATORIO_CONTAS_RECEBER_SUCESSO,
  payload: alert,
});

export const gerarRelatorioContasReceberErro = (mensagem) => ({
  type: GERAR_RELATORIO_CONTAS_RECEBER_ERRO,
  payload: mensagem,
});