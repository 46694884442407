import api from '../../services/api';
import { montarQueryString } from '../../utils';
import { ITributacaoFederalPisCofinsConsulta, ITributacaoFederalPisCofinsCadastro} from './interfaces';

export const obterTributacaoFederaisPisCofinsAsync = (query = {}) => {
  const querystr = montarQueryString(query);

  return api.request<{ result: ITributacaoFederalPisCofinsConsulta[]; total: number }>({
    url: `tributacao-federal-pis-cofins${querystr}`,
    method: 'GET',
  });
};



export const obterTributacaoFederalPisCofinsAsync = (id: number) => {
  return api.request<ITributacaoFederalPisCofinsConsulta>({
    url: `tributacao-federal-pis-cofins/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoTributacaoFederalPisCofinsAsync = () => {
  return api.request<number>({
    url: `tributacao-federal-pis-cofins/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarTributacaoFederalPisCofinsAsync = (tributacaoFederalPisCofins: ITributacaoFederalPisCofinsCadastro) => {
  return api.request<ITributacaoFederalPisCofinsConsulta>({
    url: `tributacao-federal-pis-cofins`,
    method: 'POST',
    data: tributacaoFederalPisCofins,
  });
};

export const atualizarTributacaoFederalPisCofinsAsync = (id: number, tributacaoFederalPisCofins: ITributacaoFederalPisCofinsCadastro) => {
  return api.request<ITributacaoFederalPisCofinsConsulta>({
    url: `tributacao-federal-pis-cofins/${id}`,
    method: 'PATCH',
    data: tributacaoFederalPisCofins,
  });
};


export const removerTributacaoFederalPisCofinsEmMassaAsync = (ids: number[]) => {
  return api.request<{ affected: number }>({
    url: `tributacao-federal-pis-cofins`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerTributacaoFederalPisCofinsAsync = (id: number) =>
  api.request<{ affected: number }>({
    url: `tributacao-federal-pis-cofins/${id}`,
    method: 'DELETE',
  });
