import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { ProgramaCodigo } from '../../../constants/enums/programa-codigo.enum';
import { FILTRAR_TRIBUTACOES_SERVICOS, LISTAR_TRIBUTACOES_SERVICOS } from './constants';
import { obterTributacoesServicosAsync } from '../requests';
import { obterTributacoesServicosErro, obterTributacoesServicosSucesso } from './actions';
import { SET_LIMIT_PAGE, SET_TABLE_ORDER } from '../../../redux/table/constants';
import { montarFiltroParaApi } from '../../../utils/api-filter';

function* listarTodosTributacoesServicos() {
  try {
    const filtro = yield select((state) => (state.TributacoesServicos.filtros));
    const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.TributacoesServicos]);
    
    let query = { filtro: montarFiltroParaApi(filtro) };

    if (preferencias) {
      query.ordenacao = {
        campo: preferencias?.ordenarPor || 'nome',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      };
      query.paginacao = {
        pagina: preferencias?.page || 1,
        limite: preferencias?.limit || 50,
      };
    }

    const { data } = yield call(obterTributacoesServicosAsync, query);
    yield put(obterTributacoesServicosSucesso(data));
  } catch (error) {
    yield put(obterTributacoesServicosErro(error.response?.data?.message || error.message));
  }
}

function* ordenarTributacoesServicos({ payload }) {
  if (payload.programaId === ProgramaCodigo.TributacoesServicos) {
    yield call(listarTodosTributacoesServicos);
  }
}

function* mudarLimitePaginacao({ payload }) {
  if (payload.programaId === ProgramaCodigo.TributacoesServicos) {
    yield call(listarTodosTributacoesServicos);
  }
}

export function* watchListarTributacoesServicos() {
  yield takeLatest(LISTAR_TRIBUTACOES_SERVICOS, listarTodosTributacoesServicos);
}

export function* watchFiltrarTributacoesServicos() {
  yield takeLatest(FILTRAR_TRIBUTACOES_SERVICOS, listarTodosTributacoesServicos);
}

export function* watchOrdenarTributacoesServicos() {
  yield takeLatest(SET_TABLE_ORDER, ordenarTributacoesServicos);
}

export function* watchLimitePaginacao() {
  yield takeLatest(SET_LIMIT_PAGE, mudarLimitePaginacao);
}

function* tributacoesServicosSaga() {
  yield all([fork(watchListarTributacoesServicos), fork(watchFiltrarTributacoesServicos), fork(watchOrdenarTributacoesServicos), fork(watchLimitePaginacao)]);
}

export default tributacoesServicosSaga;
