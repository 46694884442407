import { AxiosResponse } from 'axios';
import api from '../../services/api';
import { ICentroCustoCadastro, ICentroCustoConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterCentrosCustosAsync = (
  query
): Promise<AxiosResponse<{ result: ICentroCustoConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `centros-custos${queryStr}`,
    method: 'GET',
  });
};

export const obterCentroCustoAsync = (id: number): Promise<AxiosResponse<ICentroCustoConsulta>> => {
  return api.request({
    url: `centros-custos/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoCentroCustoAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `centros-custos/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarCentroCustoAsync = (
  CentroCusto: ICentroCustoCadastro
): Promise<AxiosResponse<ICentroCustoConsulta>> => {
  return api.request({
    url: `centros-custos`,
    data: CentroCusto,
    method: 'POST',
  });
};

export const atualizarCentroCustoAsync = (
  id: number,
  CentroCusto: ICentroCustoCadastro
): Promise<AxiosResponse<ICentroCustoConsulta>> => {
  return api.request({
    url: `centros-custos/${id}`,
    data: CentroCusto,
    method: 'PATCH',
  });
};

export const removerCentrosCustosEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `centros-custos`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerCentroCustoAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `centros-custos/${id}`,
    method: 'DELETE',
  });
