import Swal from 'sweetalert2';
import { filtroPadraoStatus } from '../../../constants/filtros';
import {
  FILTRAR_MODELOS_ETIQUETA,
  LISTAR_MODELOS_ETIQUETA,
  LISTAR_MODELOS_ETIQUETA_ERRO,
  LISTAR_MODELOS_ETIQUETA_SUCESSO,
} from './constants';

const INIT_STATE = {
  modelosEtiquetas: [],
  filtros: { ...filtroPadraoStatus },
  carregando: false,
  relatorio: {},
  gerandoRelatorio: false,
};

const ModelosEtiquetasReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LISTAR_MODELOS_ETIQUETA:
      return { ...state, carregando: true };
    case FILTRAR_MODELOS_ETIQUETA:
      return { ...state, filtros: action.payload, carregando: true };
    case LISTAR_MODELOS_ETIQUETA_SUCESSO:
      return { ...state, modelosEtiquetas: action.payload, carregando: false };
    case LISTAR_MODELOS_ETIQUETA_ERRO:
      Swal.fire({
        icon: 'error',
        title: 'Erro ao buscar Modelos de Etiquetas',
        timer: 2000,
        text: action.payload,
      });
      return { ...state, modelosEtiquetas: [], carregando: false };

    default:
      return state;
  }
};

export default ModelosEtiquetasReducer;
