import { AxiosResponse } from 'axios';

import api from '../../services/api';
import { montarQueryString } from '../../utils';
import { IGrupoAtividadeCadastro, IGrupoAtividadeConsulta } from './interfaces';

export const obterGruposAtividadesAsync = (
  query,
): Promise<AxiosResponse<{ result: IGrupoAtividadeConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `grupos-atividades${queryStr}`,
    method: 'GET',
  });
};

export const obterGruposAtividadeParaUsuario = (query = {}) => {
  const querystr = montarQueryString(query);

  return api.request<IGrupoAtividadeConsulta[]>({
    url: `grupos-atividades/grupos-atividade-usuario${querystr}`,
    method: 'GET',
  });
};

export const salvarIconeGrupoAtividadeAsync = (grupoAtividadeId: number, file: File) => {
  let formData = new FormData();
  formData.append('file', file);

  return api.request({
    url: `grupos-atividades/${grupoAtividadeId}/icone`,
    method: 'POST',
    data: formData,
  });
};

export const obterGrupoAtividadeAsync = (id: number): Promise<AxiosResponse<IGrupoAtividadeConsulta>> => {
  return api.request({
    url: `grupos-atividades/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoGrupoAtividadeAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `grupos-atividades/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarGrupoAtividadeAsync = (
  grupoAtividade: IGrupoAtividadeCadastro,
): Promise<AxiosResponse<IGrupoAtividadeConsulta>> => {
  return api.request({
    url: `grupos-atividades`,
    data: grupoAtividade,
    method: 'POST',
  });
};

export const atualizarGrupoAtividadeAsync = (
  id: number,
  grupoAtividade: IGrupoAtividadeCadastro,
): Promise<AxiosResponse<IGrupoAtividadeConsulta>> => {
  return api.request({
    url: `grupos-atividades/${id}`,
    data: grupoAtividade,
    method: 'PATCH',
  });
};

export const removerGruposAtividadesEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `grupos-atividades`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerGrupoAtividadeAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `grupos-atividades/${id}`,
    method: 'DELETE',
  });

export const deletarIconeGrupoAtividadeAsync = async (grupoAtividadeId: number) => {
  return api.request({
    url: `grupos-atividades/${grupoAtividadeId}/deletar-icone`,
    method: 'PATCH',
  });
};
