import {
  FILTRAR_MARCAS_VEICULOS,
  GERAR_RELATORIO_MARCAS_VEICULOS,
  GERAR_RELATORIO_MARCAS_VEICULOS_ERRO,
  GERAR_RELATORIO_MARCAS_VEICULOS_SUCESSO,
  LISTAR_MARCAS_VEICULOS,
  LISTAR_MARCAS_VEICULOS_ERRO,
  LISTAR_MARCAS_VEICULOS_SUCESSO,
} from './constants';

export const obterTodosMarcasVeiculos = () => ({
  type: LISTAR_MARCAS_VEICULOS,
});

export const obterMarcasVeiculosSucesso = (marcasVeiculos) => ({
  type: LISTAR_MARCAS_VEICULOS_SUCESSO,
  payload: marcasVeiculos,
});

export const obterMarcasVeiculosErro = (mensagem) => ({
  type: LISTAR_MARCAS_VEICULOS_ERRO,
  payload: mensagem,
});

export const filtrarMarcasVeiculos = (filtros) => ({
  type: FILTRAR_MARCAS_VEICULOS,
  payload: filtros,
});

export const gerarRelatorioMarcasVeiculos = (payload) => ({
  type: GERAR_RELATORIO_MARCAS_VEICULOS,
  payload: payload,
});

export const gerarRelatorioMarcasVeiculosSucesso = (alert) => ({
  type: GERAR_RELATORIO_MARCAS_VEICULOS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioMarcasVeiculosErro = (mensagem) => ({
  type: GERAR_RELATORIO_MARCAS_VEICULOS_ERRO,
  payload: mensagem,
});
