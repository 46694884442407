import moment from 'moment';

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const onlyNums = (value: string) => {
  if (value === undefined) return value;
  return Number(value.replace(/[^\d]/g, ''));
};

export const formatStringToMoney = (value: string) => {
  const number = onlyNums(value);

  return `R$ ${(number / 100)
    .toFixed(2)
    .replace('.', ',')
    .split('')
    .reverse()
    .map((v, i) => (i > 5 && (i + 6) % 3 === 0 ? `${v}.` : v))
    .reverse()
    .join('')}`;
};

// Formatação de R$ 10,00 para 10.00
export const formatMoneyToDecimal = (value: string) => {
  return value.split('R$ ')[1].replace(',', '.');
};

// Transforma o date do back-end para o formato DD/MM/YYYY
export const formatDateAndHour = (value: string | Date) => {
  if (!value) return value;

  return moment(value).add(3, 'h').format('DD/MM/YYYY HH:mm');
};

// Transforma o date do back-end para o formato DD/MM/YYYY
export const formatDate = (value: string | Date) => {
  if (!value) return value;

  return moment(value).format('DD/MM/YYYY');
};

// Transforma o date do back-end para o formato DD/MM/YYYY
export const formatHour = (value: string | Date, format?: string) => {
  if (!value) return value;

  return moment(value).format(format || 'HH:mm');
};

export const convertStringToFunction = (stringFunction) => {
  const funcBody = stringFunction.match(/function[^{]+\{([\s\S]*)\}$/)[1];
  // eslint-disable-next-line
  return new Function('props', funcBody);
};

// preenche com caracter à esquerda
export const lpad = (caracter: string, quantidade: number, valor: any): string => {
  return (caracter.padStart(quantidade, caracter) + valor).slice(-quantidade);
};

export const formatarDecimal = (valor: number | string | undefined, casasDecimais?: number): string => {
  if (typeof valor === 'string') {
    valor = Number(valor);
  }

  return (valor || 0).toLocaleString('pt-BR', {
    minimumFractionDigits: casasDecimais || 0,
    maximumFractionDigits: casasDecimais || 0,
  });
};

export const removerAcentos = (texto: string) => {
  return texto.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};
