import {
  FILTRAR_CATEGORIAS_PRODUTOS_AGRO,
  GERAR_RELATORIO_CATEGORIAS_PRODUTOS_AGRO,
  GERAR_RELATORIO_CATEGORIAS_PRODUTOS_AGRO_ERRO,
  GERAR_RELATORIO_CATEGORIAS_PRODUTOS_AGRO_SUCESSO,
  LISTAR_CATEGORIAS_PRODUTOS_AGRO,
  LISTAR_CATEGORIAS_PRODUTOS_AGRO_ERRO,
  LISTAR_CATEGORIAS_PRODUTOS_AGRO_SUCESSO,
} from './constants';

export const obterTodosCategoriasProdutosAgro = () => ({
  type: LISTAR_CATEGORIAS_PRODUTOS_AGRO,
});

export const obterCategoriasProdutosAgroSucesso = (categoriaProdutoAgro) => ({
  type: LISTAR_CATEGORIAS_PRODUTOS_AGRO_SUCESSO,
  payload: categoriaProdutoAgro,
});

export const obterCategoriasProdutosAgroErro = (mensagem) => ({
  type: LISTAR_CATEGORIAS_PRODUTOS_AGRO_ERRO,
  payload: mensagem,
});

export const filtrarCategoriasProdutosAgro = (filtros) => ({
  type: FILTRAR_CATEGORIAS_PRODUTOS_AGRO,
  payload: filtros,
});

export const gerarRelatorioCategoriasProdutosAgro = (payload) => ({
  type: GERAR_RELATORIO_CATEGORIAS_PRODUTOS_AGRO,
  payload: payload,
});

export const gerarRelatorioCategoriasProdutosAgroSucesso = (alert) => ({
  type: GERAR_RELATORIO_CATEGORIAS_PRODUTOS_AGRO_SUCESSO,
  payload: alert,
});

export const gerarRelatorioCategoriasProdutosAgroErro = (mensagem) => ({
  type: GERAR_RELATORIO_CATEGORIAS_PRODUTOS_AGRO_ERRO,
  payload: mensagem,
});
