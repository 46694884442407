import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { ProgramaCodigo } from '../../../constants/enums/programa-codigo.enum';
import { SET_LIMIT_PAGE, SET_TABLE_ORDER } from '../../../redux/table/constants';
import { DEFAULT_DOWNLOAD_SUCCESS_MESSAGE, gerarRelatorioRacing } from '../../../services/requests/relatorio';
import { montarFiltroParaApi } from '../../../utils/api-filter';
import { obterVendasColheitasAsync } from '../requests';
import {
  gerarRelatorioVendasColheitasErro,
  gerarRelatorioVendasColheitasSucesso,
  obterVendasColheitasErro,
  obterVendasColheitasSucesso,
} from './actions';
import { FILTRAR_VENDAS_COLHEITAS, GERAR_RELATORIO_VENDAS_COLHEITAS, LISTAR_VENDAS_COLHEITAS } from './constants';

function* listarTodosVendasColheitas() {
  try {
    const filtro = yield select((state) => state.VendasColheitas.filtros);
    const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.VendasColheitas]);

    let query = { filtro: montarFiltroParaApi(filtro) };

    if (preferencias) {
      query.ordenacao = {
        campo: preferencias?.ordenarPor || 'dataCriacao',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      };
      query.paginacao = {
        pagina: preferencias?.page || 1,
        limite: preferencias?.limit || 50,
      };
    }

    const { data } = yield call(obterVendasColheitasAsync, query);
    yield put(obterVendasColheitasSucesso(data));
  } catch (error) {
    yield put(obterVendasColheitasErro(error.response?.data?.message || error.message));
  }
}

function* relatorioVendasColheitas() {
  const { filtros, relatorio } = yield select((state) => state.VendasColheitas);
  const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.VendasColheitas]);
  const { modelo, tipo, comFiltro, colunas } = relatorio;
  const columns = colunas?.map((coluna) => coluna.value);
  let query = {};
  if (comFiltro) {
    query = {
      filtro: {
        ...montarFiltroParaApi(filtros),
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
      ordenacao: {
        campo: preferencias?.ordenarPor || 'dataCriacao',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      },
    };
  } else {
    query = {
      filtro: {
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
    };
  }
  try {
    const { data, headers } = yield call(gerarRelatorioRacing, ProgramaCodigo.VendasColheitas, query, tipo);

    if (headers['content-type'].includes('application/json')) {
      yield put(
        gerarRelatorioVendasColheitasSucesso({
          titulo: 'Pedido de impressão salvo.',
          mensagem: 'Seu relatório está sendo gerado.',
        }),
      );
    } else {
      const url = URL.createObjectURL(
        new Blob([data], {
          type: headers['content-type'] || 'application/pdf',
        }),
      );

      if (url) {
        yield put(gerarRelatorioVendasColheitasSucesso(DEFAULT_DOWNLOAD_SUCCESS_MESSAGE));
        window.open(url, '_blank');
      }
    }
  } catch (e) {
    yield put(
      gerarRelatorioVendasColheitasErro('Ocorreu um erro ao tentar gerar o relatório. Tente novamente mais tarde.'),
    );
  }
}

function* ordenarVendasColheitas({ payload }) {
  if (payload.programaId === ProgramaCodigo.VendasColheitas) {
    yield call(listarTodosVendasColheitas);
  }
}

function* mudarLimitePaginacao({ payload }) {
  if (payload.programaId === ProgramaCodigo.VendasColheitas) {
    yield call(listarTodosVendasColheitas);
  }
}

export function* watchListarVendasColheitas() {
  yield takeLatest(LISTAR_VENDAS_COLHEITAS, listarTodosVendasColheitas);
}

export function* watchFiltrarVendasColheitas() {
  yield takeLatest(FILTRAR_VENDAS_COLHEITAS, listarTodosVendasColheitas);
}

export function* watchRelatorioVendasColheitas() {
  yield takeLatest(GERAR_RELATORIO_VENDAS_COLHEITAS, relatorioVendasColheitas);
}

export function* watchOrdenarVendasColheitas() {
  yield takeLatest(SET_TABLE_ORDER, ordenarVendasColheitas);
}

export function* watchLimitePaginacao() {
  yield takeLatest(SET_LIMIT_PAGE, mudarLimitePaginacao);
}

function* vendasColheitasSaga() {
  yield all([
    fork(watchListarVendasColheitas),
    fork(watchFiltrarVendasColheitas),
    fork(watchRelatorioVendasColheitas),
    fork(watchOrdenarVendasColheitas),
    fork(watchLimitePaginacao),
  ]);
}

export default vendasColheitasSaga;
