import api from '../../services/api';
import { montarQueryString } from '../../utils';
import { ILancamentoAtividadeColheitaConsulta } from './cadastro/Colheita/interfaces';
import { IEtiquetaRastreabilidade } from './etiqueta/interfaces';
import { ILancamentoAtividadeCadastro, ILancamentoAtividadeConsulta, IProdutorEtiqueta } from './interfaces';

export const obterLancamentosAtividadesAsync = (query = {}) => {
  const querystr = montarQueryString(query);

  return api.request<{ result: ILancamentoAtividadeConsulta[]; total: number }>({
    url: `lancamentos-atividades${querystr}`,
    method: 'GET',
  });
};

export const obterLancamentosAtividadesColheitasAsync = (query = {}) => {
  const querystr = montarQueryString(query);

  return api.request<{ result: ILancamentoAtividadeColheitaConsulta[]; total: number }>({
    url: `lancamentos-atividades/colheitas${querystr}`,
    method: 'GET',
  });
};

export const obterProdutoresEtiquetaAsync = (query = {}) => {
  const querystr = montarQueryString(query);

  return api.request<{ result: IProdutorEtiqueta[]; total: number }>({
    url: `lancamentos-atividades/produtores-etiqueta${querystr}`,
    method: 'GET',
  });
};

export const obterLancamentoAtividadeAsync = (id: number) => {
  return api.request<ILancamentoAtividadeConsulta>({
    url: `lancamentos-atividades/${id}`,
    method: 'GET',
  });
};

export const obterUltimoHorimetroMaquinaAsync = (maquinaId: number) => {
  return api.request<number>({
    url: `lancamentos-atividades/ultimo-horimetro-maquina/${maquinaId}`,
    method: 'GET',
  });
};

export const obterPrecoCustoEntradaProdutoAsync = (produtoBaseId: number, data: string) => {
  return api.request<number>({
    url: `lancamentos-atividades/preco-custo-entrada-produto/${produtoBaseId}/${data}`,
    method: 'GET',
  });
};

export const criarLancamentoAtividadeAsync = (lancamentoAtividade: ILancamentoAtividadeCadastro) => {
  return api.request<ILancamentoAtividadeConsulta>({
    url: `lancamentos-atividades`,
    method: 'POST',
    data: lancamentoAtividade,
  });
};

export const atualizarLancamentoAtividadeAsync = (id: number, lancamentoAtividade: ILancamentoAtividadeCadastro) => {
  return api.request<ILancamentoAtividadeConsulta>({
    url: `lancamentos-atividades/${id}`,
    method: 'PATCH',
    data: lancamentoAtividade,
  });
};

export const removerLancamentosAtividadesEmMassaAsync = (ids: number[]) => {
  return api.request<{ affected: number }>({
    url: `lancamentos-atividades`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerLancamentoAtividadeAsync = (id: number) => {
  return api.request<{ affected: number }>({
    url: `lancamentos-atividades/${id}`,
    method: 'DELETE',
  });
};

export const gerarEtiquetaRastreabilidadeAsync = (dadosEtiqueta: IEtiquetaRastreabilidade) => {
  return api.request<Blob>({
    url: `etiquetas/rastreabilidade`,
    method: 'POST',
    headers: {
      responseType: 'arraybuffer',
      dataType: 'blob',
    },
    responseType: 'blob',
    data: dadosEtiqueta,
  });
};

export const gerarEtiquetaRastreabilidadePersonalizadoAsync = (dadosEtiqueta: IEtiquetaRastreabilidade) => {
  return api.request<Blob>({
    url: `etiquetas/rastreabilidade-personalizado`,
    method: 'POST',
    headers: {
      responseType: 'arraybuffer',
      dataType: 'blob',
    },
    responseType: 'blob',
    data: dadosEtiqueta,
  });
};

export const gerarEtiquetaTesteAsync = (dadosEtiqueta: any) => {
  return api.request<Blob>({
    url: `etiquetas/etiqueta-teste`,
    method: 'POST',
    headers: {
      responseType: 'arraybuffer',
      dataType: 'blob',
    },
    responseType: 'blob',
    data: dadosEtiqueta,
  });
};
