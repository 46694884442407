import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { ProgramaCodigo } from '../../../constants/enums/programa-codigo.enum';
import { SET_LIMIT_PAGE, SET_TABLE_ORDER } from '../../../redux/table/constants';
import { DEFAULT_DOWNLOAD_SUCCESS_MESSAGE, gerarRelatorioRacing } from '../../../services/requests/relatorio';
import { montarFiltroParaApi } from '../../../utils/api-filter';
import { obterPaisesAsync } from '../requests';
import { gerarRelatorioPaisesErro, gerarRelatorioPaisesSucesso, obterPaisesErro, obterPaisesSucesso } from './actions';
import { FILTRAR_PAISES, GERAR_RELATORIO_PAISES, LISTAR_PAISES } from './constants';

function* listarTodosPaises() {
  try {
    const filtro = yield select((state) => state.Paises.filtros);
    const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.Pais]);

    let query = { filtro: montarFiltroParaApi(filtro) };

    if (preferencias) {
      query.ordenacao = {
        campo: preferencias?.ordenarPor || 'nome',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      };
      query.paginacao = {
        pagina: preferencias?.page || 1,
        limite: preferencias?.limit || 50,
      };
    }

    const { data } = yield call(obterPaisesAsync, query);
    yield put(obterPaisesSucesso(data));
  } catch (error) {
    yield put(obterPaisesErro(error.response?.data?.message || error.message));
  }
}

function* relatorioPaises() {
  const { filtros, relatorio } = yield select((state) => state.Paises);
  const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.Pais]);
  const { modelo, tipo, comFiltro, colunas } = relatorio;
  const columns = colunas?.map((coluna) => coluna.value);
  let query = {};

  if (comFiltro) {
    query = {
      filtro: {
        ...montarFiltroParaApi(filtros),
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
      ordenacao: {
        campo: preferencias?.ordenarPor || 'nome',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      },
    };
  } else {
    query = {
      filtro: {
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
    };
  }

  try {
    const { data, headers } = yield call(gerarRelatorioRacing, ProgramaCodigo.Pais, query, tipo);

    if (headers['content-type'].includes('application/json')) {
      yield put(
        gerarRelatorioPaisesSucesso({
          titulo: 'Pedido de impressão salvo.',
          mensagem: 'Seu relatório está sendo gerado.',
        }),
      );
    } else {
      const url = URL.createObjectURL(
        new Blob([data], {
          type: headers['content-type'] || 'application/pdf',
        }),
      );

      if (url) {
        yield put(gerarRelatorioPaisesSucesso(DEFAULT_DOWNLOAD_SUCCESS_MESSAGE));
        window.open(url, '_blank');
      }
    }
  } catch (e) {
    yield put(gerarRelatorioPaisesErro('Ocorreu um erro ao tentar gerar o relatório. Tente novamente mais tarde.'));
  }
}

function* ordenarPaises({ payload }) {
  if (payload.programaId === ProgramaCodigo.Pais) {
    yield call(listarTodosPaises);
  }
}

function* mudarLimitePaginacao({ payload }) {
  if (payload.programaId === ProgramaCodigo.Pais) {
    yield call(listarTodosPaises);
  }
}

export function* watchListarPaises() {
  yield takeLatest(LISTAR_PAISES, listarTodosPaises);
}

export function* watchFiltrarPaises() {
  yield takeLatest(FILTRAR_PAISES, listarTodosPaises);
}

export function* watchRelatorioPaises() {
  yield takeLatest(GERAR_RELATORIO_PAISES, relatorioPaises);
}

export function* watchOrdenarPaises() {
  yield takeLatest(SET_TABLE_ORDER, ordenarPaises);
}

export function* watchLimitePaginacao() {
  yield takeLatest(SET_LIMIT_PAGE, mudarLimitePaginacao);
}

function* paisSaga() {
  yield all([
    fork(watchListarPaises),
    fork(watchFiltrarPaises),
    fork(watchRelatorioPaises),
    fork(watchOrdenarPaises),
    fork(watchLimitePaginacao),
  ]);
}

export default paisSaga;
