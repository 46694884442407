import {
  FILTRAR_INFORMACOES_NUTRICIONAIS,
  GERAR_RELATORIO_INFORMACOES_NUTRICIONAIS,
  GERAR_RELATORIO_INFORMACOES_NUTRICIONAIS_ERRO,
  GERAR_RELATORIO_INFORMACOES_NUTRICIONAIS_SUCESSO,
  LISTAR_INFORMACOES_NUTRICIONAIS,
  LISTAR_INFORMACOES_NUTRICIONAIS_ERRO,
  LISTAR_INFORMACOES_NUTRICIONAIS_SUCESSO,
} from './constants';

export const obterTodosInformacoesNutricionais = () => ({
  type: LISTAR_INFORMACOES_NUTRICIONAIS,
});

export const obterInformacoesNutricionaisSucesso = (informacaoNutricional) => ({
  type: LISTAR_INFORMACOES_NUTRICIONAIS_SUCESSO,
  payload: informacaoNutricional,
});

export const obterInformacoesNutricionaisErro = (mensagem) => ({
  type: LISTAR_INFORMACOES_NUTRICIONAIS_ERRO,
  payload: mensagem,
});

export const filtrarInformacoesNutricionais = (filtros) => ({
  type: FILTRAR_INFORMACOES_NUTRICIONAIS,
  payload: filtros,
});

export const gerarRelatorioInformacoesNutricionais = (payload) => ({
  type: GERAR_RELATORIO_INFORMACOES_NUTRICIONAIS,
  payload: payload,
});

export const gerarRelatorioInformacoesNutricionaisSucesso = (alert) => ({
  type: GERAR_RELATORIO_INFORMACOES_NUTRICIONAIS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioInformacoesNutricionaisErro = (mensagem) => ({
  type: GERAR_RELATORIO_INFORMACOES_NUTRICIONAIS_ERRO,
  payload: mensagem,
});