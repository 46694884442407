import {
  FILTRAR_TRANSFERENCIA_DEPOSITOS,
  GERAR_RELATORIO_TRANSFERENCIA_DEPOSITOS,
  GERAR_RELATORIO_TRANSFERENCIA_DEPOSITOS_ERRO,
  GERAR_RELATORIO_TRANSFERENCIA_DEPOSITOS_SUCESSO,
  LISTAR_TRANSFERENCIA_DEPOSITOS,
  LISTAR_TRANSFERENCIA_DEPOSITOS_ERRO,
  LISTAR_TRANSFERENCIA_DEPOSITOS_SUCESSO,
} from './constants';

export const obterTodosTransferenciaDeposito = () => ({
  type: LISTAR_TRANSFERENCIA_DEPOSITOS,
});

export const obterTransferenciaDepositoSucesso = (TransferenciaDeposito) => ({
  type: LISTAR_TRANSFERENCIA_DEPOSITOS_SUCESSO,
  payload: TransferenciaDeposito,
});

export const obterTransferenciaDepositoErro = (mensagem) => ({
  type: LISTAR_TRANSFERENCIA_DEPOSITOS_ERRO,
  payload: mensagem,
});

export const filtrarTransferenciaDeposito = (filtros) => ({
  type: FILTRAR_TRANSFERENCIA_DEPOSITOS,
  payload: filtros,
});

export const gerarRelatorioTransferenciaDeposito = (payload) => ({
  type: GERAR_RELATORIO_TRANSFERENCIA_DEPOSITOS,
  payload: payload,
});

export const gerarRelatorioTransferenciaDepositoSucesso = (alert) => ({
  type: GERAR_RELATORIO_TRANSFERENCIA_DEPOSITOS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioTransferenciaDepositoErro = (mensagem) => ({
  type: GERAR_RELATORIO_TRANSFERENCIA_DEPOSITOS_ERRO,
  payload: mensagem,
});
