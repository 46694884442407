import styled from 'styled-components';

interface IContainerProps {
  carregando?: boolean;
}

export const Container = styled.div<IContainerProps>`
  h1,
  h3 {
    text-align: center;
  }

  p {
    text-align: justify;
  }

  img {
    border-radius: 5px;
  }

  height: ${(props) => (props.carregando ? '100vh' : '100%')};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: 2rem auto;

  & > div > h1 {
    margin-bottom: 2rem;
  }

  & .row {
    align-items: center;
  }
`;

export const Content = styled.div`
  min-width: 100%;
`;

export const ContainerMapa = styled.div`
  height: 20rem;

  @media (max-width: 1200px) {
    margin-top: 2rem;
  }
`;

export const ContainerTexto = styled.div`
  display: flex;
  align-items: center;

  & > h5 {
    font-size: 1rem;
    font-weight: 600;
  }

  & > span {
    margin-left: 5px;
  }
`;
