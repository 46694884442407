import api from '../../services/api';
import { AxiosResponse } from 'axios';
import { IVeiculoCadastro, IVeiculoConsulta } from './interfaces';
import { montarQueryString } from '../../utils';
import { ICentroCustoConsulta } from '../centros-custos/interfaces';

export const obterVeiculosAsync = (query): Promise<AxiosResponse<{ result: IVeiculoConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `veiculos${queryStr}`,
    method: 'GET',
  });
};

export const obterVeiculoAsync = (id: number): Promise<AxiosResponse<IVeiculoConsulta>> => {
  return api.request({
    url: `veiculos/${id}`,
    method: 'GET',
  });
};

export const obterCentroCustoVeiculoAsync = (veiculoId: number): Promise<AxiosResponse<ICentroCustoConsulta>> => {
  return api.request({
    url: `veiculos/obter-centro-custo/${veiculoId}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoVeiculoAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `veiculos/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarVeiculosAsync = (veiculo: IVeiculoCadastro): Promise<AxiosResponse<IVeiculoConsulta>> => {
  return api.request({
    url: `veiculos`,
    data: veiculo,
    method: 'POST',
  });
};

export const atualizarVeiculosAsync = (
  id: number,
  veiculo: IVeiculoCadastro,
): Promise<AxiosResponse<IVeiculoConsulta>> => {
  return api.request({
    url: `veiculos/${id}`,
    data: veiculo,
    method: 'PATCH',
  });
};

export const removerVeiculosEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `veiculos`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerVeiculosAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `veiculos/${id}`,
    method: 'DELETE',
  });
