import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { ProgramaCodigo } from '../../../constants/enums/programa-codigo.enum';
import { FILTRAR_INTEGRADORES, LISTAR_INTEGRADORES } from './constants';
import { obterIntegradoresAsync } from '../requests';
import {
  obterIntegradoresErro,
  obterIntegradoresSucesso,
} from './actions';
import { SET_LIMIT_PAGE, SET_TABLE_ORDER } from '../../../redux/table/constants';
import { montarFiltroParaApi } from '../../../utils/api-filter';

function* listarTodosIntegradores() {
  try {
    const filtro = yield select((state) => state.Integradores.filtros);
    const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.Integradores]);

    let query = { filtro: montarFiltroParaApi(filtro) };

    if (preferencias) {
      query.ordenacao = {
        campo: preferencias?.ordenarPor || 'nome',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      };
      query.paginacao = {
        pagina: preferencias?.page || 1,
        limite: preferencias?.limit || 50,
      };
    }

    const { data } = yield call(obterIntegradoresAsync, query);
    yield put(obterIntegradoresSucesso(data));
  } catch (error) {
    yield put(obterIntegradoresErro(error.response?.data?.message || error.message));
  }
}

function* ordenarIntegradores({ payload }) {
  if (payload.programaId === ProgramaCodigo.Integradores) {
    yield call(listarTodosIntegradores);
  }
}

function* mudarLimitePaginacao({ payload }) {
  if (payload.programaId === ProgramaCodigo.Integradores) {
    yield call(listarTodosIntegradores);
  }
}

export function* watchListarIntegradores() {
  yield takeLatest(LISTAR_INTEGRADORES, listarTodosIntegradores);
}

export function* watchFiltrarIntegradores() {
  yield takeLatest(FILTRAR_INTEGRADORES, listarTodosIntegradores);
}

export function* watchOrdenarIntegradores() {
  yield takeLatest(SET_TABLE_ORDER, ordenarIntegradores);
}

export function* watchLimitePaginacao() {
  yield takeLatest(SET_LIMIT_PAGE, mudarLimitePaginacao);
}

function* integradoresSaga() {
  yield all([
    fork(watchListarIntegradores),
    fork(watchFiltrarIntegradores),
    fork(watchOrdenarIntegradores),
    fork(watchLimitePaginacao),
  ]);
}

export default integradoresSaga;
