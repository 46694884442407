import api from '../../services/api';
import { AxiosResponse } from 'axios';
import { montarQueryString } from '../../utils';
import { IRegistroClimaticoCadastro, IRegistroClimaticoConsulta } from './interfaces';

export const obterRegistroClimaticoPorIdAsync = (id: number): Promise<AxiosResponse<IRegistroClimaticoConsulta>> => {
  return api.request({
    url: `registros-climaticos/${id}`,
    method: 'GET',
  });
};

export const obterRegistrosClimaticosAsync = ( query = {}) => {
  const querystr = montarQueryString(query);
  return api.request<{ result: IRegistroClimaticoConsulta[]; total: number }>({
    url: `registros-climaticos${querystr}`,
    method: 'GET',
  });
};

export const cadastrarRegistroClimaticoAsync = (registroClimatico: IRegistroClimaticoCadastro) => {
  return api.request<IRegistroClimaticoConsulta>({
    url: `registros-climaticos`,
    method: 'POST',
    data: registroClimatico,
  });
};

export const editarRegistroClimaticoAsync = (id: number, registroClimatico: IRegistroClimaticoCadastro) => {
  return api.request<IRegistroClimaticoConsulta>({
    url: `registros-climaticos/${id}`,
    method: 'PATCH',
    data: registroClimatico,
  });
};

export const obterTiposOcorrenciasAsync = () => {
  return api.request({
    url: `registros-climaticos/enum/ocorrencia_registro-climatico`,
    method: 'GET',
  });
};

export const removerRegistrosClimaticosEmMassaAsync = (ids: number[]) => {
  return api.request<{ affected: number }>({
    url: `registros-climaticos`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerRegistroClimaticoAsync = (id: number) => {
  return api.request<{ affected: number }>({
    url: `registros-climaticos/${id}`,
    method: 'DELETE',
  });
};
