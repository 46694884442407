import { GERAR_RELATORIO_CADERNO_CAMPO, GERAR_RELATORIO_CADERNO_CAMPO_ERRO, GERAR_RELATORIO_CADERNO_CAMPO_SUCESSO } from './constants';

export const gerarRelatorioCadernoCampo = (payload) => ({
  type: GERAR_RELATORIO_CADERNO_CAMPO,
  payload: payload,
});

export const gerarRelatorioCadernoCampoSucesso = (alert) => ({
  type: GERAR_RELATORIO_CADERNO_CAMPO_SUCESSO,
  payload: alert,
});

export const gerarRelatorioCadernoCampoErro = (mensagem) => ({
  type: GERAR_RELATORIO_CADERNO_CAMPO_ERRO,
  payload: mensagem,
});