import { AxiosResponse } from 'axios';

import api from '../../services/api';
import { ICidadeCadastro, ICidadeConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterCidadesAsync = (query): Promise<AxiosResponse<{ result: ICidadeConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `cidade${queryStr}`,
    method: 'GET',
  });
};

export const obterCidadeAsync = (id: number): Promise<AxiosResponse<ICidadeConsulta>> => {
  return api.request({
    url: `cidade/${id}`,
    method: 'GET',
  });
};

export const criarCidadeAsync = (cidade: ICidadeCadastro): Promise<AxiosResponse<ICidadeConsulta>> => {
  return api.request({
    url: `cidade`,
    data: cidade,
    method: 'POST',
  });
};

export const atualizarCidadeAsync = (id: number, cidade: ICidadeCadastro): Promise<AxiosResponse<ICidadeConsulta>> => {
  return api.request({
    url: `cidade/${id}`,
    data: cidade,
    method: 'PATCH',
  });
};

export const removerCidadesEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `cidade`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerCidadeAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `cidade/${id}`,
    method: 'DELETE',
  });
