import { AxiosResponse } from 'axios';

import api from '../../services/api';
import { IMarcaProdutoCadastro, IMarcaProdutoConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterMarcasProdutosAsync = (
  query
): Promise<AxiosResponse<{ result: IMarcaProdutoConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `marcas-produtos${queryStr}`,
    method: 'GET',
  });
};

export const obterMarcaProdutoAsync = (id: number): Promise<AxiosResponse<IMarcaProdutoConsulta>> => {
  return api.request({
    url: `marcas-produtos/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoMarcaProdutoAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `marcas-produtos/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarMarcaProdutoAsync = (
  marcaProduto: IMarcaProdutoCadastro
): Promise<AxiosResponse<IMarcaProdutoConsulta>> => {
  return api.request({
    url: `marcas-produtos`,
    data: marcaProduto,
    method: 'POST',
  });
};

export const atualizarMarcaProdutoAsync = (
  id: number,
  marcaProduto: IMarcaProdutoCadastro
): Promise<AxiosResponse<IMarcaProdutoConsulta>> => {
  return api.request({
    url: `marcas-produtos/${id}`,
    data: marcaProduto,
    method: 'PATCH',
  });
};

export const removerMarcasProdutosEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `marcas-produtos`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerMarcaProdutoAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `marcas-produtos/${id}`,
    method: 'DELETE',
  });
