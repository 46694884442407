import {
  FILTRAR_OBSERVACOES_ATIVIDADES,
  GERAR_RELATORIO_OBSERVACOES_ATIVIDADES,
  GERAR_RELATORIO_OBSERVACOES_ATIVIDADES_ERRO,
  GERAR_RELATORIO_OBSERVACOES_ATIVIDADES_SUCESSO,
  LISTAR_OBSERVACOES_ATIVIDADES,
  LISTAR_OBSERVACOES_ATIVIDADES_ERRO,
  LISTAR_OBSERVACOES_ATIVIDADES_SUCESSO,
} from './constants';

export const obterTodosObservacoesAtividades = () => ({
  type: LISTAR_OBSERVACOES_ATIVIDADES,
});

export const obterObservacoesAtividadesSucesso = (ObservacoesAtividades) => ({
  type: LISTAR_OBSERVACOES_ATIVIDADES_SUCESSO,
  payload: ObservacoesAtividades,
});

export const obterObservacoesAtividadesErro = (mensagem) => ({
  type: LISTAR_OBSERVACOES_ATIVIDADES_ERRO,
  payload: mensagem,
});

export const filtrarObservacoesAtividades = (filtros) => ({
  type: FILTRAR_OBSERVACOES_ATIVIDADES,
  payload: filtros,
});

export const gerarRelatorioObservacoesAtividades = (payload) => ({
  type: GERAR_RELATORIO_OBSERVACOES_ATIVIDADES,
  payload: payload,
});

export const gerarRelatorioObservacoesAtividadesSucesso = (alert) => ({
  type: GERAR_RELATORIO_OBSERVACOES_ATIVIDADES_SUCESSO,
  payload: alert,
});

export const gerarRelatorioObservacoesAtividadesErro = (mensagem) => ({
  type: GERAR_RELATORIO_OBSERVACOES_ATIVIDADES_ERRO,
  payload: mensagem,
});