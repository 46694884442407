import { IMenu } from '../../interfaces/menu';
import { PermissaoCodigo } from '../enums/permissao-codigo.enum';
import { ProgramaCodigo } from '../enums/programa-codigo.enum';
import { RoleCodigo } from '../enums/role-codigo.enum';

export const FinanceirosMenus: IMenu = {
  roles: [RoleCodigo.ClienteUser, RoleCodigo.ClienteAdmin],
  name: 'Financeiro',
  children: [
    {
      programaCod: ProgramaCodigo.CaixasBancos,
      name: 'Caixa e Bancos',
      path: '/caixa-bancos/listar',
      component: 'listarCaixasBancos',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/caixa-bancos/listar',
          component: 'listarCaixasBancos',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/caixa-bancos/criar',
          component: 'caixaBancos',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/caixa-bancos/editar/:id',
          component: 'caixaBancos',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/caixa-bancos/visualizar/:id',
          component: 'caixaBancos',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.ContasPagar,
      name: 'Contas a Pagar',
      path: '/contas-pagar/listar',
      component: 'listarContasPagar',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/contas-pagar/listar',
          component: 'listarContasPagar',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/contas-pagar/criar',
          component: 'contasPagar',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/contas-pagar/editar/:id',
          component: 'contasPagar',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/contas-pagar/visualizar/:id',
          component: 'contasPagar',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/contas-pagar/ocorrencias/:id',
          component: 'contasPagarOcorrencias',
        },
        {
          permissaoCod: PermissaoCodigo.BaixarTituloFinanceiro,
          path: '/contas-pagar/pagamentos/:id',
          component: 'contasPagarPagamentos',
        },
        {
          permissaoCod: PermissaoCodigo.BaixarTituloFinanceiro,
          path: '/contas-pagar/baixa-multipla',
          component: 'baixaMultipla',
        },
        {
          permissaoCod: PermissaoCodigo.BaixarTituloFinanceiro,
          path: '/contas-pagar/baixa-por-valor',
          component: 'baixaPorValor',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/contas-pagar/replicar/:id',
          component: 'replicacaoTitulo',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.ContasReceber,
      name: 'Contas a Receber',
      path: '/contas-receber/listar',
      component: 'listarContasReceber',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/contas-receber/listar',
          component: 'listarContasReceber',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/contas-receber/criar',
          component: 'contasReceber',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/contas-receber/editar/:id',
          component: 'contasReceber',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/contas-receber/visualizar/:id',
          component: 'contasReceber',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/contas-receber/ocorrencias/:id',
          component: 'contasReceberOcorrencias',
        },
        {
          permissaoCod: PermissaoCodigo.BaixarTituloFinanceiro,
          path: '/contas-receber/pagamentos/:id',
          component: 'contasReceberPagamentos',
        },
        {
          permissaoCod: PermissaoCodigo.BaixarTituloFinanceiro,
          path: '/contas-receber/baixa-multipla',
          component: 'baixaMultipla',
        },
        {
          permissaoCod: PermissaoCodigo.BaixarTituloFinanceiro,
          path: '/contas-receber/baixa-por-valor',
          component: 'baixaPorValor',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/contas-receber/replicar/:id',
          component: 'replicacaoTitulo',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.Contratos,
      name: 'Contratos',
      path: '/contratos/listar',
      component: 'listarContratos',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/contratos/listar',
          component: 'listarContratos',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/contratos/criar',
          component: 'contratos',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/contratos/editar/:id',
          component: 'contratos',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/contratos/visualizar/:id',
          component: 'contratos',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/contratos/ocorrencias/:id',
          component: 'contratosOcorrencias',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/contratos/situacao/:id',
          component: 'contratoSituacao',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.TransferenciasCaixaBancos,
      name: 'Transferências entre Caixa e Bancos',
      path: '/transferencias-caixa-bancos/listar',
      component: 'listarTransferenciasCaixaBancos',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/transferencias-caixa-bancos/listar',
          component: 'listarTransferenciasCaixaBancos',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/transferencias-caixa-bancos/criar',
          component: 'transferenciasCaixaBancos',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/transferencias-caixa-bancos/editar/:id',
          component: 'transferenciasCaixaBancos',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/transferencias-caixa-bancos/visualizar/:id',
          component: 'transferenciasCaixaBancos',
        },
      ],
    },
  ],
};
