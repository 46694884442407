import api from '../../services/api';
import { AxiosResponse } from 'axios';
import { IVasilhamesCadastro, IVasilhamesConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterVasilhamesAsync = (
  query,
): Promise<AxiosResponse<{ result: IVasilhamesConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `vasilhames${queryStr}`,
    method: 'GET',
  });
};

export const obterVasilhameAsync = (id: number): Promise<AxiosResponse<IVasilhamesConsulta>> => {
  return api.request({
    url: `vasilhames/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoVasilhameAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `vasilhames/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarVasilhameAsync = (vasilhame: IVasilhamesCadastro): Promise<AxiosResponse<IVasilhamesConsulta>> => {
  return api.request({
    url: `vasilhames`,
    data: vasilhame,
    method: 'POST',
  });
};

export const atualizarVasilhameAsync = (
  id: number,
  vasilhame: IVasilhamesCadastro,
): Promise<AxiosResponse<IVasilhamesConsulta>> => {
  return api.request({
    url: `vasilhames/${id}`,
    data: vasilhame,
    method: 'PATCH',
  });
};

export const removerVasilhamesEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `vasilhames`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerVasilhameAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `vasilhames/${id}`,
    method: 'DELETE',
  });
