import { GERAR_RELATORIO_CUSTOS, GERAR_RELATORIO_CUSTOS_ERRO, GERAR_RELATORIO_CUSTOS_SUCESSO } from './constants';

export const gerarRelatorioCustos = (payload) => ({
  type: GERAR_RELATORIO_CUSTOS,
  payload: payload,
});

export const gerarRelatorioCustosSucesso = (alert) => ({
  type: GERAR_RELATORIO_CUSTOS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioCustosErro = (mensagem) => ({
  type: GERAR_RELATORIO_CUSTOS_ERRO,
  payload: mensagem,
});
