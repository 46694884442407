// @flow
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILED,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILED,
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAILED,
  UPDATE_USER,
  SETAR_ORGANIZACOES_AUTORIZADAS,
  SETAR_EMPRESAS_AUTORIZADAS,
  OBTER_ORGANIZACOES_AUTORIZADAS,
  OBTER_EMPRESAS_AUTORIZADAS,
  SETAR_PROGRAMAS_PERMISSOES,
  OBTER_SEGMENTOS_AUTORIZADOS,
  SETAR_SEGMENTOS_AUTORIZADOS,
  SELECIONAR_ORGANIZACAO,
  SELECIONAR_EMPRESA,
  SELECIONAR_SEGMENTO,
  SELECIONAR_SOLUCAO_ID,
  SETAR_PARAMETROS_REDUX,
} from './constants';

import { getLoggedInUser } from '../../../helpers/authUtils';

const INIT_STATE = {
  user: getLoggedInUser(),
  programasPermissoes: [],
  organizacoesAutorizadas: [],
  empresasAutorizadas: [],
  segmentosAutorizados: [],
  solucaoId: null,
  loading: false,
  parametros: {}
};

const Auth = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true };
    case LOGIN_USER_SUCCESS:
      return { ...state, user: action.payload, loading: false, error: null };
    case LOGIN_USER_FAILED:
      return { ...state, error: action.payload, loading: false };
    case REGISTER_USER:
      return { ...state, loading: true };
    case REGISTER_USER_SUCCESS:
      return { ...state, user: action.payload, loading: false, error: null };
    case REGISTER_USER_FAILED:
      return { ...state, error: action.payload, loading: false };
    case LOGOUT_USER: {
      return { ...state, user: null, ...INIT_STATE };
    }
    case FORGET_PASSWORD:
      return { ...state, loading: true };
    case FORGET_PASSWORD_SUCCESS:
      return { ...state, passwordResetStatus: action.payload, loading: false, error: null };
    case FORGET_PASSWORD_FAILED:
      return { ...state, error: action.payload, loading: false };
    case UPDATE_USER:
      return { ...state, user: { ...state.user, ...action.payload }, loading: false };

    case OBTER_SEGMENTOS_AUTORIZADOS:
    case OBTER_ORGANIZACOES_AUTORIZADAS:
    case OBTER_EMPRESAS_AUTORIZADAS:
      return { ...state, loading: true };

    case SETAR_EMPRESAS_AUTORIZADAS:
      return { ...state, empresasAutorizadas: action.payload, loading: action.payload?.length === 1 };
    case SETAR_SEGMENTOS_AUTORIZADOS:
      return { ...state, segmentosAutorizados: action.payload, loading: action.payload?.length === 1 };
    case SETAR_ORGANIZACOES_AUTORIZADAS:
      return { ...state, organizacoesAutorizadas: action.payload, loading: action.payload?.length === 1 };

    case SELECIONAR_ORGANIZACAO:
    case SELECIONAR_EMPRESA:
    case SELECIONAR_SEGMENTO:
      return { ...state, loading: false };

    case SETAR_PROGRAMAS_PERMISSOES:
      return { ...state, programasPermissoes: action.payload };

    case SELECIONAR_SOLUCAO_ID:
      return { ...state, solucaoId: action.payload };

    case SETAR_PARAMETROS_REDUX:
      return { ...state, parametros: action.payload };

    default:
      return state;
  }
};

export default Auth;
