import React from 'react';
import { Badge } from 'reactstrap';
import { StatusRecomendacaoTecnica } from '../../../constants/enums/status-recomendacao-tecnica.enum';

const BadgeStatus: React.FC = ({ children }) => {
  switch (children) {
    case StatusRecomendacaoTecnica.Aberta:
      return <Badge color="success">Aberta</Badge>;

    case StatusRecomendacaoTecnica.ExecutadaIntegral:
      return <Badge color="info">Executada Integral</Badge>;

    case StatusRecomendacaoTecnica.ExecutadaComAlteracoes:
      return <Badge color="secondary">Executada com Alterações</Badge>;

    case StatusRecomendacaoTecnica.NaoExecutada:
      return <Badge color="danger">Não Executada</Badge>;

    default:
      return null;
  }
};

export default BadgeStatus;
