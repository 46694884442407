import { AxiosResponse } from 'axios';

import api from '../../services/api';
import { ISistemaIrrigacaoCadastro, ISistemaIrrigacaoConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterSistemasIrrigacaoAsync = (
  query
): Promise<AxiosResponse<{ result: ISistemaIrrigacaoConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `sistemas-irrigacao${queryStr}`,
    method: 'GET',
  });
};

export const obterSistemaIrrigacaoAsync = (id: number): Promise<AxiosResponse<ISistemaIrrigacaoConsulta>> => {
  return api.request({
    url: `sistemas-irrigacao/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoSistemaIrrigacaoAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `sistemas-irrigacao/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarSistemaIrrigacaoAsync = (
  sistemaIrrigacao: ISistemaIrrigacaoCadastro
): Promise<AxiosResponse<ISistemaIrrigacaoConsulta>> => {
  return api.request({
    url: `sistemas-irrigacao`,
    data: sistemaIrrigacao,
    method: 'POST',
  });
};

export const atualizarSistemaIrrigacaoAsync = (
  id: number,
  sistemaIrrigacao: ISistemaIrrigacaoCadastro
): Promise<AxiosResponse<ISistemaIrrigacaoConsulta>> => {
  return api.request({
    url: `sistemas-irrigacao/${id}`,
    data: sistemaIrrigacao,
    method: 'PATCH',
  });
};

export const removerSistemasIrrigacaoEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `sistemas-irrigacao`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerSistemaIrrigacaoAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `sistemas-irrigacao/${id}`,
    method: 'DELETE',
  });
