import api from '../../services/api';
import { montarQueryString } from '../../utils';
import { IPrevisaoColheitaCadastro, IPrevisaoColheitaConsulta } from './interfaces';

export const obterPrevisoesColheitaAsync = (query = {}) => {
  const querystr = montarQueryString(query);

  return api.request<{ result: IPrevisaoColheitaConsulta[]; total: number }>({
    url: `previsoes-colheita${querystr}`,
    method: 'GET',
  });
};

export const obterPrevisaoColheitaAsync = (id: number) => {
  return api.request<IPrevisaoColheitaConsulta>({
    url: `previsoes-colheita/${id}`,
    method: 'GET',
  });
};

export const criarPrevisaoColheitaAsync = (previsaoColheita: IPrevisaoColheitaCadastro) => {
  return api.request<IPrevisaoColheitaConsulta>({
    url: `previsoes-colheita`,
    method: 'POST',
    data: previsaoColheita,
  });
};

export const atualizarPrevisaoColheitaAsync = (id: number, previsaoColheita: IPrevisaoColheitaCadastro) => {
  return api.request<IPrevisaoColheitaConsulta>({
    url: `previsoes-colheita/${id}`,
    method: 'PATCH',
    data: previsaoColheita,
  });
};

export const removerPrevisoesColheitaEmMassaAsync = (ids: number[]) => {
  return api.request<{ affected: number }>({
    url: `previsoes-colheita`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerPrevisaoColheitaAsync = (id: number) =>
  api.request<{ affected: number }>({
    url: `previsoes-colheita/${id}`,
    method: 'DELETE',
  });
