import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InputSearchBox = styled.input`
  box-sizing: border-box;
  border: 1px solid transparent;
  width: 50%;
  height: 36px;
  padding: 0 12px;
  border-radius: 3px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  font-size: 14px;
  outline: none;
  text-overflow: ellipsis;
  position: absolute;
  left: 10px;
  bottom: 26px;
`;
