import { FILTRAR_RELATORIOS, LISTAR_RELATORIOS, LISTAR_RELATORIOS_ERRO, LISTAR_RELATORIOS_SUCESSO } from './constants';

export const obterTodosRelatorios = () => ({
  type: LISTAR_RELATORIOS,
});

export const obterRelatoriosSucesso = (relatorios) => ({
  type: LISTAR_RELATORIOS_SUCESSO,
  payload: relatorios,
});

export const obterRelatoriosErro = (mensagem) => ({
  type: LISTAR_RELATORIOS_ERRO,
  payload: mensagem,
});

export const filtrarRelatorios = (filtros, programCod) => ({
  type: FILTRAR_RELATORIOS,
  payload: { filtros: filtros, programCod: programCod },
});
