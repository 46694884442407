import api from '../../services/api';
import { AxiosResponse } from 'axios';
import { IDepositoCadastro, IDepositoConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterDepositosAsync = (
  query,
): Promise<
  AxiosResponse<{ result: IDepositoConsulta[]; total: number }>
> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `depositos${queryStr}`,
    method: 'GET',
  });
};

export const obterDepositoAsync = (
  id: number,
): Promise<AxiosResponse<IDepositoConsulta>> => {
  return api.request({
    url: `depositos/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoDepositoAsync = (): Promise<
  AxiosResponse<number>
> => {
  return api.request({
    url: `depositos/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarDepositosAsync = (
  deposito: IDepositoCadastro,
): Promise<AxiosResponse<IDepositoConsulta>> => {
  return api.request({
    url: `depositos`,
    data: deposito,
    method: 'POST',
  });
};

export const atualizarDepositosAsync = (
  id: number,
  deposito: IDepositoCadastro,
): Promise<AxiosResponse<IDepositoConsulta>> => {
  return api.request({
    url: `depositos/${id}`,
    data: deposito,
    method: 'PATCH',
  });
};

export const removerDepositosEmMassaAsync = (
  ids: number[],
): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `depositos`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerDepositosAsync = (
  id: number,
): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `depositos/${id}`,
    method: 'DELETE',
  });
