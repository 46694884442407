import Swal from 'sweetalert2';
import { filtroPadraoStatus } from '../../../constants/filtros';
import {
  FILTRAR_VARIEDADES_CLONES,
  GERAR_RELATORIO_VARIEDADES_CLONES,
  GERAR_RELATORIO_VARIEDADES_CLONES_ERRO,
  GERAR_RELATORIO_VARIEDADES_CLONES_SUCESSO,
  LISTAR_VARIEDADES_CLONES,
  LISTAR_VARIEDADES_CLONES_ERRO,
  LISTAR_VARIEDADES_CLONES_SUCESSO,
} from './constants';

const INIT_STATE = {
  variedadesClones: [],
  filtros: { ...filtroPadraoStatus },
  carregando: false,
  relatorio: {},
  gerandoRelatorio: false,
};

const VariedadesClonesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LISTAR_VARIEDADES_CLONES:
      return { ...state, carregando: true };
    case FILTRAR_VARIEDADES_CLONES:
      return { ...state, filtros: action.payload, carregando: true };
    case LISTAR_VARIEDADES_CLONES_SUCESSO:
      return { ...state, variedadesClones: action.payload, carregando: false };
    case LISTAR_VARIEDADES_CLONES_ERRO:
      Swal.fire({
        icon: 'error',
        title: 'Erro ao buscar variedades clones',
        timer: 2000,
        text: action.payload,
      });
      return { ...state, variedadesClones: [], carregando: false };
    case GERAR_RELATORIO_VARIEDADES_CLONES:
      return { ...state, filtros: action.payload.filtros, relatorio: action.payload.relatorio, gerandoRelatorio: true };
    case GERAR_RELATORIO_VARIEDADES_CLONES_SUCESSO:
      if (action.payload) {
        Swal.fire({
          icon: 'success',
          title: action.payload.titulo,
          timer: action?.payload?.timer || 2000,
          text: action.payload.mensagem,
        });
      }
      return { ...state, gerandoRelatorio: false };
    case GERAR_RELATORIO_VARIEDADES_CLONES_ERRO:
      Swal.fire({
        icon: 'error',
        title: 'Falha ao gerar Relatório',
        timer: 2000,
        text: action.payload,
      });
      return { ...state, gerandoRelatorio: false };
    default:
      return state;
  }
};

export default VariedadesClonesReducer;
