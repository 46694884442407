import {
  FILTRAR_NATUREZA_OPERACOES,
  GERAR_RELATORIO_NATUREZA_OPERACOES,
  GERAR_RELATORIO_NATUREZA_OPERACOES_ERRO,
  GERAR_RELATORIO_NATUREZA_OPERACOES_SUCESSO,
  LISTAR_NATUREZA_OPERACOES,
  LISTAR_NATUREZA_OPERACOES_ERRO,
  LISTAR_NATUREZA_OPERACOES_SUCESSO,
} from './constants';

export const obterTodosNaturezaOperacoes = () => ({
  type: LISTAR_NATUREZA_OPERACOES,
});

export const obterNaturezaOperacoesSucesso = (naturezaOperacoes) => ({
  type: LISTAR_NATUREZA_OPERACOES_SUCESSO,
  payload: naturezaOperacoes,
});

export const obterNaturezaOperacoesErro = (mensagem) => ({
  type: LISTAR_NATUREZA_OPERACOES_ERRO,
  payload: mensagem,
});

export const filtrarNaturezaOperacoes = (filtros) => ({
  type: FILTRAR_NATUREZA_OPERACOES,
  payload: filtros,
});

export const gerarRelatorioNaturezaOperacoes = (payload) => ({
  type: GERAR_RELATORIO_NATUREZA_OPERACOES,
  payload: payload,
});

export const gerarRelatorioNaturezaOperacoesSucesso = (alert) => ({
  type: GERAR_RELATORIO_NATUREZA_OPERACOES_SUCESSO,
  payload: alert,
});

export const gerarRelatorioNaturezaOperacoesErro = (mensagem) => ({
  type: GERAR_RELATORIO_NATUREZA_OPERACOES_ERRO,
  payload: mensagem,
});
