import * as FeatherIcon from 'react-feather';
import { ISubMenu } from '../../../interfaces/menu';
import { PermissaoCodigo } from '../../enums/permissao-codigo.enum';
import { ProgramaCodigo } from '../../enums/programa-codigo.enum';

export const CadastrosFiscais: ISubMenu = {
  name: 'Fiscais',
  icon: FeatherIcon.Percent,
  children: [
    {
      programaCod: ProgramaCodigo.AliquotasImpostosServicos,
      name: 'Alíquotas de Impostos de Serviços',
      path: '/aliquotas-impostos-servicos/listar',
      component: 'listarAliquotasImpostosServicos',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/aliquotas-impostos-servicos/listar',
          component: 'listarAliquotasImpostosServicos',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/aliquotas-impostos-servicos/criar',
          component: 'aliquotasImpostosServicos',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/aliquotas-impostos-servicos/editar/:id',
          component: 'aliquotasImpostosServicos',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/aliquotas-impostos-servicos/visualizar/:id',
          component: 'aliquotasImpostosServicos',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.CEST,
      name: 'CESTs',
      path: '/cest/listar',
      component: 'listarCESTs',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/cest/listar',
          component: 'listarCESTs',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/cest/criar',
          component: 'CESTs',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/cest/editar/:id',
          component: 'CESTs',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/cest/visualizar/:id',
          component: 'CESTs',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.CFOP,
      name: 'CFOPs',
      path: '/cfop/listar',
      component: 'listarCFOPs',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/cfop/listar',
          component: 'listarCFOPs',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/cfop/criar',
          component: 'CFOPs',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/cfop/editar/:id',
          component: 'CFOPs',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/cfop/visualizar/:id',
          component: 'CFOPs',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.CNAE,
      name: 'CNAEs',
      path: '/cnae/listar',
      component: 'listarCNAEs',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/cnae/listar',
          component: 'listarCNAEs',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/cnae/criar',
          component: 'CNAEs',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/cnae/editar/:id',
          component: 'CNAEs',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/cnae/visualizar/:id',
          component: 'CNAEs',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.NaturezaOperacoes,
      name: 'Naturezas da Operação',
      path: '/natureza-operacoes/listar',
      component: 'listarNaturezaOperacoes',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/natureza-operacoes/listar',
          component: 'listarNaturezaOperacoes',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/natureza-operacoes/criar',
          component: 'naturezaOperacoes',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/natureza-operacoes/editar/:id',
          component: 'naturezaOperacoes',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/natureza-operacoes/visualizar/:id',
          component: 'naturezaOperacoes',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.NCM,
      name: 'NCMs e NBSs',
      path: '/ncm/listar',
      component: 'listarNCMs',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/ncm/listar',
          component: 'listarNCMs',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/ncm/criar',
          component: 'NCMs',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/ncm/editar/:id',
          component: 'NCMs',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/ncm/visualizar/:id',
          component: 'NCMs',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.TributacoesEstaduais,
      name: 'Tributações Estaduais',
      path: '/tributacoes-estaduais/listar',
      component: 'listarTributacoesEstaduais',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/tributacoes-estaduais/listar',
          component: 'listarTributacoesEstaduais',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/tributacoes-estaduais/criar',
          component: 'tributacoesEstaduais',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/tributacoes-estaduais/editar/:id',
          component: 'tributacoesEstaduais',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/tributacoes-estaduais/visualizar/:id',
          component: 'tributacoesEstaduais',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.TributacoesFederaisIpi,
      name: 'Tributações Federais IPI',
      path: '/tributacoes-federais-ipi/listar',
      component: 'listarTributacoesFederaisIpi',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/tributacoes-federais-ipi/listar',
          component: 'listarTributacoesFederaisIpi',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/tributacoes-federais-ipi/criar',
          component: 'tributacoesFederaisIpi',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/tributacoes-federais-ipi/editar/:id',
          component: 'tributacoesFederaisIpi',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/tributacoes-federais-ipi/visualizar/:id',
          component: 'tributacoesFederaisIpi',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.TributacoesFederaisPisCofins,
      name: 'Tributações Federais PIS COFINS',
      path: '/tributacao-federal-pis-cofins/listar',
      component: 'listaTributacaoFederalPisCofins',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/tributacao-federal-pis-cofins/listar',
          component: 'listaTributacaoFederalPisCofins',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/tributacao-federal-pis-cofins/criar',
          component: 'tributacoesFederalPisCofins',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/tributacao-federal-pis-cofins/editar/:id',
          component: 'tributacoesFederalPisCofins',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/tributacao-federal-pis-cofins/visualizar/:id',
          component: 'tributacoesFederalPisCofins',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.TributacoesServicos,
      name: 'Tributações Serviços',
      path: '/tributacoes-servicos/listar',
      component: 'listarTributacoesServicos',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/tributacoes-servicos/listar',
          component: 'listarTributacoesServicos',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/tributacoes-servicos/criar',
          component: 'tributacoesServicos',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/tributacoes-servicos/editar/:id',
          component: 'tributacoesServicos',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/tributacoes-servicos/visualizar/:id',
          component: 'tributacoesServicos',
        },
      ],
    },
  ],
};
