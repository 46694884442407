import * as FeatherIcon from 'react-feather';
import { ISubMenu } from '../../../interfaces/menu';
import { PermissaoCodigo } from '../../enums/permissao-codigo.enum';
import { ProgramaCodigo } from '../../enums/programa-codigo.enum';

export const CadastrosServicos: ISubMenu = {
  name: 'Serviços',
  icon: FeatherIcon.Globe,
  children: [
    {
      programaCod: ProgramaCodigo.Servicos,
      name: 'Serviços',
      path: '/servicos/listar',
      style: {
        fontWeight: 'bold',
      },
      component: 'listarServicos',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/servicos/listar',
          component: 'listarServicos',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/servicos/criar',
          component: 'servicos',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/servicos/editar/:id',
          component: 'servicos',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/servicos/visualizar/:id',
          component: 'servicos',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/servicos/replicar/:id',
          component: 'servicos',
        },
      ],
    },
    {
      programaCod: ProgramaCodigo.GrupoServico,
      name: 'Grupos de Serviços',
      path: '/grupos-servicos/listar',
      component: 'listarGruposServico',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/grupos-servicos/listar',
          component: 'listarGruposServico',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/grupos-servicos/criar',
          component: 'gruposServico',
        },
        {
          permissaoCod: PermissaoCodigo.Alterar,
          path: '/grupos-servicos/editar/:id',
          component: 'gruposServico',
        },
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/grupos-servicos/visualizar/:id',
          component: 'gruposServico',
        },
      ],
    },
  ],
};
