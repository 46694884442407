import {
  FILTRAR_TIPOS_COBRANCAS,
  GERAR_RELATORIO_TIPOS_COBRANCAS,
  GERAR_RELATORIO_TIPOS_COBRANCAS_ERRO,
  GERAR_RELATORIO_TIPOS_COBRANCAS_SUCESSO,
  LISTAR_TIPOS_COBRANCAS,
  LISTAR_TIPOS_COBRANCAS_ERRO,
  LISTAR_TIPOS_COBRANCAS_SUCESSO,
} from './constants';

export const obterTodosTiposCobrancas = () => ({
  type: LISTAR_TIPOS_COBRANCAS,
});

export const obterTiposCobrancasSucesso = (tiposCobrancas) => ({
  type: LISTAR_TIPOS_COBRANCAS_SUCESSO,
  payload: tiposCobrancas,
});

export const obterTiposCobrancasErro = (mensagem) => ({
  type: LISTAR_TIPOS_COBRANCAS_ERRO,
  payload: mensagem,
});

export const filtrarTiposCobrancas = (filtros) => ({
  type: FILTRAR_TIPOS_COBRANCAS,
  payload: filtros,
});

export const gerarRelatorioTiposCobrancas = (payload) => ({
  type: GERAR_RELATORIO_TIPOS_COBRANCAS,
  payload: payload,
});

export const gerarRelatorioTiposCobrancasSucesso = (alert) => ({
  type: GERAR_RELATORIO_TIPOS_COBRANCAS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioTiposCobrancasErro = (mensagem) => ({
  type: GERAR_RELATORIO_TIPOS_COBRANCAS_ERRO,
  payload: mensagem,
});