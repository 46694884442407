import {
  LISTAR_TRIBUTACOES_FEDERAIS_PIS_COFINS_SUCESSO,
  LISTAR_TRIBUTACOES_FEDERAIS_PIS_COFINS_ERRO,
  LISTAR_TRIBUTACOES_FEDERAIS_PIS_COFINS,
  FILTRAR_TRIBUTACOES_FEDERAIS_PIS_COFINS,
} from './constants';

export const obterTodasTributacoesFederaisPisCofins = () => ({
  type: LISTAR_TRIBUTACOES_FEDERAIS_PIS_COFINS,
});

export const obterTodasTributacoesFederaisPisCofinsSucesso = (tributacaoFederalPisCofins) => ({
  type: LISTAR_TRIBUTACOES_FEDERAIS_PIS_COFINS_SUCESSO,
  payload: tributacaoFederalPisCofins,
});

export const obterTodasTributacoesFederaisPisCofinsErro = (mensagem) => ({
  type: LISTAR_TRIBUTACOES_FEDERAIS_PIS_COFINS_ERRO,
  payload: mensagem,
});

export const filtrarTributacoesFederaisPisCofins = (filtro) => {
  return {
    type: FILTRAR_TRIBUTACOES_FEDERAIS_PIS_COFINS,
    payload: filtro,
  };
};
