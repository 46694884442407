import {
  FILTRAR_SUB_GRUPO_PRODUTO,
  GERAR_RELATORIO_SUB_GRUPO_PRODUTO,
  GERAR_RELATORIO_SUB_GRUPO_PRODUTO_ERRO,
  GERAR_RELATORIO_SUB_GRUPO_PRODUTO_SUCESSO,
  LISTAR_SUB_GRUPO_PRODUTO,
  LISTAR_SUB_GRUPO_PRODUTO_ERRO,
  LISTAR_SUB_GRUPO_PRODUTO_SUCESSO,
} from './constants';

export const obterTodosSubGrupoProduto = () => ({
  type: LISTAR_SUB_GRUPO_PRODUTO,
});

export const obterSubGrupoProdutoSucesso = (subGrupoProduto) => ({
  type: LISTAR_SUB_GRUPO_PRODUTO_SUCESSO,
  payload: subGrupoProduto,
});

export const obterSubGrupoProdutoErro = (mensagem) => ({
  type: LISTAR_SUB_GRUPO_PRODUTO_ERRO,
  payload: mensagem,
});

export const filtrarSubGrupoProduto = (filtros) => ({
  type: FILTRAR_SUB_GRUPO_PRODUTO,
  payload: filtros,
});

export const gerarRelatorioSubGrupoProduto= (payload) => ({
  type: GERAR_RELATORIO_SUB_GRUPO_PRODUTO,
  payload: payload,
});

export const gerarRelatorioSubGrupoProdutoSucesso = (alert) => ({
  type: GERAR_RELATORIO_SUB_GRUPO_PRODUTO_SUCESSO,
  payload: alert,
});

export const gerarRelatorioSubGrupoProdutoErro = (mensagem) => ({
  type: GERAR_RELATORIO_SUB_GRUPO_PRODUTO_ERRO,
  payload: mensagem,
});
