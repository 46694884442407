import {
    FILTRAR_CULTIVOS_PROTEGIDOS,
    GERAR_RELATORIO_CULTIVOS_PROTEGIDOS,
    GERAR_RELATORIO_CULTIVOS_PROTEGIDOS_ERRO,
    GERAR_RELATORIO_CULTIVOS_PROTEGIDOS_SUCESSO,
    LISTAR_CULTIVOS_PROTEGIDOS,
    LISTAR_CULTIVOS_PROTEGIDOS_ERRO,
    LISTAR_CULTIVOS_PROTEGIDOS_SUCESSO,
  } from './constants';
  
  export const obterTodosCultivosProtegidos = () => ({
    type: LISTAR_CULTIVOS_PROTEGIDOS,
  });
  
  export const obterCultivosProtegidosSucesso = (cultivosProtegidos) => ({
    type: LISTAR_CULTIVOS_PROTEGIDOS_SUCESSO,
    payload: cultivosProtegidos,
  });
  
  export const obterCultivosProtegidosErro = (mensagem) => ({
    type: LISTAR_CULTIVOS_PROTEGIDOS_ERRO,
    payload: mensagem,
  });
  
  export const filtrarCultivosProtegidos = (filtros) => ({
    type: FILTRAR_CULTIVOS_PROTEGIDOS,
    payload: filtros,
  });

  export const gerarRelatorioCultivosProtegidos = (payload) => ({
    type: GERAR_RELATORIO_CULTIVOS_PROTEGIDOS,
    payload: payload,
  });
  
  export const gerarRelatorioCultivosProtegidosSucesso = (alert) => ({
    type: GERAR_RELATORIO_CULTIVOS_PROTEGIDOS_SUCESSO,
    payload: alert,
  });
  
  export const gerarRelatorioCultivosProtegidosErro = (mensagem) => ({
    type: GERAR_RELATORIO_CULTIVOS_PROTEGIDOS_ERRO,
    payload: mensagem,
  });
  