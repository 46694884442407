import {
  FILTRAR_GRUPOS_MAQUINAS_IMPLEMENTOS,
  GERAR_RELATORIO_GRUPOS_MAQUINAS_IMPLEMENTOS,
  GERAR_RELATORIO_GRUPOS_MAQUINAS_IMPLEMENTOS_ERRO,
  GERAR_RELATORIO_GRUPOS_MAQUINAS_IMPLEMENTOS_SUCESSO,
  LISTAR_GRUPOS_MAQUINAS_IMPLEMENTOS,
  LISTAR_GRUPOS_MAQUINAS_IMPLEMENTOS_ERRO,
  LISTAR_GRUPOS_MAQUINAS_IMPLEMENTOS_SUCESSO,
} from './constants';

export const obterTodosGruposMaquinasImplementos = () => ({
  type: LISTAR_GRUPOS_MAQUINAS_IMPLEMENTOS,
});

export const obterGruposMaquinasImplementosSucesso = (gruposMaquinasImplementos) => ({
  type: LISTAR_GRUPOS_MAQUINAS_IMPLEMENTOS_SUCESSO,
  payload: gruposMaquinasImplementos,
});

export const obterGruposMaquinasImplementosErro = (mensagem) => ({
  type: LISTAR_GRUPOS_MAQUINAS_IMPLEMENTOS_ERRO,
  payload: mensagem,
});

export const filtrarGruposMaquinasImplementos = (filtros) => ({
  type: FILTRAR_GRUPOS_MAQUINAS_IMPLEMENTOS,
  payload: filtros,
});

export const gerarRelatorioGruposMaquinasImplementos = (payload) => ({
  type: GERAR_RELATORIO_GRUPOS_MAQUINAS_IMPLEMENTOS,
  payload: payload,
});

export const gerarRelatorioGruposMaquinasImplementosSucesso = (alert) => ({
  type: GERAR_RELATORIO_GRUPOS_MAQUINAS_IMPLEMENTOS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioGruposMaquinasImplementosErro = (mensagem) => ({
  type: GERAR_RELATORIO_GRUPOS_MAQUINAS_IMPLEMENTOS_ERRO,
  payload: mensagem,
});