import { AxiosResponse } from 'axios';

import api from '../../services/api';
import { IGrupoFinanceiroCadastro, IGrupoFinanceiroConsulta } from './interfaces';
import { montarQueryString } from '../../utils';

export const obterGruposFinanceirosAsync = (
  query,
): Promise<AxiosResponse<{ result: IGrupoFinanceiroConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `grupos-financeiros${queryStr}`,
    method: 'GET',
  });
};

export const obterGrupoFinanceiroAsync = (id: number): Promise<AxiosResponse<IGrupoFinanceiroConsulta>> => {
  return api.request({
    url: `grupos-financeiros/${id}`,
    method: 'GET',
  });
};

export const gerarProximoCodigoGrupoFinanceiroAsync = (): Promise<AxiosResponse<number>> => {
  return api.request({
    url: `grupos-financeiros/gerar-proximo-codigo`,
    method: 'GET',
  });
};

export const criarGrupoFinanceiroAsync = (
  grupoFinanceiro: IGrupoFinanceiroCadastro,
): Promise<AxiosResponse<IGrupoFinanceiroConsulta>> => {
  return api.request({
    url: `grupos-financeiros`,
    data: grupoFinanceiro,
    method: 'POST',
  });
};

export const atualizarGrupoFinanceiroAsync = (
  id: number,
  grupoFinanceiro: IGrupoFinanceiroCadastro,
): Promise<AxiosResponse<IGrupoFinanceiroConsulta>> => {
  return api.request({
    url: `grupos-financeiros/${id}`,
    data: grupoFinanceiro,
    method: 'PATCH',
  });
};

export const removerGruposFinanceirosEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `grupos-financeiros`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerGrupoFinanceiroAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `grupos-financeiros/${id}`,
    method: 'DELETE',
  });
